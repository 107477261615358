var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.types.isa.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var A2=function(a,b){a=a instanceof $CLJS.M?a.T:null;switch(a){case "\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "equal-to":return $CLJS.eF("Equal to");case "default":return $CLJS.eF("Is");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "!\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-equal-to":return $CLJS.eF("Not equal to");case "excludes":return $CLJS.eF("Excludes");case "default":return $CLJS.eF("Is not");default:throw Error(["No matching clause: ",
$CLJS.p.h(b)].join(""));}case "\x3e":switch(b=b instanceof $CLJS.M?b.T:null,b){case "after":return $CLJS.eF("After");case "default":return $CLJS.eF("Greater than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c":switch(b=b instanceof $CLJS.M?b.T:null,b){case "before":return $CLJS.eF("Before");case "default":return $CLJS.eF("Less than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3e\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.eF("Greater than or equal to");
default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.eF("Less than or equal to");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "between":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.eF("Between");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "is-empty":return $CLJS.eF("Is empty");
case "default":return $CLJS.eF("Is null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-empty":return $CLJS.eF("Not empty");case "default":return $CLJS.eF("Not null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-empty":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.eF("Is empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-empty":switch(b=
b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.eF("Not empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "contains":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.eF("Contains");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "does-not-contain":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.eF("Does not contain");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}case "starts-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.eF("Starts with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "ends-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.eF("Ends with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "inside":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.eF("Inside");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},B2=function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "\x3d":return"\x3d";case "!\x3d":return"≠";case "\x3e":return"\x3e";case "\x3c":return"\x3c";case "\x3e\x3d":return"≥";case "\x3c\x3d":return"≤";default:return A2(a,b)}},Opa=new $CLJS.M(null,"after","after",594996914),Qpa=new $CLJS.M(null,"equal-to","equal-to",608296653),Rpa=new $CLJS.M(null,"excludes","excludes",-1791725945),Spa=new $CLJS.M(null,"not-equal-to","not-equal-to",
-343181655),Tpa=new $CLJS.M(null,"before","before",-1633692388);$CLJS.C2=function(){function a(d,e){return new $CLJS.h(null,3,[$CLJS.nu,$CLJS.bK,$CLJS.tG,d,$CLJS.cK,e],null)}function b(d){return c.g?c.g(d,$CLJS.mi):c.call(null,d,$CLJS.mi)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.D2=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.C2.h($CLJS.gs),$CLJS.C2.h($CLJS.QG),$CLJS.C2.h($CLJS.cs),$CLJS.C2.h($CLJS.es),$CLJS.C2.h($CLJS.bG),$CLJS.C2.h($CLJS.ds),$CLJS.C2.h($CLJS.fs),$CLJS.C2.g($CLJS.aG,$CLJS.cG),$CLJS.C2.g($CLJS.mG,$CLJS.jG)],null);$CLJS.foa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.C2.h($CLJS.gs),$CLJS.C2.h($CLJS.QG),$CLJS.C2.h($CLJS.cG),$CLJS.C2.h($CLJS.jG),$CLJS.C2.h($CLJS.zG),$CLJS.C2.h($CLJS.sG),$CLJS.C2.h($CLJS.rG),$CLJS.C2.h($CLJS.LG)],null);
$CLJS.goa=new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.C2.g($CLJS.QG,Rpa),$CLJS.C2.h($CLJS.gs),$CLJS.C2.g($CLJS.es,Tpa),$CLJS.C2.g($CLJS.cs,Opa),$CLJS.C2.h($CLJS.bG),$CLJS.C2.g($CLJS.aG,$CLJS.cG),$CLJS.C2.g($CLJS.mG,$CLJS.jG)],null);$CLJS.hoa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.C2.h($CLJS.gs),$CLJS.C2.h($CLJS.QG),$CLJS.C2.h($CLJS.hG),$CLJS.C2.h($CLJS.cs),$CLJS.C2.h($CLJS.es),$CLJS.C2.h($CLJS.bG),$CLJS.C2.h($CLJS.ds),$CLJS.C2.h($CLJS.fs)],null);
$CLJS.ioa=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.C2.g($CLJS.gs,Qpa),$CLJS.C2.g($CLJS.QG,Spa),$CLJS.C2.h($CLJS.cs),$CLJS.C2.h($CLJS.es),$CLJS.C2.h($CLJS.bG),$CLJS.C2.h($CLJS.ds),$CLJS.C2.h($CLJS.fs),$CLJS.C2.g($CLJS.aG,$CLJS.cG),$CLJS.C2.g($CLJS.mG,$CLJS.jG)],null);
$CLJS.joa=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.C2.h($CLJS.gs),$CLJS.C2.h($CLJS.QG),$CLJS.C2.h($CLJS.zG),$CLJS.C2.h($CLJS.sG),$CLJS.C2.h($CLJS.aG),$CLJS.C2.h($CLJS.mG),$CLJS.C2.h($CLJS.cG),$CLJS.C2.h($CLJS.jG),$CLJS.C2.h($CLJS.rG),$CLJS.C2.h($CLJS.LG)],null);$CLJS.koa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.C2.h($CLJS.gs),$CLJS.C2.h($CLJS.QG),$CLJS.C2.h($CLJS.aG),$CLJS.C2.h($CLJS.mG),$CLJS.C2.h($CLJS.cG),$CLJS.C2.h($CLJS.jG)],null);
$CLJS.loa=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.C2.h($CLJS.gs),$CLJS.C2.g($CLJS.aG,$CLJS.cG),$CLJS.C2.g($CLJS.mG,$CLJS.jG)],null);$CLJS.moa=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.C2.h($CLJS.gs),$CLJS.C2.h($CLJS.QG),$CLJS.C2.h($CLJS.aG),$CLJS.C2.h($CLJS.mG)],null);$CLJS.E2=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.R.j($CLJS.C2.h($CLJS.gs),$CLJS.mi,!0),$CLJS.C2.h($CLJS.cs),$CLJS.C2.h($CLJS.es),$CLJS.C2.h($CLJS.ds),$CLJS.C2.h($CLJS.fs),$CLJS.C2.h($CLJS.QG)],null);
$CLJS.O0.m(null,$CLJS.bK,function(a,b,c,d){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.tG);b=$CLJS.J.g(b,$CLJS.cK);d=d instanceof $CLJS.M?d.T:null;switch(d){case "default":return B2(a,b);case "long":return A2(a,b);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}});
$CLJS.V0.m(null,$CLJS.bK,function(a,b,c){c=$CLJS.O(c);a=$CLJS.J.g(c,$CLJS.tG);b=$CLJS.J.g(c,$CLJS.cK);c=$CLJS.J.g(c,$CLJS.mi);a=new $CLJS.h(null,3,[$CLJS.w1,$CLJS.sB(a),$CLJS.CE,B2(a,b),$CLJS.E0,A2(a,b)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.mi,!0):a});