var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.automatic_insights.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.fk_details.js");require("./metabase.lib.drill_thru.fk_filter.js");require("./metabase.lib.drill_thru.pk.js");require("./metabase.lib.drill_thru.zoom.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.malli.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.drill_thru.zoom_in_bins.js");require("./metabase.lib.drill_thru.zoom_in_geographic.js");require("./metabase.lib.drill_thru.zoom_in_timeseries.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var h8,u8,b8,rxa,i8,j8,sxa,c8,txa,uxa,m8,n8,vxa,o8,p8,yra,q8,P7,Ara,r8,s8,t8,v8,Cra,w8,Dra,Era,Fra,Gra,x8,Hra,Jra,y8,Kra,wxa,xxa,yxa,zxa,d8,Axa,Bxa,Cxa,Dxa,Exa,Fxa,Gxa,e8,Hxa,Ixa,Jxa,Kxa,Lxa,Mxa,Nxa,Oxa,Pxa,Qxa,Rxa,Sxa,Txa,Uxa,Vxa,Wxa,Xxa,k8,l8,Yxa,Zxa,$xa,z8,aya,bya,cya,dya,A8,eya,fya,gya,B8,hya,iya,jya,kya,lya;$CLJS.Uva=function(a){return $CLJS.gc($CLJS.db(function(b,c){return $CLJS.kg.j(b,c,$CLJS.J.j(b,c,0)+1)},$CLJS.ec($CLJS.N),a))};
h8=function(a){var b=$CLJS.f0(a);return $CLJS.n(b)?$CLJS.of($CLJS.U_,$CLJS.m0(a,b)):$CLJS.Ff};
u8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.hj),e=$CLJS.J.g(c,$CLJS.o6),f=$CLJS.J.g(c,$CLJS.WL),k=$CLJS.J.g(c,$CLJS.Ij);return $CLJS.n(function(){var l=$CLJS.i7(a,b);return l?(l=$CLJS.Ie(f),$CLJS.n(l)?(l=$CLJS.n(d)?null!=k:d,l=$CLJS.n(l)?l:null==d&&null==k,$CLJS.n(l)?$CLJS.Va($CLJS.n(d)?$CLJS.V_(d):d):l):l):l}())?new $CLJS.h(null,6,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.W6,$CLJS.T6,"number"!==typeof k||0>k?2:k,$CLJS.a_,function(){var l=null==a?null:$CLJS.f0(a);l=null==l?null:$CLJS.l0(a,l);
$CLJS.n(l)||(l=null==a?null:$CLJS.o3(a),l=null==l?null:$CLJS.n0(a,l));return $CLJS.n(l)?$CLJS.q0.j(a,b,l):null}(),$CLJS.WL,f,$CLJS.o6,e],null):null};
b8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.hj),e=$CLJS.J.g(c,$CLJS.o6),f=$CLJS.J.g(c,$CLJS.WL),k=$CLJS.J.g(c,$CLJS.Ij);return $CLJS.n(function(){var l=$CLJS.i7(a,b);return l?(l=$CLJS.Va(d)||null!=k)?(l=$CLJS.H7(a,$CLJS.exa),$CLJS.n(l)?$CLJS.Ie(f):l):l:l}())?new $CLJS.h(null,4,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.H6,$CLJS.o6,e,$CLJS.WL,f],null):null};
rxa=function(a,b,c,d){var e=$CLJS.D5(a,$CLJS.VW(a,b)),f=$CLJS.Sk.g($CLJS.$K.h(c),$CLJS.zL)?new $CLJS.h(null,2,[$CLJS.WF,a,$CLJS.TL,b],null):$CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.WF,a,$CLJS.TL,e],null):new $CLJS.h(null,2,[$CLJS.WF,$CLJS.G7(a),$CLJS.TL,-1],null),k=$CLJS.F3.g($CLJS.WF.h(f),$CLJS.TL.h(f));a=function(){var l=$CLJS.z2.v($CLJS.WF.h(f),$CLJS.TL.h(f),d,k);if($CLJS.n(l))return l;l=$CLJS.c3.h(c);return $CLJS.n(l)?$CLJS.QH(function(m){return $CLJS.E.g($CLJS.c3.h(m),$CLJS.c3.h(c))},k):l}();return $CLJS.R.j(f,
$CLJS.hj,a)};i8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.hj);var e=$CLJS.J.g(d,$CLJS.o6);d=$CLJS.J.g(d,$CLJS.Ij);var f=$CLJS.i7(a,b);d=f?$CLJS.n(c)?null==d&&!$CLJS.V_(c):c:f;return $CLJS.n(d)?(d=$CLJS.O_(c)?null:$CLJS.R.j($CLJS.A($CLJS.V2(c)),$CLJS.nu,$CLJS.bK),$CLJS.ll.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.C6,$CLJS.A6,d],null),rxa(a,b,c,e)]))):null};
j8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.hj);d=$CLJS.J.g(d,$CLJS.Ij);var e=$CLJS.i7(a,b);a=e?$CLJS.n(c)?null==d&&$CLJS.Sk.g($CLJS.$K.h(c),$CLJS.zL)&&!$CLJS.U_(c)&&!$CLJS.V_(c)&&!$CLJS.c0(c)&&!$CLJS.S_(c)&&$CLJS.Va($CLJS.y($CLJS.W5.j(a,b,c))):c:e;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.V6,$CLJS.hj,c],null):null};
sxa=function(a,b,c){c=$CLJS.O(c);b=$CLJS.J.g(c,$CLJS.hj);c=$CLJS.J.g(c,$CLJS.Ij);return $CLJS.T_(b)&&null!=c&&$CLJS.Sk.g(c,$CLJS.a7)?new $CLJS.h(null,5,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.U6,$CLJS.hj,b,$CLJS.p6,c,$CLJS.j6,1<$CLJS.D(h8(a))],null):null};
c8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.hj);var e=$CLJS.J.g(d,$CLJS.o6);d=$CLJS.J.g(d,$CLJS.Ij);if($CLJS.n($CLJS.n(c)?null!=d&&$CLJS.Sk.g(d,$CLJS.a7)&&$CLJS.i7(a,b)&&!$CLJS.U_(c)&&$CLJS.T_(c):c)){var f=null==a?null:$CLJS.f0(a);f=null==f?null:$CLJS.l0(a,f);$CLJS.n(f)||(f=null==a?null:$CLJS.o3(a),f=null==f?null:$CLJS.n0(a,f));return new $CLJS.h(null,5,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.F6,$CLJS.dG,$CLJS.GW(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gs,$CLJS.N,e,d],null)),$CLJS.kG,$CLJS.q0.v(a,
b,c,$CLJS.D0),$CLJS.a_,$CLJS.q0.j(a,0,f)],null)}return null};
txa=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.hj),f=$CLJS.J.g(d,$CLJS.Ij),k=$CLJS.J.g(d,$CLJS.qi);if(null!=f&&$CLJS.i7(a,b)&&1<$CLJS.D(h8(a))&&!$CLJS.T_(e)){if($CLJS.U_(e))return null!=f&&$CLJS.Sk.g(f,$CLJS.a7)?new $CLJS.h(null,3,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.N6,$CLJS.WL,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.hj,e,$CLJS.Ij,f],null)],null)],null):null;var l=h8(a);a=function(){return function u(t){return new $CLJS.qe(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var z=
x;if($CLJS.Cd(z)){var C=$CLJS.lc(z),G=$CLJS.D(C),K=$CLJS.te(G);return function(){for(var ca=0;;)if(ca<G){var ka=$CLJS.md(C,ca),wa=$CLJS.Ij.h($CLJS.QH(function(Qa,ib,Ta){return function(Db){return $CLJS.E.g($CLJS.T.h($CLJS.hj.h(Db)),$CLJS.T.h(Ta))}}(ca,v,ka,C,G,K,z,x,l,c,d,d,e,f,k),k));$CLJS.n(wa)&&$CLJS.xe(K,new $CLJS.h(null,2,[$CLJS.hj,ka,$CLJS.Ij,wa],null));ca+=1}else return!0}()?$CLJS.we($CLJS.ye(K),u($CLJS.mc(z))):$CLJS.we($CLJS.ye(K),null)}var S=$CLJS.A(z),Z=$CLJS.Ij.h($CLJS.QH(function(ca,ka){return function(wa){return $CLJS.E.g($CLJS.T.h($CLJS.hj.h(wa)),
$CLJS.T.h(ka))}}(v,S,z,x,l,c,d,d,e,f,k),k));if($CLJS.n(Z))return $CLJS.he(new $CLJS.h(null,2,[$CLJS.hj,S,$CLJS.Ij,Z],null),u($CLJS.Lc(z)));v=$CLJS.Lc(z)}else return null}},null,null)}(l)}();return $CLJS.y(a)?new $CLJS.h(null,3,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.N6,$CLJS.WL,$CLJS.Gf($CLJS.Qsa(function(m){return $CLJS.WA(m,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hj,$CLJS.tj],null))},a))],null):null}return null};
uxa=function(a,b,c){var d=$CLJS.O(c);b=$CLJS.J.g(d,$CLJS.hj);c=$CLJS.J.g(d,$CLJS.Ij);d=$CLJS.J.g(d,$CLJS.qi);if(null==c||1<$CLJS.D(h8(a)))return null;if($CLJS.U_(b))return $CLJS.E.g(c,$CLJS.a7)?null:new $CLJS.h(null,5,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.q7,$CLJS.hj,b,$CLJS.p6,c,$CLJS.j6,!1],null);a=h8(a);var e=$CLJS.I(a,0,null);a=$CLJS.Ij.h($CLJS.QH(function(f){return $CLJS.E.g($CLJS.T.h($CLJS.hj.h(f)),$CLJS.T.h(e))},d));return $CLJS.n(a)?null==a?null:new $CLJS.h(null,5,[$CLJS.nu,$CLJS.aM,$CLJS.rj,
$CLJS.q7,$CLJS.hj,e,$CLJS.p6,a,$CLJS.j6,!1],null):null};m8=function(a,b,c){return $CLJS.Ue(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)},new $CLJS.P(null,3,5,$CLJS.Q,[sxa,txa,uxa],null))};n8=function(a,b,c,d){var e=$CLJS.O(c);c=$CLJS.J.g(e,$CLJS.hj);e=$CLJS.J.g(e,$CLJS.Ij);var f=$CLJS.i7(a,b);c=f?$CLJS.n(c)?null!=e&&$CLJS.E.g($CLJS.$K.h(c),$CLJS.zL):c:f;return $CLJS.n(c)?$CLJS.of(d,$CLJS.V5.g(a,b)):null};
vxa=function(a,b){var c=$CLJS.Uva($CLJS.lf.g(function(d){d=$CLJS.p0.j(a,b,d);return $CLJS.O_(d)?$CLJS.QP:$CLJS.X_(d)?$CLJS.fxa:$CLJS.R_(d)?$CLJS.SG:null},$CLJS.T5.g(a,b)));return $CLJS.E.g(new $CLJS.h(null,1,[$CLJS.QP,1],null),c)?new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.SG,null,$CLJS.x6,null],null),null):$CLJS.E.g(new $CLJS.h(null,2,[$CLJS.QP,1,$CLJS.SG,1],null),c)?new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.SG,null,$CLJS.x6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.fxa,1],null),c)?
new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.SG,null,$CLJS.Yy,null],null),null):$CLJS.E.g($CLJS.N,c)?new $CLJS.dh(null,new $CLJS.h(null,3,[$CLJS.SG,null,$CLJS.Yy,null,$CLJS.x6,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.SG,1],null),c)?new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.SG,null,$CLJS.Yy,null],null),null):$CLJS.E.g(new $CLJS.h(null,1,[$CLJS.SG,2],null),c)?new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.SG,null,$CLJS.Yy,null],null),null):$CLJS.eh};
o8=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.hj);c=$CLJS.J.g(d,$CLJS.WL);var f=$CLJS.J.g(d,$CLJS.Ij);if($CLJS.n(function(){var m=$CLJS.i7(a,b);return m?$CLJS.n(e)?null!=f&&$CLJS.E.g($CLJS.$K.h(e),$CLJS.zL)&&0<$CLJS.D($CLJS.K4.g(a,b)):e:m}())){var k=vxa(a,b),l=$CLJS.hg.g($CLJS.N,function(){return function u(t){return new $CLJS.qe(null,function(){for(var v=t;;)if(v=$CLJS.y(v)){if($CLJS.Cd(v)){var x=$CLJS.lc(v),z=$CLJS.D(x),C=$CLJS.te(z);a:for(var G=0;;)if(G<z){var K=$CLJS.md(x,G),S=$CLJS.J.g($CLJS.gxa,
K);S=n8(a,b,d,S);$CLJS.n($CLJS.Ie(S))&&C.add(new $CLJS.P(null,2,5,$CLJS.Q,[K,S],null));G+=1}else{x=!0;break a}return x?$CLJS.we($CLJS.ye(C),u($CLJS.mc(v))):$CLJS.we($CLJS.ye(C),null)}C=$CLJS.A(v);x=$CLJS.J.g($CLJS.gxa,C);x=n8(a,b,d,x);if($CLJS.n($CLJS.Ie(x)))return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[C,x],null),u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null},null,null)}(k)}());return $CLJS.ud(l)?null:new $CLJS.h(null,4,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.d7,$CLJS.WL,c,$CLJS.t6,l],null)}return null};
p8=function(a,b){return $CLJS.GW($CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.N],null),b))};
yra=function(a,b){var c=$CLJS.HW(a);if($CLJS.V_(a))return $CLJS.Ff;if($CLJS.E.g(b,$CLJS.a7))return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.T,"\x3d",$CLJS.dG,p8($CLJS.aG,$CLJS.H([c]))],null),new $CLJS.h(null,2,[$CLJS.T,"≠",$CLJS.dG,p8($CLJS.mG,$CLJS.H([c]))],null)],null);if($CLJS.P_(a)||$CLJS.O_(a))return a=function f(e){return new $CLJS.qe(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);return function(){for(var x=0;;)if(x<
m){var z=$CLJS.md(l,x),C=$CLJS.I(z,0,null);z=$CLJS.I(z,1,null);var G=new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.cs,null,$CLJS.es,null],null),null);G=G.h?G.h(C):G.call(null,C);G=(G=$CLJS.Va(G))?G:$CLJS.ZJ(c,b);$CLJS.n(G)&&(C=new $CLJS.h(null,2,[$CLJS.T,z,$CLJS.dG,p8(C,$CLJS.H([c,b]))],null),t.add(C));x+=1}else return!0}()?$CLJS.we($CLJS.ye(t),f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);if($CLJS.n(function(){var x=new $CLJS.dh(null,new $CLJS.h(null,
2,[$CLJS.cs,null,$CLJS.es,null],null),null);x=x.h?x.h(v):x.call(null,v);return(x=$CLJS.Va(x))?x:$CLJS.ZJ(c,b)}()))return $CLJS.he(new $CLJS.h(null,2,[$CLJS.T,u,$CLJS.dG,p8(v,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)},a(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.es,"\x3c"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cs,"\x3e"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QG,"≠"],null)],null));
if($CLJS.v0(a)&&($CLJS.c0(a)||$CLJS.S_(a)))return a=function f(e){return new $CLJS.qe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.dG,p8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.we($CLJS.ye(t),f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),null)}l=$CLJS.A(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);
return $CLJS.he(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.dG,p8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)},a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zG,"contains"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sG,"does-not-contain"],null)],null));a=function f(e){return new $CLJS.qe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u),x=$CLJS.I(v,0,null);v=$CLJS.I(v,
1,null);x=new $CLJS.h(null,2,[$CLJS.T,v,$CLJS.dG,p8(x,$CLJS.H([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.we($CLJS.ye(t),f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),null)}l=$CLJS.A(k);t=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.he(new $CLJS.h(null,2,[$CLJS.T,l,$CLJS.dG,p8(t,$CLJS.H([c,b]))],null),f($CLJS.Lc(k)))}return null}},null,null)};return a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QG,"≠"],null)],
null))};q8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.hj);var e=$CLJS.J.g(d,$CLJS.o6);d=$CLJS.J.g(d,$CLJS.Ij);var f=(f=$CLJS.i7(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.V_(c)&&!$CLJS.U_(c)&&!$CLJS.T_(c):c:f;return $CLJS.n(f)?(a=rxa(a,b,c,e),$CLJS.ll.l($CLJS.H([new $CLJS.h(null,4,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.n6,$CLJS.RL,yra($CLJS.hj.h(a),d),$CLJS.Ij,d],null),a]))):null};
P7=function(a,b,c){return $CLJS.QH(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);d=$CLJS.I(d,2,null);return $CLJS.z2.v(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null))},$CLJS.M7.g(a,b))};Ara=function(a,b,c){a=P7(a,b,c);return $CLJS.n(a)?(b=$CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),b):null};
r8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.hj),e=$CLJS.J.g(c,$CLJS.o6),f=$CLJS.J.g(c,$CLJS.Ij);if($CLJS.n(function(){var l=$CLJS.i7(a,b);return l?$CLJS.n(d)?null==f&&!$CLJS.V_(d):d:l}())&&$CLJS.n($CLJS.z2.v(a,b,e,$CLJS.N7.g(a,b)))){var k=Ara(a,b,d);return new $CLJS.h(null,4,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.J6,$CLJS.hj,d,$CLJS.M6,function(){switch(k instanceof $CLJS.M?k.T:null){case "asc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qG],null);case "desc":return new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.xG],null);default:return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xG,$CLJS.qG],null)}}()],null)}return null};
s8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.hj);d=$CLJS.J.g(d,$CLJS.Ij);var e=$CLJS.i7(a,b);a=e?$CLJS.n(c)?null==d&&!$CLJS.V_(c)&&$CLJS.Sk.g($CLJS.$K.h(c),$CLJS.zL)&&$CLJS.Va($CLJS.y($CLJS.W5.j(a,b,c))):c:e;return $CLJS.n(a)?(a=$CLJS.kf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.eG],null),$CLJS.Q_(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MG,$CLJS.uG],null):null),new $CLJS.h(null,4,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.K6,$CLJS.hj,c,$CLJS.P6,a],null)):null};
t8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.hj);d=$CLJS.J.g(d,$CLJS.Ij);var e=$CLJS.i7(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.V_(c)&&$CLJS.Q_(c)&&$CLJS.Sk.g($CLJS.$K.h(c),$CLJS.zL):c:e;if($CLJS.n(d)&&(d=$CLJS.QH($CLJS.O_,$CLJS.V5.g(a,b)),$CLJS.n(d)&&(e=$CLJS.QH($CLJS.mi,$CLJS.l1.j(a,b,d)),$CLJS.n(e)))){var f=$CLJS.I3(d,e);return $CLJS.n($CLJS.ZV($CLJS.lf.g($CLJS.HW,$CLJS.he(f,$CLJS.T5.g(a,b)))))?new $CLJS.h(null,5,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.$6,$CLJS.hj,c,$CLJS.oG,d,$CLJS.zi,$CLJS.i1.h(e)],
null):null}return null};
v8=function(a,b,c){var d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.hj);d=$CLJS.J.g(d,$CLJS.Ij);if($CLJS.n($CLJS.n(c)?d:c))if(b=$CLJS.A($CLJS.W5.j(a,b,c)),$CLJS.n(b))if(b=$CLJS.l7(b),$CLJS.n(b))if(a=$CLJS.I7(a,c,d),$CLJS.n(a)){var e=$CLJS.O(a);a=$CLJS.J.g(e,$CLJS.VL);var f=$CLJS.J.g(e,$CLJS.YL);e=$CLJS.J.g(e,$CLJS.RE);var k=$CLJS.SE.h(b);k=k instanceof $CLJS.M?k.T:null;switch(k){case "num-bins":case "default":return new $CLJS.h(null,6,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.O6,$CLJS.hj,c,$CLJS.VL,d,$CLJS.YL,d+e,$CLJS.I6,
new $CLJS.h(null,1,[$CLJS.SE,$CLJS.mi],null)],null);case "bin-width":return new $CLJS.h(null,6,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.O6,$CLJS.hj,c,$CLJS.VL,a,$CLJS.YL,f,$CLJS.I6,$CLJS.Xk.j(b,$CLJS.RE,function(l){return l/10})],null);default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}else return null;else return null;else return null;else return null};
Cra=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.qi),e=$CLJS.WW(a,b);b=$CLJS.Ue(function(f){var k=$CLJS.QH($CLJS.a0,f);return $CLJS.n(k)?(f=$CLJS.QH($CLJS.b0,f),$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null):null):null},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.X0.j(a,b,e),$CLJS.Z0.j(a,b,e)],null));a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?(e=function(f){return $CLJS.Ue(function(k){var l=$CLJS.hj.h(k);l=$CLJS.n($CLJS.tj.h(f))?$CLJS.E.g($CLJS.tj.h(f),$CLJS.tj.h(l)):
$CLJS.E.g($CLJS.ZZ.h(f),$CLJS.ZZ.h(l));return $CLJS.n(l)?$CLJS.Ij.h(k):null},d)},$CLJS.R.l(c,$CLJS.Y7,a,$CLJS.H([$CLJS.Z7,b,$CLJS.qxa,e(a),$CLJS.pxa,e(b)]))):null};
w8=function(a,b){var c=$CLJS.O(a);a=$CLJS.J.g(c,$CLJS.hj);var d=$CLJS.J.g(c,$CLJS.Ij),e=$CLJS.J.g(c,$CLJS.Y7);c=$CLJS.J.g(c,$CLJS.Z7);return $CLJS.n(d)?new $CLJS.h(null,7,[$CLJS.nu,$CLJS.aM,$CLJS.rj,$CLJS.h7,$CLJS.s6,$CLJS.D6,$CLJS.hj,a,$CLJS.Ij,d,$CLJS.G6,new $CLJS.h(null,2,[$CLJS.hj,e,$CLJS.RE,b],null),$CLJS.E6,new $CLJS.h(null,2,[$CLJS.hj,c,$CLJS.RE,b],null)],null):null};Dra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.hj);return $CLJS.n(null==b?null:$CLJS.$_(b))?w8(a,10):null};
Era=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.hj);return $CLJS.n(null==b?null:$CLJS.Z_(b))?w8(a,1):null};Fra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.hj);return $CLJS.n(null==b?null:$CLJS.Y_(b))?w8(a,.1):null};
Gra=function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.Y7);var d=$CLJS.J.g(c,$CLJS.Z7),e=$CLJS.J.g(c,$CLJS.qxa);c=$CLJS.J.g(c,$CLJS.pxa);if($CLJS.n($CLJS.n(e)?c:e)){var f=$CLJS.I7(a,b,e);if($CLJS.n(f)&&(f=$CLJS.O(f),f=$CLJS.J.g(f,$CLJS.RE),a=$CLJS.I7(a,d,c),$CLJS.n(a))){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.RE);var k=20<=f&&20<=a?new $CLJS.P(null,2,5,$CLJS.Q,[10,10],null):new $CLJS.P(null,2,5,$CLJS.Q,[f/10,a/10],null),l=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return new $CLJS.h(null,5,[$CLJS.nu,$CLJS.aM,
$CLJS.rj,$CLJS.h7,$CLJS.s6,$CLJS.l6,$CLJS.G6,new $CLJS.h(null,4,[$CLJS.hj,b,$CLJS.RE,l,$CLJS.Wi,e,$CLJS.Fk,e+f],null),$CLJS.E6,new $CLJS.h(null,4,[$CLJS.hj,d,$CLJS.RE,k,$CLJS.Wi,c,$CLJS.Fk,c+a],null)],null)}}return null};x8=function(a,b,c){c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.Ij);if($CLJS.n(d)){var e=Cra(a,b,c);return $CLJS.n(e)?$CLJS.Ue(function(f){return f.h?f.h(e):f.call(null,e)},new $CLJS.P(null,4,5,$CLJS.Q,[Dra,Era,Fra,$CLJS.Ye(Gra,a)],null)):null}return null};
Hra=function(a,b,c){return $CLJS.A(function(){return function f(e){return new $CLJS.qe(null,function(){for(var k=e;;){var l=$CLJS.y(k);if(l){var m=l,t=$CLJS.A(m);if($CLJS.n(function(){var u=$CLJS.ZY(t,$CLJS.AG);return u?$CLJS.R2(t):u}())&&(l=$CLJS.y(function(u,v,x,z){return function K(G){return new $CLJS.qe(null,function(S,Z){return function(){for(var ca=G;;)if(ca=$CLJS.y(ca)){if($CLJS.Cd(ca)){var ka=$CLJS.lc(ca),wa=$CLJS.D(ka),Qa=$CLJS.te(wa);return function(){for(var xb=0;;)if(xb<wa){var kb=$CLJS.md(ka,
xb),mb=$CLJS.O(kb);kb=mb;mb=$CLJS.J.g(mb,$CLJS.hj);var Pa=$CLJS.z2.g(Z,new $CLJS.P(null,1,5,$CLJS.Q,[mb],null));mb=$CLJS.n(Pa)?$CLJS.E.g($CLJS.R2(Z),$CLJS.R2(mb)):Pa;$CLJS.n(mb)&&(kb=$CLJS.R.j(kb,$CLJS.o6,Z),Qa.add(kb));xb+=1}else return!0}()?$CLJS.we($CLJS.ye(Qa),K($CLJS.mc(ca))):$CLJS.we($CLJS.ye(Qa),null)}var ib=$CLJS.A(ca),Ta=ib=$CLJS.O(ib),Db=$CLJS.J.g(ib,$CLJS.hj);if($CLJS.n(function(){var xb=$CLJS.z2.g(Z,new $CLJS.P(null,1,5,$CLJS.Q,[Db],null));return $CLJS.n(xb)?$CLJS.E.g($CLJS.R2(Z),$CLJS.R2(Db)):
xb}()))return $CLJS.he($CLJS.R.j(Ta,$CLJS.o6,Z),K($CLJS.Lc(ca)));ca=$CLJS.Lc(ca)}else return null}}(u,v,x,z),null,null)}}(k,t,m,l)(c))))return $CLJS.kf.g(l,f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null}},null,null)}($CLJS.T5.g(a,b))}())};
Jra=function(a){a=a instanceof $CLJS.M?a.T:null;switch(a){case "quarter":return $CLJS.eF("See this year by quarter");case "month":return $CLJS.eF("See this quarter by month");case "week":return $CLJS.eF("See this month by week");case "day":return $CLJS.eF("See this week by day");case "hour":return $CLJS.eF("See this day by hour");case "minute":return $CLJS.eF("See this hour by minute");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}};
y8=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.WL);var d=(d=$CLJS.i7(a,b))?$CLJS.Ie(c):d;return $CLJS.n(d)&&(a=Hra(a,b,c),$CLJS.n(a)&&(a=$CLJS.O(a),b=$CLJS.J.g(a,$CLJS.Ij),$CLJS.n(b)))?(b=$CLJS.hj.h(a),b=$CLJS.i1.h(b),b=$CLJS.n(b)?$CLJS.Ld($CLJS.ih($CLJS.f8),b)?$CLJS.g8.h?$CLJS.g8.h(b):$CLJS.g8.call(null,b):null:null,$CLJS.n(b)?new $CLJS.h(null,5,[$CLJS.nu,$CLJS.aM,$CLJS.CE,Jra(b),$CLJS.rj,$CLJS.f7,$CLJS.DF,a,$CLJS.Z6,b],null):null):null};
Kra=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.WL);return $CLJS.Ie(function(){return function f(e){return new $CLJS.qe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u);v=$CLJS.ll.l($CLJS.H([b,v]));t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.we($CLJS.ye(t),f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),null)}t=$CLJS.A(k);return $CLJS.he($CLJS.ll.l($CLJS.H([b,t])),f($CLJS.Lc(k)))}return null}},null,
null)}(c)}())};wxa=new $CLJS.r(null,"metabase.lib.drill-thru.pivot","metabase.lib.drill-thru.pivot",109208756,null);xxa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-bins","metabase.lib.drill-thru.zoom-in-bins",477390405,null);yxa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-bins","zoom-in-binning-drill","metabase.lib.drill-thru.zoom-in-bins/zoom-in-binning-drill",-862934837,null);
zxa=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column-by-time","metabase.lib.drill-thru.summarize-column-by-time",-369504986,null);d8=new $CLJS.r(null,"stage-number","stage-number",-112198111,null);Axa=new $CLJS.r(null,"underlying-records-drill","underlying-records-drill",713740052,null);Bxa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-timeseries","zoom-in-timeseries-drill","metabase.lib.drill-thru.zoom-in-timeseries/zoom-in-timeseries-drill",313862423,null);
Cxa=new $CLJS.r(null,"zoom-in-timeseries-drill","zoom-in-timeseries-drill",-1304664195,null);Dxa=new $CLJS.r(null,"distribution-drill","distribution-drill",-898205041,null);Exa=new $CLJS.r(null,"sort-drill","sort-drill",600387798,null);Fxa=new $CLJS.M(null,"drill","drill",-1704624793);Gxa=new $CLJS.r("metabase.lib.drill-thru.quick-filter","quick-filter-drill","metabase.lib.drill-thru.quick-filter/quick-filter-drill",457872114,null);e8=new $CLJS.r(null,"_context","_context",-1238791322,null);
Hxa=new $CLJS.r(null,"metabase.lib.drill-thru.distribution","metabase.lib.drill-thru.distribution",-514507852,null);Ixa=new $CLJS.r(null,"zoom-in-binning-drill","zoom-in-binning-drill",1550598377,null);Jxa=new $CLJS.r(null,"metabase.lib.drill-thru.object-details","metabase.lib.drill-thru.object-details",1788168194,null);Kxa=new $CLJS.r(null,"automatic-insights-drill","automatic-insights-drill",-538289251,null);
Lxa=new $CLJS.r("metabase.lib.drill-thru.zoom-in-geographic","zoom-in-geographic-drill","metabase.lib.drill-thru.zoom-in-geographic/zoom-in-geographic-drill",754461586,null);Mxa=new $CLJS.r("metabase.lib.drill-thru.distribution","distribution-drill","metabase.lib.drill-thru.distribution/distribution-drill",-2103693273,null);Nxa=new $CLJS.r(null,"fk-filter-drill","fk-filter-drill",-1258190784,null);
Oxa=new $CLJS.r(null,"metabase.lib.drill-thru.summarize-column","metabase.lib.drill-thru.summarize-column",490384430,null);Pxa=new $CLJS.r(null,"pivot-drill","pivot-drill",-1794856768,null);Qxa=new $CLJS.r(null,"metabase.lib.drill-thru.quick-filter","metabase.lib.drill-thru.quick-filter",671398986,null);Rxa=new $CLJS.r(null,"metabase.lib.drill-thru.underlying-records","metabase.lib.drill-thru.underlying-records",-827008688,null);
Sxa=new $CLJS.r("metabase.lib.drill-thru.summarize-column-by-time","summarize-column-by-time-drill","metabase.lib.drill-thru.summarize-column-by-time/summarize-column-by-time-drill",391970943,null);Txa=new $CLJS.r(null,"summarize-column-drill","summarize-column-drill",-1961542613,null);Uxa=new $CLJS.r(null,"column-filter-drill","column-filter-drill",-1088669153,null);
Vxa=new $CLJS.r("metabase.lib.drill-thru.underlying-records","underlying-records-drill","metabase.lib.drill-thru.underlying-records/underlying-records-drill",1176519262,null);Wxa=new $CLJS.r(null,"metabase.lib.drill-thru.sort","metabase.lib.drill-thru.sort",961185366,null);Xxa=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-geographic","metabase.lib.drill-thru.zoom-in-geographic",-1562146002,null);k8=new $CLJS.r(null,"dimensions","dimensions",1385713430,null);
l8=new $CLJS.M(null,"return-drills-for-dimensions?","return-drills-for-dimensions?",-1759804348);Yxa=new $CLJS.r("metabase.lib.drill-thru.column-filter","column-filter-drill","metabase.lib.drill-thru.column-filter/column-filter-drill",-1130871894,null);Zxa=new $CLJS.r(null,"object-detail-drill","object-detail-drill",-613919053,null);$xa=new $CLJS.r(null,"metabase.lib.drill-thru.column-filter","metabase.lib.drill-thru.column-filter",23504270,null);
z8=new $CLJS.r(null,"context","context",810340414,null);aya=new $CLJS.r(null,"zoom-in-geographic-drill","zoom-in-geographic-drill",312914971,null);bya=new $CLJS.r("metabase.lib.drill-thru.automatic-insights","automatic-insights-drill","metabase.lib.drill-thru.automatic-insights/automatic-insights-drill",1471807986,null);cya=new $CLJS.r(null,"summarize-column-by-time-drill","summarize-column-by-time-drill",1117784387,null);
dya=new $CLJS.r("metabase.lib.drill-thru.object-details","object-detail-drill","metabase.lib.drill-thru.object-details/object-detail-drill",-1376576725,null);A8=new $CLJS.r(null,"column-ref","column-ref",-636247393,null);eya=new $CLJS.r("metabase.lib.drill-thru.pivot","pivot-drill","metabase.lib.drill-thru.pivot/pivot-drill",1004065694,null);fya=new $CLJS.r(null,"metabase.lib.drill-thru.fk-filter","metabase.lib.drill-thru.fk-filter",-1939155669,null);
gya=new $CLJS.r("metabase.lib.drill-thru.summarize-column","summarize-column-drill","metabase.lib.drill-thru.summarize-column/summarize-column-drill",-894275347,null);B8=new $CLJS.r(null,"query","query",352022017,null);hya=new $CLJS.r(null,"metabase.lib.drill-thru.zoom-in-timeseries","metabase.lib.drill-thru.zoom-in-timeseries",105846904,null);iya=new $CLJS.r(null,"metabase.lib.drill-thru.automatic-insights","metabase.lib.drill-thru.automatic-insights",912989182,null);
jya=new $CLJS.r("metabase.lib.drill-thru.fk-filter","fk-filter-drill","metabase.lib.drill-thru.fk-filter/fk-filter-drill",-716335012,null);kya=new $CLJS.r("metabase.lib.drill-thru.sort","sort-drill","metabase.lib.drill-thru.sort/sort-drill",2019212116,null);lya=new $CLJS.r(null,"quick-filter-drill","quick-filter-drill",534264366,null);var ysa;$CLJS.V0.m(null,$CLJS.aM,function(a,b,c){return $CLJS.Z5.j(a,b,c)});
ysa=new $CLJS.P(null,14,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return b8},bya,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Kwa],null)],null),iya,Kxa,"metabase/lib/drill_thru/automatic_insights.cljc",34,1,11,11,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,d8,new $CLJS.h(null,
1,[$CLJS.Tp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sA,A8,k8,$CLJS.$i],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru]\n          \n\n  Automatic insights appears:\n  - When clicking on a value with a breakout - eg. a point in a time series, a cell of a table, a bar or pie slice\n  - Or when clicking a pivot cell, with a value but no column.\n  - Or when clicking a chart legend, in which case there\'s no column or value set.\n  - There must be at least 1 breakout\n  - X-rays must be enabled (check the settings)\n\n  There are two forms: X-ray, and "Compare to the rest". This is a simple user choice and does not need extra data.',
$CLJS.n(b8)?b8.H:null])),l8,!1],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return i8},Yxa,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.y6],null)],null),$xa,Uxa,"metabase/lib/drill_thru/column_filter.cljc",29,1,82,82,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,d8,new $CLJS.h(null,
1,[$CLJS.Tp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sA,A8,$CLJS.$i],null)],null)],null)),'Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.column-filter]\n          \n\n  Filtering at the column level, based on its type. Displays a submenu of eg. "Today", "This Week", etc. for date\n  columns.\n\n  Note that if the clicked column is an aggregation, filtering by it will require a new stage. Therefore this drill\n  returns a possibly-updated `:query` and `:stage-number` along with a `:column` referencing that later stage.',
$CLJS.n(i8)?i8.H:null])),l8,!0],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return j8},Mxa,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.r6],null)],null),Hxa,Dxa,"metabase/lib/drill_thru/distribution.cljc",28,1,42,42,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,d8,new $CLJS.h(null,
1,[$CLJS.Tp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sA,$CLJS.$i],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.distribution]\n          \n\n  Select a column and see a histogram of how many rows fall into an automatic set of bins/buckets.\n  - For dates, breaks out by month by default.\n  - For numeric values, by an auto-selected set of bins\n  - For strings, by each distinct value (which might be \x3d the number of rows)",
$CLJS.n(j8)?j8.H:null])),l8,!0],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return c8},jya,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.X6],null)],null),fya,Nxa,"metabase/lib/drill_thru/fk_filter.cljc",25,1,37,37,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,d8,new $CLJS.h(null,
2,[$CLJS.Tp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sA,A8,$CLJS.$i],null),$CLJS.pt,e8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.fk-filter]\n          \n\n  When clicking on a foreign key value, filter this query by that column.\n\n  This has the same effect as the `\x3d` filter on a generic field (ie. not a key), but renders differently.\n\n  Contrast [[metabase.lib.drill-thru.object-details/object-detail-drill]], which shows the details of the foreign\n  object.",
$CLJS.n(c8)?c8.H:null])),l8,!1],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return m8},dya,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,$CLJS.m6,$CLJS.Y6,$CLJS.Q6],null)],null)],null),Jxa,Zxa,"metabase/lib/drill_thru/object_details.cljc",
29,1,10,10,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,d8,z8],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int context :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe\n           [:or\n            :metabase.lib.schema.drill-thru/drill-thru.pk\n            :metabase.lib.schema.drill-thru/drill-thru.zoom\n            :metabase.lib.schema.drill-thru/drill-thru.fk-details]]\n          \n\n  When clicking a foreign key or primary key value, drill through to the details for that specific object.\n\n  Contrast [[metabase.lib.drill-thru.fk-filter/fk-filter-drill]], which filters this query to only those rows with a\n  specific value for a FK column.",
$CLJS.n(m8)?m8.H:null])),l8,!1],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return o8},eya,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.u6],null)],null),wxa,Pxa,"metabase/lib/drill_thru/pivot.cljc",21,1,119,119,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,d8,new $CLJS.h(null,
2,[$CLJS.Tp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sA,k8,$CLJS.$i],null),$CLJS.pt,z8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column dimensions value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.pivot]\n          \n\n  Return all possible pivoting options on the given column and value.\n\n  See `:pivots` key, which holds a map `{t [breakouts...]}` where `t` is `:category`, `:location`, or `:time`.\n  If a key is missing, there are no breakouts of that kind.",
$CLJS.n(o8)?o8.H:null])),l8,!1],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return q8},Gxa,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.R6],null)],null),Qxa,lya,"metabase/lib/drill_thru/quick_filter.cljc",28,1,93,93,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,d8,new $CLJS.h(null,
2,[$CLJS.Tp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sA,A8,$CLJS.$i],null),$CLJS.pt,e8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.quick-filter]\n          \n\n  Filter the current query based on the value clicked.\n\n  The options vary depending on the type of the field:\n  - `:is-null` and `:not-null` for a `NULL` value;\n  - `:\x3d` and `:!\x3d` for everything else;\n  - plus `:\x3c` and `:\x3e` for numeric and date columns.\n\n  Note that this returns a single `::drill-thru` value with 1 or more `:operators`; these are rendered as a set of small\n  buttons in a single row of the drop-down.",
$CLJS.n(q8)?q8.H:null])),l8,!1],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return r8},kya,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.j7],null)],null),Wxa,Exa,"metabase/lib/drill_thru/sort.cljc",20,1,51,51,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,d8,new $CLJS.h(null,
2,[$CLJS.Tp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sA,A8,$CLJS.$i],null),$CLJS.pt,e8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.sort]\n          \n\n  Sorting on a clicked column.",$CLJS.n(r8)?r8.H:null])),l8,!0],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return s8},gya,$CLJS.Lg([$CLJS.ul,$CLJS.aj,
$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.B6],null)],null),Oxa,Txa,"metabase/lib/drill_thru/summarize_column.cljc",32,1,31,31,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,d8,new $CLJS.h(null,1,[$CLJS.Tp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sA,$CLJS.$i],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column]\n          \n\n  A set of possible aggregations that can summarize this column: distinct values, sum, average.\n  Separate from [[summarize-column-by-time-drill]] which breaks out a column over time.",
$CLJS.n(s8)?s8.H:null])),l8,!0],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return t8},Sxa,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.z6],null)],null),zxa,cya,"metabase/lib/drill_thru/summarize_column_by_time.cljc",40,1,40,40,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,
[B8,d8,new $CLJS.h(null,1,[$CLJS.Tp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sA,$CLJS.$i],null)],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value]} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time]\n          \n\n  A breakout summarizing a column over time.\n  Separate from single-value [[summarize-column-drill]] for sum, average, and distinct value count.",$CLJS.n(t8)?
t8.H:null])),l8,!0],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return u8},Vxa,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.g7],null)],null),Rxa,Axa,"metabase/lib/drill_thru/underlying_records.cljc",34,1,51,51,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,d8,new $CLJS.h(null,
2,[$CLJS.Tp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sA,A8,k8,$CLJS.$i],null),$CLJS.pt,e8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column column-ref dimensions value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.underlying-records]\n          \n\n  When clicking on a particular broken-out group, offer a look at the details of all the rows that went into this\n  bucket. Eg. distribution of People by State, then click New York and see the table of all People filtered by\n  `STATE \x3d 'New York'`.\n\n  There is another quite different case: clicking the legend of a chart with multiple bars or lines broken out by\n  category. Then `column` is nil!",
$CLJS.n(u8)?u8.H:null])),l8,!1],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return y8},Bxa,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.b7],null)],null),hya,Cxa,"metabase/lib/drill_thru/zoom_in_timeseries.cljc",34,1,89,89,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,d8,
new $CLJS.h(null,2,[$CLJS.Tp,new $CLJS.P(null,1,5,$CLJS.Q,[k8],null),$CLJS.pt,e8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [dimensions], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries]\n          \n\n  Zooms in on some window, showing it in finer detail.\n\n  For example: The month of a year, days or weeks of a quarter, smaller lat/long regions, etc.\n\n  This is different from the `:drill-thru/zoom` type, which is for showing the details of a single object.",
$CLJS.n(y8)?y8.H:null])),l8,!1],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return x8},Lxa,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.c7],null)],null),Xxa,aya,"metabase/lib/drill_thru/zoom_in_geographic.cljc",34,1,189,189,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,
d8,new $CLJS.h(null,2,[$CLJS.Tp,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$i],null),$CLJS.pt,z8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [value], :as context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.geographic]\n          \n\n  Return a `:drill-thru/zoom-in.geographic` drill if appropriate. See docstring\n  for [[metabase.lib.drill-thru.zoom-in-geographic]] for more information on what circumstances this is returned in\n  and what it means to apply this drill.",
$CLJS.n(x8)?x8.H:null])),l8,!0],null),new $CLJS.h(null,2,[$CLJS.mt,new $CLJS.Gc(function(){return v8},yxa,$CLJS.Lg([$CLJS.ul,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dp,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ZL,$CLJS.Ki,$CLJS.w6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.e7],null)],null),xxa,Ixa,"metabase/lib/drill_thru/zoom_in_bins.cljc",31,1,86,86,$CLJS.V(new $CLJS.P(null,3,5,$CLJS.Q,[B8,d8,new $CLJS.h(null,
2,[$CLJS.Tp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sA,$CLJS.$i],null),$CLJS.pt,e8],null)],null)),"Inputs: [query :- :metabase.lib.schema/query stage-number :- :int {:keys [column value], :as _context} :- :metabase.lib.schema.drill-thru/context]\n  Return: [:maybe :metabase.lib.schema.drill-thru/drill-thru.zoom-in.binning]\n          \n\n  Return a drill thru that 'zooms in' on a breakout that uses `:binning` if applicable.\n  See [[metabase.lib.drill-thru.zoom-in-bins]] docstring for more information.",
$CLJS.n(v8)?v8.H:null])),l8,!0],null)],null);
$CLJS.zsa=function(){function a(d,e,f){try{return $CLJS.hg.g($CLJS.Ff,$CLJS.Apa(d)?function(){var l=Kra(f);return function u(t){return new $CLJS.qe(null,function(){for(var v=t;;){var x=$CLJS.y(v);if(x){var z=x,C=$CLJS.A(z),G=$CLJS.O(C),K=$CLJS.J.g(G,$CLJS.mt),S=$CLJS.J.g(G,l8);if(x=$CLJS.y(function(Z,ca,ka,wa,Qa,ib,Ta,Db){return function mb(kb){return new $CLJS.qe(null,function(Pa,Wc,Ok,Vd){return function(){for(var ad=kb;;)if(ad=$CLJS.y(ad)){if($CLJS.Cd(ad)){var wd=$CLJS.lc(ad),Ed=$CLJS.D(wd),Kd=
$CLJS.te(Ed);a:for(var Fi=0;;)if(Fi<Ed){var dk=$CLJS.md(wd,Fi);dk=Vd.j?Vd.j(d,e,dk):Vd.call(null,d,e,dk);$CLJS.n(dk)&&Kd.add(dk);Fi+=1}else{wd=!0;break a}return wd?$CLJS.we($CLJS.ye(Kd),mb($CLJS.mc(ad))):$CLJS.we($CLJS.ye(Kd),null)}Kd=$CLJS.A(ad);Kd=Vd.j?Vd.j(d,e,Kd):Vd.call(null,d,e,Kd);if($CLJS.n(Kd))return $CLJS.he(Kd,mb($CLJS.Lc(ad)));ad=$CLJS.Lc(ad)}else return null}}(Z,ca,ka,wa,Qa,ib,Ta,Db),null,null)}}(v,C,G,K,S,z,x,l)($CLJS.n(function(){var Z=S;return $CLJS.n(Z)?l:Z}())?l:new $CLJS.P(null,
1,5,$CLJS.Q,[f],null))))return $CLJS.kf.g(x,u($CLJS.Lc(v)));v=$CLJS.Lc(v)}else return null}},null,null)}(ysa)}():null)}catch(l){var k=l;throw $CLJS.ki(["Error getting available drill thrus for query: ",$CLJS.p.h($CLJS.JV(k))].join(""),new $CLJS.h(null,3,[$CLJS.WF,d,$CLJS.TL,e,$CLJS.DN,f],null),k);}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Asa=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,k,l,m){var t=null;if(3<arguments.length){t=0;for(var u=Array(arguments.length-3);t<u.length;)u[t]=arguments[t+3],++t;t=new $CLJS.w(u,0,null)}return e.call(this,f,k,l,t)}function e(f,k,l,m){var t=$CLJS.eB($CLJS.Uy);$CLJS.n($CLJS.dB("metabase.lib.drill-thru",t))&&$CLJS.cB("metabase.lib.drill-thru",t,$CLJS.LW("Applying drill thru: %s",$CLJS.H([$CLJS.MW(new $CLJS.h(null,4,[$CLJS.WF,f,$CLJS.TL,
k,Fxa,l,$CLJS.dt,m],null))])),null);return $CLJS.Ve.N($CLJS.Y5,f,k,l,m)}d.A=3;d.B=function(f){var k=$CLJS.A(f);f=$CLJS.B(f);var l=$CLJS.A(f);f=$CLJS.B(f);var m=$CLJS.A(f);f=$CLJS.Lc(f);return e(k,l,m,f)};d.l=e;return d}();b=function(d,e,f,k){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var m=Array(arguments.length-3);l<m.length;)m[l]=arguments[l+3],++l;l=new $CLJS.w(m,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);
};b.A=3;b.B=c.B;b.g=a;b.l=c.l;return b}();