var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var Gu,Iu,Ju,Ku,Lu,Mu,Nu,Uu,Vu,Wu,Xu,Yu,pba,av,qba,tu,ev,gv,hv,iv,jv,kv,lv,qv,tv,vv,yv,rba,sba,Dv,tba,Fv,Gv,Hv,Nv,Iv,Wv,$v,fw,hw,mw,qw,vw,xw,yw,sw,Aw,Bw,Dw,Cw,Ew,xba,Jw,Sw,Tw,Mw,zba,Xw,$w,bx,cx,Bba,Cba,Dba,gx,kx,lx,mx,nx,Eba,sx,vx,Fba,Gba,Hba,Iba,Jba,Kba,Lba,Mba,Nba,Pba,Ax,Oba,zx,Qba,Bx,Rba,Sba,Cx,Tba,Dx,Uba,Vba,Wba,Yba,Zba,aca,bca,dca,fca,Mx,Px,hca,gca,ica,rw,uw,Wx,Xx,Yx,Zx,by,cy,lca,mca,nca,fy,oca,gy,ey,hy,cv,Tx,wv,px,hda,Vv,ida,kda,dv,sv,Qx,lda,Rv,pw,xv,mda,nda,Mv,xda,Ix,Cv,ow,Kv,
qx,yda,uv,zda,yx,Gx,Ux,Ada,Ow,ay,Qw,Ov,nw,bv,Fx,eda,Uv,Jv,mv,Bda,kw,Cda,Gw,Pw,Lx,Dda,Nx,cda,Hda,Lda,Pda,Zv,Uda,wx,Yv,Xda,Hw,Fw,Yda,nv,Zda,gda,Ox,zv,jy,dx,ky,ly,Iw,$da,Bv,aea,fda,bea,jw,ux,lw,dea,ex,Rx,hea,tx,iea,zw,rv,jea,Tv,oy,Uw,kea,fv,Ex,Xv,bda,Hx,ov,fx,Jx,ox,hx,jx,xx,Nw,py,qy,lea,uu,mea,qu,Fu,nea,Av,oea;Gu=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};$CLJS.Hu=function(){};
Iu=function(a){if(null!=a&&null!=a.rc)a=a.rc(a);else{var b=Iu[$CLJS.xa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=Iu._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.ab("IWriter.-flush",a);}return a};Ju=function(){};Ku=function(a){if(null!=a&&null!=a.Lc)a=a.Lc(a);else{var b=Ku[$CLJS.xa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=Ku._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.ab("IPending.-realized?",a);}return a};
Lu=function(a){if("number"===typeof a)return String.fromCharCode(a);if("string"===typeof a&&1===a.length)return a;throw Error("Argument to char must be a character or number");};Mu=function(a,b){return a-b*$CLJS.Zd(a,b)};Nu=function(a,b,c){if(null!=a&&null!=a.m)a.m(a,b,c);else{var d=Nu[$CLJS.xa(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=Nu._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.ab("IMultiFn.-add-method",a);}};
$CLJS.Ou=function(a,b){var c=$CLJS.ec($CLJS.N);a=$CLJS.y(a);for(b=$CLJS.y(b);;)if(a&&b)c=$CLJS.kg.j(c,$CLJS.A(a),$CLJS.A(b)),a=$CLJS.B(a),b=$CLJS.B(b);else return $CLJS.gc(c)};$CLJS.Pu=function(a,b){return $CLJS.oh(a,b)};$CLJS.Qu=function(a,b){return new $CLJS.qe(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.A(c);d=a.h?a.h(d):a.call(null,d);c=$CLJS.n(d)?$CLJS.he($CLJS.A(c),$CLJS.Qu(a,$CLJS.Lc(c))):null}else c=null;return c},null,null)};
$CLJS.Ru=function(a,b){return $CLJS.lf.j(function(c){return c},b,$CLJS.ef(a,b))};Uu=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.Ve.g(Su,0<b.length?new $CLJS.w(b.slice(0),0,null):null);$CLJS.bc($CLJS.Tu,"\n")};Vu=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
Wu=function(a,b,c){var d=c;for(c=$CLJS.Ff;;){if($CLJS.ud(d))return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null);var e=$CLJS.A(d);d=$CLJS.B(d);e=$CLJS.Ve.g(a,new $CLJS.P(null,2,5,$CLJS.Q,[e,b],null));b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);c=$CLJS.ee.g(c,b);b=e}};Xu=function(a,b){var c=b;for(b=$CLJS.Ff;;){var d=$CLJS.Ve.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[c],null));c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.Va(c))return new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null);b=$CLJS.ee.g(b,c);c=d}};
Yu=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hg.g($CLJS.N,function(){return function d(c){return new $CLJS.qe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.te(k);a:for(var m=0;;)if(m<k){var t=$CLJS.md(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);var v=$CLJS.I(t,0,null);$CLJS.I(t,1,null);l.add(new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null));m+=1}else{f=!0;break a}return f?$CLJS.we($CLJS.ye(l),d($CLJS.mc(e))):$CLJS.we($CLJS.ye(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);k=$CLJS.I(f,0,null);$CLJS.I(f,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()),$CLJS.hg.g($CLJS.N,function(){return function d(c){return new $CLJS.qe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.te(k);a:for(var m=0;;)if(m<k){var t=$CLJS.md(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);l.add(new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null));m+=1}else{f=!0;break a}return f?$CLJS.we($CLJS.ye(l),d($CLJS.mc(e))):$CLJS.we($CLJS.ye(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[l,f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())],null)};
pba=function(a,b){return $CLJS.hg.g($CLJS.N,function(){return function e(d){return new $CLJS.qe(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);a:for(var t=0;;)if(t<l){var u=$CLJS.md(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,new $CLJS.P(null,2,5,$CLJS.Q,[u,b],null)],null));t+=1}else{k=!0;break a}return k?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);
k=$CLJS.I(k,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[m,new $CLJS.P(null,2,5,$CLJS.Q,[k,b],null)],null),e($CLJS.Lc(f)))}return null}},null,null)}(a)}())};$CLJS.Zu=function(){var a=$CLJS.Tu;if(null!=a&&null!=a.uf)a.uf(a);else{var b=$CLJS.Zu[$CLJS.xa(null==a?null:a)];if(null!=b)b.h?b.h(a):b.call(null,a);else if(b=$CLJS.Zu._,null!=b)b.h?b.h(a):b.call(null,a);else throw $CLJS.ab("IPrettyFlush.-ppflush",a);}};$CLJS.$u=function(a,b){a=$CLJS.q($CLJS.q(a));return b.h?b.h(a):b.call(null,a)};
av=function(a,b,c){$CLJS.Rh.v($CLJS.q(a),$CLJS.R,b,c)};qba=function(a,b){$CLJS.E.g(b,"\n")?(av(a,$CLJS.pu,0),av(a,$CLJS.Ej,$CLJS.$u(a,$CLJS.Ej)+1)):av(a,$CLJS.pu,$CLJS.$u(a,$CLJS.pu)+1);return $CLJS.bc($CLJS.$u(a,qu),b)};tu=function(a,b,c,d){this.Ca=a;this.Nf=b;this.Yc=c;this.Dh=d;this.C=1074167808;this.I=0};
ev=function(a,b,c,d,e,f,k,l,m,t,u,v,x){this.parent=a;this.Cb=b;this.tb=c;this.sb=d;this.vb=e;this.wb=f;this.prefix=k;this.Ab=l;this.Db=m;this.xb=t;this.S=u;this.G=v;this.J=x;this.C=2230716170;this.I=139264};gv=function(a,b){for(b=uu.h(b);;){if(null==b)return!1;if(a===b)return!0;b=uu.h(b)}};hv=function(a,b,c,d,e,f,k,l){this.ca=a;this.data=b;this.ac=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};
iv=function(a,b,c,d,e,f,k,l){this.ca=a;this.type=b;this.ea=c;this.ba=d;this.aa=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};jv=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};kv=function(a,b,c,d,e,f,k){this.ca=a;this.ea=b;this.ba=c;this.aa=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
lv=function(a,b,c,d,e,f,k,l,m){this.ca=a;this.ea=b;this.Wb=c;this.offset=d;this.ba=e;this.aa=f;this.S=k;this.G=l;this.J=m;this.C=2230716170;this.I=139264};
qv=function(a,b,c){b=$CLJS.y(b);for(var d=null,e=0,f=0;;)if(f<e){var k=d.X(null,f);if(!$CLJS.E.g(Fu.h(k),bv)){var l=cv.h($CLJS.q($CLJS.q(a)));$CLJS.n(l)&&$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),l)}pv.g(a,k);$CLJS.Rh.v($CLJS.q(a),$CLJS.R,cv,cv.h(k));k=cv.h($CLJS.q($CLJS.q(a)));$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),k),$CLJS.Rh.v($CLJS.q(a),$CLJS.R,cv,null));f+=1}else if(b=$CLJS.y(b))$CLJS.Cd(b)?(d=$CLJS.lc(b),b=$CLJS.mc(b),k=d,e=$CLJS.D(d),d=k):(k=$CLJS.A(b),$CLJS.E.g(Fu.h(k),bv)||
(d=cv.h($CLJS.q($CLJS.q(a))),$CLJS.n(d)&&$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),d)),pv.g(a,k),$CLJS.Rh.v($CLJS.q(a),$CLJS.R,cv,cv.h(k)),k=cv.h($CLJS.q($CLJS.q(a))),$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),k),$CLJS.Rh.v($CLJS.q(a),$CLJS.R,cv,null)),b=$CLJS.B(b),d=null,e=0),f=0;else break};tv=function(a,b){var c=$CLJS.$u(qu.h($CLJS.q($CLJS.q(a))),$CLJS.Fk),d;(d=null==c)||(a=$CLJS.$u(qu.h($CLJS.q($CLJS.q(a))),$CLJS.pu),b=(b=$CLJS.y(b))?dv.h($CLJS.kd(b))-fv.h($CLJS.A(b)):0,d=a+b<c);return d};
vv=function(a,b,c){b=$CLJS.q(mv.h(b));return $CLJS.n(b)?b:!tv(a,c)};yv=function(a,b,c){var d=nv.h($CLJS.q($CLJS.q(a))),e=$CLJS.$u(qu.h($CLJS.q($CLJS.q(a))),$CLJS.Fk);return $CLJS.n(d)?$CLJS.n(e)?(d=$CLJS.q(ov.h(b))>=e-d)?vv(a,b,c):d:e:d};rba=function(a){var b=$CLJS.A(a),c=rv.h(b);b=$CLJS.y($CLJS.Qu(function(d){return!($CLJS.E.g(Fu.h(d),bv)&&gv(rv.h(d),c))},$CLJS.B(a)));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.ef($CLJS.D(b)+1,a))],null)};
sba=function(a){var b=$CLJS.A(a),c=rv.h(b);return $CLJS.y($CLJS.Qu(function(d){var e=rv.h(d);return!($CLJS.E.g(Fu.h(d),bv)&&($CLJS.E.g(e,c)||gv(e,c)))},$CLJS.B(a)))};
Dv=function(a,b){$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.Rh.v($CLJS.q(a),$CLJS.R,cv,null);b=rv.h(b);var c=sv.h(b);$CLJS.n(c)&&$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),c);c=$CLJS.Ve.g($CLJS.p,$CLJS.gf($CLJS.q(uv.h(b))-$CLJS.D(c)," "));$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),c);a:for($CLJS.af(wv.h(b),!0),$CLJS.af(mv.h(b),!0),a=uu.h(b);;)if($CLJS.n(a))$CLJS.af(mv.h(a),!0),$CLJS.af(wv.h(a),!0),a=uu.h(a);else break a;return null};
tba=function(a){var b=$CLJS.y($CLJS.Qu(function(c){return!$CLJS.E.g(Fu.h(c),bv)},a));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.y($CLJS.ef($CLJS.D(b),a))],null)};Fv=function(a){for(var b=$CLJS.vt.h($CLJS.q($CLJS.q(a)));;){$CLJS.Rh.v($CLJS.q(a),$CLJS.R,$CLJS.vt,$CLJS.hg.g($CLJS.Ff,b));if(tv(a,b))return null;var c=Ev(a,b);if(b!==c)b=c;else return null}};
Gv=function(a,b){$CLJS.Rh.v($CLJS.q(a),$CLJS.R,$CLJS.vt,$CLJS.ee.g($CLJS.vt.h($CLJS.q($CLJS.q(a))),b));return tv(a,$CLJS.vt.h($CLJS.q($CLJS.q(a))))?null:Fv(a)};Hv=function(a){var b=cv.h($CLJS.q($CLJS.q(a)));return $CLJS.n(b)?($CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),b),$CLJS.Rh.v($CLJS.q(a),$CLJS.R,cv,null)):null};
Nv=function(a,b){var c=$CLJS.Yt(b,"\n",-1);if($CLJS.E.g($CLJS.D(c),1))return b;b=sv.h($CLJS.A(xv.h($CLJS.q($CLJS.q(a)))));var d=$CLJS.A(c);if($CLJS.E.g(zv,$CLJS.Ci.h($CLJS.q($CLJS.q(a))))){var e=Av.h($CLJS.q($CLJS.q(a))),f=e+$CLJS.D(d);$CLJS.Rh.v($CLJS.q(a),$CLJS.R,Av,f);Gv(a,new hv(Bv,d,null,e,f,null,null,null));Fv(a);d=$CLJS.vt.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(qv(a,d,!0),$CLJS.Rh.v($CLJS.q(a),$CLJS.R,$CLJS.vt,$CLJS.Ff))}else Hv(a),$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),d);$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),
"\n");d=$CLJS.y($CLJS.B($CLJS.Ut(c)));e=null;for(var k=f=0;;)if(k<f){var l=e.X(null,k);$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),l);$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.n(b)&&$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),b);k+=1}else if(d=$CLJS.y(d))e=d,$CLJS.Cd(e)?(d=$CLJS.lc(e),k=$CLJS.mc(e),e=d,f=$CLJS.D(d),d=k):(d=$CLJS.A(e),$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),d),$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),"\n"),$CLJS.n(b)&&$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),b),d=$CLJS.B(e),e=null,f=0),k=0;else break;$CLJS.Rh.v($CLJS.q(a),
$CLJS.R,zv,Cv);return $CLJS.kd(c)};Iv=function(a,b,c,d,e,f){this.Ca=a;this.Nf=b;this.li=c;this.mh=d;this.Yc=e;this.Eh=f;this.C=1074167808;this.I=0};
$CLJS.Sv=function(a){var b=$CLJS.Pv,c=Qv,d=new ev(null,null,$CLJS.$e(0),$CLJS.$e(0),$CLJS.$e(!1),$CLJS.$e(!1),null,null,null,null,null,null,null),e=$CLJS.$e($CLJS.Lg([xv,nv,bda,Jv,cda,$CLJS.Ci,Av,cv,qu,eda,$CLJS.vt],[d,c,d,!0,null,Cv,0,null,new tu(a,b,$CLJS.$e(new $CLJS.h(null,4,[$CLJS.Fk,b,$CLJS.pu,0,$CLJS.Ej,0,qu,a],null)),$CLJS.N),1,$CLJS.Ff]));return new Iv(a,b,c,d,e,$CLJS.N)};
Wv=function(a,b){var c=$CLJS.Tu;b=new ev(xv.h($CLJS.q($CLJS.q(c))),null,$CLJS.$e(0),$CLJS.$e(0),$CLJS.$e(!1),$CLJS.$e(!1),a,null,b,null,null,null,null);$CLJS.Rh.v($CLJS.q(c),$CLJS.R,xv,b);if($CLJS.E.g($CLJS.Ci.h($CLJS.q($CLJS.q(c))),Cv)){Hv(c);var d=Kv.h($CLJS.q($CLJS.q(c)));$CLJS.n(d)&&(d.h?d.h($CLJS.Lv):d.call(null,$CLJS.Lv));$CLJS.n(a)&&$CLJS.bc(qu.h($CLJS.q($CLJS.q(c))),a);c=$CLJS.$u(qu.h($CLJS.q($CLJS.q(c))),$CLJS.pu);$CLJS.af(b.tb,c);$CLJS.af(b.sb,c)}else d=Av.h($CLJS.q($CLJS.q(c))),a=d+($CLJS.n(a)?
$CLJS.D(a):0),$CLJS.Rh.v($CLJS.q(c),$CLJS.R,Av,a),Gv(c,new jv(Mv,b,d,a,null,null,null))};
$v=function(){var a=$CLJS.Tu,b=xv.h($CLJS.q($CLJS.q(a))),c=Ov.h(b);if($CLJS.E.g($CLJS.Ci.h($CLJS.q($CLJS.q(a))),Cv)){Hv(a);$CLJS.n(c)&&$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),c);var d=Kv.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(d.h?d.h(Rv):d.call(null,Rv))}else d=Av.h($CLJS.q($CLJS.q(a))),c=d+($CLJS.n(c)?$CLJS.D(c):0),$CLJS.Rh.v($CLJS.q(a),$CLJS.R,Av,c),Gv(a,new kv(Tv,b,d,c,null,null,null));$CLJS.Rh.v($CLJS.q(a),$CLJS.R,xv,uu.h(b))};
$CLJS.aw=function(a){var b=null!=a?a.C&32768||$CLJS.Bc===a.ag?!0:a.C?!1:$CLJS.Za($CLJS.Hu,a):$CLJS.Za($CLJS.Hu,a);return b?Jv.h($CLJS.q($CLJS.q(a))):b};$CLJS.ew=function(a){var b=bw;$CLJS.n(b)&&(b=$CLJS.Ma,b=$CLJS.n(b)?bw>=$CLJS.Ma:b);$CLJS.Va($CLJS.cw)?Su.call(null,a):$CLJS.n(b)?$CLJS.bc($CLJS.Tu,"..."):($CLJS.n(bw)&&(bw+=1),dw.call(null,a));return b};
fw=function(a,b){if($CLJS.Va(b.h?b.h(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.h(a),". It must be one of ",$CLJS.p.h(b)].join(""));};hw=function(){var a=$CLJS.th;return $CLJS.n(a)?gw>=$CLJS.th:a};mw=function(a){fw(a,new $CLJS.dh(null,new $CLJS.h(null,4,[Uv,null,Vv,null,Xv,null,Yv,null],null),null));var b=$CLJS.Tu;$CLJS.Rh.v($CLJS.q(b),$CLJS.R,$CLJS.Ci,zv);var c=Av.h($CLJS.q($CLJS.q(b))),d=xv.h($CLJS.q($CLJS.q(b)));Gv(b,new iv(bv,a,d,c,c,null,null,null))};
qw=function(a,b){fw(a,new $CLJS.dh(null,new $CLJS.h(null,2,[Zv,null,$CLJS.iw,null],null),null));var c=$CLJS.Tu,d=xv.h($CLJS.q($CLJS.q(c)));if($CLJS.E.g($CLJS.Ci.h($CLJS.q($CLJS.q(c))),Cv)){Hv(c);var e=uv.h(d);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Zv,a):$CLJS.E.call(null,Zv,a)))a=$CLJS.q(ov.h(d));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.iw,a):$CLJS.E.call(null,$CLJS.iw,a)))a=$CLJS.$u(qu.h($CLJS.q($CLJS.q(c))),$CLJS.pu);else throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));$CLJS.af(e,b+a)}else e=
Av.h($CLJS.q($CLJS.q(c))),Gv(c,new lv(jw,d,a,b,e,e,null,null,null))};vw=function(a,b,c){b="string"===typeof b?rw(b):b;c=sw(c);a:{var d=new $CLJS.Fa,e=$CLJS.Va(a)||!0===a?new $CLJS.sc(d):a,f=tw(b)&&$CLJS.Va($CLJS.aw(e))?$CLJS.n($CLJS.aw(e))?e:$CLJS.Sv(e):e,k=$CLJS.Tu;$CLJS.Tu=f;try{try{uw(b,c)}finally{e!==f&&Iu(f)}var l=$CLJS.Va(a)?$CLJS.p.h(d):!0===a?$CLJS.xh($CLJS.p.h(d)):null;break a}finally{$CLJS.Tu=k}l=void 0}return l};
xw=function(a,b){a=[$CLJS.p.h(a),"\n",$CLJS.p.h(ww),"\n",$CLJS.p.h($CLJS.Ve.g($CLJS.p,$CLJS.gf(b," "))),"^\n"].join("");throw Error(a);};yw=function(a,b,c,d,e,f){this.uc=a;this.pb=b;this.$b=c;this.S=d;this.G=e;this.J=f;this.C=2230716170;this.I=139264};sw=function(a){a=$CLJS.y(a);return new yw(a,a,0,null,null,null)};
Aw=function(a){var b=$CLJS.cj.h(a);if($CLJS.n(b))return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(b),new yw(kw.h(a),$CLJS.B(b),Av.h(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};Bw=function(a){var b=Aw(a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);a="string"===typeof a?rw(a):a;return new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null)};Dw=function(a,b){return b>=Av.h(a)?(b=Av.h(a)-b,Cw.g?Cw.g(a,b):Cw.call(null,a,b)):new yw(kw.h(a),$CLJS.ef(b,kw.h(a)),b,null,null,null)};
Cw=function(a,b){var c=Av.h(a)+b;return 0>b?Dw(a,c):new yw(kw.h(a),$CLJS.ef(b,$CLJS.cj.h(a)),c,null,null,null)};Ew=function(a,b,c,d,e,f,k){this.dc=a;this.cc=b;this.kc=c;this.offset=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
xba=function(a,b){var c=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);d=$CLJS.Ld(new $CLJS.dh(null,new $CLJS.h(null,2,[lw,null,nw,null],null),null),c)?new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null):$CLJS.E.g(d,ow)?Aw(b):$CLJS.E.g(d,pw)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.D($CLJS.cj.h(b)),b],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.P(null,2,5,$CLJS.Q,
[b,a],null)],null),d],null)};Jw=function(a,b){b=Wu(xba,b,a);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hg.g($CLJS.N,a),b],null)};
Sw=function(a,b,c){c=Aw(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Id(d))if($CLJS.E.g(Kw,10))var e=[$CLJS.p.h(d),$CLJS.n(Lw)?".":null].join("");else{e=$CLJS.p;var f=e.h;if($CLJS.n(Lw)){var k=$CLJS.J.g(yba,Kw);k=$CLJS.n(k)?k:["#",$CLJS.p.h(Kw),"r"].join("")}else k=null;e=[f.call(e,k),$CLJS.p.h(Mw(Kw,d))].join("")}else e=null;a=$CLJS.n(e)?e:a.h?a.h(d):a.call(null,d);d=a.length;e=d+zw.h(b);e=e>=Fw.h(b)?e:e+($CLJS.Zd(Fw.h(b)-e-1,Gw.h(b))+1)*Gw.h(b);d=$CLJS.Ve.g($CLJS.p,$CLJS.gf(e-d,Hw.h(b)));
$CLJS.n(nw.h(b))?Rw.l($CLJS.H([[$CLJS.p.h(d),$CLJS.p.h(a)].join("")])):Rw.l($CLJS.H([[$CLJS.p.h(a),$CLJS.p.h(d)].join("")]));return c};Tw=function(a,b){return $CLJS.fe($CLJS.A(Xu(function(c){return 0<c?new $CLJS.P(null,2,5,$CLJS.Q,[Mu(c,a),$CLJS.Zd(c,a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null)},b)))};Mw=function(a,b){return 0===b?"0":$CLJS.Ve.g($CLJS.p,$CLJS.lf.g(function(c){return 10>c?Lu(Vu("0")+c):Lu(Vu("a")+(c-10))},Tw(a,b)))};
zba=function(a,b){return $CLJS.fe($CLJS.A(Xu(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.y($CLJS.fe($CLJS.df(a,c))),$CLJS.y($CLJS.ef(a,c))],null)},$CLJS.fe(b))))};
Xw=function(a,b,c){c=Aw(c);var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.Id(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.E.g(d,Math.floor(d))){var e=0>d,f=Mw(a,e?-d:d);a=$CLJS.n(lw.h(b))?function(){var k=$CLJS.lf.g(function(m){return $CLJS.Ve.g($CLJS.p,m)},zba(Iw.h(b),f)),l=$CLJS.gf($CLJS.D(k),Nw.h(b));return $CLJS.Ve.g($CLJS.p,$CLJS.B($CLJS.hf.g(l,k)))}():f;a=e?["-",$CLJS.p.h(a)].join(""):$CLJS.n(nw.h(b))?["+",$CLJS.p.h(a)].join(""):a;a=a.length<
Fw.h(b)?[$CLJS.p.h($CLJS.Ve.g($CLJS.p,$CLJS.gf(Fw.h(b)-a.length,Hw.h(b)))),$CLJS.p.h(a)].join(""):a;Rw.l($CLJS.H([a]))}else Sw($CLJS.Ww,new $CLJS.h(null,5,[Fw,Fw.h(b),Gw,1,zw,0,Hw,Hw.h(b),nw,!0],null),sw(new $CLJS.P(null,1,5,$CLJS.Q,[d],null)));return c};
$w=function(a){var b=$CLJS.Zd(a,100);a=Mu(a,100);var c=0<b?[$CLJS.p.h($CLJS.F(Yw,b))," hundred"].join(""):null,d=$CLJS.p,e=d.h;if(0<a)if(20>a)var f=$CLJS.F(Yw,a);else{f=$CLJS.Zd(a,10);var k=Mu(a,10);f=[$CLJS.p.h(0<f?$CLJS.F(Zw,f):null),0<f&&0<k?"-":null,$CLJS.p.h(0<k?$CLJS.F(Yw,k):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
bx=function(a,b){var c=$CLJS.D(a),d=$CLJS.Ff;--c;var e=$CLJS.A(a);for(a=$CLJS.B(a);;){if(null==a)return[$CLJS.p.h($CLJS.Ve.g($CLJS.p,$CLJS.jf(", ",d))),$CLJS.ud(e)||$CLJS.ud(d)?null:", ",$CLJS.p.h(e),!$CLJS.ud(e)&&0<c+b?[" ",$CLJS.p.h($CLJS.F(ax,c+b))].join(""):null].join("");d=$CLJS.ud(e)?d:$CLJS.ee.g(d,[$CLJS.p.h(e)," ",$CLJS.p.h($CLJS.F(ax,c+b))].join(""));--c;e=$CLJS.A(a);a=$CLJS.B(a)}};
cx=function(a,b){b=Aw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=Tw(10,c);c=$CLJS.Ff;for(var e=$CLJS.D(d)-1;;)if($CLJS.ud(d)){Rw.l($CLJS.H([$CLJS.Ve.g($CLJS.p,c)]));break}else{var f=$CLJS.A(d);c=$CLJS.E.g(0,f)?c:$CLJS.ee.g(c,$CLJS.F($CLJS.F(a,e),f-1));--e;d=$CLJS.B(d)}}else Xw(10,new $CLJS.h(null,5,[Fw,0,Hw," ",Nw,",",Iw,3,lw,!0],null),sw(new $CLJS.P(null,1,5,$CLJS.Q,[c],null)));return b};
Bba=function(a,b){a=Aw(b);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);var c=Vu(b);b=c&127;c&=128;var d=$CLJS.J.g(Aba,b);0<c&&Rw.l($CLJS.H(["Meta-"]));Rw.l($CLJS.H([$CLJS.n(d)?d:32>b?["Control-",$CLJS.p.h(Lu(b+64))].join(""):$CLJS.E.g(b,127)?"Control-?":Lu(b)]));return a};
Cba=function(a,b){var c=Aw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);a=Ow.h(a);if($CLJS.n($CLJS.E.g?$CLJS.E.g("o",a):$CLJS.E.call(null,"o",a)))vw(!0,"\\o~3,'0o",$CLJS.H([Vu(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g("u",a):$CLJS.E.call(null,"u",a)))vw(!0,"\\u~4,'0x",$CLJS.H([Vu(b)]));else if($CLJS.n($CLJS.E.g?$CLJS.E.g(null,a):$CLJS.E.call(null,null,a)))$CLJS.bc($CLJS.Tu,$CLJS.n($CLJS.E.g?$CLJS.E.g("\b",b):$CLJS.E.call(null,"\b",b))?"\\backspace":$CLJS.n($CLJS.E.g?$CLJS.E.g(" ",b):$CLJS.E.call(null,
" ",b))?"\\space":$CLJS.n($CLJS.E.g?$CLJS.E.g("\t",b):$CLJS.E.call(null,"\t",b))?"\\tab":$CLJS.n($CLJS.E.g?$CLJS.E.g("\n",b):$CLJS.E.call(null,"\n",b))?"\\newline":$CLJS.n($CLJS.E.g?$CLJS.E.g("\f",b):$CLJS.E.call(null,"\f",b))?"\\formfeed":$CLJS.n($CLJS.E.g?$CLJS.E.g("\r",b):$CLJS.E.call(null,"\r",b))?"\\return":$CLJS.n($CLJS.E.g?$CLJS.E.g('"',b):$CLJS.E.call(null,'"',b))?'\\"':$CLJS.n($CLJS.E.g?$CLJS.E.g("\\",b):$CLJS.E.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.h(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.h(a)].join(""));return c};Dba=function(a,b){b=Aw(b);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);Rw.l($CLJS.H([a]));return b};gx=function(a){a=$CLJS.A(a);return $CLJS.E.g(Pw,a)||$CLJS.E.g(Qw,a)};
kx=function(a,b,c){return $CLJS.jd(Wu(function(d,e){if(gx(e))return new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=Jw(Uw.h(d),e);var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=Yu(f);f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);f=$CLJS.R.j(f,dx,c);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Ve.g(ex.h(d),new $CLJS.P(null,3,5,$CLJS.Q,[f,e,k],null))],null)},b,a))};
lx=function(a){a=$CLJS.p.h(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.p.h(a.length-1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.h(c-1)],null):0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);a:if(c=$CLJS.D(b),0<c&&$CLJS.E.g($CLJS.F(b,
$CLJS.D(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.E.g($CLJS.F(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.D(c);if(0<d&&$CLJS.E.g($CLJS.F(c,0),"0"))for(var e=0;;){if($CLJS.E.g(e,d)||!$CLJS.E.g($CLJS.F(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.D(b)-$CLJS.D(c);a=0<$CLJS.D(a)&&$CLJS.E.g($CLJS.F(a,0),"+")?a.substring(1):a;return $CLJS.ud(c)?new $CLJS.P(null,2,5,$CLJS.Q,["0",0],null):new $CLJS.P(null,2,5,$CLJS.Q,[c,parseInt(a,10)-b],null)};
mx=function(a,b,c,d){if($CLJS.n($CLJS.n(c)?c:d)){var e=$CLJS.D(a);d=$CLJS.n(d)?2>d?2:d:0;$CLJS.n(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.E.g(c,0)?new $CLJS.P(null,4,5,$CLJS.Q,[["0",$CLJS.p.h(a)].join(""),b+1,1,e+1],null):new $CLJS.P(null,4,5,$CLJS.Q,[a,b,c,e],null);c=$CLJS.I(f,0,null);e=$CLJS.I(f,1,null);d=$CLJS.I(f,2,null);f=$CLJS.I(f,3,null);if($CLJS.n(d)){if(0>d)return new $CLJS.P(null,3,5,$CLJS.Q,["0",0,!1],null);if(f>d){b=$CLJS.F(c,d);a=c.substring(0,d);if(Vu(b)>=Vu("5")){a:for(b=
$CLJS.D(a)-1,c=b|0;;){if(0>c){b=$CLJS.Ve.j($CLJS.p,"1",$CLJS.gf(b+1,"0"));break a}if($CLJS.E.g("9",a.charAt(c)))--c;else{b=$CLJS.Ve.v($CLJS.p,a.substring(0,c),Lu(Vu(a.charAt(c))+1),$CLJS.gf(b-c,"0"));break a}}a=$CLJS.D(b)>a.length;c=$CLJS.Q;a&&(d=$CLJS.D(b)-1,b=b.substring(0,d));return new $CLJS.P(null,3,5,c,[b,e,a],null)}return new $CLJS.P(null,3,5,$CLJS.Q,[a,e,!1],null)}}}return new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null)};
nx=function(a,b,c){var d=0>b?new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h($CLJS.Ve.g($CLJS.p,$CLJS.gf(-b-1,"0"))),$CLJS.p.h(a)].join(""),-1],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);a=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);d=$CLJS.D(a);c=$CLJS.n(c)?e+c+1:e+1;c=d<c?[$CLJS.p.h(a),$CLJS.p.h($CLJS.Ve.g($CLJS.p,$CLJS.gf(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.h(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
Eba=function(a,b){return 0>b?[".",$CLJS.p.h(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
sx=function(a,b){var c=fx.h(a),d=hx.h(a);b=Aw(b);var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var f=0>e?new $CLJS.P(null,2,5,$CLJS.Q,["-",-e],null):new $CLJS.P(null,2,5,$CLJS.Q,["+",e],null),k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);f=lx(f);var l=$CLJS.I(f,0,null),m=$CLJS.I(f,1,null)+$CLJS.ix.h(a);f=function(){var x=nw.h(a);return $CLJS.n(x)?x:0>e}();var t=$CLJS.Va(d)&&$CLJS.D(l)-1<=m,u=mx(l,m,d,$CLJS.n(c)?c-($CLJS.n(f)?1:0):null);l=$CLJS.I(u,0,null);m=$CLJS.I(u,1,null);u=$CLJS.I(u,2,null);l=nx(l,$CLJS.n(u)?
m+1:m,d);d=$CLJS.n($CLJS.n(c)?$CLJS.n(d)?1<=d&&$CLJS.E.g(l.charAt(0),"0")&&$CLJS.E.g(l.charAt(1),".")&&l.length>c-($CLJS.n(f)?1:0):d:c)?l.substring(1):l;m=$CLJS.E.g($CLJS.A(d),".");if($CLJS.n(c)){l=d.length;l=$CLJS.n(f)?l+1:l;m=m&&!(l>=c);t=t&&!(l>=c);var v=m||t?l+1:l;$CLJS.n(function(){var x=v>c;return x?jx.h(a):x}())?Rw.l($CLJS.H([$CLJS.Ve.g($CLJS.p,$CLJS.gf(c,jx.h(a)))])):Rw.l($CLJS.H([[$CLJS.p.h($CLJS.Ve.g($CLJS.p,$CLJS.gf(c-v,Hw.h(a)))),$CLJS.p.h($CLJS.n(f)?k:null),m?"0":null,d,t?"0":null].join("")]))}else Rw.l($CLJS.H([[$CLJS.p.h($CLJS.n(f)?
k:null),m?"0":null,d,t?"0":null].join("")]));return b};
vx=function(a,b){b=Aw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=lx(0>c?-c:c);$CLJS.I(d,0,null);for($CLJS.I(d,1,null);;){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=fx.h(a),l=hx.h(a),m=ox.h(a),t=$CLJS.ix.h(a),u=function(){var K=px.h(a);return $CLJS.n(K)?K:"E"}();d=function(){var K=nw.h(a);return $CLJS.n(K)?K:0>c}();var v=0>=t,x=f-(t-1),z=$CLJS.p.h(Math.abs(x));u=[$CLJS.p.h(u),0>x?"-":"+",$CLJS.p.h($CLJS.n(m)?$CLJS.Ve.g($CLJS.p,$CLJS.gf(m-z.length,"0")):null),z].join("");var C=u.length;
x=$CLJS.D(e);e=[$CLJS.p.h($CLJS.Ve.g($CLJS.p,$CLJS.gf(-t,"0"))),$CLJS.p.h(e),$CLJS.p.h($CLJS.n(l)?$CLJS.Ve.g($CLJS.p,$CLJS.gf(l-(x-1)-(0>t?-t:0),"0")):null)].join("");x=$CLJS.n(k)?k-C:null;e=mx(e,0,$CLJS.E.g(t,0)?l-1:0<t?l:0>t?l-1:null,$CLJS.n(x)?x-($CLJS.n(d)?1:0):null);x=$CLJS.I(e,0,null);$CLJS.I(e,1,null);z=$CLJS.I(e,2,null);e=Eba(x,t);l=$CLJS.E.g(t,$CLJS.D(x))&&null==l;if($CLJS.Va(z)){if($CLJS.n(k)){f=e.length+C;f=$CLJS.n(d)?f+1:f;var G=(v=v&&!$CLJS.E.g(f,k))?f+1:f;f=l&&G<k;$CLJS.n(function(){var K=
G>k;K||(K=m,K=$CLJS.n(K)?C-2>m:K);return $CLJS.n(K)?jx.h(a):K}())?Rw.l($CLJS.H([$CLJS.Ve.g($CLJS.p,$CLJS.gf(k,jx.h(a)))])):Rw.l($CLJS.H([[$CLJS.p.h($CLJS.Ve.g($CLJS.p,$CLJS.gf(k-G-(f?1:0),Hw.h(a)))),$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else Rw.l($CLJS.H([[$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.P(null,2,5,$CLJS.Q,[x,f+1],null)}return b};
Fba=function(a,b,c){var d=Aw(b),e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);d=lx(0>e?-e:e);var f=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var k=fx.h(a),l=hx.h(a),m=ox.h(a);d=$CLJS.E.g(e,0)?0:d+1;e=$CLJS.n(m)?m+2:4;k=$CLJS.n(k)?k-e:null;$CLJS.n(l)?f=l:(f=$CLJS.D(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=sx(new $CLJS.h(null,6,[fx,k,hx,d,$CLJS.ix,0,jx,jx.h(a),Hw,Hw.h(a),nw,nw.h(a)],null),b,c),Rw.l($CLJS.H([$CLJS.Ve.g($CLJS.p,$CLJS.gf(e," "))])),a):vx(a,b,c)};
Gba=function(a,b){b=Aw(b);var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=lx(Math.abs(c)),e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null),k=hx.h(a),l=$CLJS.sn.h(a);d=fx.h(a);var m=function(){var u=nw.h(a);return $CLJS.n(u)?u:0>c}(),t=mx(e,f,k,null);e=$CLJS.I(t,0,null);f=$CLJS.I(t,1,null);t=$CLJS.I(t,2,null);k=nx(e,$CLJS.n(t)?f+1:f,k);l=[$CLJS.p.h($CLJS.Ve.g($CLJS.p,$CLJS.gf(l-k.indexOf("."),"0"))),k].join("");k=l.length+($CLJS.n(m)?1:0);Rw.l($CLJS.H([[$CLJS.n(function(){var u=lw.h(a);return $CLJS.n(u)?
m:u}())?0>c?"-":"+":null,$CLJS.p.h($CLJS.Ve.g($CLJS.p,$CLJS.gf(d-k,Hw.h(a)))),$CLJS.n(function(){var u=$CLJS.Va(lw.h(a));return u?m:u}())?0>c?"-":"+":null,l].join("")]));return b};Hba=function(a,b){var c=qx.h(a);c=$CLJS.n(c)?new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null):Aw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.rx.h(a);b=0>b||b>=$CLJS.D(d)?$CLJS.A($CLJS.wt.h(a)):$CLJS.F(d,b);return $CLJS.n(b)?kx(b,c,dx.h(a)):c};
Iba=function(a,b){var c=Aw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var d=$CLJS.rx.h(a);b=$CLJS.n(b)?$CLJS.jd(d):$CLJS.A(d);return $CLJS.n(b)?kx(b,c,dx.h(a)):c};Jba=function(a,b){var c=Aw(b),d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.rx.h(a);e=$CLJS.n(d)?$CLJS.A(e):null;return $CLJS.n(d)?$CLJS.n(e)?kx(e,b,dx.h(a)):b:c};
Kba=function(a,b){var c=tx.h(a),d=$CLJS.A($CLJS.rx.h(a));d=$CLJS.ud(d)?Bw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=Aw(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=0;e=sw(e);for(var k=-1;;){if($CLJS.Va(c)&&$CLJS.E.g(Av.h(e),k)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");k=$CLJS.ud($CLJS.cj.h(e))&&($CLJS.Va(lw.h(ux.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=kx(b,e,dx.h(a));if($CLJS.E.g(Pw,$CLJS.A(k)))return d;
f+=1;var l=Av.h(e);e=k;k=l}};Lba=function(a,b){var c=tx.h(a),d=$CLJS.A($CLJS.rx.h(a));d=$CLJS.ud(d)?Bw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=Aw(d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);for(var f=0;;){var k=$CLJS.ud(e)&&($CLJS.Va(lw.h(ux.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=kx(b,sw($CLJS.A(e)),sw($CLJS.B(e)));if($CLJS.E.g(Qw,$CLJS.A(k)))return d;f+=1;e=$CLJS.B(e)}};
Mba=function(a,b){var c=tx.h(a),d=$CLJS.A($CLJS.rx.h(a)),e=$CLJS.ud(d)?Bw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;e=$CLJS.I(e,1,null);for(var f=-1;;){if($CLJS.Va(c)&&$CLJS.E.g(Av.h(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.ud($CLJS.cj.h(e))&&($CLJS.Va(lw.h(ux.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=kx(b,e,dx.h(a));if($CLJS.E.g(Pw,$CLJS.A(f)))return $CLJS.jd(f);d+=1;var k=Av.h(e);e=f;f=k}};
Nba=function(a,b){var c=tx.h(a),d=$CLJS.A($CLJS.rx.h(a)),e=$CLJS.ud(d)?Bw(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.I(e,0,null);d=0;for(e=$CLJS.I(e,1,null);;){var f=$CLJS.ud($CLJS.cj.h(e))&&($CLJS.Va(lw.h(ux.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=$CLJS.cj.h(e);f=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(f),new yw(kw.h(e),$CLJS.B(f),Av.h(e)+1,null,null,null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=kx(b,sw(e),f);
if($CLJS.E.g(Qw,$CLJS.A(e)))return f;e=f;d+=1}};Pba=function(a,b){if($CLJS.n(lw.h(ux.h(a)))){var c=$CLJS.rx.h(a),d=$CLJS.D(c),e=1<d?$CLJS.tk.h(Uw.h($CLJS.A($CLJS.A(c)))):$CLJS.n(lw.h(a))?"(":null,f=$CLJS.F(c,1<d?1:0);c=2<d?$CLJS.tk.h(Uw.h($CLJS.A($CLJS.F(c,2)))):$CLJS.n(lw.h(a))?")":null;d=Aw(b);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{var k=gw,l=bw;gw+=1;bw=0;try{Wv(e,c),kx(f,sw(b),dx.h(a)),$v()}finally{bw=l,gw=k}}a=d}else a=Oba(a,b);return a};
Ax=function(a,b,c){for(var d=$CLJS.Ff;;){if($CLJS.ud(a))return new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);var e=$CLJS.A(a);a:{var f=new $CLJS.Fa,k=$CLJS.Tu;$CLJS.Tu=new $CLJS.sc(f);try{var l=new $CLJS.P(null,2,5,$CLJS.Q,[kx(e,b,c),$CLJS.p.h(f)],null);break a}finally{$CLJS.Tu=k}l=void 0}b=$CLJS.I(l,0,null);e=$CLJS.I(l,1,null);if($CLJS.E.g(Pw,$CLJS.A(b)))return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.jd(b)],null);a=$CLJS.B(a);d=$CLJS.ee.g(d,e)}};
Oba=function(a,b){var c=function(){var Z=$CLJS.wt.h(a);return $CLJS.n(Z)?Ax(Z,b,dx.h(a)):null}(),d=$CLJS.I(c,0,null);d=$CLJS.I(d,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.n(c)?c:b;c=function(){var Z=wx.h(a);return $CLJS.n(Z)?Jw(Z,e):null}();var f=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var k=$CLJS.n(c)?c:e;c=function(){var Z=$CLJS.A(xx.h(f));return $CLJS.n(Z)?Z:0}();var l=function(){var Z=$CLJS.A(yx.h(f));return $CLJS.n(Z)?Z:$CLJS.$u($CLJS.Tu,$CLJS.Fk)}(),m=$CLJS.rx.h(a);k=Ax(m,k,dx.h(a));var t=$CLJS.I(k,
0,null);k=$CLJS.I(k,1,null);var u=function(){var Z=$CLJS.D(t)-1+($CLJS.n(lw.h(a))?1:0)+($CLJS.n(nw.h(a))?1:0);return 1>Z?1:Z}();m=$CLJS.Td($CLJS.Nk,$CLJS.lf.g($CLJS.D,t));var v=Fw.h(a),x=zw.h(a),z=Gw.h(a),C=m+u*x;v=C<=v?v:v+z*(1+$CLJS.Zd(C-v-1,z));var G=v-m;m=function(){var Z=$CLJS.Zd(G,u);return x>Z?x:Z}();z=G-m*u;m=$CLJS.Ve.g($CLJS.p,$CLJS.gf(m,Hw.h(a)));$CLJS.n($CLJS.n(d)?$CLJS.$u(qu.h($CLJS.q($CLJS.q($CLJS.Tu))),$CLJS.pu)+c+v>l:d)&&Rw.l($CLJS.H([d]));c=z;for(var K=t,S=function(){var Z=lw.h(a);
return $CLJS.n(Z)?Z:$CLJS.E.g($CLJS.D(K),1)&&$CLJS.Va(nw.h(a))}();;)if($CLJS.y(K))Rw.l($CLJS.H([[$CLJS.p.h($CLJS.Va(S)?$CLJS.A(K):null),$CLJS.p.h($CLJS.n(function(){var Z=S;return $CLJS.n(Z)?Z:(Z=$CLJS.B(K))?Z:nw.h(a)}())?m:null),$CLJS.p.h(0<c?Hw.h(a):null)].join("")])),--c,K=d=$CLJS.n(S)?K:$CLJS.B(K),S=!1;else break;return k};zx=function(a,b){this.Ca=a;this.Fh=b;this.C=1074135040;this.I=0};Qba=function(a){return new zx(a,$CLJS.N)};Bx=function(a,b){this.Ca=a;this.Gh=b;this.C=1074135040;this.I=0};
Rba=function(a){return new Bx(a,$CLJS.N)};
Sba=function(a,b){var c=$CLJS.A(a);return $CLJS.Ve.g($CLJS.p,$CLJS.A(Xu(function(d){if($CLJS.ud(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.n(e)?e.index+1:e;return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[[d.substring(0,e),$CLJS.F(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,null],null)},$CLJS.n($CLJS.n(b)?$CLJS.n(c)?Gu(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
Cx=function(a,b,c){this.Ca=a;this.Jd=b;this.Hh=c;this.C=1074135040;this.I=0};Tba=function(a){return new Cx(a,$CLJS.$e(!0),$CLJS.N)};Dx=function(a,b,c){this.Ca=a;this.vd=b;this.Jh=c;this.C=1074135040;this.I=0};Uba=function(a){return new Dx(a,$CLJS.$e(!1),$CLJS.N)};Vba=function(a,b){var c=$CLJS.n(lw.h(a))?$CLJS.iw:Zv;qw(c,$CLJS.sn.h(a));return b};Wba=function(a,b){a=$CLJS.n(lw.h(a))?$CLJS.n(nw.h(a))?Uv:Xv:$CLJS.n(nw.h(a))?Vv:Yv;mw(a);return b};
Yba=function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null);a=new RegExp(Xba.source,"g");var e=a.exec(b);return $CLJS.n(e)?(d=$CLJS.A(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.E.g(",",$CLJS.F(b,0))?new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b.substring(1),a+1,!0],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b,a,!1],null)],null)):$CLJS.n(d)?xw("Badly formed parameters in format directive",
c):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null)],null)};Zba=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.E.g(b.length,0)?null:$CLJS.E.g(b.length,1)&&$CLJS.Ld(new $CLJS.dh(null,new $CLJS.h(null,2,["V",null,"v",null],null),null),$CLJS.F(b,0))?ow:$CLJS.E.g(b.length,1)&&$CLJS.E.g("#",$CLJS.F(b,0))?pw:$CLJS.E.g(b.length,2)&&$CLJS.E.g("'",$CLJS.F(b,0))?$CLJS.F(b,1):parseInt(b,10),a],null)};
aca=function(a,b){return Xu(function(c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.ud(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null);var f=$CLJS.J.g($ba,$CLJS.A(d));return $CLJS.n(f)?$CLJS.Ld(c,f)?xw(['Flag "',$CLJS.p.h($CLJS.A(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[d.substring(1),e+1,$CLJS.R.j(c,f,new $CLJS.P(null,2,5,$CLJS.Q,[!0,e],null))],
null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null)},new $CLJS.P(null,3,5,$CLJS.Q,[a,b,$CLJS.N],null))};
bca=function(a,b){var c=Ex.h(a);$CLJS.n(function(){var d=$CLJS.Va(nw.h(c));return d?nw.h(b):d}())&&xw(['"@" is an illegal flag for format directive "',$CLJS.p.h(Fx.h(a)),'"'].join(""),$CLJS.F(nw.h(b),1));$CLJS.n(function(){var d=$CLJS.Va(lw.h(c));return d?lw.h(b):d}())&&xw(['":" is an illegal flag for format directive "',$CLJS.p.h(Fx.h(a)),'"'].join(""),$CLJS.F(lw.h(b),1));$CLJS.n(function(){var d=$CLJS.Va(Gx.h(c));return d?(d=nw.h(b),$CLJS.n(d)?lw.h(b):d):d}())&&xw(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.h(Fx.h(a)),'"'].join(""),function(){var d=$CLJS.F(lw.h(b),1),e=$CLJS.F(nw.h(b),1);return d<e?d:e}())};
dca=function(a,b,c,d){bca(a,c);$CLJS.D(b)>$CLJS.D(Uw.h(a))&&xw(vw(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.H([Fx.h(a),$CLJS.D(b),$CLJS.D(Uw.h(a))])),$CLJS.jd($CLJS.A(b)));$CLJS.ph($CLJS.lf.j(function(e,f){var k=$CLJS.A(e);return null==k||$CLJS.Ld(cca,k)||$CLJS.E.g($CLJS.jd($CLJS.jd(f)),$CLJS.$a(k))?null:xw(["Parameter ",$CLJS.jh($CLJS.A(f)),' has bad type in directive "',$CLJS.p.h(Fx.h(a)),'": ',$CLJS.p.h($CLJS.$a(k))].join(""),
$CLJS.jd(e))},b,Uw.h(a)));return $CLJS.ll.l($CLJS.H([$CLJS.hg.g($CLJS.N,$CLJS.fe(function(){return function k(f){return new $CLJS.qe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Cd(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.te(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.I(x,0,null);u.add(new $CLJS.P(null,2,5,$CLJS.Q,[z,new $CLJS.P(null,2,5,$CLJS.Q,[x,d],null)],null));v+=1}else{m=!0;break a}return m?$CLJS.we($CLJS.ye(u),k($CLJS.mc(l))):
$CLJS.we($CLJS.ye(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.I(m,0,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[u,new $CLJS.P(null,2,5,$CLJS.Q,[m,d],null)],null),k($CLJS.Lc(l)))}return null}},null,null)}(Uw.h(a))}())),$CLJS.db(function(e,f){return $CLJS.Ve.j($CLJS.R,e,f)},$CLJS.N,$CLJS.of(function(e){return $CLJS.A($CLJS.F(e,1))},$CLJS.Ou($CLJS.$g(Uw.h(a)),b))),c]))};
fca=function(a,b){b=Xu(Yba,new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null));a=$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);b=aca(b,c);$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);c=$CLJS.A(d);var f=$CLJS.J.g(eca,c.toUpperCase()),k=$CLJS.n(f)?dca(f,$CLJS.lf.g(Zba,a),b,e):null;$CLJS.Va(c)&&xw("Format string ended in the middle of a directive",e);$CLJS.Va(f)&&xw(['Directive "',$CLJS.p.h(c),'" is undefined'].join(""),
e);return new $CLJS.P(null,2,5,$CLJS.Q,[new Ew(function(){var l=Hx.h(f);return l.g?l.g(k,e):l.call(null,k,e)}(),f,k,e,null,null,null),function(){var l=d.substring(1),m=e+1;if($CLJS.E.g("\n",Fx.h(f))&&$CLJS.Va(lw.h(k)))a:{var t=new $CLJS.P(null,2,5,$CLJS.Q,[" ","\t"],null);t=$CLJS.vd(t)?$CLJS.ih(t):$CLJS.fh([t]);for(var u=0;;){var v;(v=$CLJS.E.g(u,$CLJS.D(l)))||(v=$CLJS.F(l,u),v=t.h?t.h(v):t.call(null,v),v=$CLJS.Va(v));if(v){t=u;break a}u+=1}}else t=0;return new $CLJS.P(null,2,5,$CLJS.Q,[l.substring(t),
m+t],null)}()],null)};Mx=function(a,b){return new Ew(function(c,d){Rw.l($CLJS.H([a]));return d},null,new $CLJS.h(null,1,[$CLJS.tk,a],null),b,null,null,null)};Px=function(a,b){var c=gca(Ix.h(Jx.h(a)),$CLJS.xt.h(a),b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new Ew(ex.h(a),Jx.h(a),$CLJS.ll.l($CLJS.H([Uw.h(a),pba(b,$CLJS.xt.h(a))])),$CLJS.xt.h(a),null,null,null),c],null)};
hca=function(a,b,c){return Xu(function(d){if($CLJS.ud(d))return xw("No closing bracket found.",b);var e=$CLJS.A(d);d=$CLJS.B(d);if($CLJS.n($CLJS.Kx.h(Ix.h(Jx.h(e)))))e=Px(e,d);else if($CLJS.E.g($CLJS.Kx.h(a),Fx.h(Jx.h(e))))e=new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[Lx,Uw.h(e),null,d],null)],null);else{var f=$CLJS.yt.h(Ix.h(Jx.h(e)));f=$CLJS.n(f)?lw.h(Uw.h(e)):f;e=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.wt,null,Uw.h(e),d],null)],null):
$CLJS.n($CLJS.yt.h(Ix.h(Jx.h(e))))?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.yt,null,null,d],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[e,d],null)}return e},c)};
gca=function(a,b,c){return $CLJS.jd(Xu(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);d=$CLJS.I(d,2,null);var k=hca(a,b,d);d=$CLJS.I(k,0,null);var l=$CLJS.I(k,1,null);k=$CLJS.I(l,0,null);var m=$CLJS.I(l,1,null),t=$CLJS.I(l,2,null);l=$CLJS.I(l,3,null);return $CLJS.E.g(k,Lx)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Sx.l($CLJS.kf,$CLJS.H([e,$CLJS.He([$CLJS.n(f)?$CLJS.wt:$CLJS.rx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null),ux,m])])),l],null)],null):$CLJS.E.g(k,$CLJS.wt)?
$CLJS.n($CLJS.wt.h(e))?xw('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Va($CLJS.wt.h(a))?xw('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.E.g(Nx,$CLJS.wt.h(a))&&$CLJS.y($CLJS.rx.h(e))?xw('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.E.g(Nx,$CLJS.wt.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Sx.l($CLJS.kf,$CLJS.H([e,new $CLJS.h(null,2,[$CLJS.wt,new $CLJS.P(null,1,5,
$CLJS.Q,[d],null),wx,t],null)])),!1,l],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Sx.l($CLJS.kf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.rx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!0,l],null)],null):$CLJS.E.g(k,$CLJS.yt)?$CLJS.n(f)?xw('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Va(Ox.h(a))?xw('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.Sx.l($CLJS.kf,$CLJS.H([e,new $CLJS.h(null,1,[$CLJS.rx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.h(null,1,[$CLJS.rx,$CLJS.Ff],null),!1,c],null)))};ica=function(a){return $CLJS.A(Xu(function(b){var c=$CLJS.A(b);b=$CLJS.B(b);var d=Ix.h(Jx.h(c));return $CLJS.n($CLJS.Kx.h(d))?Px(c,b):new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)},a))};
rw=function(a){var b=ww;ww=a;try{return ica($CLJS.A(Xu(function(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.ud(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.P(null,2,5,$CLJS.Q,[Mx(d,c),new $CLJS.P(null,2,5,$CLJS.Q,["",c+d.length],null)],null):0===e?fca(d.substring(1),c+1):new $CLJS.P(null,2,5,$CLJS.Q,[Mx(d.substring(0,e),c),new $CLJS.P(null,2,5,$CLJS.Q,[d.substring(e),e+c],null)],null)},new $CLJS.P(null,2,5,$CLJS.Q,[a,0],null))))}finally{ww=
b}};uw=function(a,b){Wu(function(c,d){if(gx(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=Jw(Uw.h(c),d);var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=Yu(e);e=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);e=$CLJS.R.j(e,dx,d);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.Ve.g(ex.h(c),new $CLJS.P(null,3,5,$CLJS.Q,[e,d,f],null))],null)},b,a);return null};
Wx=function(a){var b=$CLJS.A(a);b=Vx.h?Vx.h(b):Vx.call(null,b);return $CLJS.n($CLJS.n(b)?$CLJS.E.g(2,$CLJS.D(a)):b)?($CLJS.bc($CLJS.Tu,b),$CLJS.ew($CLJS.jd(a)),!0):null};
Xx=function(a){if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{var b=gw,c=bw;gw+=1;bw=0;try{Wv("[","]");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.ew($CLJS.A(e)),$CLJS.B(e))){$CLJS.bc($CLJS.Tu," ");mw(Yv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.bc($CLJS.Tu,"...");break}$v()}finally{bw=c,gw=b}}return null};
Yx=function(a){var b=$CLJS.Ad(a)?null:function(){var m=new $CLJS.Gc(function(){return $CLJS.Kh},fda,$CLJS.Lg([$CLJS.$n,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[!0,$CLJS.si,gda,"cljs/core.cljs",15,1,10543,10543,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jj],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.n($CLJS.Kh)?$CLJS.Kh.H:null]));return m.h?m.h(a):m.call(null,a)}(),c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.n(b)?
b:a,e=$CLJS.n(c)?["#:",$CLJS.p.h(c),"{"].join(""):"{";if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{c=gw;b=bw;gw+=1;bw=0;try{Wv(e,"}");e=0;for(var f=$CLJS.y(d);;){if($CLJS.Va($CLJS.Ma)||e<$CLJS.Ma){if(f){if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{d=gw;var k=bw;gw+=1;bw=0;try{Wv(null,null),$CLJS.ew($CLJS.A($CLJS.A(f))),$CLJS.bc($CLJS.Tu," "),mw(Yv),bw=0,$CLJS.ew($CLJS.A($CLJS.B($CLJS.A(f)))),$v()}finally{bw=k,gw=d}}if($CLJS.B(f)){$CLJS.bc($CLJS.Tu,", ");mw(Yv);d=e+1;var l=$CLJS.B(f);e=d;f=l;continue}}}else $CLJS.bc($CLJS.Tu,
"...");break}$v()}finally{bw=b,gw=c}}return null};Zx=function(a){return $CLJS.bc($CLJS.Tu,$CLJS.Ph.l($CLJS.H([a])))};by=function(a){return a instanceof $CLJS.Uf?Qx:(null!=a?a.C&32768||$CLJS.Bc===a.ag||(a.C?0:$CLJS.Za($CLJS.Hu,a)):$CLJS.Za($CLJS.Hu,a))?Rx:a instanceof $CLJS.r?$CLJS.Ti:$CLJS.Gd(a)?$CLJS.Xj:$CLJS.zd(a)?$CLJS.Dk:$CLJS.Bd(a)?$CLJS.kk:$CLJS.xd(a)?$CLJS.uj:null==a?null:$CLJS.mi};
cy=function(a){return $CLJS.Bd(a)?new $CLJS.P(null,2,5,$CLJS.Q,["[","]"],null):new $CLJS.P(null,2,5,$CLJS.Q,["(",")"],null)};
lca=function(a){if($CLJS.yd(a)){var b=cy(a),c=$CLJS.I(b,0,null),d=$CLJS.I(b,1,null),e=$CLJS.y(a),f=$CLJS.A(e),k=$CLJS.B(e);if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{var l=gw,m=bw,t=gw+1;gw=t;bw=0;try{Wv(c,d);(function(){var Qa=dy("~w~:i");return function(){function ib(Db){var xb=null;if(0<arguments.length){xb=0;for(var kb=Array(arguments.length-0);xb<kb.length;)kb[xb]=arguments[xb+0],++xb;xb=new $CLJS.w(kb,0,null)}return Ta.call(this,xb)}function Ta(Db){Db=sw(Db);return uw(Qa,Db)}ib.A=0;ib.B=function(Db){Db=
$CLJS.y(Db);return Ta(Db)};ib.l=Ta;return ib}()})()(f);for(var u=k;;)if($CLJS.y(u)){(function(){var Qa=dy(" ");return function(ib,Ta,Db){return function(){function xb(mb){var Pa=null;if(0<arguments.length){Pa=0;for(var Wc=Array(arguments.length-0);Pa<Wc.length;)Wc[Pa]=arguments[Pa+0],++Pa;Pa=new $CLJS.w(Wc,0,null)}return kb.call(this,Pa)}function kb(mb){mb=sw(mb);return uw(Db,mb)}xb.A=0;xb.B=function(mb){mb=$CLJS.y(mb);return kb(mb)};xb.l=kb;return xb}()}(u," ",Qa,l,m,t,0,b,c,d,a,e,f,k,f,k)})()();
var v=$CLJS.A(u);if($CLJS.yd(v)){var x=cy(v),z=$CLJS.I(x,0,null),C=$CLJS.I(x,1,null);if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{var G=gw,K=bw,S=gw+1;gw=S;bw=0;try{Wv(z,C);if($CLJS.E.g($CLJS.D(v),3)&&$CLJS.jd(v)instanceof $CLJS.M){var Z=v,ca=$CLJS.I(Z,0,null),ka=$CLJS.I(Z,1,null),wa=$CLJS.I(Z,2,null);(function(){var Qa=dy("~w ~w ");return function(ib,Ta,Db){return function(){function xb(mb){var Pa=null;if(0<arguments.length){Pa=0;for(var Wc=Array(arguments.length-0);Pa<Wc.length;)Wc[Pa]=arguments[Pa+
0],++Pa;Pa=new $CLJS.w(Wc,0,null)}return kb.call(this,Pa)}function kb(mb){mb=sw(mb);return uw(Db,mb)}xb.A=0;xb.B=function(mb){mb=$CLJS.y(mb);return kb(mb)};xb.l=kb;return xb}()}(u,"~w ~w ",Qa,Z,ca,ka,wa,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)})()(ca,ka);$CLJS.yd(wa)?function(){var Qa=$CLJS.Bd(wa)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",ib="string"===typeof Qa?dy(Qa):Qa;return function(Ta,Db,xb){return function(){function kb(Pa){var Wc=null;if(0<arguments.length){Wc=0;
for(var Ok=Array(arguments.length-0);Wc<Ok.length;)Ok[Wc]=arguments[Wc+0],++Wc;Wc=new $CLJS.w(Ok,0,null)}return mb.call(this,Wc)}function mb(Pa){Pa=sw(Pa);return uw(xb,Pa)}kb.A=0;kb.B=function(Pa){Pa=$CLJS.y(Pa);return mb(Pa)};kb.l=mb;return kb}()}(u,Qa,ib,Z,ca,ka,wa,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()(wa):$CLJS.ew(wa)}else $CLJS.Ve.g(function(){var Qa=dy("~w ~:i~@{~w~^ ~:_~}");return function(ib,Ta,Db){return function(){function xb(mb){var Pa=null;if(0<arguments.length){Pa=0;for(var Wc=
Array(arguments.length-0);Pa<Wc.length;)Wc[Pa]=arguments[Pa+0],++Pa;Pa=new $CLJS.w(Wc,0,null)}return kb.call(this,Pa)}function kb(mb){mb=sw(mb);return uw(Db,mb)}xb.A=0;xb.B=function(mb){mb=$CLJS.y(mb);return kb(mb)};xb.l=kb;return xb}()}(u,"~w ~:i~@{~w~^ ~:_~}",Qa,G,K,S,0,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}(),v);$v()}finally{bw=K,gw=G}}$CLJS.B(u)&&function(){var Qa=dy("~_");return function(ib,Ta,Db){return function(){function xb(mb){var Pa=null;if(0<arguments.length){Pa=0;for(var Wc=Array(arguments.length-
0);Pa<Wc.length;)Wc[Pa]=arguments[Pa+0],++Pa;Pa=new $CLJS.w(Wc,0,null)}return kb.call(this,Pa)}function kb(mb){mb=sw(mb);return uw(Db,mb)}xb.A=0;xb.B=function(mb){mb=$CLJS.y(mb);return kb(mb)};xb.l=kb;return xb}()}(u,"~_",Qa,x,z,C,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()()}else $CLJS.ew(v),$CLJS.B(u)&&function(){var Qa=dy("~:_");return function(ib,Ta,Db){return function(){function xb(mb){var Pa=null;if(0<arguments.length){Pa=0;for(var Wc=Array(arguments.length-0);Pa<Wc.length;)Wc[Pa]=arguments[Pa+0],++Pa;
Pa=new $CLJS.w(Wc,0,null)}return kb.call(this,Pa)}function kb(mb){mb=sw(mb);return uw(Db,mb)}xb.A=0;xb.B=function(mb){mb=$CLJS.y(mb);return kb(mb)};xb.l=kb;return xb}()}(u,"~:_",Qa,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()();u=$CLJS.B(u)}else break;$v()}finally{bw=m,gw=l}}}else $CLJS.ew(a)};
mca=function(a,b){$CLJS.y(a)&&($CLJS.n(b)?function(){var c=dy(" ~_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=sw(f);return uw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()():function(){var c=dy(" ~@_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<
l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=sw(f);return uw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;return d}()}()(),function(){var c=dy("~{~w~^ ~_~}");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.w(l,0,null)}return e.call(this,k)}function e(f){f=sw(f);return uw(c,f)}d.A=0;d.B=function(f){f=$CLJS.y(f);return e(f)};d.l=e;
return d}()}()(a))};nca=function(a){$CLJS.y(a)&&function(){var b=dy(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var k=Array(arguments.length-0);f<k.length;)k[f]=arguments[f+0],++f;f=new $CLJS.w(k,0,null)}return d.call(this,f)}function d(e){e=sw(e);return uw(b,e)}c.A=0;c.B=function(e){e=$CLJS.y(e);return d(e)};c.l=d;return c}()}()(a)};
fy=function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d="string"===typeof $CLJS.A(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=$CLJS.zd($CLJS.A(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{var f=
gw,k=bw;gw+=1;bw=0;try{Wv("(",")"),function(){var l=dy("~w ~1I~@_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=sw(u);return uw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(a,b),$CLJS.n(c)&&function(){var l=dy(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=sw(u);return uw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}()(c),$CLJS.n(d)&&function(){var l=dy(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=sw(u);return uw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};
m.l=t;return m}()}()(d),$CLJS.Bd($CLJS.A(e))?mca(e,$CLJS.n(c)?c:d):nca(e),$v()}finally{bw=k,gw=f}}return null}return ey(a)};
oca=function(a){if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{var b=gw,c=bw;gw+=1;bw=0;try{Wv("[","]");for(var d=0;;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if($CLJS.y(a)){if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{var e=gw,f=bw;gw+=1;bw=0;try{Wv(null,null),$CLJS.ew($CLJS.A(a)),$CLJS.B(a)&&($CLJS.bc($CLJS.Tu," "),mw(Vv),$CLJS.ew($CLJS.jd(a))),$v()}finally{bw=f,gw=e}}if($CLJS.B($CLJS.Lc(a))){$CLJS.bc($CLJS.Tu," ");mw(Yv);e=d+1;var k=$CLJS.B($CLJS.Lc(a));d=e;a=k;continue}}}else $CLJS.bc($CLJS.Tu,"...");
break}$v()}finally{bw=c,gw=b}}};
gy=function(a){var b=$CLJS.A(a);if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{var c=gw,d=bw;gw+=1;bw=0;try{Wv("(",")"),$CLJS.B(a)&&$CLJS.Bd($CLJS.jd(a))?(function(){var e=dy("~w ~1I~@_");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=sw(l);return uw(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()(b),oca($CLJS.jd(a)),function(){var e=
dy(" ~_~{~w~^ ~_~}");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.w(t,0,null)}return k.call(this,m)}function k(l){l=sw(l);return uw(e,l)}f.A=0;f.B=function(l){l=$CLJS.y(l);return k(l)};f.l=k;return f}()}()($CLJS.B($CLJS.Lc(a)))):ey(a),$v()}finally{bw=d,gw=c}}return null};
ey=function(a){if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{var b=gw,c=bw;gw+=1;bw=0;try{Wv("(",")");qw(Zv,1);for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.ew($CLJS.A(e)),$CLJS.B(e))){$CLJS.bc($CLJS.Tu," ");mw(Yv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.bc($CLJS.Tu,"...");break}$v()}finally{bw=c,gw=b}}return null};$CLJS.qe.prototype.Lc=$CLJS.va(2,function(){return $CLJS.Va(this.Rb)});$CLJS.ff.prototype.Lc=$CLJS.va(1,function(){return!1});
$CLJS.Mh.prototype.Lc=$CLJS.va(0,function(){return $CLJS.Va(this.f)});$CLJS.Tu=null;hy=function hy(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return hy.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};hy.l=function(a){a=$CLJS.y(a);for(var b=$CLJS.ec($CLJS.jg);;)if(a){var c=$CLJS.B(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.h($CLJS.A(a))].join(""));var d=$CLJS.B(c);b=$CLJS.kg.j(b,$CLJS.A(a),$CLJS.A(c));a=d}else return $CLJS.gc(b)};
hy.A=0;hy.B=function(a){return this.l($CLJS.y(a))};$CLJS.Sx=function Sx(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Sx.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
$CLJS.Sx.l=function(a,b){if($CLJS.n($CLJS.Ue($CLJS.Xd,b))){var c=function(d,e){var f=$CLJS.Fb(e),k=$CLJS.Gb(e);if($CLJS.Ld(d,f)){e=$CLJS.R.j;var l=$CLJS.J.g(d,f);k=a.g?a.g(l,k):a.call(null,l,k);d=e.call($CLJS.R,d,f,k)}else d=$CLJS.R.j(d,f,k);return d};return $CLJS.Td(function(d,e){return $CLJS.db(c,$CLJS.n(d)?d:$CLJS.N,$CLJS.y(e))},b)}return null};$CLJS.Sx.A=1;$CLJS.Sx.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
$CLJS.Ww=function Ww(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Ww.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};$CLJS.Ww.l=function(a){return $CLJS.Hh(a,$CLJS.R.j($CLJS.Na(),$CLJS.Ha,!1))};$CLJS.Ww.A=0;$CLJS.Ww.B=function(a){return this.l($CLJS.y(a))};cv=new $CLJS.M(null,"trailing-white-space","trailing-white-space",1496006996);Tx=new $CLJS.M(null,"relative-to","relative-to",-470100051);wv=new $CLJS.M(null,"intra-block-nl","intra-block-nl",1808826875);
px=new $CLJS.M(null,"exponentchar","exponentchar",1986664222);hda=new $CLJS.r(null,"when-let","when-let",-1383043480,null);Vv=new $CLJS.M(null,"miser","miser",-556060186);$CLJS.iw=new $CLJS.M(null,"current","current",-1088038603);ida=new $CLJS.r(null,"-\x3e","-\x3e",-2139605430,null);kda=new $CLJS.r(null,"..","..",-300507420,null);dv=new $CLJS.M(null,"end-pos","end-pos",-1643883926);sv=new $CLJS.M(null,"per-line-prefix","per-line-prefix",846941813);Qx=new $CLJS.M(null,"queue","queue",1455835879);
lda=new $CLJS.r(null,"if-let","if-let",1803593690,null);Rv=new $CLJS.M(null,"end","end",-268185958);pw=new $CLJS.M(null,"remaining-arg-count","remaining-arg-count",-1216589335);xv=new $CLJS.M(null,"logical-blocks","logical-blocks",-1466339776);mda=new $CLJS.r(null,"defmacro","defmacro",2054157304,null);nda=new $CLJS.r(null,"when-first","when-first",821699168,null);Mv=new $CLJS.M(null,"start-block-t","start-block-t",-373430594);xda=new $CLJS.r(null,"binding","binding",-2114503176,null);
Ix=new $CLJS.M(null,"bracket-info","bracket-info",-1600092774);Cv=new $CLJS.M(null,"writing","writing",-1486865108);ow=new $CLJS.M(null,"parameter-from-args","parameter-from-args",-758446196);Kv=new $CLJS.M(null,"logical-block-callback","logical-block-callback",1612691194);qx=new $CLJS.M(null,"selector","selector",762528866);yda=new $CLJS.r(null,"struct","struct",325972931,null);uv=new $CLJS.M(null,"indent","indent",-148200125);zda=new $CLJS.r(null,"loop","loop",1244978678,null);
yx=new $CLJS.M(null,"max-columns","max-columns",1742323262);Gx=new $CLJS.M(null,"both","both",-393648840);Ux=new $CLJS.M(null,"colnum","colnum",2023796854);Ada=new $CLJS.r(null,"doseq","doseq",221164135,null);$CLJS.$x=new $CLJS.M(null,"length","length",588987862);Ow=new $CLJS.M(null,"char-format","char-format",-1016499218);ay=new $CLJS.M(null,"prefix","prefix",-265908465);Qw=new $CLJS.M(null,"colon-up-arrow","colon-up-arrow",244853007);Ov=new $CLJS.M(null,"suffix","suffix",367373057);
nw=new $CLJS.M(null,"at","at",1476951349);bv=new $CLJS.M(null,"nl-t","nl-t",-1608382114);Fx=new $CLJS.M(null,"directive","directive",793559132);eda=new $CLJS.M(null,"buffer-level","buffer-level",928864731);Uv=new $CLJS.M(null,"mandatory","mandatory",542802336);Jv=new $CLJS.M(null,"pretty-writer","pretty-writer",-1222834267);mv=new $CLJS.M(null,"done-nl","done-nl",-381024340);Bda=new $CLJS.r(null,"condp","condp",1054325175,null);kw=new $CLJS.M(null,"seq","seq",-1817803783);
Cda=new $CLJS.r(null,"defn","defn",-126010802,null);Gw=new $CLJS.M(null,"colinc","colinc",-584873385);Pw=new $CLJS.M(null,"up-arrow","up-arrow",1705310333);Lx=new $CLJS.M(null,"right-bracket","right-bracket",951856080);Dda=new $CLJS.M(null,"radix","radix",857016463);Nx=new $CLJS.M(null,"first","first",-644103046);cda=new $CLJS.M(null,"sections","sections",-886710106);$CLJS.Lv=new $CLJS.M(null,"start","start",-355208981);Hda=new $CLJS.r("cljs.core","unquote","cljs.core/unquote",1013085760,null);
Lda=new $CLJS.r(null,"defn-","defn-",1097765044,null);Pda=new $CLJS.r("cljs.core","deref","cljs.core/deref",1901963335,null);Zv=new $CLJS.M(null,"block","block",664686210);Uda=new $CLJS.r(null,"when","when",1064114221,null);wx=new $CLJS.M(null,"else-params","else-params",-832171646);$CLJS.iy=new $CLJS.M(null,"count","count",2139924085);$CLJS.Kx=new $CLJS.M(null,"right","right",-452581833);Yv=new $CLJS.M(null,"linear","linear",872268697);Xda=new $CLJS.r(null,"when-not","when-not",-1223136340,null);
Hw=new $CLJS.M(null,"padchar","padchar",2018584530);Fw=new $CLJS.M(null,"mincol","mincol",1230695445);Yda=new $CLJS.M(null,"not-delivered","not-delivered",1599158697);nv=new $CLJS.M(null,"miser-width","miser-width",-1310049437);Zda=new $CLJS.r(null,"with-local-vars","with-local-vars",837642072,null);gda=new $CLJS.r(null,"lift-ns","lift-ns",602311926,null);Ox=new $CLJS.M(null,"allows-separator","allows-separator",-818967742);zv=new $CLJS.M(null,"buffering","buffering",-876713613);
jy=new $CLJS.M(null,"arg1","arg1",951899358);dx=new $CLJS.M(null,"base-args","base-args",-1268706822);ky=new $CLJS.M(null,"arg3","arg3",-1486822496);ly=new $CLJS.M(null,"arg2","arg2",1729550917);Iw=new $CLJS.M(null,"commainterval","commainterval",-1980061083);$da=new $CLJS.M(null,"right-margin","right-margin",-810413306);Bv=new $CLJS.M(null,"buffer-blob","buffer-blob",-1830112173);aea=new $CLJS.r(null,"with-open","with-open",172119667,null);
fda=new $CLJS.r("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);bea=new $CLJS.M(null,"lines","lines",-700165781);jw=new $CLJS.M(null,"indent-t","indent-t",528318969);ux=new $CLJS.M(null,"right-params","right-params",-1790676237);lw=new $CLJS.M(null,"colon","colon",-965200945);dea=new $CLJS.r(null,"if-not","if-not",-265415609,null);ex=new $CLJS.M(null,"func","func",-238706040);$CLJS.ny=new $CLJS.M(null,"last","last",1105735132);Rx=new $CLJS.M(null,"deref","deref",-145586795);
hea=new $CLJS.r(null,"dotimes","dotimes",-818708397,null);tx=new $CLJS.M(null,"max-iterations","max-iterations",2021275563);iea=new $CLJS.r(null,"cond","cond",1606708055,null);zw=new $CLJS.M(null,"minpad","minpad",323570901);rv=new $CLJS.M(null,"logical-block","logical-block",-581022564);jea=new $CLJS.r(null,"struct-map","struct-map",-1387540878,null);Tv=new $CLJS.M(null,"end-block-t","end-block-t",1544648735);oy=new $CLJS.M(null,"stream","stream",1534941648);
Uw=new $CLJS.M(null,"params","params",710516235);kea=new $CLJS.M(null,"circle","circle",1903212362);fv=new $CLJS.M(null,"start-pos","start-pos",668789086);Ex=new $CLJS.M(null,"flags","flags",1775418075);Xv=new $CLJS.M(null,"fill","fill",883462889);bda=new $CLJS.M(null,"buffer-block","buffer-block",-10937307);Hx=new $CLJS.M(null,"generator-fn","generator-fn",811851656);ov=new $CLJS.M(null,"start-col","start-col",668080143);fx=new $CLJS.M(null,"w","w",354169001);Jx=new $CLJS.M(null,"def","def",-1043430536);
$CLJS.ix=new $CLJS.M(null,"k","k",-2146297393);ox=new $CLJS.M(null,"e","e",1381269198);hx=new $CLJS.M(null,"d","d",1972142424);jx=new $CLJS.M(null,"overflowchar","overflowchar",-1620088106);xx=new $CLJS.M(null,"min-remaining","min-remaining",962687677);Nw=new $CLJS.M(null,"commachar","commachar",652859327);py=new $CLJS.M(null,"section","section",-300141526);qy=new $CLJS.M(null,"pretty","pretty",-1916372486);lea=new $CLJS.r(null,"let","let",358118826,null);uu=new $CLJS.M(null,"parent","parent",-878878779);
$CLJS.rx=new $CLJS.M(null,"clauses","clauses",1454841241);mea=new $CLJS.r(null,"defonce","defonce",-1681484013,null);qu=new $CLJS.M(null,"base","base",185279322);Fu=new $CLJS.M(null,"type-tag","type-tag",-1873863267);nea=new $CLJS.r(null,"locking","locking",1542862874,null);Av=new $CLJS.M(null,"pos","pos",-864607220);oea=new $CLJS.M(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.pu=new $CLJS.M(null,"cur","cur",1153190599);var Rw=function Rw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Rw.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)},dw,Qv,vy,wy,xy,Lw,Kw,gw,bw,yy;Rw.l=function(a){return $CLJS.bc($CLJS.Tu,$CLJS.Ve.g($CLJS.Ww,a))};Rw.A=0;Rw.B=function(a){return this.l($CLJS.y(a))};var Su=function Su(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return Su.l(0<c.length?new $CLJS.w(c.slice(0),0,null):null)};
Su.l=function(a){return $CLJS.bc($CLJS.Tu,$CLJS.Ve.g($CLJS.Ph,a))};Su.A=0;Su.B=function(a){return this.l($CLJS.y(a))};$CLJS.g=tu.prototype;$CLJS.g.P=function(a,b){return new tu(this.Ca,this.Nf,this.Yc,b)};$CLJS.g.O=function(){return this.Dh};$CLJS.g.Qb=function(){return this.Yc};$CLJS.g.rc=function(){return Iu(this.Ca)};
$CLJS.g.Mc=function(a,b){a=$CLJS.$a(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?av(this,$CLJS.pu,$CLJS.$u(this,$CLJS.pu)+$CLJS.D(b)):(av(this,$CLJS.pu,$CLJS.D(b)-a-1),av(this,$CLJS.Ej,$CLJS.$u(this,$CLJS.Ej)+$CLJS.D($CLJS.of(function(c){return $CLJS.E.g(c,"\n")},b)))),$CLJS.bc($CLJS.$u(this,qu),b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return qba(this,b);throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));
};$CLJS.g=ev.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "parent":return this.parent;case "section":return this.Cb;case "start-col":return this.tb;case "indent":return this.sb;case "done-nl":return this.vb;case "intra-block-nl":return this.wb;case "prefix":return this.prefix;case "per-line-prefix":return this.Ab;case "suffix":return this.Db;case "logical-block-callback":return this.xb;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.db(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.kf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[uu,this.parent],null),new $CLJS.P(null,2,5,$CLJS.Q,[py,this.Cb],null),new $CLJS.P(null,2,5,$CLJS.Q,[ov,this.tb],null),new $CLJS.P(null,2,5,$CLJS.Q,[uv,this.sb],null),new $CLJS.P(null,2,5,$CLJS.Q,[mv,this.vb],null),new $CLJS.P(null,2,5,$CLJS.Q,[wv,this.wb],null),new $CLJS.P(null,2,5,$CLJS.Q,
[ay,this.prefix],null),new $CLJS.P(null,2,5,$CLJS.Q,[sv,this.Ab],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ov,this.Db],null),new $CLJS.P(null,2,5,$CLJS.Q,[Kv,this.xb],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Vt(this,10,new $CLJS.P(null,10,5,$CLJS.Q,[uu,py,ov,uv,mv,wv,ay,sv,Ov,Kv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ke())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 10+$CLJS.D(this.G)};
$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1977012399^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.parent,b.parent)&&$CLJS.E.g(this.Cb,b.Cb)&&$CLJS.E.g(this.tb,b.tb)&&$CLJS.E.g(this.sb,b.sb)&&$CLJS.E.g(this.vb,b.vb)&&$CLJS.E.g(this.wb,b.wb)&&$CLJS.E.g(this.prefix,b.prefix)&&$CLJS.E.g(this.Ab,b.Ab)&&$CLJS.E.g(this.Db,b.Db)&&$CLJS.E.g(this.xb,b.xb)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Ld(new $CLJS.dh(null,new $CLJS.h(null,10,[Ov,null,uv,null,uu,null,py,null,mv,null,ov,null,ay,null,sv,null,Kv,null,wv,null],null),null),b)?$CLJS.Lk.g($CLJS.Pb($CLJS.hg.g($CLJS.N,this),this.S),b):new ev(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.Ie($CLJS.Lk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Ld(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ke.g?$CLJS.ke.g(uu,b):$CLJS.ke.call(null,uu,b))?new ev(c,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(py,b):$CLJS.ke.call(null,py,b))?new ev(this.parent,c,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(ov,b):$CLJS.ke.call(null,ov,b))?new ev(this.parent,this.Cb,c,this.sb,this.vb,this.wb,this.prefix,this.Ab,
this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(uv,b):$CLJS.ke.call(null,uv,b))?new ev(this.parent,this.Cb,this.tb,c,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(mv,b):$CLJS.ke.call(null,mv,b))?new ev(this.parent,this.Cb,this.tb,this.sb,c,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(wv,b):$CLJS.ke.call(null,wv,b))?new ev(this.parent,this.Cb,this.tb,this.sb,this.vb,c,this.prefix,
this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(ay,b):$CLJS.ke.call(null,ay,b))?new ev(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,c,this.Ab,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(sv,b):$CLJS.ke.call(null,sv,b))?new ev(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,c,this.Db,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(Ov,b):$CLJS.ke.call(null,Ov,b))?new ev(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,
this.prefix,this.Ab,c,this.xb,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(Kv,b):$CLJS.ke.call(null,Kv,b))?new ev(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,c,this.S,this.G,null):new ev(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.kf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.ag(uu,this.parent),new $CLJS.ag(py,this.Cb),new $CLJS.ag(ov,this.tb),new $CLJS.ag(uv,this.sb),new $CLJS.ag(mv,this.vb),new $CLJS.ag(wv,this.wb),new $CLJS.ag(ay,this.prefix),new $CLJS.ag(sv,this.Ab),new $CLJS.ag(Ov,this.Db),new $CLJS.ag(Kv,this.xb)],null),this.G))};$CLJS.g.P=function(a,b){return new ev(this.parent,this.Cb,this.tb,this.sb,this.vb,this.wb,this.prefix,this.Ab,this.Db,this.xb,b,this.G,this.J)};
$CLJS.g.ja=function(a,b){return $CLJS.Bd(b)?this.ma(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.db($CLJS.pb,this,b)};$CLJS.g=hv.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "data":return this.data;case "trailing-white-space":return this.ac;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};
$CLJS.g.Fb=function(a,b,c){return $CLJS.db(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.kf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Fu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Jj,this.data],null),new $CLJS.P(null,2,5,$CLJS.Q,[cv,this.ac],null),new $CLJS.P(null,2,5,$CLJS.Q,[fv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[dv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Vt(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[Fu,$CLJS.Jj,cv,fv,dv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ke())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1809113693^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.data,b.data)&&$CLJS.E.g(this.ac,b.ac)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Ld(new $CLJS.dh(null,new $CLJS.h(null,5,[dv,null,cv,null,Fu,null,fv,null,$CLJS.Jj,null],null),null),b)?$CLJS.Lk.g($CLJS.Pb($CLJS.hg.g($CLJS.N,this),this.S),b):new hv(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.Ie($CLJS.Lk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ld(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ke.g?$CLJS.ke.g(Fu,b):$CLJS.ke.call(null,Fu,b))?new hv(c,this.data,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g($CLJS.Jj,b):$CLJS.ke.call(null,$CLJS.Jj,b))?new hv(this.ca,c,this.ac,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(cv,b):$CLJS.ke.call(null,cv,b))?new hv(this.ca,this.data,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(fv,b):$CLJS.ke.call(null,fv,b))?new hv(this.ca,this.data,
this.ac,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(dv,b):$CLJS.ke.call(null,dv,b))?new hv(this.ca,this.data,this.ac,this.ba,c,this.S,this.G,null):new hv(this.ca,this.data,this.ac,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.kf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.ag(Fu,this.ca),new $CLJS.ag($CLJS.Jj,this.data),new $CLJS.ag(cv,this.ac),new $CLJS.ag(fv,this.ba),new $CLJS.ag(dv,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new hv(this.ca,this.data,this.ac,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.Bd(b)?this.ma(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.db($CLJS.pb,this,b)};$CLJS.g=iv.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "type":return this.type;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.db(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.kf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Fu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,this.type],null),new $CLJS.P(null,2,5,$CLJS.Q,[rv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[fv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[dv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Vt(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[Fu,$CLJS.rj,rv,fv,dv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ke())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1640656800^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.type,b.type)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Ld(new $CLJS.dh(null,new $CLJS.h(null,5,[dv,null,$CLJS.rj,null,rv,null,Fu,null,fv,null],null),null),b)?$CLJS.Lk.g($CLJS.Pb($CLJS.hg.g($CLJS.N,this),this.S),b):new iv(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.Ie($CLJS.Lk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ld(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ke.g?$CLJS.ke.g(Fu,b):$CLJS.ke.call(null,Fu,b))?new iv(c,this.type,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g($CLJS.rj,b):$CLJS.ke.call(null,$CLJS.rj,b))?new iv(this.ca,c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(rv,b):$CLJS.ke.call(null,rv,b))?new iv(this.ca,this.type,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(fv,b):$CLJS.ke.call(null,fv,b))?new iv(this.ca,this.type,
this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(dv,b):$CLJS.ke.call(null,dv,b))?new iv(this.ca,this.type,this.ea,this.ba,c,this.S,this.G,null):new iv(this.ca,this.type,this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.kf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.ag(Fu,this.ca),new $CLJS.ag($CLJS.rj,this.type),new $CLJS.ag(rv,this.ea),new $CLJS.ag(fv,this.ba),new $CLJS.ag(dv,this.aa)],null),this.G))};
$CLJS.g.P=function(a,b){return new iv(this.ca,this.type,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.Bd(b)?this.ma(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.db($CLJS.pb,this,b)};$CLJS.g=jv.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.db(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.kf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Fu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[rv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[fv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[dv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Vt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Fu,rv,fv,dv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ke())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-414877272^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Ld(new $CLJS.dh(null,new $CLJS.h(null,4,[dv,null,rv,null,Fu,null,fv,null],null),null),b)?$CLJS.Lk.g($CLJS.Pb($CLJS.hg.g($CLJS.N,this),this.S),b):new jv(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Ie($CLJS.Lk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ld(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ke.g?$CLJS.ke.g(Fu,b):$CLJS.ke.call(null,Fu,b))?new jv(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(rv,b):$CLJS.ke.call(null,rv,b))?new jv(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(fv,b):$CLJS.ke.call(null,fv,b))?new jv(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(dv,b):$CLJS.ke.call(null,dv,b))?new jv(this.ca,this.ea,this.ba,c,this.S,this.G,null):new jv(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.kf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.ag(Fu,this.ca),new $CLJS.ag(rv,this.ea),new $CLJS.ag(fv,this.ba),new $CLJS.ag(dv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new jv(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.Bd(b)?this.ma(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.db($CLJS.pb,this,b)};$CLJS.g=kv.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.db(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.kf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Fu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[rv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[fv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[dv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Vt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Fu,rv,fv,dv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ke())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1365867980^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Ld(new $CLJS.dh(null,new $CLJS.h(null,4,[dv,null,rv,null,Fu,null,fv,null],null),null),b)?$CLJS.Lk.g($CLJS.Pb($CLJS.hg.g($CLJS.N,this),this.S),b):new kv(this.ca,this.ea,this.ba,this.aa,this.S,$CLJS.Ie($CLJS.Lk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ld(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ke.g?$CLJS.ke.g(Fu,b):$CLJS.ke.call(null,Fu,b))?new kv(c,this.ea,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(rv,b):$CLJS.ke.call(null,rv,b))?new kv(this.ca,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(fv,b):$CLJS.ke.call(null,fv,b))?new kv(this.ca,this.ea,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(dv,b):$CLJS.ke.call(null,dv,b))?new kv(this.ca,this.ea,this.ba,c,this.S,this.G,null):new kv(this.ca,
this.ea,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.kf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.ag(Fu,this.ca),new $CLJS.ag(rv,this.ea),new $CLJS.ag(fv,this.ba),new $CLJS.ag(dv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new kv(this.ca,this.ea,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.Bd(b)?this.ma(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.db($CLJS.pb,this,b)};$CLJS.g=lv.prototype;
$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ca;case "logical-block":return this.ea;case "relative-to":return this.Wb;case "offset":return this.offset;case "start-pos":return this.ba;case "end-pos":return this.aa;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.db(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.kf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Fu,this.ca],null),new $CLJS.P(null,2,5,$CLJS.Q,[rv,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Tx,this.Wb],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xt,this.offset],null),new $CLJS.P(null,2,5,$CLJS.Q,[fv,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[dv,this.aa],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Vt(this,6,new $CLJS.P(null,6,5,$CLJS.Q,[Fu,rv,Tx,$CLJS.xt,fv,dv],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ke())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 6+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1602780238^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.ca,b.ca)&&$CLJS.E.g(this.ea,b.ea)&&$CLJS.E.g(this.Wb,b.Wb)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.ba,b.ba)&&$CLJS.E.g(this.aa,b.aa)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Ld(new $CLJS.dh(null,new $CLJS.h(null,6,[$CLJS.xt,null,dv,null,Tx,null,rv,null,Fu,null,fv,null],null),null),b)?$CLJS.Lk.g($CLJS.Pb($CLJS.hg.g($CLJS.N,this),this.S),b):new lv(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.Ie($CLJS.Lk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Ld(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ke.g?$CLJS.ke.g(Fu,b):$CLJS.ke.call(null,Fu,b))?new lv(c,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(rv,b):$CLJS.ke.call(null,rv,b))?new lv(this.ca,c,this.Wb,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(Tx,b):$CLJS.ke.call(null,Tx,b))?new lv(this.ca,this.ea,c,this.offset,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g($CLJS.xt,b):$CLJS.ke.call(null,$CLJS.xt,
b))?new lv(this.ca,this.ea,this.Wb,c,this.ba,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(fv,b):$CLJS.ke.call(null,fv,b))?new lv(this.ca,this.ea,this.Wb,this.offset,c,this.aa,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(dv,b):$CLJS.ke.call(null,dv,b))?new lv(this.ca,this.ea,this.Wb,this.offset,this.ba,c,this.S,this.G,null):new lv(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.kf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.ag(Fu,this.ca),new $CLJS.ag(rv,this.ea),new $CLJS.ag(Tx,this.Wb),new $CLJS.ag($CLJS.xt,this.offset),new $CLJS.ag(fv,this.ba),new $CLJS.ag(dv,this.aa)],null),this.G))};$CLJS.g.P=function(a,b){return new lv(this.ca,this.ea,this.Wb,this.offset,this.ba,this.aa,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.Bd(b)?this.ma(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.db($CLJS.pb,this,b)};
var pv=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("cljs.pprint","write-token"),function(f,k){return Fu.h(k)},e,a,b,c,d)}();
pv.m(null,Mv,function(a,b){var c=Kv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h($CLJS.Lv):c.call(null,$CLJS.Lv));b=rv.h(b);c=ay.h(b);$CLJS.n(c)&&$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),c);a=$CLJS.$u(qu.h($CLJS.q($CLJS.q(a))),$CLJS.pu);$CLJS.af(ov.h(b),a);return $CLJS.af(uv.h(b),a)});pv.m(null,Tv,function(a,b){var c=Kv.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h(Rv):c.call(null,Rv));b=Ov.h(rv.h(b));return $CLJS.n(b)?$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),b):null});
pv.m(null,jw,function(a,b){var c=rv.h(b),d=uv.h(c),e=$CLJS.xt.h(b);b=Tx.h(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(Zv,b):$CLJS.E.call(null,Zv,b)))a=$CLJS.q(ov.h(c));else if($CLJS.n($CLJS.E.g?$CLJS.E.g($CLJS.iw,b):$CLJS.E.call(null,$CLJS.iw,b)))a=$CLJS.$u(qu.h($CLJS.q($CLJS.q(a))),$CLJS.pu);else throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));return $CLJS.af(d,e+a)});pv.m(null,Bv,function(a,b){return $CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),$CLJS.Jj.h(b))});
pv.m(null,bv,function(a,b){var c=$CLJS.E.g($CLJS.rj.h(b),Uv);c||(c=(c=!$CLJS.E.g($CLJS.rj.h(b),Xv))?$CLJS.q(mv.h(rv.h(b))):c);$CLJS.n(c)?Dv.g?Dv.g(a,b):Dv.call(null,a,b):(b=cv.h($CLJS.q($CLJS.q(a))),$CLJS.n(b)&&$CLJS.bc(qu.h($CLJS.q($CLJS.q(a))),b));return $CLJS.Rh.v($CLJS.q(a),$CLJS.R,cv,null)});
var uy=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("cljs.pprint","emit-nl?"),function(f){return $CLJS.rj.h(f)},e,a,b,c,d)}();uy.m(null,Yv,function(a,b,c){a=rv.h(a);return vv(b,a,c)});uy.m(null,Vv,function(a,b,c){a=rv.h(a);return yv(b,a,c)});
uy.m(null,Xv,function(a,b,c,d){a=rv.h(a);var e=$CLJS.q(wv.h(a));return $CLJS.n(e)?e:(d=!tv(b,d))?d:yv(b,a,c)});uy.m(null,Uv,function(){return!0});
var Ev=function Ev(a,b){var d=tba(b);b=$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null);$CLJS.n(b)&&qv(a,b,!1);if($CLJS.n(e)){d=rba(e);b=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var f=$CLJS.A(e),k=uy.v(f,a,b,sba(e));$CLJS.n(k)&&(Dv(a,f),e=$CLJS.B(e));tv(a,e)?a=e:(e=Ev.g?Ev.g(a,b):Ev.call(null,a,b),$CLJS.E.g(e,b)?(qv(a,b,!1),a=d):a=$CLJS.hg.g($CLJS.Ff,$CLJS.kf.g(e,d)));return a}return null};$CLJS.g=Iv.prototype;$CLJS.g.P=function(a,b){return new Iv(this.Ca,this.Nf,this.li,this.mh,this.Yc,b)};$CLJS.g.O=function(){return this.Eh};
$CLJS.g.Qb=function(){return this.Yc};
$CLJS.g.Mc=function(a,b){a=$CLJS.$a(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a))){var c=Nv(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.Ci.h($CLJS.q($CLJS.q(this)));if($CLJS.E.g(d,Cv))return Hv(this),$CLJS.bc(qu.h($CLJS.q($CLJS.q(this))),b),$CLJS.Rh.v($CLJS.q(this),$CLJS.R,cv,a);d=Av.h($CLJS.q($CLJS.q(this)));c=d+$CLJS.D(c);$CLJS.Rh.v($CLJS.q(this),$CLJS.R,Av,c);return Gv(this,new hv(Bv,b,a,d,c,null,null,null))}if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,
a):$CLJS.E.call(null,Number,a)))return $CLJS.E.g($CLJS.Ci.h($CLJS.q($CLJS.q(this))),Cv)?(Hv(this),b=$CLJS.bc(qu.h($CLJS.q($CLJS.q(this))),b)):$CLJS.E.g(b,"\n")?b=Nv(this,"\n"):(a=Av.h($CLJS.q($CLJS.q(this))),c=a+1,$CLJS.Rh.v($CLJS.q(this),$CLJS.R,Av,c),b=Lu(b),b=Gv(this,new hv(Bv,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};$CLJS.g.rc=function(){this.uf(null);return Iu(qu.h($CLJS.q($CLJS.q(this))))};
$CLJS.g.uf=function(){return $CLJS.E.g($CLJS.Ci.h($CLJS.q($CLJS.q(this))),zv)?(qv(this,$CLJS.vt.h($CLJS.q($CLJS.q(this))),!0),$CLJS.Rh.v($CLJS.q(this),$CLJS.R,$CLJS.vt,$CLJS.Ff)):Hv(this)};$CLJS.cw=!0;dw=null;$CLJS.Pv=72;Qv=40;vy=null;wy=null;xy=null;Lw=null;Kw=10;gw=0;bw=null;yy=function yy(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return yy.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
yy.l=function(a,b){var c=$CLJS.ll.l($CLJS.H([new $CLJS.h(null,1,[oy,!0],null),$CLJS.Ve.g(hy,b)]));b=Kw;var d=wy,e=$CLJS.Ma,f=$CLJS.th,k=vy,l=Qv,m=dw,t=$CLJS.cw,u=Lw,v=$CLJS.Ia,x=$CLJS.Pv,z=xy,C=qu.g(c,Kw),G=kea.g(c,wy),K=$CLJS.$x.g(c,$CLJS.Ma),S=$CLJS.Bt.g(c,$CLJS.th),Z=bea.g(c,vy),ca=nv.g(c,Qv),ka=$CLJS.bj.g(c,dw),wa=qy.g(c,$CLJS.cw),Qa=Dda.g(c,Lw),ib=$CLJS.Ha.g(c,$CLJS.Ia),Ta=$da.g(c,$CLJS.Pv),Db=oea.g(c,xy);Kw=C;wy=G;$CLJS.Ma=K;$CLJS.th=S;vy=Z;Qv=ca;dw=ka;$CLJS.cw=wa;Lw=Qa;$CLJS.Ia=ib;$CLJS.Pv=
Ta;xy=Db;try{var xb=new $CLJS.Fa,kb=$CLJS.Ld(c,oy)?oy.h(c):!0,mb=!0===kb||null==kb?new $CLJS.sc(xb):kb;if($CLJS.n($CLJS.cw)){var Pa=$CLJS.Va($CLJS.aw(mb));c=$CLJS.Tu;$CLJS.Tu=Pa?$CLJS.Sv(mb):mb;try{$CLJS.ew(a),$CLJS.Zu()}finally{$CLJS.Tu=c}}else{Pa=$CLJS.Tu;$CLJS.Tu=mb;try{Su.call(null,a)}finally{$CLJS.Tu=Pa}}!0===kb&&$CLJS.xh($CLJS.p.h(xb));return null==kb?$CLJS.p.h(xb):null}finally{xy=z,$CLJS.Pv=x,$CLJS.Ia=v,Lw=u,$CLJS.cw=t,dw=m,Qv=l,vy=k,$CLJS.th=f,$CLJS.Ma=e,wy=d,Kw=b}};yy.A=1;
yy.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};var ww=null;$CLJS.g=yw.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "seq":return this.uc;case "rest":return this.pb;case "pos":return this.$b;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.db(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.kf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[kw,this.uc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,this.pb],null),new $CLJS.P(null,2,5,$CLJS.Q,[Av,this.$b],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Vt(this,3,new $CLJS.P(null,3,5,$CLJS.Q,[kw,$CLJS.cj,Av],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ke())};
$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 3+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-402038447^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.uc,b.uc)&&$CLJS.E.g(this.pb,b.pb)&&$CLJS.E.g(this.$b,b.$b)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Ld(new $CLJS.dh(null,new $CLJS.h(null,3,[Av,null,kw,null,$CLJS.cj,null],null),null),b)?$CLJS.Lk.g($CLJS.Pb($CLJS.hg.g($CLJS.N,this),this.S),b):new yw(this.uc,this.pb,this.$b,this.S,$CLJS.Ie($CLJS.Lk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Ld(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ke.g?$CLJS.ke.g(kw,b):$CLJS.ke.call(null,kw,b))?new yw(c,this.pb,this.$b,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g($CLJS.cj,b):$CLJS.ke.call(null,$CLJS.cj,b))?new yw(this.uc,c,this.$b,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(Av,b):$CLJS.ke.call(null,Av,b))?new yw(this.uc,this.pb,c,this.S,this.G,null):new yw(this.uc,this.pb,this.$b,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.y($CLJS.kf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.ag(kw,this.uc),new $CLJS.ag($CLJS.cj,this.pb),new $CLJS.ag(Av,this.$b)],null),this.G))};$CLJS.g.P=function(a,b){return new yw(this.uc,this.pb,this.$b,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.Bd(b)?this.ma(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.db($CLJS.pb,this,b)};$CLJS.g=Ew.prototype;$CLJS.g.na=function(a,b){return this.$(null,b,null)};
$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "func":return this.dc;case "def":return this.cc;case "params":return this.kc;case "offset":return this.offset;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.db(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.kf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[ex,this.dc],null),new $CLJS.P(null,2,5,$CLJS.Q,[Jx,this.cc],null),new $CLJS.P(null,2,5,$CLJS.Q,[Uw,this.kc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xt,this.offset],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Vt(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[ex,Jx,Uw,$CLJS.xt],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ke())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-829256337^$CLJS.Qc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.dc,b.dc)&&$CLJS.E.g(this.cc,b.cc)&&$CLJS.E.g(this.kc,b.kc)&&$CLJS.E.g(this.offset,b.offset)&&$CLJS.E.g(this.G,b.G)};$CLJS.g.Gb=function(a,b){return $CLJS.Ld(new $CLJS.dh(null,new $CLJS.h(null,4,[$CLJS.xt,null,ex,null,Uw,null,Jx,null],null),null),b)?$CLJS.Lk.g($CLJS.Pb($CLJS.hg.g($CLJS.N,this),this.S),b):new Ew(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.Ie($CLJS.Lk.g(this.G,b)),null)};
$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Ld(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ke.g?$CLJS.ke.g(ex,b):$CLJS.ke.call(null,ex,b))?new Ew(c,this.cc,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(Jx,b):$CLJS.ke.call(null,Jx,b))?new Ew(this.dc,c,this.kc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g(Uw,b):$CLJS.ke.call(null,Uw,b))?new Ew(this.dc,this.cc,c,this.offset,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g($CLJS.xt,b):$CLJS.ke.call(null,$CLJS.xt,b))?new Ew(this.dc,this.cc,this.kc,c,this.S,
this.G,null):new Ew(this.dc,this.cc,this.kc,this.offset,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.kf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.ag(ex,this.dc),new $CLJS.ag(Jx,this.cc),new $CLJS.ag(Uw,this.kc),new $CLJS.ag($CLJS.xt,this.offset)],null),this.G))};$CLJS.g.P=function(a,b){return new Ew(this.dc,this.cc,this.kc,this.offset,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.Bd(b)?this.ma(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.db($CLJS.pb,this,b)};
var yba=new $CLJS.h(null,3,[2,"#b",8,"#o",16,"#x"],null),Yw=new $CLJS.P(null,20,5,$CLJS.Q,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),zy=new $CLJS.P(null,20,5,$CLJS.Q,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),Zw=new $CLJS.P(null,10,5,$CLJS.Q,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),Vca=new $CLJS.P(null,10,5,$CLJS.Q,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),ax=new $CLJS.P(null,22,5,$CLJS.Q," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),Wca=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),Xca=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),Aba=new $CLJS.h(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);zx.prototype.P=function(a,b){return new zx(this.Ca,b)};zx.prototype.O=function(){return this.Fh};zx.prototype.rc=function(){return Iu(this.Ca)};
zx.prototype.Mc=function(a,b){a=$CLJS.$a(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.bc(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.bc(this.Ca,Lu(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};Bx.prototype.P=function(a,b){return new Bx(this.Ca,b)};Bx.prototype.O=function(){return this.Gh};Bx.prototype.rc=function(){return Iu(this.Ca)};
Bx.prototype.Mc=function(a,b){a=$CLJS.$a(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.bc(this.Ca,b.toUpperCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return $CLJS.bc(this.Ca,Lu(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};Cx.prototype.P=function(a,b){return new Cx(this.Ca,this.Jd,b)};Cx.prototype.O=function(){return this.Hh};Cx.prototype.rc=function(){return Iu(this.Ca)};
Cx.prototype.Mc=function(a,b){a=$CLJS.$a(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return $CLJS.bc(this.Ca,Sba(b.toLowerCase(),$CLJS.q(this.Jd))),0<b.length?$CLJS.af(this.Jd,$CLJS.Ba($CLJS.F(b,$CLJS.D(b)-1))):null;if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=Lu(b),a=$CLJS.n($CLJS.q(this.Jd))?b.toUpperCase():b,$CLJS.bc(this.Ca,a),$CLJS.af(this.Jd,$CLJS.Ba(b));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
Dx.prototype.P=function(a,b){return new Dx(this.Ca,this.vd,b)};Dx.prototype.O=function(){return this.Jh};Dx.prototype.rc=function(){return Iu(this.Ca)};
Dx.prototype.Mc=function(a,b){a=$CLJS.$a(b);if($CLJS.n($CLJS.E.g?$CLJS.E.g(String,a):$CLJS.E.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Va($CLJS.q(this.vd))?(a=RegExp("\\S","g").exec(b),a=$CLJS.n(a)?a.index:a,$CLJS.n(a)?($CLJS.bc(this.Ca,[b.substring(0,a),$CLJS.F(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.af(this.vd,!0)):$CLJS.bc(this.Ca,b)):$CLJS.bc(this.Ca,b.toLowerCase());if($CLJS.n($CLJS.E.g?$CLJS.E.g(Number,a):$CLJS.E.call(null,Number,a)))return b=Lu(b),a=$CLJS.Va($CLJS.q(this.vd)),
$CLJS.n(a?Gu(b):a)?($CLJS.af(this.vd,!0),$CLJS.bc(this.Ca,b.toUpperCase())):$CLJS.bc(this.Ca,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
var eca=$CLJS.Lg("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.h(null,5,[Fx,"A",Uw,new $CLJS.h(null,4,[Fw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Gw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,null,Gx,null],null),null),Ix,$CLJS.N,Hx,function(){return function(a,b){return Sw($CLJS.Ww,a,b)}}],null),new $CLJS.h(null,
5,[Fx,"S",Uw,new $CLJS.h(null,4,[Fw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Gw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,null,Gx,null],null),null),Ix,$CLJS.N,Hx,function(){return function(a,b){return Sw($CLJS.Ph,a,b)}}],null),new $CLJS.h(null,5,[Fx,"D",Uw,new $CLJS.h(null,4,[Fw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Hw,new $CLJS.P(null,
2,5,$CLJS.Q,[" ",String],null),Nw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Iw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,null,Gx,null],null),null),Ix,$CLJS.N,Hx,function(){return function(a,b){return Xw(10,a,b)}}],null),new $CLJS.h(null,5,[Fx,"B",Uw,new $CLJS.h(null,4,[Fw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Nw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Iw,new $CLJS.P(null,
2,5,$CLJS.Q,[3,Number],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,null,Gx,null],null),null),Ix,$CLJS.N,Hx,function(){return function(a,b){return Xw(2,a,b)}}],null),new $CLJS.h(null,5,[Fx,"O",Uw,new $CLJS.h(null,4,[Fw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Nw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Iw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,null,Gx,
null],null),null),Ix,$CLJS.N,Hx,function(){return function(a,b){return Xw(8,a,b)}}],null),new $CLJS.h(null,5,[Fx,"X",Uw,new $CLJS.h(null,4,[Fw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Nw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Iw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,null,Gx,null],null),null),Ix,$CLJS.N,Hx,function(){return function(a,b){return Xw(16,a,b)}}],null),new $CLJS.h(null,
5,[Fx,"R",Uw,new $CLJS.h(null,5,[qu,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Fw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Nw,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),Iw,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,null,Gx,null],null),null),Ix,$CLJS.N,Hx,function(a){return $CLJS.n($CLJS.A(qu.h(a)))?function(b,c){return Xw(qu.h(b),b,c)}:$CLJS.n(function(){var b=nw.h(a);return $CLJS.n(b)?
lw.h(a):b}())?function(b,c){return cx(Wca,c)}:$CLJS.n(nw.h(a))?function(b,c){return cx(Xca,c)}:$CLJS.n(lw.h(a))?function(b,c){b=Aw(c);c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);if($CLJS.E.g(0,c))Rw.l($CLJS.H(["zeroth"]));else{var d=Tw(1E3,0>c?-c:c);if($CLJS.D(d)<=$CLJS.D(ax)){var e=$CLJS.lf.g($w,$CLJS.Ru(1,d));e=bx(e,1);var f=$CLJS.kd(d);d=$CLJS.Zd(f,100);f=Mu(f,100);var k=0<d?[$CLJS.p.h($CLJS.F(Yw,d))," hundred"].join(""):null,l=$CLJS.p,m=l.h;if(0<f)if(20>f)var t=$CLJS.F(zy,f);else{t=$CLJS.Zd(f,10);
var u=Mu(f,10);t=0<t&&!(0<u)?$CLJS.F(Vca,t):[$CLJS.p.h(0<t?$CLJS.F(Zw,t):null),0<t&&0<u?"-":null,$CLJS.p.h(0<u?$CLJS.F(zy,u):null)].join("")}else t=0<d?"th":null;d=[k,0<d&&0<f?" ":null,m.call(l,t)].join("");Rw.l($CLJS.H([[0>c?"minus ":null,$CLJS.ud(e)||$CLJS.ud(d)?$CLJS.ud(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else Xw(10,new $CLJS.h(null,5,[Fw,0,Hw," ",Nw,",",Iw,3,lw,!0],null),sw(new $CLJS.P(null,1,5,$CLJS.Q,[c],null))),e=Mu(c,100),c=11<e||19>e,e=Mu(e,10),Rw.l($CLJS.H([1===e&&c?
"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=Aw(c);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);if($CLJS.E.g(0,b))Rw.l($CLJS.H(["zero"]));else{var d=Tw(1E3,0>b?-b:b);$CLJS.D(d)<=$CLJS.D(ax)?(d=$CLJS.lf.g($w,d),d=bx(d,0),Rw.l($CLJS.H([[0>b?"minus ":null,d].join("")]))):Xw(10,new $CLJS.h(null,5,[Fw,0,Hw," ",Nw,",",Iw,3,lw,!0],null),sw(new $CLJS.P(null,1,5,$CLJS.Q,[b],null)))}return c}}],null),new $CLJS.h(null,5,[Fx,"P",Uw,$CLJS.N,Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,
null,Gx,null],null),null),Ix,$CLJS.N,Hx,function(){return function(a,b){b=$CLJS.n(lw.h(a))?Cw(b,-1):b;a=$CLJS.n(nw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,["y","ies"],null):new $CLJS.P(null,2,5,$CLJS.Q,["","s"],null);var c=Aw(b);b=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);Rw.l($CLJS.H([$CLJS.E.g(b,1)?$CLJS.A(a):$CLJS.jd(a)]));return c}}],null),new $CLJS.h(null,5,[Fx,"C",Uw,new $CLJS.h(null,1,[Ow,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,null,
Gx,null],null),null),Ix,$CLJS.N,Hx,function(a){return $CLJS.n(lw.h(a))?Bba:$CLJS.n(nw.h(a))?Cba:Dba}],null),new $CLJS.h(null,5,[Fx,"F",Uw,new $CLJS.h(null,5,[fx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),hx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.ix,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),jx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,1,[nw,null],null),null),Ix,$CLJS.N,Hx,
function(){return sx}],null),new $CLJS.h(null,5,[Fx,"E",Uw,new $CLJS.h(null,7,[fx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),hx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),ox,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.ix,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),jx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),px,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,1,[nw,null],
null),null),Ix,$CLJS.N,Hx,function(){return vx}],null),new $CLJS.h(null,5,[Fx,"G",Uw,new $CLJS.h(null,7,[fx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),hx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),ox,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.ix,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),jx,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),px,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,
1,[nw,null],null),null),Ix,$CLJS.N,Hx,function(){return Fba}],null),new $CLJS.h(null,5,[Fx,"$",Uw,new $CLJS.h(null,4,[hx,new $CLJS.P(null,2,5,$CLJS.Q,[2,Number],null),$CLJS.sn,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),fx,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,null,Gx,null],null),null),Ix,$CLJS.N,Hx,function(){return Gba}],null),new $CLJS.h(null,5,[Fx,"%",Uw,new $CLJS.h(null,1,[$CLJS.iy,
new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),Ex,$CLJS.eh,Ix,$CLJS.N,Hx,function(){return function(a,b){a=$CLJS.iy.h(a);for(var c=0;;)if(c<a)Uu(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[Fx,"\x26",Uw,new $CLJS.h(null,1,[$CLJS.iy,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,1,[qy,null],null),null),Ix,$CLJS.N,Hx,function(){return function(a,b){a=$CLJS.iy.h(a);0<a&&((null!=$CLJS.Tu?$CLJS.Tu.C&32768||$CLJS.Bc===$CLJS.Tu.ag||($CLJS.Tu.C?
0:$CLJS.Za($CLJS.Hu,$CLJS.Tu)):$CLJS.Za($CLJS.Hu,$CLJS.Tu))?$CLJS.E.g(0,$CLJS.$u(qu.h($CLJS.q($CLJS.q($CLJS.Tu))),$CLJS.pu))||Uu():Uu());--a;for(var c=0;;)if(c<a)Uu(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[Fx,"|",Uw,new $CLJS.h(null,1,[$CLJS.iy,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),Ex,$CLJS.eh,Ix,$CLJS.N,Hx,function(){return function(a,b){a=$CLJS.iy.h(a);for(var c=0;;)if(c<a)Rw.l($CLJS.H(["\f"])),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[Fx,"~",Uw,new $CLJS.h(null,
1,[$CLJS.sn,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),Ex,$CLJS.eh,Ix,$CLJS.N,Hx,function(){return function(a,b){a=$CLJS.sn.h(a);Rw.l($CLJS.H([$CLJS.Ve.g($CLJS.p,$CLJS.gf(a,"~"))]));return b}}],null),new $CLJS.h(null,5,[Fx,"\n",Uw,$CLJS.N,Ex,new $CLJS.dh(null,new $CLJS.h(null,2,[lw,null,nw,null],null),null),Ix,$CLJS.N,Hx,function(){return function(a,b){$CLJS.n(nw.h(a))&&Uu();return b}}],null),new $CLJS.h(null,5,[Fx,"T",Uw,new $CLJS.h(null,2,[Ux,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),
Gw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,2,[nw,null,qy,null],null),null),Ix,$CLJS.N,Hx,function(a){return $CLJS.n(nw.h(a))?function(b,c){var d=Ux.h(b);b=Gw.h(b);var e=d+$CLJS.$u(qu.h($CLJS.q($CLJS.q($CLJS.Tu))),$CLJS.pu);e=0<b?Mu(e,b):0;d+=$CLJS.E.g(0,e)?0:b-e;Rw.l($CLJS.H([$CLJS.Ve.g($CLJS.p,$CLJS.gf(d," "))]));return c}:function(b,c){var d=Ux.h(b);b=Gw.h(b);var e=$CLJS.$u(qu.h($CLJS.q($CLJS.q($CLJS.Tu))),$CLJS.pu);d=e<d?d-e:$CLJS.E.g(b,0)?0:b-
Mu(e-d,b);Rw.l($CLJS.H([$CLJS.Ve.g($CLJS.p,$CLJS.gf(d," "))]));return c}}],null),new $CLJS.h(null,5,[Fx,"*",Uw,new $CLJS.h(null,1,[$CLJS.sn,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,2,[lw,null,nw,null],null),null),Ix,$CLJS.N,Hx,function(){return function(a,b){var c=$CLJS.sn.h(a);return $CLJS.n(nw.h(a))?Dw(b,c):Cw(b,$CLJS.n(lw.h(a))?-c:c)}}],null),new $CLJS.h(null,5,[Fx,"?",Uw,$CLJS.N,Ex,new $CLJS.dh(null,new $CLJS.h(null,1,[nw,null],null),null),Ix,
$CLJS.N,Hx,function(a){return $CLJS.n(nw.h(a))?function(b,c){var d=Bw(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return kx(c,d,dx.h(b))}:function(b,c){var d=Bw(c);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);var e=Aw(d);d=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);d=sw(d);kx(c,d,dx.h(b));return e}}],null),new $CLJS.h(null,5,[Fx,"(",Uw,$CLJS.N,Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,null,Gx,null],null),null),Ix,new $CLJS.h(null,3,[$CLJS.Kx,")",Ox,null,$CLJS.wt,null],null),Hx,function(a){var b=$CLJS.n(function(){var c=
nw.h(a);return $CLJS.n(c)?lw.h(a):c}())?Rba:$CLJS.n(lw.h(a))?Tba:$CLJS.n(nw.h(a))?Uba:Qba;return function(c,d){a:{var e=$CLJS.A($CLJS.rx.h(c)),f=$CLJS.Tu;$CLJS.Tu=b.h?b.h($CLJS.Tu):b.call(null,$CLJS.Tu);try{var k=kx(e,d,dx.h(c));break a}finally{$CLJS.Tu=f}k=void 0}return k}}],null),new $CLJS.h(null,5,[Fx,")",Uw,$CLJS.N,Ex,$CLJS.eh,Ix,$CLJS.N,Hx,function(){return null}],null),new $CLJS.h(null,5,[Fx,"[",Uw,new $CLJS.h(null,1,[qx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),Ex,new $CLJS.dh(null,
new $CLJS.h(null,2,[lw,null,nw,null],null),null),Ix,new $CLJS.h(null,3,[$CLJS.Kx,"]",Ox,!0,$CLJS.wt,$CLJS.ny],null),Hx,function(a){return $CLJS.n(lw.h(a))?Iba:$CLJS.n(nw.h(a))?Jba:Hba}],null),new $CLJS.h(null,5,[Fx,";",Uw,new $CLJS.h(null,2,[xx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),yx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,1,[lw,null],null),null),Ix,new $CLJS.h(null,1,[$CLJS.yt,!0],null),Hx,function(){return null}],null),new $CLJS.h(null,
5,[Fx,"]",Uw,$CLJS.N,Ex,$CLJS.eh,Ix,$CLJS.N,Hx,function(){return null}],null),new $CLJS.h(null,5,[Fx,"{",Uw,new $CLJS.h(null,1,[tx,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,null,Gx,null],null),null),Ix,new $CLJS.h(null,2,[$CLJS.Kx,"}",Ox,!1],null),Hx,function(a){var b=nw.h(a);b=$CLJS.n(b)?lw.h(a):b;return $CLJS.n(b)?Nba:$CLJS.n(lw.h(a))?Lba:$CLJS.n(nw.h(a))?Mba:Kba}],null),new $CLJS.h(null,5,[Fx,"}",Uw,$CLJS.N,Ex,new $CLJS.dh(null,
new $CLJS.h(null,1,[lw,null],null),null),Ix,$CLJS.N,Hx,function(){return null}],null),new $CLJS.h(null,5,[Fx,"\x3c",Uw,new $CLJS.h(null,4,[Fw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Gw,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),zw,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Hw,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,4,[lw,null,nw,null,Gx,null,qy,null],null),null),Ix,new $CLJS.h(null,3,[$CLJS.Kx,"\x3e",Ox,!0,$CLJS.wt,Nx],null),Hx,function(){return Pba}],
null),new $CLJS.h(null,5,[Fx,"\x3e",Uw,$CLJS.N,Ex,new $CLJS.dh(null,new $CLJS.h(null,1,[lw,null],null),null),Ix,$CLJS.N,Hx,function(){return null}],null),new $CLJS.h(null,5,[Fx,"^",Uw,new $CLJS.h(null,3,[jy,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),ly,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),ky,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,1,[lw,null],null),null),Ix,$CLJS.N,Hx,function(){return function(a,b){var c=jy.h(a),d=ly.h(a),e=
ky.h(a),f=$CLJS.n(lw.h(a))?Qw:Pw;return $CLJS.n($CLJS.n(c)?$CLJS.n(d)?e:d:c)?c<=d&&d<=e?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n($CLJS.n(c)?d:c)?$CLJS.E.g(c,d)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n(c)?$CLJS.E.g(c,0)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:($CLJS.n(lw.h(a))?$CLJS.ud($CLJS.cj.h(dx.h(a))):$CLJS.ud($CLJS.cj.h(b)))?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b}}],null),new $CLJS.h(null,5,[Fx,"W",Uw,$CLJS.N,Ex,new $CLJS.dh(null,new $CLJS.h(null,4,[lw,null,nw,null,
Gx,null,qy,null],null),null),Ix,$CLJS.N,Hx,function(a){if($CLJS.n(function(){var c=nw.h(a);return $CLJS.n(c)?c:lw.h(a)}())){var b=$CLJS.kf.g($CLJS.n(nw.h(a))?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bt,null,$CLJS.$x,null],null):$CLJS.Ff,$CLJS.n(lw.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[qy,!0],null):$CLJS.Ff);return function(c,d){d=Aw(d);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.n($CLJS.Ve.j(yy,c,b))?new $CLJS.P(null,2,5,$CLJS.Q,[Pw,d],null):d}}return function(c,d){d=Aw(d);c=$CLJS.I(d,0,null);d=
$CLJS.I(d,1,null);return $CLJS.n($CLJS.ew(c))?new $CLJS.P(null,2,5,$CLJS.Q,[Pw,d],null):d}}],null),new $CLJS.h(null,5,[Fx,"_",Uw,$CLJS.N,Ex,new $CLJS.dh(null,new $CLJS.h(null,3,[lw,null,nw,null,Gx,null],null),null),Ix,$CLJS.N,Hx,function(){return Wba}],null),new $CLJS.h(null,5,[Fx,"I",Uw,new $CLJS.h(null,1,[$CLJS.sn,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null)],null),Ex,new $CLJS.dh(null,new $CLJS.h(null,1,[lw,null],null),null),Ix,$CLJS.N,Hx,function(){return Vba}],null)]),Xba=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,
cca=new $CLJS.dh(null,new $CLJS.h(null,2,[pw,null,ow,null],null),null),$ba=new $CLJS.h(null,2,[":",lw,"@",nw],null),tw=function tw(a){for(;;){if($CLJS.ud(a))return!1;var c=qy.h(Ex.h(Jx.h($CLJS.A(a))));$CLJS.n(c)||(c=$CLJS.Ue(tw,$CLJS.A($CLJS.rx.h(Uw.h($CLJS.A(a))))),c=$CLJS.n(c)?c:$CLJS.Ue(tw,$CLJS.A($CLJS.wt.h(Uw.h($CLJS.A(a))))));if($CLJS.n(c))return!0;a=$CLJS.B(a)}},dy=$CLJS.Sh(rw),Vx=new $CLJS.h(null,6,[$CLJS.Uj,"'",$CLJS.Yj,"#'",$CLJS.Mca,"@",$CLJS.Nca,"~",Pda,"@",Hda,"~"],null);
(function(){var a=dy("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=sw(d);return uw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()})();
var Ay=function(){var a=dy("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=sw(d);return uw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),By=new $CLJS.h(null,2,["core$future_call","Future","core$promise","Promise"],null),Yca=function(){var a=dy("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=sw(d);return uw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),Cy,pea=$CLJS.$e($CLJS.N),qea=$CLJS.$e($CLJS.N),rea=$CLJS.$e($CLJS.N),sea=$CLJS.$e($CLJS.N),tea=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
Cy=new $CLJS.hi($CLJS.Ih.g("cljs.pprint","simple-dispatch"),by,tea,pea,qea,rea,sea);Nu(Cy,$CLJS.Xj,function(a){if($CLJS.Va(Wx(a)))if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{var b=gw,c=bw;gw+=1;bw=0;try{Wv("(",")");for(var d=0,e=$CLJS.y(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.ew($CLJS.A(e)),$CLJS.B(e))){$CLJS.bc($CLJS.Tu," ");mw(Yv);a=d+1;var f=$CLJS.B(e);d=a;e=f;continue}}else $CLJS.bc($CLJS.Tu,"...");break}$v()}finally{bw=c,gw=b}}return null});Nu(Cy,$CLJS.kk,Xx);Nu(Cy,$CLJS.Dk,Yx);
Nu(Cy,$CLJS.uj,Ay);Nu(Cy,null,function(){return $CLJS.bc($CLJS.Tu,$CLJS.Ph.l($CLJS.H([null])))});Nu(Cy,$CLJS.mi,Zx);dw=Cy;
var Dy=function(){var a=dy("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=sw(d);return uw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),Ey=function(){var a=dy("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.w(f,0,null)}return c.call(this,e)}function c(d){d=sw(d);return uw(a,d)}b.A=0;b.B=function(d){d=$CLJS.y(d);return c(d)};b.l=c;return b}()}(),Fy=$CLJS.N,dda=function(a){return $CLJS.hg.g($CLJS.N,$CLJS.mf($CLJS.Xd,$CLJS.H([function(){return function d(c){return new $CLJS.qe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.te(k);a:for(var m=0;;)if(m<k){var t=$CLJS.md(f,m);t=new $CLJS.P(null,
2,5,$CLJS.Q,[t,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ih.h($CLJS.jh($CLJS.A(t))),$CLJS.jd(t)],null)],null);l.add(t);m+=1}else{f=!0;break a}return f?$CLJS.we($CLJS.ye(l),d($CLJS.mc(e))):$CLJS.we($CLJS.ye(l),null)}l=$CLJS.A(e);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ih.h($CLJS.jh($CLJS.A(l))),$CLJS.jd(l)],null)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.hg.g($CLJS.N,$CLJS.lf.g(function(b){var c=$CLJS.I(b,0,null),d=$CLJS.I(b,
1,null);var e=$CLJS.le(c);e=$CLJS.n(e)?e:$CLJS.Ld(new $CLJS.dh(null,new $CLJS.h(null,24,[$CLJS.fj,"null",$CLJS.vj,"null",$CLJS.wk,"null",$CLJS.yk,"null",$CLJS.qj,"null",$CLJS.Qj,"null",$CLJS.Mj,"null",$CLJS.Rj,"null",$CLJS.vi,"null",$CLJS.bk,"null",$CLJS.Gj,"null",$CLJS.yj,"null",$CLJS.jk,"null",$CLJS.xk,"null",$CLJS.Xi,"null",$CLJS.Si,"null",$CLJS.oj,"null",$CLJS.Aj,"null",$CLJS.gj,"null",$CLJS.Yj,"null",$CLJS.Uj,"null",$CLJS.pk,"null",$CLJS.Oi,"null",$CLJS.mk,"null"],null),null),c);return $CLJS.Va(e)?
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ih.g("clojure.core",$CLJS.jh(c)),d],null):b},a))}($CLJS.Lg([$CLJS.gj,$CLJS.Si,nda,$CLJS.bk,Bda,kda,mda,Cda,zda,yda,Ada,dea,Xda,$CLJS.mk,Uda,aea,Zda,mea,hda,$CLJS.yj,hea,iea,lea,$CLJS.Vj,Lda,nea,ida,lda,xda,jea],[Dy,function(a){var b=$CLJS.jd(a),c=$CLJS.A($CLJS.Lc($CLJS.Lc(a)));if($CLJS.Bd(b)){a=Fy;Fy=$CLJS.E.g(1,$CLJS.D(b))?$CLJS.He([$CLJS.A(b),"%"]):$CLJS.hg.g($CLJS.N,$CLJS.lf.j(function(d,e){return new $CLJS.P(null,2,5,$CLJS.Q,[d,["%",$CLJS.p.h(e)].join("")],
null)},b,$CLJS.Pu(1,$CLJS.D(b)+1)));try{return function(){var d=dy("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(k){var l=null;if(0<arguments.length){l=0;for(var m=Array(arguments.length-0);l<m.length;)m[l]=arguments[l+0],++l;l=new $CLJS.w(m,0,null)}return f.call(this,l)}function f(k){k=sw(k);return uw(d,k)}e.A=0;e.B=function(k){k=$CLJS.y(k);return f(k)};e.l=f;return e}()}()(c)}finally{Fy=a}}else return ey(a)},gy,Ey,function(a){if(3<$CLJS.D(a)){if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,
"#");else{var b=gw,c=bw;gw+=1;bw=0;try{Wv("(",")");qw(Zv,1);$CLJS.Ve.g(function(){var l=dy("~w ~@_~w ~@_~w ~_");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.w(x,0,null)}return t.call(this,v)}function t(u){u=sw(u);return uw(l,u)}m.A=0;m.B=function(u){u=$CLJS.y(u);return t(u)};m.l=t;return m}()}(),a);for(var d=0,e=$CLJS.y($CLJS.ef(3,a));;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,
"#");else{a=gw;var f=bw;gw+=1;bw=0;try{Wv(null,null),$CLJS.ew($CLJS.A(e)),$CLJS.B(e)&&($CLJS.bc($CLJS.Tu," "),mw(Vv),$CLJS.ew($CLJS.jd(e))),$v()}finally{bw=f,gw=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.bc($CLJS.Tu," ");mw(Yv);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.bc($CLJS.Tu,"...");break}$v()}finally{bw=c,gw=b}}return null}return ey(a)},Dy,fy,fy,gy,Dy,gy,Ey,Ey,Dy,Ey,gy,gy,Dy,gy,function(a){if($CLJS.B(a)){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=
"string"===typeof $CLJS.A(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(c),$CLJS.B(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);d=$CLJS.zd($CLJS.A(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(d),$CLJS.B(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{d=gw;var k=bw;gw+=1;bw=0;try{Wv("(",")");(function(){var m=dy("~w ~1I~@_~w");return function(){function t(v){var x=
null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,x)}function u(v){v=sw(v);return uw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()})()(a,b);$CLJS.n($CLJS.n(c)?c:$CLJS.n(e)?e:$CLJS.y(f))&&function(){var m=dy("~@:_");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,
x)}function u(v){v=sw(v);return uw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};t.l=u;return t}()}()();$CLJS.n(c)&&vw(!0,'"~a"~:[~;~:@_~]',$CLJS.H([c,$CLJS.n(e)?e:$CLJS.y(f)]));$CLJS.n(e)&&function(){var m=dy("~w~:[~;~:@_~]");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var z=Array(arguments.length-0);x<z.length;)z[x]=arguments[x+0],++x;x=new $CLJS.w(z,0,null)}return u.call(this,x)}function u(v){v=sw(v);return uw(m,v)}t.A=0;t.B=function(v){v=$CLJS.y(v);return u(v)};
t.l=u;return t}()}()(e,$CLJS.y(f));for(a=f;;){lca($CLJS.A(a));var l=$CLJS.B(a);if(l)b=l,mw(Yv),a=b;else break}$v()}finally{bw=k,gw=d}}return null}return $CLJS.ew(a)},gy,function(a){if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{var b=gw,c=bw;gw+=1;bw=0;try{Wv("(",")");qw(Zv,1);$CLJS.ew($CLJS.A(a));if($CLJS.B(a)){$CLJS.bc($CLJS.Tu," ");mw(Yv);for(var d=0,e=$CLJS.B(a);;){if($CLJS.Va($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{a=gw;var f=bw;gw+=1;bw=0;try{Wv(null,null),$CLJS.ew($CLJS.A(e)),
$CLJS.B(e)&&($CLJS.bc($CLJS.Tu," "),mw(Vv),$CLJS.ew($CLJS.jd(e))),$v()}finally{bw=f,gw=a}}if($CLJS.B($CLJS.Lc(e))){$CLJS.bc($CLJS.Tu," ");mw(Yv);a=d+1;var k=$CLJS.B($CLJS.Lc(e));d=a;e=k;continue}}}else $CLJS.bc($CLJS.Tu,"...");break}}$v()}finally{bw=c,gw=b}}return null},gy,fy,fy,Dy,Dy,gy,gy,Dy]))),Gy,uea=$CLJS.$e($CLJS.N),vea=$CLJS.$e($CLJS.N),wea=$CLJS.$e($CLJS.N),xea=$CLJS.$e($CLJS.N),yea=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
Gy=new $CLJS.hi($CLJS.Ih.g("cljs.pprint","code-dispatch"),by,yea,uea,vea,wea,xea);Nu(Gy,$CLJS.Xj,function(a){if($CLJS.Va(Wx(a))){var b=dda.call(null,$CLJS.A(a));return $CLJS.n(b)?b.h?b.h(a):b.call(null,a):ey(a)}return null});Nu(Gy,$CLJS.Ti,function(a){var b=a.h?a.h(Fy):a.call(null,Fy);return $CLJS.n(b)?Rw.l($CLJS.H([b])):$CLJS.n(xy)?Rw.l($CLJS.H([$CLJS.jh(a)])):Su.call(null,a)});Nu(Gy,$CLJS.kk,Xx);Nu(Gy,$CLJS.Dk,Yx);Nu(Gy,$CLJS.uj,Ay);Nu(Gy,Qx,Yca);
Nu(Gy,Rx,function(a){var b=$CLJS.p,c=b.h,d=$CLJS.$a(a).name;var e=$CLJS.rh(/^[^$]+\$[^$]+/,d);e=$CLJS.n(e)?By.h?By.h(e):By.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.n(e)?e:d),"@",$CLJS.p.h($CLJS.za(a)),": "].join("");if($CLJS.n(hw()))$CLJS.bc($CLJS.Tu,"#");else{c=gw;d=bw;gw+=1;bw=0;try{Wv(b,"\x3e");qw(Zv,-(b.length-2));mw(Yv);var f=null!=a?a.I&1||$CLJS.Bc===a.jj?!0:a.I?!1:$CLJS.Za(Ju,a):$CLJS.Za(Ju,a);var k=f?!Ku(a):f;$CLJS.ew(k?Yda:$CLJS.q(a));$v()}finally{bw=d,gw=c}}return null});Nu(Gy,null,Su);
Nu(Gy,$CLJS.mi,Zx);dw=Cy;