var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.js");
'use strict';var jra=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.KB);return $CLJS.Ue(function(c){return $CLJS.MC(b,c)},$CLJS.GF)},X7=new $CLJS.M("metabase.lib.order-by","order-by-clause","metabase.lib.order-by/order-by-clause",774979647);$CLJS.MF($CLJS.xG,X7);$CLJS.MF($CLJS.qG,X7);
$CLJS.R0.m(null,$CLJS.cS,function(a,b){var c=$CLJS.Ie($CLJS.cS.h($CLJS.WW(a,b)));return $CLJS.n(c)?$CLJS.BE("Sorted by {0}",$CLJS.H([$CLJS.p2($CLJS.eF("and"),function(){return function f(e){return new $CLJS.qe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u);v=$CLJS.q0.v(a,b,v,$CLJS.D0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.we($CLJS.ye(t),f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),null)}t=$CLJS.A(k);
return $CLJS.he($CLJS.q0.v(a,b,t,$CLJS.D0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.O0.m(null,X7,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.q0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "asc":return $CLJS.BE("{0} ascending",$CLJS.H([a]));case "desc":return $CLJS.BE("{0} descending",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.V0.m(null,X7,function(a,b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.R.j($CLJS.t0.j(a,b,c),$CLJS.toa,d)});var J7,ixa=$CLJS.$e($CLJS.N),jxa=$CLJS.$e($CLJS.N),kxa=$CLJS.$e($CLJS.N),lxa=$CLJS.$e($CLJS.N),mxa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));J7=new $CLJS.hi($CLJS.Ih.g("metabase.lib.order-by","order-by-clause-method"),$CLJS.yC,mxa,ixa,jxa,kxa,lxa);J7.m(null,X7,function(a){return $CLJS.GW(a)});
J7.m(null,$CLJS.mi,function(a){if(null==a)throw $CLJS.li($CLJS.eF("Can''t order by nil"),$CLJS.N);return $CLJS.GW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xG,$CLJS.HW(a)],null))});
$CLJS.K7=function(){function a(d,e){d=J7.h(d);e=$CLJS.n(e)?e:$CLJS.xG;return $CLJS.R.j($CLJS.Gf(d),0,e)}function b(d){return c.g?c.g(d,$CLJS.xG):c.call(null,d,$CLJS.xG)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.L7=function(){function a(e,f,k,l){f=$CLJS.n(f)?f:-1;var m=function(){var t=J7.h(k);return $CLJS.n(l)?$CLJS.R.j($CLJS.Gf(t),0,l):t}();return $CLJS.gX.l(e,f,$CLJS.Xk,$CLJS.H([$CLJS.cS,function(t){return $CLJS.ee.g($CLJS.Gf(t),m)}]))}function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.v?d.v(e,-1,f,null):d.call(null,e,-1,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,
e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();$CLJS.M7=function(){function a(d,e){return $CLJS.Ie($CLJS.J.g($CLJS.WW(d,e),$CLJS.cS))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.N7=function(){function a(d,e){var f=$CLJS.Ie($CLJS.U5.g(d,e)),k=$CLJS.Ie($CLJS.L4.g(d,e));f=$CLJS.n($CLJS.n(f)?f:k)?$CLJS.kf.g(f,k):function(){var t=$CLJS.WW(d,e);return $CLJS.Z0.v(d,e,t,new $CLJS.h(null,1,[$CLJS.v1,!1],null))}();var l=$CLJS.of(jra,f);f=$CLJS.lf.g(function(t){$CLJS.I(t,0,null);$CLJS.I(t,1,null);return $CLJS.I(t,2,null)},$CLJS.M7.g(d,e));if($CLJS.ud(l))return null;if($CLJS.ud(f))return $CLJS.Gf(l);var m=$CLJS.hg.j($CLJS.N,$CLJS.Uk.g($CLJS.lf.h($CLJS.HW),$CLJS.XY(function(t,u){u=
$CLJS.z2.v(d,e,u,l);return $CLJS.n(u)?new $CLJS.P(null,2,5,$CLJS.Q,[u,t],null):null})),f);return $CLJS.Wk.g(function(t){var u=m.h?m.h(t):m.call(null,t);return $CLJS.n(u)?$CLJS.R.j(t,$CLJS.M0,u):t},l)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.O7=new $CLJS.h(null,2,[$CLJS.xG,$CLJS.qG,$CLJS.qG,$CLJS.xG],null);$CLJS.nxa=function(){function a(d,e){return $CLJS.gX.l(d,e,$CLJS.Lk,$CLJS.H([$CLJS.cS]))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();