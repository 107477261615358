var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var RH,SH,wH,xH,zfa,Afa,Bfa,yH,vH;$CLJS.PH=function(a,b){return $CLJS.gc($CLJS.db(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.kg.j(c,e,$CLJS.ee.g($CLJS.J.j(c,e,$CLJS.Ff),d))},$CLJS.ec($CLJS.N),b))};$CLJS.QH=function(a,b){return $CLJS.db(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
RH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.MC(b,$CLJS.no)?$CLJS.uH:$CLJS.MC(b,$CLJS.yo)?$CLJS.qH:$CLJS.MC(b,$CLJS.qo)?$CLJS.kE:null;return $CLJS.n(b)?$CLJS.LF(b,a):!0};
SH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.PH(function(d){return $CLJS.MC($CLJS.hF(d),$CLJS.sD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Sk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.hF($CLJS.A(a));return $CLJS.Ue(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(RH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
wH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.h(null,1,[$CLJS.Ht,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ME],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.$t,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Ij);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(SH(b))].join("")}],null),$CLJS.We(SH)],null)],null)};
xH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Ht,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ME],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.Wi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)],null)],null)};
zfa=function(a){return $CLJS.Td($CLJS.OF,$CLJS.lf.g(function(b){var c=$CLJS.hF(b),d=$CLJS.MC(c,$CLJS.oF);b=d?$CLJS.LF($CLJS.EG,b):d;return $CLJS.n(b)?$CLJS.ok:c},a))};Afa=function(a){a=$CLJS.QH(function(b){return!$CLJS.MC(b,$CLJS.sD)},$CLJS.lf.g($CLJS.hF,a));return $CLJS.MC(a,$CLJS.oF)?$CLJS.sk:a};
Bfa=function(a,b){return $CLJS.n($CLJS.Ue(function(c){return $CLJS.MC($CLJS.hF(c),$CLJS.sD)},b))?Afa(b):$CLJS.E.g(a,$CLJS.rt)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Te(function(c){return $CLJS.MC($CLJS.hF(c),$CLJS.no)},b)||$CLJS.Te(function(c){return $CLJS.MC($CLJS.hF(c),$CLJS.qo)},b))?$CLJS.sD:zfa(b)};yH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.zH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);vH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.js,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,vH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.hF(a);return $CLJS.Te(function(d){return RH(d,c)},b)}],null)],null));
$CLJS.X(yH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.Wi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)],null));
var iga=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Ht,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.rt],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ME],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null)],
null);$CLJS.cH.g($CLJS.js,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,wH($CLJS.js),xH($CLJS.js)],null));$CLJS.cH.g($CLJS.rt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,wH($CLJS.rt),iga,xH($CLJS.rt)],null));$CLJS.VF($CLJS.ls,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dt,yH],null)]));$CLJS.VF($CLJS.UG,$CLJS.H([$CLJS.rt,$CLJS.cE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dt,yH],null)]));
for(var AH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.js,$CLJS.rt,$CLJS.ls],null)),TH=null,UH=0,VH=0;;)if(VH<UH){var jga=TH.X(null,VH);$CLJS.MF(jga,$CLJS.zH);VH+=1}else{var WH=$CLJS.y(AH);if(WH){var XH=WH;if($CLJS.Cd(XH)){var YH=$CLJS.lc(XH),kga=$CLJS.mc(XH),lga=YH,mga=$CLJS.D(YH);AH=kga;TH=lga;UH=mga}else{var nga=$CLJS.A(XH);$CLJS.MF(nga,$CLJS.zH);AH=$CLJS.B(XH);TH=null;UH=0}VH=0}else break}
$CLJS.gF.m(null,$CLJS.zH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Bfa(a,b)});$CLJS.TF($CLJS.$F,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)]));$CLJS.MF($CLJS.$F,$CLJS.sF);
for(var ZH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WG,$CLJS.BF,$CLJS.nG],null)),$H=null,aI=0,bI=0;;)if(bI<aI){var oga=$H.X(null,bI);$CLJS.TF(oga,$CLJS.H([$CLJS.rt,$CLJS.cE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)]));bI+=1}else{var cI=$CLJS.y(ZH);if(cI){var dI=cI;if($CLJS.Cd(dI)){var eI=$CLJS.lc(dI),pga=$CLJS.mc(dI),qga=eI,rga=$CLJS.D(eI);ZH=pga;$H=qga;aI=rga}else{var xga=$CLJS.A(dI);$CLJS.TF(xga,$CLJS.H([$CLJS.rt,$CLJS.cE,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)]));ZH=$CLJS.B(dI);$H=null;aI=0}bI=0}else break}
for(var fI=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,$CLJS.yG,$CLJS.FG],null)),gI=null,hI=0,iI=0;;)if(iI<hI){var yga=gI.X(null,iI);$CLJS.TF(yga,$CLJS.H([$CLJS.rt,$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)]));iI+=1}else{var jI=$CLJS.y(fI);if(jI){var kI=jI;if($CLJS.Cd(kI)){var lI=$CLJS.lc(kI),zga=$CLJS.mc(kI),Aga=lI,Cga=$CLJS.D(lI);fI=zga;gI=Aga;hI=Cga}else{var Dga=$CLJS.A(kI);$CLJS.TF(Dga,$CLJS.H([$CLJS.rt,$CLJS.Oj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)]));fI=$CLJS.B(kI);gI=null;hI=0}iI=0}else break}$CLJS.TF($CLJS.XF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)]));
$CLJS.gF.m(null,$CLJS.XF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.MC($CLJS.hF(b),$CLJS.Oj)&&$CLJS.MC($CLJS.hF(a),$CLJS.Oj)?$CLJS.Oj:$CLJS.cE});