var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var bJ,dJ,gJ,pJ,sJ,xJ;$CLJS.aJ=new $CLJS.M(null,"get-month","get-month",-369374731);bJ=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.cJ=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);dJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.eJ=new $CLJS.M(null,"iso","iso",-1366207543);gJ=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.hJ=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.iJ=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.jJ=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.kJ=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.lJ=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.mJ=new $CLJS.M(null,"us","us",746429226);$CLJS.nJ=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.oJ=new $CLJS.M(null,"now","now",-1650525531);
pJ=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.qJ=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.rJ=new $CLJS.M(null,"get-day","get-day",127568857);sJ=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.tJ=new $CLJS.M(null,"get-minute","get-minute",1468086343);
$CLJS.uJ=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.vJ=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);$CLJS.wJ=new $CLJS.M(null,"get-hour","get-hour",622714059);xJ=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.yJ=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);
$CLJS.zJ=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);$CLJS.AJ=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.BJ=new $CLJS.M(null,"target","target",253001721);$CLJS.TF($CLJS.kC,$CLJS.H([$CLJS.rt,$CLJS.sD,$CLJS.Ki,$CLJS.kE]));$CLJS.gF.m(null,$CLJS.kJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.hF(a);return $CLJS.xd(a)?(a=$CLJS.Cu.g(a,new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.no,null,$CLJS.qo,null],null),null)),$CLJS.E.g($CLJS.D(a),1)?$CLJS.A(a):a):a});$CLJS.MF($CLJS.kJ,$CLJS.sF);
for(var CJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rC,$CLJS.uC],null)),DJ=null,EJ=0,FJ=0;;)if(FJ<EJ){var GJ=DJ.X(null,FJ);$CLJS.TF(GJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null),$CLJS.Ki,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)]));$CLJS.MF(GJ,$CLJS.kJ);FJ+=1}else{var HJ=$CLJS.y(CJ);if(HJ){var IJ=HJ;if($CLJS.Cd(IJ)){var JJ=$CLJS.lc(IJ),iha=$CLJS.mc(IJ),jha=JJ,kha=$CLJS.D(JJ);CJ=iha;DJ=jha;EJ=kha}else{var KJ=$CLJS.A(IJ);$CLJS.TF(KJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.ZE],null),$CLJS.Ki,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)]));$CLJS.MF(KJ,$CLJS.kJ);CJ=$CLJS.B(IJ);DJ=null;EJ=0}FJ=0}else break}
for(var LJ=$CLJS.y(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.iJ,$CLJS.aJ,$CLJS.rJ,$CLJS.wJ,$CLJS.tJ,$CLJS.hJ,$CLJS.yJ],null)),MJ=null,NJ=0,OJ=0;;)if(OJ<NJ){var lha=MJ.X(null,OJ);$CLJS.TF(lha,$CLJS.H([$CLJS.rt,$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null)]));OJ+=1}else{var PJ=$CLJS.y(LJ);if(PJ){var QJ=PJ;if($CLJS.Cd(QJ)){var RJ=$CLJS.lc(QJ),mha=$CLJS.mc(QJ),nha=RJ,oha=$CLJS.D(RJ);LJ=mha;MJ=nha;NJ=oha}else{var pha=$CLJS.A(QJ);$CLJS.TF(pha,$CLJS.H([$CLJS.rt,
$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null)]));LJ=$CLJS.B(QJ);MJ=null;NJ=0}OJ=0}else break}$CLJS.TF($CLJS.fC,$CLJS.H([$CLJS.rt,$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pH],null)]));
for(var SJ=$CLJS.y(new $CLJS.dh(null,new $CLJS.h(null,8,[$CLJS.yJ,null,$CLJS.tJ,null,$CLJS.wJ,null,$CLJS.uJ,null,$CLJS.hJ,null,$CLJS.aJ,null,$CLJS.iJ,null,$CLJS.rJ,null],null),null)),TJ=null,UJ=0,VJ=0;;)if(VJ<UJ){var qha=TJ.X(null,VJ);$CLJS.TF(qha,$CLJS.H([$CLJS.rt,$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null)]));VJ+=1}else{var WJ=$CLJS.y(SJ);if(WJ){var XJ=WJ;if($CLJS.Cd(XJ)){var YJ=$CLJS.lc(XJ),rha=$CLJS.mc(XJ),wha=YJ,xha=$CLJS.D(YJ);
SJ=rha;TJ=wha;UJ=xha}else{var yha=$CLJS.A(XJ);$CLJS.TF(yha,$CLJS.H([$CLJS.rt,$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null)]));SJ=$CLJS.B(XJ);TJ=null;UJ=0}VJ=0}else break}$CLJS.X(xJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,$CLJS.eJ,$CLJS.mJ,$CLJS.qJ],null));
$CLJS.VF($CLJS.pC,$CLJS.H([$CLJS.rt,$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xJ],null)],null)],null)],null)]));
$CLJS.X($CLJS.vJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,$CLJS.HE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,2,[$CLJS.Ht,"valid timezone ID",$CLJS.$t,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return["invalid timezone ID: ",$CLJS.Ph.l($CLJS.H([a]))].join("")}],null)],null),$CLJS.oH($CLJS.Md,$CLJS.fJ.tz.names())),$CLJS.FI],null)],null));
$CLJS.VF($CLJS.cJ,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vJ],null)],null)],null)],
null)]));$CLJS.MF($CLJS.cJ,$CLJS.kJ);$CLJS.TF($CLJS.oJ,$CLJS.H([$CLJS.rt,$CLJS.po]));$CLJS.X(gJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Ue(function(b){return $CLJS.MC(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.no,$CLJS.qo],null))}],null)],null));
$CLJS.X(bJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ME],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KB,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gJ],null)],null)],null)],null));
$CLJS.cH.g($CLJS.jJ,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Ht,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.jJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Ht,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.WI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DI],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.mi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rH],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Ht,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.iw],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.mi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jH],null)],null)],null)],null)],null)],null));
$CLJS.gF.m(null,$CLJS.jJ,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);a=$CLJS.E.g(b,$CLJS.iw)?$CLJS.E.g(a,$CLJS.mi)?$CLJS.qo:$CLJS.n($CLJS.LF($CLJS.rH,a))?$CLJS.no:$CLJS.qo:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.qh($CLJS.SI,b))?$CLJS.no:$CLJS.n($CLJS.qh($CLJS.TI,b))?$CLJS.no:null:null;if($CLJS.n(a))return a;b=$CLJS.hF(b);b=$CLJS.xd(b)?$CLJS.hg.j($CLJS.eh,$CLJS.nf(function(c){return $CLJS.MC(c,$CLJS.sk)}),b):b;return $CLJS.xd(b)&&
$CLJS.E.g($CLJS.D(b),1)?$CLJS.A(b):b});$CLJS.X(sJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.iw],null),$CLJS.Ki],null));
$CLJS.VF($CLJS.lC,$CLJS.H([$CLJS.rt,$CLJS.qo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sn,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)],null)],null)],null)]));
$CLJS.TF($CLJS.Yy,$CLJS.H([$CLJS.rt,$CLJS.yo,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.qH],null)]));$CLJS.X(dJ,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.rs,$CLJS.lH,$CLJS.Mi,$CLJS.Gk,$CLJS.lJ,$CLJS.zJ,$CLJS.AJ,$CLJS.gk,$CLJS.Qi,$CLJS.Gi,$CLJS.Cj,$CLJS.iH],null));$CLJS.X(pJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,$CLJS.eJ,$CLJS.mJ,$CLJS.qJ],null));
$CLJS.VF($CLJS.sC,$CLJS.H([$CLJS.rt,$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nJ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pJ],null)],null)],
null)],null)]));