var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var xra,D7,bxa,Bra,S7,T7,cxa;xra=function(a){if("string"===typeof a)return $CLJS.qh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.qh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.nm(a));};$CLJS.R7=function(a){return $CLJS.Xa(a)?$CLJS.hA(a,$CLJS.H([$CLJS.Di,!0])):a};
D7=function(a,b){if(null!=a&&null!=a.oh)a=a.oh(a,b);else{var c=D7[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=D7._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("MetadataProvider.setting",a);}return a};$CLJS.H7=function(a,b){return D7($CLJS.k0(a),b)};bxa=function(a,b,c){a=(b-a)/c;if(null!=a)a=Math.ceil(a);else throw $CLJS.li("Unexpected Null passed to ceil",new $CLJS.h(null,1,[$CLJS.ej,"ceil"],null));a=$CLJS.sa(a);return 1<a?a:1};
Bra=function(a,b,c){var d=xra(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.UA(d))/Math.log(10)));return $CLJS.Ue(function(f){f*=e;return f>=d?f:null},$CLJS.wra)};
$CLJS.I7=function(a,b,c){var d=$CLJS.l7(b);if($CLJS.n(d)){var e=$CLJS.SE.h(d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "num-bins":e=$CLJS.WA(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fO,$CLJS.rj,$CLJS.ok],null));if($CLJS.n(e)){a=$CLJS.O(e);e=$CLJS.J.g(a,$CLJS.Wi);var f=$CLJS.J.g(a,$CLJS.Fk);a=$CLJS.O(d);a=$CLJS.J.g(a,$CLJS.rE);a=Bra(e,f,a);return new $CLJS.h(null,3,[$CLJS.RE,a,$CLJS.VL,c,$CLJS.YL,c+a],null)}return null;case "bin-width":return a=$CLJS.O(d),a=$CLJS.J.g(a,$CLJS.RE),new $CLJS.h(null,
3,[$CLJS.RE,a,$CLJS.VL,c,$CLJS.YL,c+a],null);case "default":e=$CLJS.WA(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fO,$CLJS.rj,$CLJS.ok],null));if($CLJS.n(e)){d=$CLJS.O(e);e=$CLJS.J.g(d,$CLJS.Wi);f=$CLJS.J.g(d,$CLJS.Fk);a:switch(d=e,e=f,f=$CLJS.mi instanceof $CLJS.M?$CLJS.mi.T:null,f){case "num-bins":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rE,new $CLJS.h(null,2,[$CLJS.rE,null,$CLJS.RE,xra(((e-d)/0).toFixed(5))],null)],null);break a;case "bin-width":a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RE,new $CLJS.h(null,
2,[$CLJS.RE,null,$CLJS.rE,bxa(d,e,null)],null)],null);break a;case "default":$CLJS.x0(b)?(a=$CLJS.H7(a,$CLJS.Msa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RE,new $CLJS.h(null,2,[$CLJS.RE,a,$CLJS.rE,bxa(d,e,a)],null)],null)):(a=$CLJS.H7(a,$CLJS.Ksa),a=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rE,new $CLJS.h(null,2,[$CLJS.rE,a,$CLJS.RE,xra(((e-d)/a).toFixed(5))],null)],null));break a;default:throw Error(["No matching clause: ",$CLJS.p.h(f)].join(""));}if($CLJS.n(a))return $CLJS.I(a,0,null),a=$CLJS.I(a,1,null),
a=$CLJS.O(a),a=$CLJS.J.g(a,$CLJS.RE),new $CLJS.h(null,3,[$CLJS.RE,a,$CLJS.VL,c,$CLJS.YL,c+a],null)}return null;default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}}else return null};S7=function(a){var b=$CLJS.zA.h($CLJS.l3(a));b=b.h?b.h($CLJS.Zwa):b.call(null,$CLJS.Zwa);if($CLJS.n(b))a:for(b=a;;)if($CLJS.ud($CLJS.K4.g(b,-1))&&$CLJS.ud($CLJS.T5.g(b,-1))){if(b=$CLJS.Xk.j(b,$CLJS.GL,$CLJS.td),!$CLJS.y($CLJS.GL.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
T7=function(a,b){var c=S7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.QV(b,new $CLJS.h(null,2,[$CLJS.CW,$CLJS.$wa,$CLJS.bX,$CLJS.axa],null));var d=$CLJS.X0,e=d.j,f=a;var k=a;var l=$CLJS.o2(k,-1);k=$CLJS.n(l)?$CLJS.WW(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.z2.v(a,-2,$CLJS.HW(b),d);if($CLJS.n(b))a=$CLJS.Xk.j(a,$CLJS.GL,$CLJS.td);else return null}};
cxa=function(a,b,c){var d=function(){var e=$CLJS.n($CLJS.l7(b))?function(){var f=$CLJS.s5(b,null);if(null!=c){var k=$CLJS.I7(a,b,c);if($CLJS.n(k)){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.VL);l=$CLJS.J.g(l,$CLJS.YL);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.i6(f,k),$CLJS.h6(f,l)],null)}return null}return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.n2($CLJS.aG,new $CLJS.P(null,1,5,$CLJS.Q,[f],null))],null)}():null;if($CLJS.n(e))return e;e=function(){var f=$CLJS.$wa.h(b);return $CLJS.n(f)?$CLJS.I3(b,f):b}();return new $CLJS.P(null,
1,5,$CLJS.Q,[(0,$CLJS.C3)(e,c)],null)}();return $CLJS.db(function(e,f){return $CLJS.D3.j(e,-1,f)},a,d)};
$CLJS.dxa=function(a,b){b=$CLJS.O(b);var c=$CLJS.J.g(b,$CLJS.o6),d=$CLJS.J.g(b,$CLJS.WL);b=$CLJS.gX.l(a,-1,$CLJS.Lk,$CLJS.H([$CLJS.pG,$CLJS.oG,$CLJS.cS,$CLJS.zS,$CLJS.WE]));b=$CLJS.db(function(f,k){var l=$CLJS.O(k);k=$CLJS.J.g(l,$CLJS.hj);l=$CLJS.J.g(l,$CLJS.Ij);return cxa(f,k,l)},b,function(){return function l(k){return new $CLJS.qe(null,function(){for(var m=k;;)if(m=$CLJS.y(m)){if($CLJS.Cd(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.te(u);a:for(var x=0;;)if(x<u){var z=$CLJS.md(t,x);$CLJS.Sk.g($CLJS.$K.h($CLJS.hj.h(z)),
$CLJS.zL)&&v.add(z);x+=1}else{t=!0;break a}return t?$CLJS.we($CLJS.ye(v),l($CLJS.mc(m))):$CLJS.we($CLJS.ye(v),null)}v=$CLJS.A(m);if($CLJS.Sk.g($CLJS.$K.h($CLJS.hj.h(v)),$CLJS.zL))return $CLJS.he(v,l($CLJS.Lc(m)));m=$CLJS.Lc(m)}else return null},null,null)}(d)}());var e=function(){var f=$CLJS.kd(c);return $CLJS.n(f)?$CLJS.QH(function(k){return $CLJS.E.g($CLJS.B5(k),f)},$CLJS.K4.g(a,-1)):null}();return $CLJS.db(function(f,k){return $CLJS.D3.j(f,-1,k)},b,$CLJS.n(e)?function(){var f=$CLJS.A(e);switch(f instanceof
$CLJS.M?f.T:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kd(e)],null);case "metric":return $CLJS.E3.g($CLJS.R.j($CLJS.qZ.h((0,$CLJS.UY)($CLJS.m2($CLJS.R7(new $CLJS.h(null,2,[$CLJS.rj,$CLJS.WF,$CLJS.WF,$CLJS.R7($CLJS.UZ.h($CLJS.o0(a,$CLJS.kd(e))))],null)),$CLJS.rj,$CLJS.WF))),$CLJS.iQ,$CLJS.iQ.h(a)),-1);default:return null}}():null)};$CLJS.Z5.m(null,$CLJS.W6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.T6);b=$CLJS.J.g(b,$CLJS.a_);return new $CLJS.h(null,3,[$CLJS.rj,$CLJS.W6,$CLJS.T6,a,$CLJS.a_,b],null)});
$CLJS.Y5.m(null,$CLJS.W6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){return $CLJS.dxa(S7(c),$CLJS.Xk.j(e,$CLJS.WL,function(f){return function m(l){return new $CLJS.qe(null,function(){for(;;){var t=$CLJS.y(l);if(t){var u=t;if($CLJS.Cd(u)){var v=$CLJS.lc(u),x=$CLJS.D(v),z=$CLJS.te(x);return function(){for(var G=0;;)if(G<x){var K=$CLJS.md(v,
G);$CLJS.xe(z,$CLJS.Xk.j(K,$CLJS.hj,function(){return function(S){return T7(c,S)}}(G,K,v,x,z,u,t)));G+=1}else return!0}()?$CLJS.we($CLJS.ye(z),m($CLJS.mc(u))):$CLJS.we($CLJS.ye(z),null)}var C=$CLJS.A(u);return $CLJS.he($CLJS.Xk.j(C,$CLJS.hj,function(){return function(G){return T7(c,G)}}(C,u,t)),m($CLJS.Lc(u)))}return null}},null,null)}(f)}))}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=b;return a}());