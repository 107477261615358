var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.malli.registry.js");
'use strict';var UI,fha,gha,GI,HI,VI,XI,hha,ZI;UI=function(a){switch(arguments.length){case 2:return $CLJS.LF(arguments[0],arguments[1]);case 3:return $CLJS.KF(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.CI=new $CLJS.M("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
fha=new $CLJS.M("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.DI=new $CLJS.M("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);gha=new $CLJS.M("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.EI=new $CLJS.M("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
$CLJS.FI=new $CLJS.M("metabase.lib.schema.literal","string.zone-offset","metabase.lib.schema.literal/string.zone-offset",-437074263);GI=new $CLJS.M("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);HI=new $CLJS.M("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);VI=new $CLJS.M("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);
$CLJS.WI=new $CLJS.M("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);XI=new $CLJS.M("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);hha=new $CLJS.M("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.YI=new $CLJS.M("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);
ZI=new $CLJS.M("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);$CLJS.$I=new $CLJS.M("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.gF.m(null,$CLJS.NB,function(){return $CLJS.Wj});$CLJS.X(ZI,$CLJS.Rs);$CLJS.gF.m(null,$CLJS.PB,function(){return $CLJS.lk});$CLJS.X(ZI,$CLJS.Rs);$CLJS.X(hha,$CLJS.Ki);$CLJS.gF.m(null,$CLJS.CB,function(){return $CLJS.Oj});$CLJS.X(fha,$CLJS.Ns);$CLJS.gF.m(null,$CLJS.nB,function(){return $CLJS.cE});$CLJS.X(gha,$CLJS.tk);$CLJS.X(HI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ht,"date string literal"],null),$CLJS.QI],null));
$CLJS.X($CLJS.FI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ht,"timezone offset string literal"],null),$CLJS.tga],null));$CLJS.X(GI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ht,"local time string literal"],null),$CLJS.RI],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ht,"offset time string literal"],null),$CLJS.uga],null)],null));
$CLJS.X(VI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ht,"local date time string literal"],null),$CLJS.vga],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ht,"offset date time string literal"],null),$CLJS.wga],null)],null));
$CLJS.gF.m(null,$CLJS.BB,function(a){return $CLJS.n($CLJS.LF?$CLJS.LF(VI,a):UI.call(null,VI,a))?new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.Ak,null,$CLJS.qo,null],null),null):$CLJS.n($CLJS.LF?$CLJS.LF(HI,a):UI.call(null,HI,a))?new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.no,null,$CLJS.Ak,null],null),null):$CLJS.n($CLJS.LF?$CLJS.LF(GI,a):UI.call(null,GI,a))?new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.yo,null,$CLJS.Ak,null],null),null):$CLJS.Ak});$CLJS.X($CLJS.WI,HI);$CLJS.X($CLJS.EI,GI);
$CLJS.X($CLJS.$I,VI);$CLJS.X($CLJS.CI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,$CLJS.WI,$CLJS.EI,$CLJS.$I],null));$CLJS.X($CLJS.YI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ht,"year-month string literal"],null),$CLJS.SI],null));$CLJS.X($CLJS.DI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ht,"year string literal"],null),$CLJS.TI],null));
$CLJS.X(XI,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ME],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.QE],null)],null)],null));$CLJS.cH.g($CLJS.Ij,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Li,new $CLJS.h(null,1,[$CLJS.Ht,"Value :value clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.Ij],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XI],null),$CLJS.Hl],null));