var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./shadow.js.shim.module$ttag.js");
'use strict';var V$=function(a){return new $CLJS.qe(null,function(){return $CLJS.he(a.o?a.o():a.call(null),V$(a))},null,null)},W$=function(a,b){return new $CLJS.qe(null,function(){var c=$CLJS.y(b);if(c){var d=$CLJS.A(c),e=a.h?a.h(d):a.call(null,d),f=$CLJS.he(d,$CLJS.Qu(function(k){return $CLJS.E.g(e,a.h?a.h(k):a.call(null,k))},$CLJS.B(c)));return $CLJS.he(f,W$(a,new $CLJS.qe(null,function(){return $CLJS.ef($CLJS.D(f),c)},null,null)))}return null},null,null)},twa=function(a,b){switch(a){case "minutes":return $CLJS.PV("Minute",
"Minutes",b);case "hours":return $CLJS.PV("Hour","Hours",b);case "days":return $CLJS.PV("Day","Days",b);case "weeks":return $CLJS.PV("Week","Weeks",b);case "months":return $CLJS.PV("Month","Months",b);case "quarters":return $CLJS.PV("Quarter","Quarters",b);case "years":return $CLJS.PV("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},uwa=function(a,b,c){b=parseInt(b);c=twa(c,b);a=new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.E.g(b,1)],null);if($CLJS.E.g(new $CLJS.P(null,
2,5,$CLJS.Q,["past",!0],null),a))return $CLJS.BE("Previous {0}",$CLJS.H([c]));if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,["past",!1],null),a))return $CLJS.BE("Previous {0} {1}",$CLJS.H([b,c]));if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!0],null),a))return $CLJS.BE("Next {0}",$CLJS.H([c]));if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!1],null),a))return $CLJS.BE("Next {0} {1}",$CLJS.H([b,c]));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));},wwa=function(a){return $CLJS.ZA(a,
vwa,function(b){return["\\",$CLJS.p.h(b)].join("")})},X$=function(a,b,c,d,e){this.tag=a;this.source=b;this.S=c;this.G=d;this.J=e;this.C=2230716170;this.I=139264},ywa=function(a){var b=$CLJS.lu(a,xwa),c=$CLJS.lf.g($CLJS.A,$CLJS.jX(Y$,a)),d=function(){var e=$CLJS.D(b),f=$CLJS.D(c);return e>f?e:f}();a=$CLJS.kf.g(b,$CLJS.df(d-$CLJS.D(b),V$($CLJS.Xe(""))));d=$CLJS.kf.g(c,$CLJS.df(d-$CLJS.D(c),V$($CLJS.Xe(""))));a=$CLJS.hf.g(a,d);return $CLJS.lf.g(function(e){var f=$CLJS.qh(Y$,e);return $CLJS.n(f)?($CLJS.I(f,
0,null),f=$CLJS.I(f,1,null),new X$(f,e,null,null,null)):e},a)},zwa=function(a){return $CLJS.mf(function(b){return"string"===typeof $CLJS.A(b)?new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.NV(b)],null):b},$CLJS.H([W$(function(b){return"string"===typeof b||$CLJS.Va($CLJS.Ij.h(b))},a)]))},Awa=function(a,b,c,d){return $CLJS.lf.g(function(e){if(e instanceof X$){var f=$CLJS.R.j;var k=$CLJS.Lj.h(e),l=$CLJS.J.g(a,k);k=$CLJS.Ij.h(l);l=$CLJS.rj.h(l);if($CLJS.n(k))try{var m=Z$.j(l,k,b);var t=$CLJS.n(c)?wwa(m):m}catch(u){if(u instanceof
Error)t=Z$.j($CLJS.mi,k,b);else throw u;}else t=null;e=f.call($CLJS.R,e,$CLJS.Ij,t)}return e},d)},Dwa=function(a){a=$CLJS.NV($CLJS.lf.g(function(b){return b instanceof X$?b:$CLJS.ZA(b,Bwa,"")},a));return $CLJS.ZA(a,Cwa,$CLJS.jd)},Ewa=function(a){return $CLJS.XA($CLJS.A($CLJS.VY(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.IN],null),new $CLJS.P(null,1,5,$CLJS.Q,[a],null))),$CLJS.Ch)};var Z$=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.Ch.h(f)},e,a,b,c,d)}();Z$.m(null,$CLJS.kP,function(a,b,c){return(0,$CLJS.fJ)(b).locale(c).format("MMMM D, YYYY")});
Z$.m(null,$CLJS.NN,function(a,b,c){a=(0,$CLJS.fJ)(b,"YYYY-MM").locale(c);return $CLJS.n(a.isValid())?a.format("MMMM, YYYY"):""});Z$.m(null,$CLJS.HO,function(a,b,c){a=(0,$CLJS.fJ)(b,"[Q]Q-YYYY").locale(c);return $CLJS.n(a.isValid())?a.format("[Q]Q, YYYY"):""});Z$.m(null,$CLJS.qN,function(a,b,c){b=$CLJS.lu(b,/~/);a=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?[$CLJS.p.h(Z$.j($CLJS.kP,a,c))," - ",$CLJS.p.h(Z$.j($CLJS.kP,b,c))].join(""):""});
Z$.m(null,$CLJS.IO,function(a,b){function c(d,e){return $CLJS.B($CLJS.rh(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.eF("Today");if(c(/^thisweek$/,b))return $CLJS.eF("This Week");if(c(/^thismonth$/,b))return $CLJS.eF("This Month");if(c(/^thisquarter$/,b))return $CLJS.eF("This Quarter");if(c(/^thisyear$/,b))return $CLJS.eF("This Year");if(c(/^past1days$/,b))return $CLJS.eF("Yesterday");if(c(/^next1days$/,b))return $CLJS.eF("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.Ve.g(uwa,
a);throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));});Z$.m(null,$CLJS.pS,function(a,b,c){function d(e,f){return $CLJS.jd($CLJS.rh(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.n(a))return Z$.j($CLJS.IO,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.n(a))return Z$.j($CLJS.kP,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.n(a)?Z$.j($CLJS.qN,a,c):Z$.j($CLJS.IO,b,c)});
Z$.m(null,$CLJS.mi,function(a,b){$CLJS.yd(b)?(a=$CLJS.D(b),b=$CLJS.n($CLJS.E.g?$CLJS.E.g(1,a):$CLJS.E.call(null,1,a))?$CLJS.p.h($CLJS.A(b)):$CLJS.n($CLJS.E.g?$CLJS.E.g(2,a):$CLJS.E.call(null,2,a))?$CLJS.BE("{0} and {1}",$CLJS.H([$CLJS.A(b),$CLJS.jd(b)])):$CLJS.BE("{0}, {1}, and {2}",$CLJS.H([$CLJS.Xt(", ",$CLJS.Ru(2,b)),$CLJS.F(b,$CLJS.D(b)-2),$CLJS.kd(b)]))):b=$CLJS.p.h(b);return b});var vwa=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,Y$=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,xwa=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;
$CLJS.g=X$.prototype;$CLJS.g.toString=function(){var a=$CLJS.Ij.h(this);return $CLJS.n(a)?a:this.source};$CLJS.g.na=function(a,b){return this.$(null,b,null)};$CLJS.g.$=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.J.j(this.G,b,c)}};$CLJS.g.Fb=function(a,b,c){return $CLJS.db(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.kf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,this.tag],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,this.source],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Vt(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,$CLJS.tA],null),$CLJS.n(this.G)?$CLJS.rc(this.G):$CLJS.Ke())};$CLJS.g.O=function(){return this.S};
$CLJS.g.ha=function(){return 2+$CLJS.D(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-884441273^$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.E.g(this.tag,b.tag)&&$CLJS.E.g(this.source,b.source)&&$CLJS.E.g(this.G,b.G)};
$CLJS.g.Gb=function(a,b){return $CLJS.Ld(new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.tA,null,$CLJS.Lj,null],null),null),b)?$CLJS.Lk.g($CLJS.Pb($CLJS.hg.g($CLJS.N,this),this.S),b):new X$(this.tag,this.source,this.S,$CLJS.Ie($CLJS.Lk.g(this.G,b)),null)};$CLJS.g.Va=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "tag":case "source":return!0;default:return $CLJS.Ld(this.G,b)}};
$CLJS.g.ma=function(a,b,c){return $CLJS.n($CLJS.ke.g?$CLJS.ke.g($CLJS.Lj,b):$CLJS.ke.call(null,$CLJS.Lj,b))?new X$(c,this.source,this.S,this.G,null):$CLJS.n($CLJS.ke.g?$CLJS.ke.g($CLJS.tA,b):$CLJS.ke.call(null,$CLJS.tA,b))?new X$(this.tag,c,this.S,this.G,null):new X$(this.tag,this.source,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.y($CLJS.kf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.ag($CLJS.Lj,this.tag),new $CLJS.ag($CLJS.tA,this.source)],null),this.G))};
$CLJS.g.P=function(a,b){return new X$(this.tag,this.source,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.Bd(b)?this.ma(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.db($CLJS.pb,this,b)};var Bwa=/\[\[.+\]\]/,Cwa=/\[\[(.+?)\]\]/,$$=function $$(a){switch(arguments.length){case 2:return $$.g(arguments[0],arguments[1]);case 4:return $$.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.Aa("metabase.shared.parameters.parameters.substitute_tags",$$);$$.g=function(a,b){return $$.v(a,b,"en",!0)};$$.v=function(a,b,c,d){return $CLJS.n(a)?(b=$CLJS.iA(b),b=$CLJS.Wt(b,Ewa),Dwa(zwa(Awa(b,c,d,ywa(a))))):null};$$.A=4;module.exports={tag_names:function(a){a=$CLJS.ih($CLJS.lf.g($CLJS.jd,$CLJS.jX(Y$,$CLJS.n(a)?a:"")));return $CLJS.ol(a)},substitute_tags:$$};