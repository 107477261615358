var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Loa,Moa,Noa,Ooa,Soa,j1;
$CLJS.g1=function(){function a(e,f){if($CLJS.Va(f))return"";e=$CLJS.UA(e);var k=$CLJS.Ch.h(f);switch(k instanceof $CLJS.M?k.T:null){case "default":return $CLJS.PV("Default period","Default periods",e);case "millisecond":return $CLJS.PV("Millisecond","Milliseconds",e);case "second":return $CLJS.PV("Second","Seconds",e);case "minute":return $CLJS.PV("Minute","Minutes",e);case "hour":return $CLJS.PV("Hour","Hours",e);case "day":return $CLJS.PV("Day","Days",e);case "week":return $CLJS.PV("Week","Weeks",
e);case "month":return $CLJS.PV("Month","Months",e);case "quarter":return $CLJS.PV("Quarter","Quarters",e);case "year":return $CLJS.PV("Year","Years",e);case "minute-of-hour":return $CLJS.PV("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.PV("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.PV("Day of week","Days of week",e);case "day-of-month":return $CLJS.PV("Day of month","Days of month",e);case "day-of-year":return $CLJS.PV("Day of year","Days of year",e);case "week-of-year":return $CLJS.PV("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.PV("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.PV("Quarter of year","Quarters of year",e);default:return f=$CLJS.lu($CLJS.jh(f),/-/),e=$CLJS.y(f),f=$CLJS.A(e),e=$CLJS.B(e),$CLJS.Xt(" ",$CLJS.he($CLJS.RA(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.h1=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.yC(f)},e,a,b,c,d)}();Loa=$CLJS.$e($CLJS.N);Moa=$CLJS.$e($CLJS.N);
Noa=$CLJS.$e($CLJS.N);Ooa=$CLJS.$e($CLJS.N);Soa=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));$CLJS.i1=new $CLJS.hi($CLJS.Ih.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.yC,Soa,Loa,Moa,Noa,Ooa);$CLJS.i1.m(null,$CLJS.mi,function(){return null});$CLJS.i1.m(null,$CLJS.sH,function(a){return $CLJS.zi.h(a)});j1=new $CLJS.dh(null,new $CLJS.h(null,4,[$CLJS.hH,null,$CLJS.kH,null,$CLJS.lH,null,$CLJS.iH,null],null),null);
$CLJS.rna=$CLJS.hg.j($CLJS.Ff,$CLJS.Uk.g($CLJS.Vk.h(j1),$CLJS.lf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.nu,$CLJS.sH,$CLJS.zi,a],null);return $CLJS.E.g(a,$CLJS.Dl)?$CLJS.R.j(b,$CLJS.mi,!0):b})),$CLJS.KH);$CLJS.sna=$CLJS.Wk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.nu,$CLJS.sH,$CLJS.zi,a],null);return $CLJS.E.g(a,$CLJS.vn)?$CLJS.R.j(b,$CLJS.mi,!0):b},$CLJS.FH);
$CLJS.tna=$CLJS.hg.j($CLJS.Ff,$CLJS.Uk.g($CLJS.Vk.h(j1),$CLJS.lf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.nu,$CLJS.sH,$CLJS.zi,a],null);return $CLJS.E.g(a,$CLJS.vn)?$CLJS.R.j(b,$CLJS.mi,!0):b})),$CLJS.LH);$CLJS.O0.m(null,$CLJS.sH,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.zi);return $CLJS.g1.h(a)});
$CLJS.V0.m(null,$CLJS.sH,function(a,b,c){var d=$CLJS.ll.l;a=$CLJS.q0.j(a,b,c);b=$CLJS.sB($CLJS.i1.h(c));var e=$CLJS.i1.h(c);e=$CLJS.Ld($CLJS.NH,e)&&!$CLJS.Ld($CLJS.MH,e);return d.call($CLJS.ll,$CLJS.H([new $CLJS.h(null,3,[$CLJS.CE,a,$CLJS.w1,b,$CLJS.K0,e],null),$CLJS.im(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mi,$CLJS.J0],null))]))});
$CLJS.k1=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.yC(l)},e,a,b,c,d)}();$CLJS.k1.m(null,$CLJS.mi,function(){return $CLJS.eh});
$CLJS.l1=function(){function a(d,e,f){return $CLJS.k1.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();