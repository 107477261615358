var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var $J;$CLJS.qoa=function(a){return $CLJS.n($CLJS.KC($CLJS.yd,$CLJS.xd,$CLJS.El)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.ZJ=function(a,b){return $CLJS.Ue($CLJS.Hd,function(){return function e(d){return new $CLJS.qe(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.A(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.qe(null,function(K,S,Z,ca){return function(){for(var ka=C;;){var wa=$CLJS.y(ka);if(wa){var Qa=wa,ib=$CLJS.A(Qa);if(wa=$CLJS.y(function(Ta,Db,xb,kb,mb,Pa,Wc,Ok){return function wd(ad){return new $CLJS.qe(null,function(Ed,Kd,Fi,dk,Ps,Xz){return function(){for(;;){var lA=
$CLJS.y(ad);if(lA){if($CLJS.Cd(lA)){var mA=$CLJS.lc(lA),yD=$CLJS.D(mA),Vr=$CLJS.te(yD);a:for(var Vw=0;;)if(Vw<yD){var my=$CLJS.md(mA,Vw);my=$CLJS.E.g(Xz,$CLJS.oF)||$CLJS.E.g(Fi,$CLJS.oF)||$CLJS.AF(Xz,my)&&$CLJS.AF(Fi,my);Vr.add(my);Vw+=1}else{mA=!0;break a}return mA?$CLJS.we($CLJS.ye(Vr),wd($CLJS.mc(lA))):$CLJS.we($CLJS.ye(Vr),null)}Vr=$CLJS.A(lA);return $CLJS.he($CLJS.E.g(Xz,$CLJS.oF)||$CLJS.E.g(Fi,$CLJS.oF)||$CLJS.AF(Xz,Vr)&&$CLJS.AF(Fi,Vr),wd($CLJS.Lc(lA)))}return null}}}(Ta,Db,xb,kb,mb,Pa,Wc,
Ok),null,null)}}(ka,K,ib,Qa,wa,S,Z,ca)($CLJS.GF)))return $CLJS.kf.g(wa,G($CLJS.Lc(ka)));ka=$CLJS.Lc(ka)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.qoa($CLJS.hF(b)))))return $CLJS.kf.g(k,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null}},null,null)}($CLJS.qoa($CLJS.hF(a)))}())};
$J=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,$CLJS.Ve.j($CLJS.SF,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.A(f);f=$CLJS.B(f);$CLJS.A(f);f=$CLJS.B(f);var k=$CLJS.Gf(f);return $CLJS.Te($CLJS.Pl,
$CLJS.lf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.ZJ($CLJS.J.g(k,m),$CLJS.J.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.A(d);d=$CLJS.Lc(d);return c(e,d)};b.l=c;return b}()};$CLJS.aK=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.bK=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.cK=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var dK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.As],null)),eK=null,fK=0,gK=0;;)if(gK<fK){var zha=eK.X(null,gK);$CLJS.VF(zha,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.Wi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)],null)],null)]));gK+=1}else{var hK=$CLJS.y(dK);if(hK){var iK=hK;if($CLJS.Cd(iK)){var kK=$CLJS.lc(iK),Aha=$CLJS.mc(iK),
Bha=kK,Cha=$CLJS.D(kK);dK=Aha;eK=Bha;fK=Cha}else{var Dha=$CLJS.A(iK);$CLJS.VF(Dha,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.Wi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)],null)],null)]));dK=$CLJS.B(iK);eK=null;fK=0}gK=0}else break}$CLJS.TF($CLJS.Ds,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)]));
for(var lK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.QG],null)),mK=null,nK=0,oK=0;;)if(oK<nK){var Eha=mK.X(null,oK);$CLJS.VF(Eha,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.Wi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)],null)],null)]));oK+=1}else{var pK=$CLJS.y(lK);if(pK){var qK=pK;if($CLJS.Cd(qK)){var rK=$CLJS.lc(qK),Fha=$CLJS.mc(qK),
Gha=rK,Hha=$CLJS.D(rK);lK=Fha;mK=Gha;nK=Hha}else{var Iha=$CLJS.A(qK);$CLJS.VF(Iha,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.os,new $CLJS.h(null,1,[$CLJS.Wi,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rF],null)],null)],null)],null)]));lK=$CLJS.B(qK);mK=null;nK=0}oK=0}else break}
for(var sK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.es,$CLJS.fs,$CLJS.cs,$CLJS.ds],null)),tK=null,uK=0,vK=0;;)if(vK<uK){var Jha=tK.X(null,vK);$CLJS.RF.l($J($CLJS.fh([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Jha,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)]));vK+=1}else{var wK=$CLJS.y(sK);if(wK){var xK=wK;if($CLJS.Cd(xK)){var yK=$CLJS.lc(xK),Kha=$CLJS.mc(xK),Lha=yK,Mha=$CLJS.D(yK);sK=Kha;tK=Lha;uK=Mha}else{var Nha=
$CLJS.A(xK);$CLJS.RF.l($J($CLJS.fh([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Nha,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)]));sK=$CLJS.B(xK);tK=null;uK=0}vK=0}else break}
$CLJS.RF.l($J($CLJS.fh([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.bG,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)]));
$CLJS.RF.l($J($CLJS.fh([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.hG,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)]));
for(var zK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aG,$CLJS.mG],null)),AK=null,BK=0,CK=0;;)if(CK<BK){var Oha=AK.X(null,CK);$CLJS.TF(Oha,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)]));CK+=1}else{var DK=$CLJS.y(zK);if(DK){var EK=DK;if($CLJS.Cd(EK)){var FK=$CLJS.lc(EK),Pha=$CLJS.mc(EK),Qha=FK,Rha=$CLJS.D(FK);zK=Pha;AK=Qha;BK=Rha}else{var Sha=$CLJS.A(EK);$CLJS.TF(Sha,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)]));zK=$CLJS.B(EK);
AK=null;BK=0}CK=0}else break}
for(var GK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cG,$CLJS.jG],null)),HK=null,IK=0,JK=0;;)if(JK<IK){var Tha=HK.X(null,JK);$CLJS.TF(Tha,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)]));JK+=1}else{var KK=$CLJS.y(GK);if(KK){var LK=KK;if($CLJS.Cd(LK)){var MK=$CLJS.lc(LK),Uha=$CLJS.mc(LK),Vha=MK,Wha=$CLJS.D(MK);GK=Uha;HK=Vha;IK=Wha}else{var Xha=$CLJS.A(LK);$CLJS.TF(Xha,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vF],null)]));GK=$CLJS.B(LK);
HK=null;IK=0}JK=0}else break}
for(var WK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null)],null),NK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rG,$CLJS.LG,$CLJS.zG,$CLJS.sG],null)),OK=null,PK=0,QK=0;;)if(QK<PK){var RK=OK.X(null,QK);$CLJS.cH.v(RK,$CLJS.rt,$CLJS.lk,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,RK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,$CLJS.ME,WK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.wF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null)],null));QK+=1}else{var SK=$CLJS.y(NK);if(SK){var TK=SK;if($CLJS.Cd(TK)){var UK=$CLJS.lc(TK),Yha=$CLJS.mc(TK),Zha=UK,$ha=$CLJS.D(UK);NK=Yha;OK=Zha;PK=$ha}else{var VK=$CLJS.A(TK);$CLJS.cH.v(VK,$CLJS.rt,$CLJS.lk,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,VK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,$CLJS.ME,WK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.wF],null)],null));NK=$CLJS.B(TK);OK=null;PK=0}QK=0}else break}
$CLJS.cH.v($CLJS.tC,$CLJS.rt,$CLJS.lk,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.tC],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,$CLJS.ME,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vo,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,$CLJS.iw,$CLJS.ny,$CLJS.gG],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.zF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kE],null)],null));$CLJS.cH.v($CLJS.YF,$CLJS.rt,$CLJS.lk,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.YF],null),$CLJS.ME,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.nE,$CLJS.HE],null)],null));
$CLJS.X($CLJS.aK,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.bK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.rs,$CLJS.gs,$CLJS.QG,$CLJS.hG,$CLJS.bG,$CLJS.es,$CLJS.cs,$CLJS.fs,$CLJS.ds,$CLJS.aG,$CLJS.mG,$CLJS.cG,$CLJS.jG,$CLJS.zG,$CLJS.sG,$CLJS.rG,$CLJS.LG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cK,$CLJS.pj],null)],null));