var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./metabase.util.malli.registry.js");
'use strict';var YV,$V,aW,zka,eW,UV,dW,Dla,fW,gW,hW,jW,kW,lW,mW,nW,oW,qW,rW,sW,tW,uW,xW,yW,Cla;YV=function(a){return $CLJS.hg.j($CLJS.N,$CLJS.Vk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.pe(c)}),a)};
$CLJS.ZV=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Ve.g($CLJS.SC,function(){return function e(d){return new $CLJS.qe(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.md(l,v);$CLJS.xe(t,$CLJS.cF(x,function(){return function(z){return $CLJS.Lk.l(YV(z),$CLJS.KB,$CLJS.H([$CLJS.Pi]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.we($CLJS.ye(t),e($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),
null)}var u=$CLJS.A(k);return $CLJS.he($CLJS.cF(u,function(){return function(v){return $CLJS.Lk.l(YV(v),$CLJS.KB,$CLJS.H([$CLJS.Pi]))}}(u,k,f,b)),e($CLJS.Lc(k)))}return null}},null,null)}(a)}())};
$V=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ff,a],null)],null);for(var c=$CLJS.Ff;;){var d=$CLJS.sd(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.td(a);a=function(t,u,v,x,z,C,G,K){return function(S){return $CLJS.hg.j(v,$CLJS.lf.h(function(Z,ca,ka,wa,Qa){return function(ib){var Ta=$CLJS.I(ib,0,null);ib=$CLJS.I(ib,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ee.g(Qa,Ta),ib],null)}}(t,u,v,x,z,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.ee.g(c,l)):a=$CLJS.zd(k)?a(k):$CLJS.yd(k)?a($CLJS.hm($CLJS.ft,k)):m}else return c}};aW=function(a,b,c){return $CLJS.Bd(c)&&$CLJS.E.g(a,$CLJS.A(c))&&!$CLJS.Ld(b,$CLJS.J.g(c,2))};$CLJS.bW=function(a){var b=$CLJS.hg.j($CLJS.eh,$CLJS.lf.h($CLJS.Uk.g($CLJS.GE,$CLJS.jd)),$CLJS.nQ.h(a));return $V($CLJS.Lk.l(a,$CLJS.RN,$CLJS.H([$CLJS.BV])),function(c){return aW($CLJS.HB,b,c)})};
$CLJS.cW=function(a){var b=$CLJS.hg.j($CLJS.eh,$CLJS.lf.h($CLJS.Uk.g($CLJS.OE,$CLJS.jd)),$CLJS.pG.h(a));return $V($CLJS.Lk.l(a,$CLJS.RN,$CLJS.H([$CLJS.BV])),function(c){return aW($CLJS.pG,b,c)})};zka=function(a){function b(d){return $CLJS.mf(c,$CLJS.H([$CLJS.RN.h(d)]))}function c(d){return $CLJS.he($CLJS.lF.h(d),$CLJS.mf(b,$CLJS.H([$CLJS.GL.h(d)])))}return $CLJS.n($CLJS.TV.h(a))?$CLJS.Xe(!0):$CLJS.ih(b(a))};
eW=function(a){if($CLJS.yd(a))for(var b=$CLJS.Xe(!1),c=$CLJS.y(a),d=$CLJS.A(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.A(v),z=$CLJS.B(v),C=x,G=z,K=$CLJS.VA(m,zka(C));f=$CLJS.A($CLJS.y($CLJS.of($CLJS.Wa,function(S,Z,ca,ka,wa,Qa){return function xb(Ta,Db){try{if($CLJS.Bd(Db)&&3===$CLJS.D(Db))try{var kb=$CLJS.F(Db,0);if($CLJS.ke(kb,$CLJS.AG))try{var mb=$CLJS.F(Db,1);if($CLJS.n($CLJS.IP.h(mb)))try{var Pa=$CLJS.F(Db,1);if(null!=Pa?Pa.C&256||$CLJS.Bc===Pa.mf||(Pa.C?0:$CLJS.Za($CLJS.wb,
Pa)):$CLJS.Za($CLJS.wb,Pa))try{var Wc=$CLJS.J.j(Pa,$CLJS.IP,$CLJS.IV);if($CLJS.n($CLJS.We(ka)(Wc))){var Ok=$CLJS.J.g(Pa,$CLJS.IP);$CLJS.F(Db,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Qa),": no join named ",$CLJS.Ph.l($CLJS.H([Ok]))].join("")],null)}throw $CLJS.Y;}catch(ad){if(ad instanceof Error){var Vd=ad;if(Vd===$CLJS.Y)throw $CLJS.Y;throw Vd;}throw ad;}else throw $CLJS.Y;}catch(ad){if(ad instanceof Error){Vd=ad;if(Vd===$CLJS.Y)throw $CLJS.Y;throw Vd;
}throw ad;}else throw $CLJS.Y;}catch(ad){if(ad instanceof Error){Vd=ad;if(Vd===$CLJS.Y)throw $CLJS.Y;throw Vd;}throw ad;}else throw $CLJS.Y;}catch(ad){if(ad instanceof Error){Vd=ad;if(Vd===$CLJS.Y)throw $CLJS.Y;throw Vd;}throw ad;}else throw $CLJS.Y;}catch(ad){if(ad instanceof Error){Vd=ad;if(Vd===$CLJS.Y)return $CLJS.RV(xb,Ta,Db);throw Vd;}throw ad;}}}(f,k,l,K,m,t,u,v,x,z,C,G,b,0,a,a,c,d,e,d,e)($CLJS.Ff,$CLJS.Lk.l(C,$CLJS.RN,$CLJS.H([Cla]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};UV=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);dW=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Dla=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);fW=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
gW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);hW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.iW=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);jW=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
kW=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);lW=new $CLJS.r(null,"refs","refs",80480079,null);mW=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);nW=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);oW=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.pW=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);qW=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);rW=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);sW=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);tW=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
uW=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.vW=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.TV=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.wW=new $CLJS.M(null,"filters","filters",974726919);xW=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);yW=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.zW=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Cla=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(mW,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.pW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xQ,$CLJS.Wa],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.Hl],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.HE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UQ,new $CLJS.h(null,1,[$CLJS.Ts,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tV],null)],null)],null));$CLJS.X(rW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EG],null));
$CLJS.X(UV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.h(null,1,[$CLJS.Wi,1],null),rW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"Breakouts must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.ZV},dW,$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.CV,fW,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[lW],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.ZV)?$CLJS.ZV.H:null]))],null)],null));
$CLJS.X(sW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.h(null,1,[$CLJS.Wi,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,":fields must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.ZV},dW,$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.CV,fW,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.V(new $CLJS.P(null,
1,5,$CLJS.Q,[lW],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.ZV)?$CLJS.ZV.H:null]))],null)],null));$CLJS.X(tW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.YF],null),$CLJS.Dk,$CLJS.tk],null)],null));
$CLJS.X(qW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.h(null,1,[$CLJS.Wi,1],null),tW],null));
var DW=$CLJS.VA(function(a){a=$CLJS.A($CLJS.bW(a));if($CLJS.n(a)){var b=$CLJS.WA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Ph.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.A($CLJS.cW(a));if($CLJS.n(a)){var b=$CLJS.WA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.X(uW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,2,[$CLJS.Ht,"Valid references for a single query stage",$CLJS.$t,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return DW(a)}],null),$CLJS.We(DW)],null));
$CLJS.X($CLJS.zW,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.iW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.oG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),UV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$G],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),sW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wW,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),qW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cS,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ML],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sP,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TV,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jL],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Ld(a,$CLJS.WQ)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,
new $CLJS.h(null,1,[$CLJS.Ht,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.We($CLJS.zE($CLJS.sP,$CLJS.TV))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uW],null)],null));$CLJS.X(gW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cL],null)],null)],null)],null));
$CLJS.X(hW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jL],null)],null)],null)],null));$CLJS.X(kW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hW],null)],null));
$CLJS.X(nW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.We($CLJS.VA($CLJS.sP,$CLJS.TV))],null)],null));$CLJS.X(oW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.pW,$CLJS.iW],null));
$CLJS.X(Dla,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,oW],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,1,[$CLJS.bj,$CLJS.nu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.zW],null)],null)],null)],null));
$CLJS.X(yW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,oW],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,1,[$CLJS.bj,$CLJS.nu],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kW],null)],null)],null)],null));$CLJS.X(jW,nW);
$CLJS.X(xW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,2,[$CLJS.Ht,"Valid references for all query stages",$CLJS.$t,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return eW.h?eW.h(a):eW.call(null,a)}],null),$CLJS.We(eW)],null));
$CLJS.X($CLJS.CL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jW],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xW],null)],null));
$CLJS.X($CLJS.ZL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.vW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.kL,$CLJS.rL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CL],null)],null)],null),$CLJS.ska],null));