var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var hM,iM,jM,sha,tha,nM,uha,qM,rM,sM,tM,vha,uM,vM,xM,yM,kM,cM,cia,dM,dia,fM,zM,AM,eia,BM,CM,fia,DM,gia,EM,FM,hia,HM,iia,jia,kia,JM,KM,LM,MM,NM,OM,PM,QM,lia,RM,SM,TM,VM,WM,mia,XM,YM,ZM,$M,nia,aN,bN,cN,dN,oia,eN,fN,gN,hN,pia,iN,qia,jN,kN,lN,ria,mN,nN,oN,pN,rN,sN,sia,tN,uN,tia,vN,wN,xN,yN,zN,uia,AN,via,BN,CN,EN,wia,FN,GN,HN,xia,KN,LN,yia,zia,MN,ON,PN,QN,Aia,TN,UN,Bia,Cia,Dia,VN,WN,XN,YN,ZN,$N,Eia,aO,Fia,bO,cO,eO,gO,hO,iO,jO,Gia,Hia,lO,Iia,Jia,mO,nO,oO,pO,qO,Kia,rO,Lia,Mia,sO,tO,uO,vO,wO,
Nia,Oia,Pia,xO,Qia,yO,Ria,AO,BO,Sia,DO,EO,FO,GO,Tia,JO,Uia,Via,KO,LO,MO,Wia,NO,OO,PO,QO,Xia,SO,TO,UO,VO,WO,Yia,XO,YO,Zia,ZO,$O,aP,bP,cP,dP,fP,$ia,gP,aja,hP,iP,jP,bja,cja,dja,lP,mP,nP,eja,oP,pP,qP,rP,fja,gja,tP,hja,ija,uP,vP,wP,xP,yP,jja,zP,AP,BP,CP,kja,EP,FP,GP,HP,JP,lja,KP,LP,mja,MP,NP,OP,nja,PP,oja,RP,pja,SP,TP,UP,VP,WP,XP,YP,qja,rja,sja,ZP,tja,$P,aQ,uja,vja,bQ,cQ,dQ,wja,eQ,xja,fQ,yja,zja,gQ,Aja,Bja,Cja,jQ,kQ,Dja,lQ,mQ,oQ,Eja,pQ,Fja,qQ,rQ,sQ,Gja,tQ,uQ,vQ,wQ,Hja,yQ,zQ,Ija,AQ,BQ,CQ,DQ,EQ,FQ,GQ,HQ,
Jja,IQ,JQ,Kja,KQ,Lja,LQ,MQ,NQ,Mja,OQ,PQ,Nja,Oja,QQ,RQ,SQ,Pja,Qja,TQ,Rja,Sja,Tja,VQ,kka,XQ,lka,mka,YQ,ZQ,nka,uka,vka,$Q,aR,bR,cR,dR,eR,fR,wka,gR,hR,xka,iR,yka,Aka,Bka,jR,kR,lR,Cka,mR,nR,Dka,oR,Eka,pR,Fka,Gka,rR,sR,tR,uR,vR,Hka,wR,xR,Ika,yR,zR,AR,BR,CR,DR,ER,FR,Jka,GR,HR,JR,KR,LR,MR,NR,OR,PR,QR,RR,SR,Kka,TR,UR,VR,WR,YR,Lka,ZR,$R,aS,bS,Qka,dS,Ska,Tka,fS,Zka,gS,ila,hS,jla,kla,iS,lla,jS,lS,mS,mla,nla,qS,rS,ola,pla,sS,tS,uS,vS,wS,qla,xS,yS,AS,BS,rla;
$CLJS.gM=function(a){var b=$CLJS.Co.g(a,null),c=$CLJS.IF(b,$CLJS.Us,function(d){return $CLJS.Bn(d,$CLJS.Ff)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.ul,b,$CLJS.Ij,k,$CLJS.at,l],null):null}function e(k){return f.j(k,$CLJS.Ff,$CLJS.Ff)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
hM=function(a,b){return $CLJS.zd(a)?$CLJS.J.g(a,b):a};iM=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.En($CLJS.Co.g($CLJS.ul.h(a),null));if($CLJS.n(b)){var e=hM($CLJS.$t.h(b),c);$CLJS.n(e)?(e=$CLJS.Lq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:hM($CLJS.Ht.h(b),c)}return null};jM=function(a,b){return $CLJS.xd(a)||$CLJS.Nl(a)?$CLJS.J.g(a,b):$CLJS.yd(a)?$CLJS.J.g($CLJS.Gf(a),b):null};
sha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.ul),d=$CLJS.J.g(a,$CLJS.rj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.at,$CLJS.cea),f=$CLJS.J.j(b,$CLJS.Oca,!0),k=$CLJS.J.g(b,$CLJS.au),l=$CLJS.J.j(b,$CLJS.Qt,$CLJS.Ot),m=iM(a,$CLJS.Xq.h(c),k,b);if($CLJS.n(m))return m;m=iM(a,$CLJS.zt.h(c),k,b);if($CLJS.n(m))return m;m=iM(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=iM(a,function(){var t=$CLJS.jo.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=iM(a,$CLJS.Xq.h(c),
l,b);if($CLJS.n(m))return m;m=iM(a,$CLJS.zt.h(c),l,b);if($CLJS.n(m))return m;d=iM(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=iM(a,function(){var t=$CLJS.jo.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?iM(a,e.h?e.h($CLJS.Mt):e.call(null,$CLJS.Mt),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?iM(a,e.h?e.h($CLJS.Mt):e.call(null,$CLJS.Mt),l,b):f};
tha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.hg.g;var e=$CLJS.rl.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.ul),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.au);l=$CLJS.J.j(l,$CLJS.Qt,$CLJS.Ot);k=$CLJS.Xq.h(k);f=hM($CLJS.Pt.h(k),f);f=$CLJS.n(f)?f:hM($CLJS.Pt.h(k),l);d=d.call($CLJS.hg,e,f);return new $CLJS.P(null,2,5,a,[d,sha(b,c)],null)};
$CLJS.lM=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ij);a=$CLJS.J.g(b,$CLJS.at);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.Pca,$CLJS.dj),f=$CLJS.J.j(d,$CLJS.Rca,tha);return $CLJS.n(a)?$CLJS.db(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.dj,m);l=e.h?e.h(l):e.call(null,l);return kM(k,c,t,l)},null,a):null};
nM=function(a,b,c){var d=$CLJS.WA($CLJS.q($CLJS.vE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Rh.v($CLJS.vE,$CLJS.mM,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.oM=function(a){return nM($CLJS.Bk,a,function(){return $CLJS.JF.h(a)})};uha=function(){var a=pM;return nM($CLJS.Us,a,function(){var b=$CLJS.JF.h(a),c=$CLJS.gM(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
qM=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,$CLJS.pe(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};rM=function(a){if($CLJS.Bd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,qM(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,qM(c)],null);default:return qM(a)}}else return qM(a)};
sM=function(a,b){var c=$CLJS.Bd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.vd(a)?(b=$CLJS.A(b),a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
tM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ye(sM,a)],null),$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,a],null)],null)],null),function(){return function e(d){return new $CLJS.qe(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.md(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,rM(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[m,rM(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.Ct(2,2,b))}())],null)};vha=function(a){return $CLJS.Bd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
uM=function(a){return $CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.bj,vha,$CLJS.Ht,["valid instance of one of these MBQL clauses: ",$CLJS.Xt(", ",$CLJS.lf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.qe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.te(k);a:for(var m=0;;)if(m<k){var t=$CLJS.md(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.pe(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.we($CLJS.ye(l),d($CLJS.mc(e))):$CLJS.we($CLJS.ye(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.pe(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
vM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bM],null)],null)};$CLJS.wM={};xM={};yM={};$CLJS.mM=function mM(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=mM.j?mM.j(k,f,c):mM.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
kM=function kM(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=jM(b,c);f=$CLJS.n(a)?a:$CLJS.yd(b)?$CLJS.Ff:$CLJS.Ad(b)?$CLJS.N:$CLJS.ld(b);return $CLJS.n($CLJS.n(c)?$CLJS.Nt.h($CLJS.rd(f)):c)?a:$CLJS.n(c)?(b=jM(f,c),d=kM.v?kM.v(b,l,k,d):kM.call(null,b,l,k,d),$CLJS.Sl(c)&&$CLJS.yd(f)&&c>$CLJS.D(f)&&(b=$CLJS.gf(c-$CLJS.D(f),null),b=$CLJS.kf.g(f,b),f=null==f||$CLJS.Gd(f)?b:$CLJS.hg.g($CLJS.ld(f),b)),null==f||$CLJS.Nl(f)?$CLJS.R.j(f,c,d):$CLJS.xd(f)?$CLJS.ee.g(f,d):$CLJS.Ve.g($CLJS.V,$CLJS.R.j($CLJS.Gf(f),
c,d))):$CLJS.zd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Qca],null),kM.v?kM.v(f,b,c,d):kM.call(null,f,b,c,d)):$CLJS.n($CLJS.Nt.h($CLJS.rd(f)))?$CLJS.ee.g(f,d):$CLJS.Bd($CLJS.Ie(f))?f:$CLJS.qd(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.Nt,!0],null))};cM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);cia=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
dM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);dia=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);fM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);zM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);AM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
eia=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);BM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);CM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);fia=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);DM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
gia=new $CLJS.M(null,"from","from",1815293044);EM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);FM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);hia=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.GM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);HM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
iia=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);jia=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.IM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);kia=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);JM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
KM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);LM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);MM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);NM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);OM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
PM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);QM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);lia=new $CLJS.M(null,"lon-max","lon-max",1590224717);RM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);SM=new $CLJS.r(null,"stddev","stddev",775056588,null);TM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.UM=new $CLJS.M(null,"snippet","snippet",953581994);VM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);WM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);mia=new $CLJS.M(null,"lat-field","lat-field",-830652957);XM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);YM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
ZM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);$M=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);nia=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);aN=new $CLJS.M("location","country","location/country",1666636202);bN=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
cN=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);dN=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);oia=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);eN=new $CLJS.M(null,"unary","unary",-989314568);fN=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
gN=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);hN=new $CLJS.r(null,"ceil","ceil",-184398425,null);pia=new $CLJS.M(null,"lon-min","lon-min",-787291357);iN=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);qia=new $CLJS.M(null,"match","match",1220059550);jN=new $CLJS.r(null,"count-where","count-where",2025939247,null);kN=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
lN=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);ria=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);mN=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);nN=new $CLJS.r(null,"sum","sum",1777518341,null);oN=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);pN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.qN=new $CLJS.M("date","range","date/range",1647265776);rN=new $CLJS.r(null,"between","between",-1523336493,null);sN=new $CLJS.M(null,"clause-form","clause-form",1820463737);sia=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);tN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);uN=new $CLJS.r(null,"field","field",338095027,null);tia=new $CLJS.M(null,"segment-id","segment-id",1810133590);
vN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);wN=new $CLJS.r(null,"not-null","not-null",313812992,null);xN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);yN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);zN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);uia=new $CLJS.M(null,"template-tag","template-tag",310841038);
AN=new $CLJS.M(null,"invalid","invalid",412869516);via=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);BN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);CN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.DN=new $CLJS.M(null,"context","context",-830191113);EN=new $CLJS.r(null,"get-year","get-year",704520253,null);
wia=new $CLJS.M(null,"format-rows?","format-rows?",992129486);FN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);GN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);HN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.IN=new $CLJS.M(null,"parameters","parameters",-1229919748);xia=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.JN=new $CLJS.M(null,"card","card",-1430355152);KN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);LN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);yia=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);zia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);MN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.NN=new $CLJS.M("date","month-year","date/month-year",1948031290);
ON=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);PN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);QN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.RN=new $CLJS.M(null,"joins","joins",1033962699);Aia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.SN=new $CLJS.M(null,"source-field","source-field",933829534);TN=new $CLJS.r(null,"Field","Field",430385967,null);
UN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);Bia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Cia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);Dia=new $CLJS.M(null,"items","items",1031954938);VN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);WN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
XN=new $CLJS.M(null,"more","more",-2058821800);YN=new $CLJS.M(null,"first-clause","first-clause",-20953491);ZN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);$N=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);Eia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);aO=new $CLJS.r(null,"contains","contains",-1977535957,null);
Fia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);bO=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);cO=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.dO=new $CLJS.M(null,"widget-type","widget-type",1836256899);eO=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.fO=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
gO=new $CLJS.r(null,"is-null","is-null",-356519403,null);hO=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);iO=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);jO=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Gia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.kO=new $CLJS.M(null,"required","required",1807647006);Hia=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
lO=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Iia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Jia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);mO=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);nO=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);oO=new $CLJS.M("string","contains","string/contains",1602423827);pO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
qO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);Kia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);rO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);Lia=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);Mia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
sO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);tO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);uO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);vO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);wO=new $CLJS.r(null,"share","share",1051097594,null);
Nia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);Oia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);Pia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);xO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Qia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
yO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);Ria=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.zO=new $CLJS.M(null,"collection","collection",-683361892);AO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);BO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.CO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);Sia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);DO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);EO=new $CLJS.r(null,"metric","metric",2049329604,null);FO=new $CLJS.r(null,"concat","concat",-467652465,null);GO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.HO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
Tia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.IO=new $CLJS.M("date","relative","date/relative",25987732);JO=new $CLJS.M("location","city","location/city",-1746973325);Uia=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);Via=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);KO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);LO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
MO=new $CLJS.M("number","between","number/between",97700581);Wia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);NO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);OO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);PO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);QO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.RO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Xia=new $CLJS.M(null,"metric-id","metric-id",-686486942);SO=new $CLJS.r(null,"*","*",345799209,null);TO=new $CLJS.r(null,"+","+",-740910886,null);UO=new $CLJS.r(null,"-","-",-471816912,null);VO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);WO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Yia=new $CLJS.M(null,"question","question",-1411720117);XO=new $CLJS.r(null,"asc","asc",1997386096,null);
YO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Zia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);ZO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);$O=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);aP=new $CLJS.r(null,"\x3c","\x3c",993667236,null);bP=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);cP=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
dP=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.eP=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);fP=new $CLJS.M("string","ends-with","string/ends-with",302681156);$ia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);gP=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);aja=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
hP=new $CLJS.r(null,"and","and",668631710,null);iP=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);jP=new $CLJS.r(null,"round","round",-645002441,null);bja=new $CLJS.M(null,"to","to",192099007);$CLJS.kP=new $CLJS.M("date","single","date/single",1554682003);cja=new $CLJS.M(null,"action-id","action-id",-1727958578);dja=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);lP=new $CLJS.r(null,"exp","exp",1378825265,null);
mP=new $CLJS.r(null,"Filter","Filter",-424893332,null);nP=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);eja=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);oP=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);pP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);qP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
rP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.sP=new $CLJS.M(null,"source-table","source-table",-225307692);fja=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);gja=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);tP=new $CLJS.r(null,"floor","floor",-772394748,null);hja=new $CLJS.M(null,"middleware","middleware",1462115504);
ija=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);uP=new $CLJS.M(null,"requires-features","requires-features",-101116256);vP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);wP=new $CLJS.M(null,"clause-name","clause-name",-996419059);xP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);yP=new $CLJS.r(null,"now","now",-9994004,null);
jja=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);zP=new $CLJS.r(null,"not","not",1044554643,null);AP=new $CLJS.r(null,"avg","avg",1837937727,null);BP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);CP=new $CLJS.M(null,"max-results","max-results",-32858165);kja=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);EP=new $CLJS.r(null,"case","case",-1510733573,null);
FP=new $CLJS.r(null,"distinct","distinct",-148347594,null);GP=new $CLJS.r(null,"get-second","get-second",-425414791,null);HP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.IP=new $CLJS.M(null,"join-alias","join-alias",1454206794);JP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);lja=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
KP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);LP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);mja=new $CLJS.M(null,"original","original",-445386197);MP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);NP=new $CLJS.r(null,"abs","abs",1394505050,null);OP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);nja=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
PP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);oja=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.QP=new $CLJS.M(null,"date","date",-1463434462);RP=new $CLJS.M(null,"second-clause","second-clause",-461435645);pja=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);SP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
TP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);UP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);VP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);WP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);XP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);YP=new $CLJS.r(null,"or","or",1876275696,null);
qja=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);rja=new $CLJS.M(null,"constraints","constraints",422775616);sja=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);ZP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);tja=new $CLJS.M(null,"csv-download","csv-download",2141432084);$P=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
aQ=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);uja=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);vja=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);bQ=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);cQ=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
dQ=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);wja=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);eQ=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);xja=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);fQ=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);yja=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
zja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);gQ=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Aja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Bja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Cja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.hQ=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.iQ=new $CLJS.M(null,"database","database",1849087575);jQ=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);kQ=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);Dja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);lQ=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
mQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.nQ=new $CLJS.M(null,"expressions","expressions",255689909);oQ=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Eja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);pQ=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
Fja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);qQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);rQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);sQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);Gja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);
tQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);uQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);vQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);wQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.xQ=new $CLJS.M(null,"native","native",-613060878);Hja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);yQ=new $CLJS.M(null,"page","page",849072397);
zQ=new $CLJS.r(null,"length","length",-2065447907,null);Ija=new $CLJS.M(null,"dashboard","dashboard",-631747508);AQ=new $CLJS.r(null,"get-week","get-week",752472178,null);BQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);CQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);DQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);EQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
FQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);GQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);HQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Jja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);IQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);JQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
Kja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);KQ=new $CLJS.M(null,"internal","internal",-854870097);Lja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);LQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);MQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
NQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);Mja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);OQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);PQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);Nja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
Oja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);QQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);RQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);SQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
Pja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Qja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);TQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.UQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);Rja=new $CLJS.M(null,"public-question","public-question",629369976);Sja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
Tja=new $CLJS.M(null,"binary","binary",-1802232288);VQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);kka=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.WQ=new $CLJS.M(null,"source-query","source-query",198004422);XQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);lka=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
mka=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);YQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);ZQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);nka=new $CLJS.M(null,"executed-by","executed-by",-739811161);uka=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
vka=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);$Q=new $CLJS.M(null,"amount","amount",364489504);aR=new $CLJS.r(null,"percentile","percentile",1039342775,null);bR=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);cR=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);dR=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
eR=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);fR=new $CLJS.r(null,"trim","trim",-1880116002,null);wka=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);gR=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);hR=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);xka=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
iR=new $CLJS.M("string","\x3d","string/\x3d",983744235);yka=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Aka=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Bka=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);jR=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);kR=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
lR=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Cka=new $CLJS.M(null,"lat-min","lat-min",1630784161);mR=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);nR=new $CLJS.r(null,"inside","inside",-681932758,null);Dka=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);oR=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Eka=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);pR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Fka=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Gka=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.qR=new $CLJS.M(null,"card-id","card-id",-1770060179);rR=new $CLJS.M(null,"variadic","variadic",882626057);
sR=new $CLJS.r(null,"upper","upper",1886775433,null);tR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);uR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);vR=new $CLJS.r(null,"optional","optional",-600484260,null);Hka=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);wR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
xR=new $CLJS.M(null,"sugar","sugar",-73788488);Ika=new $CLJS.M(null,"lat-max","lat-max",841568226);yR=new $CLJS.r(null,"power","power",702679448,null);zR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);AR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);BR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
CR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);DR=new $CLJS.r(null,"median","median",-2084869638,null);ER=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);FR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Jka=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);GR=new $CLJS.M(null,"y","y",-1757859776);HR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.IR=new $CLJS.M(null,"binning","binning",1709835866);JR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);KR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);LR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);MR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);NR=new $CLJS.r(null,"Reference","Reference",2024574086,null);OR=new $CLJS.M(null,"b","b",1482224470);
PR=new $CLJS.M(null,"a","a",-2123407586);QR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);RR=new $CLJS.r(null,"replace","replace",853943757,null);SR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Kka=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);TR=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
UR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);VR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);WR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);YR=new $CLJS.r(null,"segment","segment",675610331,null);Lka=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);ZR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
$R=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);aS=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);bS=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Qka=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.cS=new $CLJS.M(null,"order-by","order-by",1527318070);dS=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Ska=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Tka=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.eS=new $CLJS.M(null,"condition","condition",1668437652);fS=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Zka=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);gS=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
ila=new $CLJS.M(null,"card-name","card-name",-2035606807);hS=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);jla=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);kla=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);iS=new $CLJS.r(null,"log","log",45015523,null);lla=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
jS=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.kS=new $CLJS.M(null,"database_type","database_type",-54700895);lS=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);mS=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.nS=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.oS=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.pS=new $CLJS.M("date","all-options","date/all-options",549325958);
mla=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);nla=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);qS=new $CLJS.M("location","state","location/state",-114378652);rS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);ola=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);pla=new $CLJS.M(null,"lon-field","lon-field",517872067);
sS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);tS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);uS=new $CLJS.M(null,"numeric","numeric",-1495594714);vS=new $CLJS.r(null,"variable","variable",1359185035,null);wS=new $CLJS.r(null,"lower","lower",-1534114948,null);qla=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
xS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);yS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.zS=new $CLJS.M(null,"limit","limit",-1355822363);AS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);BS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);rla=new $CLJS.M(null,"pulse","pulse",-244494476);var FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.HE],null),GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QE],null),HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.DE],null),IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nE],null),JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LE],null),KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wL],null),Uja=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jL],null),Vja=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cL],null),LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.WI],null),MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.$I],null),NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.EI],null),OS=new $CLJS.dh(null,new $CLJS.h(null,12,[$CLJS.wi,null,$CLJS.vn,null,$CLJS.Fj,null,$CLJS.mi,null,$CLJS.Qi,null,$CLJS.Pj,null,$CLJS.Gk,null,$CLJS.gk,null,$CLJS.oi,null,$CLJS.ak,null,$CLJS.yi,null,$CLJS.Mi,null],null),null),PS=new $CLJS.dh(null,new $CLJS.h(null,7,[$CLJS.Dl,null,$CLJS.hH,null,$CLJS.mi,null,$CLJS.Gi,null,$CLJS.kH,null,$CLJS.Cj,null,$CLJS.kj,null],null),null),
QS=$CLJS.vu.g(OS,PS),Wja=$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"date bucketing unit"],null)],null),OS),Xja=$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"time bucketing unit"],null)],null),PS),RS=$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"datetime bucketing unit"],null)],null),QS),SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vJ],null),Yja=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,
1,[$CLJS.Ht,"temporal extract unit"],null),$CLJS.lH,$CLJS.Mi,$CLJS.Gk,$CLJS.lJ,$CLJS.zJ,$CLJS.AJ,$CLJS.gk,$CLJS.Qi,$CLJS.Gi,$CLJS.Cj,$CLJS.iH],null),Zja=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"datetime-diff unit"],null),$CLJS.hH,$CLJS.kj,$CLJS.Dl,$CLJS.vn,$CLJS.Fj,$CLJS.Pj,$CLJS.wi,$CLJS.oi],null),TS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"temporal-extract week extraction mode"],null),$CLJS.eJ,$CLJS.mJ,$CLJS.qJ],null),US=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"relative-datetime unit"],null),$CLJS.mi,$CLJS.kj,$CLJS.Dl,$CLJS.vn,$CLJS.Fj,$CLJS.Pj,$CLJS.wi,$CLJS.oi],null),aT,aka,iT,jT,kT,lT,mT,nT,oT,$U,cka,aV,dka,eka,bV,fka,gka,hka;$CLJS.X(gN,tM($CLJS.lC,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.iw],null),$CLJS.Ki],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,US],null)])));var VS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gN],null);
$CLJS.X(MP,tM($CLJS.kC,$CLJS.H(["n",$CLJS.Ki,"unit",US])));var $ja=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MP],null);
$CLJS.X(BN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.Ht,"valid :absolute-datetime clause",$CLJS.bj,function(a){if($CLJS.Va(sM($CLJS.jJ,a)))a=AN;else{a=$CLJS.jd(a);var b=$CLJS.oM(LS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.QP:$CLJS.nJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[AN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"not an :absolute-datetime clause"],null),$CLJS.Xe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QP,tM($CLJS.jJ,
$CLJS.H(["date",LS,"unit",Wja]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nJ,tM($CLJS.jJ,$CLJS.H(["datetime",MS,"unit",RS]))],null)],null));var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BN],null);$CLJS.X(QN,tM($CLJS.Yy,$CLJS.H(["time",NS,"unit",Xja])));var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QN],null),YS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.As,new $CLJS.h(null,1,[$CLJS.Ht,"date or datetime literal"],null),WS,MS,LS],null);
$CLJS.X(BM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.h(null,1,[$CLJS.Ht,"time literal"],null),XS,NS],null));$CLJS.X(sS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.h(null,1,[$CLJS.Ht,"temporal literal"],null),YS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BM],null)],null));var ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sS],null);
$CLJS.X(xka,uM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof WS?new $CLJS.Gc(function(){return WS},$CLJS.qd(Bia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,KQ,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sQ,"metabase/mbql/schema.cljc",69,$CLJS.jJ,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(WS)?WS.H:null])):null));return $CLJS.n(a)?a:sQ}(),WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof VS?new $CLJS.Gc(function(){return VS},$CLJS.qd(mla,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,
$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),KP,"metabase/mbql/schema.cljc",29,$CLJS.lC,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lC,$CLJS.sn,$CLJS.V($CLJS.Uj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.iw],null),$CLJS.Ki],null)),$CLJS.zi,$CLJS.V($CLJS.Uj,$CLJS.V(vR,gP))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(VS)?VS.H:null])):null));return $CLJS.n(a)?a:KP}(),VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof XS?new $CLJS.Gc(function(){return XS},$CLJS.qd(zja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,KQ,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),jS,"metabase/mbql/schema.cljc",27,$CLJS.Yy,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Yy,$CLJS.Yy,$CLJS.V($CLJS.Uj,wka),$CLJS.zi,$CLJS.V($CLJS.Uj,Tia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(XS)?XS.H:null])):null));return $CLJS.n(a)?a:jS}(),XS],null)])));
$CLJS.X(hS,tM($CLJS.Ij,$CLJS.H(["value",$CLJS.ri,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kS,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,FS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uD,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,GS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ys,HS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,RS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,FS],null)],null)],null)],null)])));var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hS],null);$CLJS.X(QO,tM($CLJS.HB,$CLJS.H(["expression-name",FS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,$CLJS.Dk],null)])));
aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QO],null);
aka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.SE);c=$CLJS.J.g(c,$CLJS.rE);return $CLJS.E.g(b,$CLJS.rE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.SE);c=$CLJS.J.g(c,$CLJS.RE);return $CLJS.E.g(b,$CLJS.RE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.Ht,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"binning strategy"],null),$CLJS.rE,$CLJS.RE,$CLJS.mi],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),IS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.RE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,$CLJS.Fl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"bin width must be \x3e\x3d 0."],null),$CLJS.We($CLJS.$l)],null)],null)],null)],null)));$CLJS.bT=function bT(a){switch(arguments.length){case 1:return bT.h(arguments[0]);case 2:return bT.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.bT.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.KB);b=$CLJS.J.g(b,$CLJS.GG);return $CLJS.bT.g(a,b)};$CLJS.bT.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.MC(a,$CLJS.no)?OS:$CLJS.MC(a,$CLJS.yo)?PS:$CLJS.MC(a,$CLJS.qo)?QS:null:null;return $CLJS.n(a)?$CLJS.Ld(a,b):!0};$CLJS.bT.A=2;
$CLJS.X(wR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.Ht,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KB,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,GS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SN,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,KS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GG,new $CLJS.h(null,
1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,RS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IP,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,FS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IR,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,aka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.bT],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.We($CLJS.SE)],null)],null));
$CLJS.X(ZR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.KB);return"string"===typeof c?b:!0}],null)],null)}(tM($CLJS.AG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,KS,FS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wR],null)],null)]))));var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZR],null);
$CLJS.X(ZP,uM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof aT?new $CLJS.Gc(function(){return aT},$CLJS.qd(bO,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MN,"metabase/mbql/schema.cljc",60,$CLJS.HB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.HB,$CLJS.oS,$CLJS.V($CLJS.Uj,iN),$CLJS.xj,$CLJS.V($CLJS.Uj,$CLJS.V(vR,$CLJS.Dk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:MN}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof cT?new $CLJS.Gc(function(){return cT},
$CLJS.qd(tO,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.Er,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],["0.39.0",$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uN,"metabase/mbql/schema.cljc",51,$CLJS.AG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:uN}(),cT],null)])));
$CLJS.dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZP],null);$CLJS.X(cM,tM($CLJS.pG,$CLJS.H(["aggregation-clause-index",$CLJS.Ki,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,$CLJS.Dk],null)])));var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cM],null);
$CLJS.X(cN,uM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof eT?new $CLJS.Gc(function(){return eT},$CLJS.qd(uka,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),HR,"metabase/mbql/schema.cljc",23,$CLJS.pG,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pG,ola,$CLJS.V($CLJS.Uj,$CLJS.Ki),$CLJS.xj,$CLJS.V($CLJS.Uj,$CLJS.V(vR,$CLJS.Dk))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:HR}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof aT?new $CLJS.Gc(function(){return aT},
$CLJS.qd(bO,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MN,"metabase/mbql/schema.cljc",60,$CLJS.HB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.HB,$CLJS.oS,$CLJS.V($CLJS.Uj,iN),$CLJS.xj,
$CLJS.V($CLJS.Uj,$CLJS.V(vR,$CLJS.Dk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:MN}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof cT?new $CLJS.Gc(function(){return cT},$CLJS.qd(tO,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.Er,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,
$CLJS.U,$CLJS.zl],["0.39.0",$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uN,"metabase/mbql/schema.cljc",51,$CLJS.AG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:uN}(),cT],null)])));
var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cN],null),gT=new $CLJS.dh(null,new $CLJS.h(null,11,[$CLJS.ut,null,$CLJS.RG,null,$CLJS.CF,null,$CLJS.XG,null,$CLJS.ZF,null,$CLJS.DG,null,$CLJS.iG,null,$CLJS.Dt,null,$CLJS.PG,null,$CLJS.CG,null,$CLJS.YG,null],null),null),hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$M],null);
$CLJS.X(DM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,1,[$CLJS.bj,function(a){return"string"===typeof a?$CLJS.tk:$CLJS.n(sM(gT,a))?WM:$CLJS.n(sM($CLJS.Ij,a))?$CLJS.Ij:$CLJS.wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.tk],null),new $CLJS.P(null,2,5,$CLJS.Q,[WM,hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wt,$CLJS.dT],null)],null));iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DM],null);
jT=new $CLJS.dh(null,new $CLJS.h(null,26,[$CLJS.FF,null,$CLJS.yJ,null,$CLJS.ls,null,$CLJS.sC,null,$CLJS.tJ,null,$CLJS.nG,null,$CLJS.rt,null,$CLJS.BF,null,$CLJS.wJ,null,$CLJS.pC,null,$CLJS.DG,null,$CLJS.UG,null,$CLJS.uJ,null,$CLJS.FG,null,$CLJS.XF,null,$CLJS.hJ,null,$CLJS.js,null,$CLJS.$F,null,$CLJS.iG,null,$CLJS.aJ,null,$CLJS.$x,null,$CLJS.iJ,null,$CLJS.rJ,null,$CLJS.fC,null,$CLJS.WG,null,$CLJS.yG,null],null),null);
kT=new $CLJS.dh(null,new $CLJS.h(null,9,[$CLJS.fs,null,$CLJS.cs,null,$CLJS.As,null,$CLJS.Ds,null,$CLJS.ds,null,$CLJS.QG,null,$CLJS.Ks,null,$CLJS.gs,null,$CLJS.es,null],null),null);lT=new $CLJS.dh(null,new $CLJS.h(null,17,[$CLJS.Wi,null,$CLJS.OG,null,$CLJS.JG,null,$CLJS.BG,null,$CLJS.TG,null,$CLJS.VG,null,$CLJS.eG,null,$CLJS.lG,null,$CLJS.IM,null,$CLJS.fG,null,$CLJS.NG,null,$CLJS.HG,null,$CLJS.Fk,null,$CLJS.iy,null,$CLJS.uG,null,$CLJS.vG,null,$CLJS.MG,null],null),null);
mT=new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.oJ,null,$CLJS.cJ,null,$CLJS.uC,null,$CLJS.js,null,$CLJS.rC,null],null),null);nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oR],null);oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ON],null);$CLJS.pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QQ],null);$CLJS.qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uR],null);
$CLJS.X(BR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.Ht,"numeric expression argument",$CLJS.bj,function(a){return"number"===typeof a?$CLJS.gu:$CLJS.n(sM(jT,a))?rP:$CLJS.n(sM(lT,a))?$CLJS.pG:$CLJS.n(sM($CLJS.Ij,a))?$CLJS.Ij:$CLJS.AG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gu,$CLJS.Fl],null),new $CLJS.P(null,2,5,$CLJS.Q,[rP,nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pG,$CLJS.qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AG,
$CLJS.dT],null)],null));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BR],null);
$CLJS.X(LQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.Ht,"datetime expression argument",$CLJS.bj,function(a){return $CLJS.n(sM(lT,a))?$CLJS.pG:$CLJS.n(sM($CLJS.Ij,a))?$CLJS.Ij:$CLJS.n(sM(mT,a))?VN:$CLJS.wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pG,$CLJS.qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[VN,$CLJS.pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,YS,$CLJS.dT],null)],null)],null));
var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LQ],null);
$CLJS.X(oQ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.Ht,"expression argument",$CLJS.bj,function(a){return"number"===typeof a?$CLJS.gu:$CLJS.Fd(a)?$CLJS.Rs:$CLJS.n(sM(kT,a))?DQ:$CLJS.n(sM(jT,a))?rP:$CLJS.n(sM(mT,a))?VN:"string"===typeof a?$CLJS.tk:$CLJS.n(sM(gT,a))?WM:$CLJS.n(sM($CLJS.Ij,a))?$CLJS.Ij:$CLJS.wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gu,$CLJS.Fl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,$CLJS.Rs],null),new $CLJS.P(null,2,5,$CLJS.Q,[DQ,oT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[rP,nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[VN,$CLJS.pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.tk],null),new $CLJS.P(null,2,5,$CLJS.Q,[WM,hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wt,$CLJS.dT],null)],null));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oQ],null);$CLJS.X(mQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.h(null,1,[$CLJS.Ht,"numeric expression arg or interval"],null),$ja,rT],null));
var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null);$CLJS.X(OM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.Ht,"int greater than zero or numeric expression",$CLJS.bj,function(a){return"number"===typeof a?$CLJS.gu:$CLJS.wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gu,IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wt,nT],null)],null));var bka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OM],null);
$CLJS.X(WN,tM($CLJS.DG,$CLJS.H(["a",tT,"b",tT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,tT],null)])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WN],null);$CLJS.X(AM,tM($CLJS.CF,$CLJS.H(["s",iT,"start",bka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,rT],null)])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AM],null);$CLJS.X(EQ,tM($CLJS.$x,$CLJS.H(["s",iT])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);$CLJS.X(bN,tM($CLJS.PG,$CLJS.H(["s",iT])));
var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bN],null);$CLJS.X(fN,tM($CLJS.YG,$CLJS.H(["s",iT])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fN],null);$CLJS.X(yN,tM($CLJS.RG,$CLJS.H(["s",iT])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yN],null);$CLJS.X(jO,tM($CLJS.ut,$CLJS.H(["s",iT])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jO],null);$CLJS.X(TM,tM($CLJS.Dt,$CLJS.H(["s",iT])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TM],null);
$CLJS.X(AR,tM($CLJS.XG,$CLJS.H(["s",iT,"match",$CLJS.tk,"replacement",$CLJS.tk])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AR],null);$CLJS.X(xS,tM($CLJS.ZF,$CLJS.H(["a",iT,"b",iT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,iT],null)])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xS],null);$CLJS.X(ZO,tM($CLJS.CG,$CLJS.H(["s",iT,"pattern",$CLJS.tk])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZO],null);
$CLJS.X(WR,tM($CLJS.js,$CLJS.H(["x",uT,"y",uT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,uT],null)])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WR],null);$CLJS.X(UR,tM($CLJS.rt,$CLJS.H(["x",rT,"y",uT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,uT],null)])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UR],null);$CLJS.X(TR,tM($CLJS.UG,$CLJS.H(["x",rT,"y",rT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,rT],null)])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TR],null);
$CLJS.X(VR,tM($CLJS.ls,$CLJS.H(["x",rT,"y",rT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,rT],null)])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VR],null);$CLJS.X(lQ,tM($CLJS.yG,$CLJS.H(["x",rT])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null);$CLJS.X(dR,tM($CLJS.FF,$CLJS.H(["x",rT])));var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dR],null);$CLJS.X(AS,tM($CLJS.FG,$CLJS.H(["x",rT])));var MT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AS],null);$CLJS.X(pP,tM($CLJS.$F,$CLJS.H(["x",rT])));
var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pP],null);$CLJS.X(vP,tM($CLJS.XF,$CLJS.H(["x",rT,"y",rT])));var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vP],null);$CLJS.X(TP,tM($CLJS.nG,$CLJS.H(["x",rT])));var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TP],null);$CLJS.X(PM,tM($CLJS.BF,$CLJS.H(["x",rT])));var QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PM],null);$CLJS.X(rQ,tM($CLJS.WG,$CLJS.H(["x",rT])));var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rQ],null);
$CLJS.X(oN,tM($CLJS.fC,$CLJS.H(["datetime-x",sT,"datetime-y",sT,"unit",Zja])));var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oN],null);$CLJS.X(GN,tM($CLJS.sC,$CLJS.H(["datetime",sT,"unit",Yja,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,TS],null)])));var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GN],null);$CLJS.X(xP,tM($CLJS.iJ,$CLJS.H(["date",sT])));var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xP],null);$CLJS.X($P,tM($CLJS.yJ,$CLJS.H(["date",sT])));
var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$P],null);$CLJS.X(CM,tM($CLJS.aJ,$CLJS.H(["date",sT])));var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CM],null);$CLJS.X(yS,tM($CLJS.pC,$CLJS.H(["date",sT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,TS],null)])));var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yS],null);$CLJS.X(KO,tM($CLJS.rJ,$CLJS.H(["date",sT])));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KO],null);$CLJS.X(UP,tM($CLJS.uJ,$CLJS.H(["date",sT])));
var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UP],null);$CLJS.X(kN,tM($CLJS.wJ,$CLJS.H(["datetime",sT])));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kN],null);$CLJS.X(PO,tM($CLJS.tJ,$CLJS.H(["datetime",sT])));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PO],null);$CLJS.X(dN,tM($CLJS.hJ,$CLJS.H(["datetime",sT])));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dN],null);$CLJS.X(uO,tM($CLJS.cJ,$CLJS.H(["datetime",sT,"to",SS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,SS],null)])));
var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uO],null),dU=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"datetime arithmetic unit"],null),$CLJS.kH,$CLJS.hH,$CLJS.kj,$CLJS.Dl,$CLJS.vn,$CLJS.Fj,$CLJS.Pj,$CLJS.wi,$CLJS.oi],null);$CLJS.X(zM,tM($CLJS.rC,$CLJS.H(["datetime",sT,"amount",rT,"unit",dU])));var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zM],null);
$CLJS.X(XM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return tM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.oJ));var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XM],null);$CLJS.X(fM,tM($CLJS.uC,$CLJS.H(["datetime",sT,"amount",rT,"unit",dU])));var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fM],null);
$CLJS.X(QQ,uM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.qd(fQ,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TO,"metabase/mbql/schema.cljc",51,$CLJS.js,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.js,$CLJS.PE,$CLJS.V($CLJS.Uj,jQ),GR,$CLJS.V($CLJS.Uj,jQ),XN,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,jQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:TO}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},
$CLJS.qd(jja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[nO,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aQ,"metabase/mbql/schema.cljc",67,$CLJS.rC,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rC,$CLJS.nJ,$CLJS.V($CLJS.Uj,tS),$Q,$CLJS.V($CLJS.Uj,
eR),$CLJS.zi,$CLJS.V($CLJS.Uj,mR)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:aQ}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.qd(cia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,
$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[nO,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rS,"metabase/mbql/schema.cljc",72,$CLJS.uC,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uC,$CLJS.nJ,$CLJS.V($CLJS.Uj,tS),$Q,$CLJS.V($CLJS.Uj,eR),$CLJS.zi,$CLJS.V($CLJS.Uj,mR)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(gU)?gU.H:null])):null));
return $CLJS.n(a)?a:rS}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.qd(Eka,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.cJ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UN,"metabase/mbql/schema.cljc",71,$CLJS.cJ,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cJ,$CLJS.nJ,$CLJS.V($CLJS.Uj,tS),bja,$CLJS.V($CLJS.Uj,JR),gia,$CLJS.V($CLJS.Uj,$CLJS.V(vR,JR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:UN}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&
"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.qd(Jja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.oJ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",
45,$CLJS.oJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.oJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:yP}(),fU],null)])));$CLJS.hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WP],null);$CLJS.X(EM,tM($CLJS.Ks,$CLJS.H(["first-clause",$CLJS.hU,"second-clause",$CLJS.hU,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.hU],null)])));var iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EM],null);
$CLJS.X(qO,tM($CLJS.As,$CLJS.H(["first-clause",$CLJS.hU,"second-clause",$CLJS.hU,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,$CLJS.hU],null)])));var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qO],null);$CLJS.X(ZM,tM($CLJS.Ds,$CLJS.H(["clause",$CLJS.hU])));
var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZM],null),lU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,3,[$CLJS.Ht,":field or :expression reference or :relative-datetime",$CLJS.$t,$CLJS.Xe(":field or :expression reference or :relative-datetime"),$CLJS.bj,function(a){return $CLJS.n(sM($CLJS.lC,a))?$CLJS.lC:$CLJS.wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lC,VS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wt,$CLJS.dT],null)],null);
$CLJS.X(NQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.h(null,1,[$CLJS.Ht,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.As,$CLJS.Rs,$CLJS.Fl,$CLJS.tk,ZS,lU,tT,$S],null)],null));var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NQ],null);
$CLJS.X(dP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.Ht,"order comparable",$CLJS.bj,function(a){return $CLJS.n(sM($CLJS.Ij,a))?$CLJS.Ij:$CLJS.wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ij,$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.As,$CLJS.Fl,$CLJS.tk,ZS,tT,lU],null)],null)],null));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dP],null);
$CLJS.X(MR,tM($CLJS.gs,$CLJS.H(["field",mU,"value-or-field",mU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,mU],null)])));var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MR],null);$CLJS.X(QR,tM($CLJS.QG,$CLJS.H(["field",mU,"value-or-field",mU,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,mU],null)])));var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QR],null);$CLJS.X(LR,tM($CLJS.es,$CLJS.H(["field",nU,"value-or-field",nU])));
var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LR],null);$CLJS.X(KR,tM($CLJS.cs,$CLJS.H(["field",nU,"value-or-field",nU])));var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KR],null);$CLJS.X(zN,tM($CLJS.fs,$CLJS.H(["field",nU,"value-or-field",nU])));var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zN],null);$CLJS.X(FN,tM($CLJS.ds,$CLJS.H(["field",nU,"value-or-field",nU])));var tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FN],null);$CLJS.X(vQ,tM($CLJS.bG,$CLJS.H(["field",nU,"min",nU,"max",nU])));
var uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vQ],null);$CLJS.X(XQ,tM($CLJS.hG,$CLJS.H(["lat-field",nU,"lon-field",nU,"lat-max",nU,"lon-min",nU,"lat-min",nU,"lon-max",nU])));var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XQ],null);$CLJS.X(iO,tM($CLJS.aG,$CLJS.H(["field",$CLJS.dT])));var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iO],null);$CLJS.X(YO,tM($CLJS.mG,$CLJS.H(["field",$CLJS.dT])));var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YO],null);$CLJS.X(AO,tM($CLJS.cG,$CLJS.H(["field",$CLJS.dT])));
var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AO],null);$CLJS.X(HM,tM($CLJS.jG,$CLJS.H(["field",$CLJS.dT])));var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HM],null),AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null)],null);$CLJS.X(NO,tM($CLJS.rG,$CLJS.H(["field",iT,"string-or-field",iT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,AU],null)])));var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NO],null);
$CLJS.X(HP,tM($CLJS.LG,$CLJS.H(["field",iT,"string-or-field",iT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,AU],null)])));var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HP],null);$CLJS.X(yO,tM($CLJS.zG,$CLJS.H(["field",iT,"string-or-field",iT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,AU],null)])));var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yO],null);$CLJS.X(pQ,tM($CLJS.sG,$CLJS.H(["field",iT,"string-or-field",iT,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,AU],null)])));
var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pQ],null);$CLJS.X(QM,tM($CLJS.tC,$CLJS.H(["field",$CLJS.dT,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.Ki,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,$CLJS.iw,$CLJS.ny,$CLJS.gG],null)],null),"unit",US,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vo,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null)],null)],null)])));var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QM],null);
$CLJS.X(GQ,tM($CLJS.YF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aL],null),FS],null)])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GQ],null);
$CLJS.X(ON,uM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.qd(zR,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),hP,"metabase/mbql/schema.cljc",15,$CLJS.Ks,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ks,YN,$CLJS.V($CLJS.Uj,mP),RP,$CLJS.V($CLJS.Uj,mP),hO,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,mP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:hP}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},
$CLJS.qd(VP,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",14,$CLJS.As,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.As,YN,$CLJS.V($CLJS.Uj,mP),RP,$CLJS.V($CLJS.Uj,mP),hO,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,mP))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:YP}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.qd(HQ,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zP,"metabase/mbql/schema.cljc",15,$CLJS.Ds,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.nS,$CLJS.V($CLJS.Uj,mP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:zP}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},$CLJS.qd(dQ,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,
$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",13,$CLJS.es,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.es,$CLJS.AG,$CLJS.V($CLJS.Uj,$N),kR,$CLJS.V($CLJS.Uj,$N)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:aP}(),qU],null),
new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.qd(gS,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$O,"metabase/mbql/schema.cljc",14,$CLJS.fs,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fs,$CLJS.AG,$CLJS.V($CLJS.Uj,$N),kR,$CLJS.V($CLJS.Uj,$N)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:$O}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.qd(bQ,new $CLJS.h(null,
1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bP,"metabase/mbql/schema.cljc",13,$CLJS.cs,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.cs,$CLJS.AG,$CLJS.V($CLJS.Uj,$N),kR,$CLJS.V($CLJS.Uj,$N)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(rU)?rU.H:null])):null));
return $CLJS.n(a)?a:bP}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.qd($R,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iP,"metabase/mbql/schema.cljc",14,$CLJS.ds,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ds,$CLJS.AG,$CLJS.V($CLJS.Uj,$N),kR,$CLJS.V($CLJS.Uj,$N)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:iP}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.qd(cQ,new $CLJS.h(null,
1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",13,$CLJS.gs,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gs,$CLJS.AG,$CLJS.V($CLJS.Uj,lS),kR,$CLJS.V($CLJS.Uj,lS),OQ,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,lS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",
$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:cP}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.qd(pN,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LN,"metabase/mbql/schema.cljc",14,$CLJS.QG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.QG,$CLJS.AG,$CLJS.V($CLJS.Uj,lS),kR,$CLJS.V($CLJS.Uj,lS),OQ,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,lS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:LN}(),pU],null)])));
$CLJS.X(WP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.Ht,"valid filter expression",$CLJS.bj,function(a){return $CLJS.n(sM(mT,a))?$CLJS.nJ:$CLJS.n(sM(jT,a))?uS:$CLJS.n(sM(gT,a))?$CLJS.tk:$CLJS.n(sM(kT,a))?$CLJS.Rs:$CLJS.wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nJ,$CLJS.pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[uS,nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wt,uM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof iU?new $CLJS.Gc(function(){return iU},$CLJS.qd(zR,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",
15,$CLJS.Ks,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ks,YN,$CLJS.V($CLJS.Uj,mP),RP,$CLJS.V($CLJS.Uj,mP),hO,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,mP))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(iU)?iU.H:null])):null));return $CLJS.n(a)?a:hP}(),iU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.qd(VP,new $CLJS.h(null,1,[$CLJS.uk,
!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YP,"metabase/mbql/schema.cljc",14,$CLJS.As,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.As,YN,$CLJS.V($CLJS.Uj,mP),RP,$CLJS.V($CLJS.Uj,mP),hO,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,mP))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(jU)?
jU.H:null])):null));return $CLJS.n(a)?a:YP}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},$CLJS.qd(HQ,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zP,"metabase/mbql/schema.cljc",15,$CLJS.Ds,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ds,$CLJS.nS,$CLJS.V($CLJS.Uj,mP)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:zP}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.qd(cQ,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,
$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cP,"metabase/mbql/schema.cljc",13,$CLJS.gs,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gs,$CLJS.AG,$CLJS.V($CLJS.Uj,lS),kR,$CLJS.V($CLJS.Uj,lS),OQ,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,lS))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(oU)?oU.H:null])):null));
return $CLJS.n(a)?a:cP}(),oU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.qd(pN,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),LN,"metabase/mbql/schema.cljc",14,$CLJS.QG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.QG,$CLJS.AG,$CLJS.V($CLJS.Uj,lS),kR,$CLJS.V($CLJS.Uj,lS),OQ,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,lS))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:LN}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof qU?new $CLJS.Gc(function(){return qU},
$CLJS.qd(dQ,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aP,"metabase/mbql/schema.cljc",13,$CLJS.es,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.es,$CLJS.AG,$CLJS.V($CLJS.Uj,$N),kR,$CLJS.V($CLJS.Uj,$N)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(qU)?qU.H:null])):null));return $CLJS.n(a)?a:aP}(),qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.qd(bQ,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),bP,"metabase/mbql/schema.cljc",13,$CLJS.cs,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.cs,$CLJS.AG,$CLJS.V($CLJS.Uj,$N),kR,$CLJS.V($CLJS.Uj,$N)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:bP}(),rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.qd(gS,new $CLJS.h(null,
1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$O,"metabase/mbql/schema.cljc",14,$CLJS.fs,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fs,$CLJS.AG,$CLJS.V($CLJS.Uj,$N),kR,$CLJS.V($CLJS.Uj,$N)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(sU)?sU.H:null])):
null));return $CLJS.n(a)?a:$O}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.qd($R,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),iP,"metabase/mbql/schema.cljc",14,$CLJS.ds,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ds,$CLJS.AG,$CLJS.V($CLJS.Uj,$N),kR,$CLJS.V($CLJS.Uj,$N)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:iP}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof uU?new $CLJS.Gc(function(){return uU},$CLJS.qd(Bja,new $CLJS.h(null,
1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rN,"metabase/mbql/schema.cljc",19,$CLJS.bG,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bG,$CLJS.AG,$CLJS.V($CLJS.Uj,$N),$CLJS.Wi,$CLJS.V($CLJS.Uj,$N),$CLJS.Fk,$CLJS.V($CLJS.Uj,$N)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(uU)?uU.H:null])):null));return $CLJS.n(a)?a:rN}(),uU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.qd(Dja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pR,"metabase/mbql/schema.cljc",23,$CLJS.rG,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rG,$CLJS.AG,$CLJS.V($CLJS.Uj,jR),KN,$CLJS.V($CLJS.Uj,jR),$CLJS.xj,$CLJS.V($CLJS.Uj,$CLJS.V(vR,FQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:pR}(),BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},
$CLJS.qd(Eia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PN,"metabase/mbql/schema.cljc",21,$CLJS.LG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.LG,$CLJS.AG,$CLJS.V($CLJS.Uj,jR),KN,$CLJS.V($CLJS.Uj,jR),$CLJS.xj,$CLJS.V($CLJS.Uj,$CLJS.V(vR,FQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:PN}(),CU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.qd(Ria,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aO,"metabase/mbql/schema.cljc",20,$CLJS.zG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.zG,$CLJS.AG,$CLJS.V($CLJS.Uj,jR),KN,$CLJS.V($CLJS.Uj,jR),$CLJS.xj,$CLJS.V($CLJS.Uj,$CLJS.V(vR,FQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(DU)?DU.H:null])):null));return $CLJS.n(a)?a:aO}(),DU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==
typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.qd(kla,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FM,"metabase/mbql/schema.cljc",36,$CLJS.sG,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.sG,$CLJS.AG,$CLJS.V($CLJS.Uj,jR),KN,$CLJS.V($CLJS.Uj,jR),$CLJS.xj,$CLJS.V($CLJS.Uj,$CLJS.V(vR,FQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:FM}(),EU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.qd(Bka,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),
$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nR,"metabase/mbql/schema.cljc",26,$CLJS.hG,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.hG,mia,$CLJS.V($CLJS.Uj,$N),pla,$CLJS.V($CLJS.Uj,$N),Ika,$CLJS.V($CLJS.Uj,$N),pia,$CLJS.V($CLJS.Uj,$N),Cka,$CLJS.V($CLJS.Uj,$N),lia,$CLJS.V($CLJS.Uj,
$N)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:nR}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.qd(Sia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JP,"metabase/mbql/schema.cljc",28,$CLJS.cG,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG,$CLJS.AG,$CLJS.V($CLJS.Uj,TN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:JP}(),yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&
"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.qd(Pja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wQ,"metabase/mbql/schema.cljc",29,$CLJS.jG,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,$CLJS.AG,$CLJS.V($CLJS.Uj,TN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:wQ}(),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.qd(Ska,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gO,"metabase/mbql/schema.cljc",27,$CLJS.aG,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aG,$CLJS.AG,$CLJS.V($CLJS.Uj,TN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:gO}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&
"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.qd(dja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wN,"metabase/mbql/schema.cljc",28,$CLJS.mG,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mG,$CLJS.AG,$CLJS.V($CLJS.Uj,TN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(xU)?xU.H:null])):null));return $CLJS.n(a)?a:wN}(),xU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.qd(dia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VQ,"metabase/mbql/schema.cljc",33,$CLJS.tC,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.tC,$CLJS.AG,$CLJS.V($CLJS.Uj,TN),$CLJS.sn,$CLJS.V($CLJS.Uj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.Ki,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,$CLJS.iw,$CLJS.ny,$CLJS.gG],null)],null)),$CLJS.zi,$CLJS.V($CLJS.Uj,gP),$CLJS.xj,$CLJS.V($CLJS.Uj,$CLJS.V(vR,Gja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(FU)?FU.H:null])):null));return $CLJS.n(a)?a:VQ}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.qd(lja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YR,"metabase/mbql/schema.cljc",27,$CLJS.YF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,tia,$CLJS.V($CLJS.Uj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,lka,iN],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:YR}(),GU],null)]))],null)],null));
$CLJS.X(IQ,tM($CLJS.iG,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Li,new $CLJS.h(null,1,[$CLJS.Ht,":case subclause"],null),$CLJS.hU,tT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.Ht,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),tT],null)],null)],null)])));
var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IQ],null);
$CLJS.X(oR,uM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.qd(fQ,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TO,"metabase/mbql/schema.cljc",51,$CLJS.js,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.js,$CLJS.PE,$CLJS.V($CLJS.Uj,jQ),GR,$CLJS.V($CLJS.Uj,jQ),XN,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,jQ))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:TO}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},
$CLJS.qd(xja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",51,$CLJS.rt,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rt,$CLJS.PE,$CLJS.V($CLJS.Uj,eR),
GR,$CLJS.V($CLJS.Uj,jQ),XN,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,jQ))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:UO}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM?new $CLJS.Gc(function(){return IT},$CLJS.qd(wja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,wP,$CLJS.hj,$CLJS.Ej,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,
new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.vA,"metabase/mbql/schema.cljc",$CLJS.UG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.UG,$CLJS.PE,$CLJS.V($CLJS.Uj,eR),GR,$CLJS.V($CLJS.Uj,eR),XN,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,eR))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:$CLJS.vA}(),IT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.qd(yja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),SO,"metabase/mbql/schema.cljc",51,$CLJS.ls,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ls,$CLJS.PE,$CLJS.V($CLJS.Uj,eR),GR,$CLJS.V($CLJS.Uj,eR),XN,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,eR))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:SO}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},
$CLJS.qd(SQ,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gR,"metabase/mbql/schema.cljc",58,$CLJS.DG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.DG,PR,$CLJS.V($CLJS.Uj,KM),OR,$CLJS.V($CLJS.Uj,
KM),XN,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,KM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:gR}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.qd(Oja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,
$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zQ,"metabase/mbql/schema.cljc",56,$CLJS.$x,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$x,$CLJS.ry,$CLJS.V($CLJS.Uj,jR)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:zQ}(),xT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.qd(Fia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),tP,"metabase/mbql/schema.cljc",55,$CLJS.yG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yG,$CLJS.PE,$CLJS.V($CLJS.Uj,eR)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:tP}(),KT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.qd(zia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),
$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hN,"metabase/mbql/schema.cljc",54,$CLJS.FF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,$CLJS.PE,$CLJS.V($CLJS.Uj,eR)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(LT)?
LT.H:null])):null));return $CLJS.n(a)?a:hN}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof MT?new $CLJS.Gc(function(){return MT},$CLJS.qd(Oia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jP,"metabase/mbql/schema.cljc",55,$CLJS.FG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FG,$CLJS.PE,$CLJS.V($CLJS.Uj,eR)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(MT)?MT.H:null])):null));return $CLJS.n(a)?a:jP}(),MT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&
"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},$CLJS.qd(lla,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NP,"metabase/mbql/schema.cljc",53,$CLJS.$F,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.$F,$CLJS.PE,$CLJS.V($CLJS.Uj,eR)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:NP}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.qd(Gka,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,
sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[dS,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yR,"metabase/mbql/schema.cljc",69,$CLJS.XF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.XF,$CLJS.PE,$CLJS.V($CLJS.Uj,eR),GR,$CLJS.V($CLJS.Uj,eR)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:yR}(),OT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.qd(gja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[dS,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OO,"metabase/mbql/schema.cljc",68,$CLJS.nG,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nG,$CLJS.PE,$CLJS.V($CLJS.Uj,eR)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(PT)?PT.H:null])):null));return $CLJS.n(a)?a:OO}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof QT?new $CLJS.Gc(function(){return QT},$CLJS.qd(Hka,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),
$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[dS,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lP,"metabase/mbql/schema.cljc",67,$CLJS.BF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BF,$CLJS.PE,$CLJS.V($CLJS.Uj,eR)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(QT)?
QT.H:null])):null));return $CLJS.n(a)?a:lP}(),QT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.qd(sia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[dS,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iS,"metabase/mbql/schema.cljc",67,$CLJS.WG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WG,$CLJS.PE,$CLJS.V($CLJS.Uj,eR)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:iS}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&
"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.qd(VM,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",61,$CLJS.iG,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.iG,$CLJS.rx,$CLJS.V($CLJS.Uj,mO),$CLJS.xj,$CLJS.V($CLJS.Uj,$CLJS.V(vR,XP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:EP}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.qd(via,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,
$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.fC,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OP,"metabase/mbql/schema.cljc",65,$CLJS.fC,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.fC,Iia,$CLJS.V($CLJS.Uj,tS),Jia,$CLJS.V($CLJS.Uj,tS),$CLJS.zi,$CLJS.V($CLJS.Uj,oja)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(ST)?ST.H:null])):null));return $CLJS.n(a)?a:OP}(),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.qd(Sja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.sC,"null"],null),null),$CLJS.qd(cR,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gQ,"metabase/mbql/schema.cljc",71,$CLJS.sC,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.sC,$CLJS.nJ,$CLJS.V($CLJS.Uj,tS),$CLJS.zi,$CLJS.V($CLJS.Uj,Qka),$CLJS.Ci,$CLJS.V($CLJS.Uj,$CLJS.V(vR,BS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:gQ}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof UT?new $CLJS.Gc(function(){return UT},$CLJS.qd($ia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.sC,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EN,"metabase/mbql/schema.cljc",71,$CLJS.iJ,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iJ,$CLJS.QP,$CLJS.V($CLJS.Uj,tS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(UT)?UT.H:null])):null));return $CLJS.n(a)?a:EN}(),UT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof VT?new $CLJS.Gc(function(){return VT},$CLJS.qd(Pia,new $CLJS.h(null,1,[$CLJS.uk,!0],
null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.sC,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eQ,"metabase/mbql/schema.cljc",74,$CLJS.yJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yJ,$CLJS.QP,$CLJS.V($CLJS.Uj,tS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(VT)?VT.H:null])):null));return $CLJS.n(a)?a:eQ}(),VT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof WT?new $CLJS.Gc(function(){return WT},$CLJS.qd(vja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.sC,"null"],null),null),
$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BQ,"metabase/mbql/schema.cljc",72,$CLJS.aJ,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aJ,$CLJS.QP,$CLJS.V($CLJS.Uj,tS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(WT)?WT.H:null])):null));return $CLJS.n(a)?a:BQ}(),WT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==
typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.qd(Lka,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.sC,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AQ,"metabase/mbql/schema.cljc",
71,$CLJS.pC,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pC,$CLJS.QP,$CLJS.V($CLJS.Uj,tS),$CLJS.Ci,$CLJS.V($CLJS.Uj,$CLJS.V(vR,BS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:AQ}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},$CLJS.qd(jia,new $CLJS.h(null,1,[$CLJS.uk,
!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.sC,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tQ,"metabase/mbql/schema.cljc",70,$CLJS.rJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rJ,$CLJS.QP,$CLJS.V($CLJS.Uj,tS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(YT)?YT.H:null])):null));return $CLJS.n(a)?a:tQ}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.qd(pja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.sC,"null"],null),null),
$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",78,$CLJS.uJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uJ,$CLJS.QP,$CLJS.V($CLJS.Uj,tS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:kQ}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==
typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.qd(nja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.sC,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LM,"metabase/mbql/schema.cljc",
71,$CLJS.wJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wJ,$CLJS.nJ,$CLJS.V($CLJS.Uj,tS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:LM}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.qd(mka,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,
$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.sC,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mS,"metabase/mbql/schema.cljc",73,$CLJS.tJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tJ,$CLJS.nJ,$CLJS.V($CLJS.Uj,tS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(aU)?aU.H:null])):null));
return $CLJS.n(a)?a:mS}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.qd(kja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.sC,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GP,"metabase/mbql/schema.cljc",73,$CLJS.hJ,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hJ,$CLJS.nJ,$CLJS.V($CLJS.Uj,tS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:GP}(),bU],null)])));
$CLJS.X($M,uM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.qd(Mja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),JQ,"metabase/mbql/schema.cljc",59,$CLJS.CF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.CF,$CLJS.ry,$CLJS.V($CLJS.Uj,jR),$CLJS.Lv,$CLJS.V($CLJS.Uj,Lia),$CLJS.$x,$CLJS.V($CLJS.Uj,$CLJS.V(vR,eR))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:JQ}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},
$CLJS.qd(Jka,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fR,"metabase/mbql/schema.cljc",54,$CLJS.PG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PG,$CLJS.ry,$CLJS.V($CLJS.Uj,jR)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(yT)?yT.H:null])):null));return $CLJS.n(a)?a:fR}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.qd(qja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,
1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",55,$CLJS.RG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RG,$CLJS.ry,$CLJS.V($CLJS.Uj,jR)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:PQ}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&
"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.qd(Nja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",
55,$CLJS.YG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YG,$CLJS.ry,$CLJS.V($CLJS.Uj,jR)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:SP}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.qd(eia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,
$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RR,"metabase/mbql/schema.cljc",57,$CLJS.XG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.XG,$CLJS.ry,$CLJS.V($CLJS.Uj,jR),qia,$CLJS.V($CLJS.Uj,$CLJS.tk),$CLJS.BA,$CLJS.V($CLJS.Uj,$CLJS.tk)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:RR}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.qd(Qja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wS,"metabase/mbql/schema.cljc",55,$CLJS.Dt,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dt,$CLJS.ry,$CLJS.V($CLJS.Uj,jR)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:wS}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==
typeof yM&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.qd(Kka,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sR,"metabase/mbql/schema.cljc",55,$CLJS.ut,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,$CLJS.ry,$CLJS.V($CLJS.Uj,jR)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:sR}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.qd(Fja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,
$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",56,$CLJS.ZF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ZF,PR,$CLJS.V($CLJS.Uj,jR),OR,$CLJS.V($CLJS.Uj,jR),XN,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,jR))],null),$CLJS.Kc,"Schema for a valid concat clause.",$CLJS.n(ET)?
ET.H:null])):null));return $CLJS.n(a)?a:FO}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.qd(Lja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.nQ,"null",$CLJS.ik,"null"],null),null),
$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CN,"metabase/mbql/schema.cljc",74,$CLJS.CG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.CG,$CLJS.ry,$CLJS.V($CLJS.Uj,jR),$CLJS.AA,$CLJS.V($CLJS.Uj,$CLJS.tk)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:CN}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==
typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.qd(SQ,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.nQ,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
gR,"metabase/mbql/schema.cljc",58,$CLJS.DG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.DG,PR,$CLJS.V($CLJS.Uj,KM),OR,$CLJS.V($CLJS.Uj,KM),XN,$CLJS.V($CLJS.Uj,$CLJS.V($CLJS.Ri,KM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:gR}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},
$CLJS.qd(VM,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",61,$CLJS.iG,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.iG,$CLJS.rx,$CLJS.V($CLJS.Uj,mO),$CLJS.xj,
$CLJS.V($CLJS.Uj,$CLJS.V(vR,XP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:EP}(),HU],null)])));
$CLJS.IU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.Ht,":field or :expression reference or expression",$CLJS.bj,function(a){return $CLJS.n(sM(jT,a))?uS:$CLJS.n(sM(gT,a))?$CLJS.tk:$CLJS.n(sM(kT,a))?$CLJS.Rs:$CLJS.n(sM(mT,a))?$CLJS.nJ:$CLJS.n(sM($CLJS.iG,a))?$CLJS.iG:$CLJS.wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[uS,nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nJ,$CLJS.pT],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iG,HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wt,$CLJS.dT],null)],null);$CLJS.X(aS,tM($CLJS.iy,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,$CLJS.dT],null)])));var JU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aS],null);$CLJS.X(PP,tM($CLJS.BG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,$CLJS.dT],null)])));var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PP],null);$CLJS.X(YM,tM($CLJS.uG,$CLJS.H(["field-or-expression",$CLJS.IU])));
var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null);$CLJS.X(SR,tM($CLJS.VG,$CLJS.H(["field-or-expression",$CLJS.IU])));var MU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SR],null);$CLJS.X(xO,tM($CLJS.eG,$CLJS.H(["field-or-expression",$CLJS.IU])));var NU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xO],null);$CLJS.X(hR,tM($CLJS.MG,$CLJS.H(["field-or-expression",$CLJS.IU])));var OU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hR],null);$CLJS.X(sO,tM($CLJS.Wi,$CLJS.H(["field-or-expression",$CLJS.IU])));
var PU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sO],null);$CLJS.X(eO,tM($CLJS.Fk,$CLJS.H(["field-or-expression",$CLJS.IU])));var QU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eO],null);$CLJS.X(ZN,tM($CLJS.TG,$CLJS.H(["field-or-expression",$CLJS.IU,"pred",$CLJS.hU])));var RU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZN],null);$CLJS.X(pO,tM($CLJS.JG,$CLJS.H(["pred",$CLJS.hU])));var SU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pO],null);$CLJS.X(ZQ,tM($CLJS.HG,$CLJS.H(["pred",$CLJS.hU])));
var TU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZQ],null);$CLJS.X(vN,tM($CLJS.OG,$CLJS.H(["field-or-expression",$CLJS.IU])));var UU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vN],null);$CLJS.X(dM,tM($CLJS.fG,$CLJS.H(["field-or-expression",$CLJS.IU])));var VU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dM],null);$CLJS.X(BO,tM($CLJS.NG,$CLJS.H(["field-or-expression",$CLJS.IU])));var WU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BO],null);$CLJS.X(oP,tM($CLJS.lG,$CLJS.H(["field-or-expression",$CLJS.IU,"percentile",rT])));
var XU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oP],null);$CLJS.X(bR,tM($CLJS.vG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZK],null),FS],null)])));var YU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bR],null);
$CLJS.X(YQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.Ht,"unnamed aggregation clause or numeric expression",$CLJS.bj,function(a){return $CLJS.n(sM(jT,a))?rP:$CLJS.wt}],null),new $CLJS.P(null,2,5,$CLJS.Q,[rP,nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wt,uM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof LU?new $CLJS.Gc(function(){return LU},$CLJS.qd(Kja,
new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",60,$CLJS.uG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uG,tR,$CLJS.V($CLJS.Uj,lR)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(LU)?LU.H:null])):null));return $CLJS.n(a)?a:AP}(),LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof MU?new $CLJS.Gc(function(){return MU},$CLJS.qd(Wia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FR,"metabase/mbql/schema.cljc",64,$CLJS.VG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VG,tR,$CLJS.V($CLJS.Uj,lR)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:FR}(),MU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==
typeof yM&&"undefined"!==typeof NU?new $CLJS.Gc(function(){return NU},$CLJS.qd(Nia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FP,"metabase/mbql/schema.cljc",65,$CLJS.eG,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,tR,$CLJS.V($CLJS.Uj,lR)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(NU)?NU.H:null])):null));return $CLJS.n(a)?a:FP}(),NU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof UU?new $CLJS.Gc(function(){return UU},$CLJS.qd(Dka,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,
$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.KG,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SM,"metabase/mbql/schema.cljc",76,$CLJS.OG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OG,tR,$CLJS.V($CLJS.Uj,lR)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(UU)?UU.H:null])):null));return $CLJS.n(a)?a:SM}(),UU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof OU?new $CLJS.Gc(function(){return OU},$CLJS.qd(Aja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),nN,"metabase/mbql/schema.cljc",60,$CLJS.MG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MG,tR,$CLJS.V($CLJS.Uj,lR)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:nN}(),OU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof PU?new $CLJS.Gc(function(){return PU},$CLJS.qd(nia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,
$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.ht,"metabase/mbql/schema.cljc",60,$CLJS.Wi,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,tR,$CLJS.V($CLJS.Uj,lR)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(PU)?PU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.ht}(),PU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof QU?new $CLJS.Gc(function(){return QU},$CLJS.qd(iia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.lt,"metabase/mbql/schema.cljc",60,$CLJS.Fk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fk,tR,$CLJS.V($CLJS.Uj,lR)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(QU)?QU.H:null])):null));return $CLJS.n(a)?a:$CLJS.lt}(),QU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&
"undefined"!==typeof YU?new $CLJS.Gc(function(){return YU},$CLJS.qd(Kia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EO,"metabase/mbql/schema.cljc",18,$CLJS.vG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vG,Xia,$CLJS.V($CLJS.Uj,new $CLJS.P(null,3,5,
$CLJS.Q,[$CLJS.As,aja,iN],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(YU)?YU.H:null])):null));return $CLJS.n(a)?a:EO}(),YU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof TU?new $CLJS.Gc(function(){return TU},$CLJS.qd(ija,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,
$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wO,"metabase/mbql/schema.cljc",62,$CLJS.HG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HG,$CLJS.Bp,$CLJS.V($CLJS.Uj,mP)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(TU)?TU.H:null])):null));return $CLJS.n(a)?a:wO}(),TU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof SU?new $CLJS.Gc(function(){return SU},$CLJS.qd(oia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),jN,"metabase/mbql/schema.cljc",68,$CLJS.JG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JG,$CLJS.Bp,$CLJS.V($CLJS.Uj,mP)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(SU)?SU.H:null])):null));return $CLJS.n(a)?a:jN}(),SU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof RU?new $CLJS.Gc(function(){return RU},$CLJS.qd(jla,new $CLJS.h(null,1,[$CLJS.uk,!0],
null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BP,"metabase/mbql/schema.cljc",66,$CLJS.TG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.TG,tR,$CLJS.V($CLJS.Uj,lR),$CLJS.Bp,$CLJS.V($CLJS.Uj,mP)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(RU)?RU.H:null])):null));return $CLJS.n(a)?a:BP}(),RU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.qd(VM,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,
new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",61,$CLJS.iG,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.iG,$CLJS.rx,$CLJS.V($CLJS.Uj,mO),$CLJS.xj,$CLJS.V($CLJS.Uj,$CLJS.V(vR,XP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:EP}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&
"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof WU?new $CLJS.Gc(function(){return WU},$CLJS.qd(vka,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.wG,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DR,"metabase/mbql/schema.cljc",
68,$CLJS.NG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NG,tR,$CLJS.V($CLJS.Uj,lR)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(WU)?WU.H:null])):null));return $CLJS.n(a)?a:DR}(),WU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof XU?new $CLJS.Gc(function(){return XU},$CLJS.qd(uja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,
wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.wG,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aR,"metabase/mbql/schema.cljc",72,$CLJS.lG,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lG,tR,$CLJS.V($CLJS.Uj,lR),$CLJS.lG,$CLJS.V($CLJS.Uj,eR)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(XU)?XU.H:null])):
null));return $CLJS.n(a)?a:aR}(),XU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof VU?new $CLJS.Gc(function(){return VU},$CLJS.qd(yia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NM,"metabase/mbql/schema.cljc",77,$CLJS.fG,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fG,tR,$CLJS.V($CLJS.Uj,lR)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(VU)?VU.H:null])):null));return $CLJS.n(a)?a:NM}(),VU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof KU?new $CLJS.Gc(function(){return KU},$CLJS.qd(kia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,
$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",74,$CLJS.BG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,$CLJS.AG,$CLJS.V($CLJS.Uj,$CLJS.V(vR,TN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(KU)?KU.H:null])):null));return $CLJS.n(a)?a:nP}(),KU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof JU?new $CLJS.Gc(function(){return JU},$CLJS.qd(Mia,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([uP,$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,xR,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),
$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Bi,"metabase/mbql/schema.cljc",70,$CLJS.iy,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iy,$CLJS.AG,$CLJS.V($CLJS.Uj,$CLJS.V(vR,TN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(JU)?JU.H:null])):null));return $CLJS.n(a)?a:$CLJS.Bi}(),JU],null)]))],null)],null));
$CLJS.X(qQ,tM($CLJS.IM,$CLJS.H(["aggregation",YQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.Ht,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),FS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),FS],null)],null)])));
$CLJS.X(uR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.Ht,"aggregation clause or numeric expression",$CLJS.bj,function(a){return $CLJS.n(sM($CLJS.IM,a))?$CLJS.IM:lO}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[lO,YQ],null)],null));$CLJS.X(RM,tM($CLJS.xG,$CLJS.H(["field",fT])));var ZU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null);$CLJS.X(qP,tM($CLJS.qG,$CLJS.H(["field",fT])));
$U=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qP],null);
cka=uM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof ZU?new $CLJS.Gc(function(){return ZU},$CLJS.qd(Aka,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
XO,"metabase/mbql/schema.cljc",15,$CLJS.xG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,$CLJS.AG,$CLJS.V($CLJS.Uj,NR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(ZU)?ZU.H:null])):null));return $CLJS.n(a)?a:XO}(),ZU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof $U?new $CLJS.Gc(function(){return $U},$CLJS.qd(Via,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,
$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",16,$CLJS.qG,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,$CLJS.AG,$CLJS.V($CLJS.Uj,NR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n($U)?$U.H:null])):null));return $CLJS.n(a)?a:TQ}(),$U],null)]));
aV=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rs,$CLJS.UM,$CLJS.JN,$CLJS.DF,$CLJS.gu,$CLJS.Cl,$CLJS.QP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,FS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tj,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),FS],null)],null);
dka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,aV,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.UM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GM,FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hQ,IS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),IS],null)],null)],null);
eka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,aV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.JN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qR,IS],null)],null)],null);
bV=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,aV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.ri],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null)],null)],null);fka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ER],null);
gka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,bV,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.DF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DF,cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nj,$CLJS.pj,$CLJS.ri],
null)],null)],null)],null)],null);$CLJS.cV=new $CLJS.dh(null,new $CLJS.h(null,4,[$CLJS.QP,null,$CLJS.gu,null,$CLJS.Rs,null,$CLJS.Cl,null],null),null);hka=$CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rs],null),$CLJS.cV);
$CLJS.X(MQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,1,[$CLJS.bj,$CLJS.rj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DF,gka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UM,dka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JN,eka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Np,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,bV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,hka],null)],null)],null)],null)],null));
var dV=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nj,FS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Te(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,FS],null)],null)],null),ika=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,dV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF,$CLJS.ri],null)],null)],null),eV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uQ],null),fV=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,1,[$CLJS.bj,function(a){return $CLJS.n($CLJS.zE($CLJS.zd,
$CLJS.xQ)(a))?$CLJS.xQ:$CLJS.sE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,dV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xQ,$CLJS.ri],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sE,eV],null)],null),gV=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uD,GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[Cja,FS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.RO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,HS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Dk],null)],null)],null),hV=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,Vja,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cs,new $CLJS.h(null,1,[$CLJS.Ht,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),jka=$CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rs],null),
new $CLJS.dh(null,new $CLJS.h(null,4,[$CLJS.xF,null,$CLJS.VE,null,$CLJS.qF,null,$CLJS.uF,null],null),null)),iV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CR],null);
$CLJS.X(vO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sP,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),hV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),fV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eS,$CLJS.hU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),jka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.As,new $CLJS.h(null,1,[$CLJS.Ht,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.Hy,$CLJS.yA],null),iV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lF,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),FS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,KS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eP,new $CLJS.h(null,
1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,gV],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.zE($CLJS.VA($CLJS.sP,$CLJS.WQ),$CLJS.We($CLJS.zE($CLJS.sP,$CLJS.WQ)))],null)],null));var sla=$CLJS.Q,CS;var DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vO],null)],null);
if($CLJS.yd(DS)&&$CLJS.E.g($CLJS.A(DS),$CLJS.Tj)){var ES=$CLJS.y(DS);$CLJS.A(ES);var jV=$CLJS.B(ES),tla=$CLJS.zd($CLJS.A(jV))?jV:$CLJS.he(null,jV),kV=$CLJS.y(tla),vla=$CLJS.A(kV),wla=$CLJS.B(kV);CS=$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.R.j(vla,$CLJS.Wi,1)],null),wla)}else CS=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,DS,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(xN,new $CLJS.P(null,3,5,sla,[$CLJS.Ks,CS,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"All join aliases must be unique."],null),function(a){return $CLJS.eM($CLJS.of($CLJS.Wa,$CLJS.lf.g($CLJS.lF,a)))}],null)],null));var oka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xN],null);
$CLJS.X(CR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ul,new $CLJS.h(null,1,[$CLJS.Ht,"Distinct, non-empty sequence of Field clauses"],null),vM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.h(null,1,[$CLJS.Wi,1],null),$CLJS.dT],null))],null));
$CLJS.X(uQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),fV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sP,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),hV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.h(null,1,[$CLJS.Wi,1],null),$CLJS.qT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,new $CLJS.h(null,1,
[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.h(null,1,[$CLJS.Wi,1],null),$CLJS.dT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nj,FS,$CLJS.IU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),iV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.hU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zS,new $CLJS.h(null,
1,[$CLJS.Ts,!0],null),JS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cS,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),vM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.h(null,1,[$CLJS.Wi,1],null),cka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[yQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[yQ,IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[Dia,IS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),oka],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eP,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,gV],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.im(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WQ,$CLJS.sP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,
new $CLJS.h(null,1,[$CLJS.Ht,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.oG);b=$CLJS.J.g(b,$CLJS.WE);return $CLJS.ud($CLJS.Cu.g($CLJS.ih(a),$CLJS.ih(b)))}],null)],null));
$CLJS.oV=$CLJS.Lg([$CLJS.SG,$CLJS.QP,rO,cO,fP,qS,MO,$CLJS.pS,$CLJS.gu,LO,aN,iR,fS,$CLJS.qN,bS,oO,$CLJS.kP,JO,$CLJS.tj,$CLJS.IO,JM,$CLJS.NN,$CLJS.HO,LP,$CLJS.Rs,$CLJS.Cl,DO],[new $CLJS.h(null,1,[WO,new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.SG,null,$CLJS.QP,null,$CLJS.gu,null,$CLJS.Rs,null,$CLJS.Cl,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.QP,WO,new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.SG,null,$CLJS.QP,null,$CLJS.pS,null,$CLJS.kP,null,$CLJS.tj,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.rj,$CLJS.tk,$CLJS.KE,rR,WO,new $CLJS.dh(null,new $CLJS.h(null,1,[rO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,uS,$CLJS.KE,rR,WO,new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.SG,null,cO,null,$CLJS.gu,null,$CLJS.tj,null,JM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,$CLJS.tk,$CLJS.KE,eN,WO,new $CLJS.dh(null,new $CLJS.h(null,1,[fP,null],null),null)],null),new $CLJS.h(null,1,[WO,new $CLJS.dh(null,new $CLJS.h(null,1,[qS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,
uS,$CLJS.KE,Tja,WO,new $CLJS.dh(null,new $CLJS.h(null,1,[MO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.QP,WO,new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.pS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,uS,WO,new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.SG,null,cO,null,$CLJS.gu,null,$CLJS.tj,null,JM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,uS,$CLJS.KE,eN,WO,new $CLJS.dh(null,new $CLJS.h(null,1,[LO,null],null),null)],null),new $CLJS.h(null,1,[WO,new $CLJS.dh(null,
new $CLJS.h(null,1,[aN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,$CLJS.tk,$CLJS.KE,rR,WO,new $CLJS.dh(null,new $CLJS.h(null,8,[$CLJS.SG,null,qS,null,aN,null,iR,null,JO,null,$CLJS.tj,null,JM,null,$CLJS.Cl,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,$CLJS.tk,$CLJS.KE,eN,WO,new $CLJS.dh(null,new $CLJS.h(null,1,[fS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.QP,WO,new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.pS,null,$CLJS.qN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.rj,$CLJS.tk,$CLJS.KE,eN,WO,new $CLJS.dh(null,new $CLJS.h(null,1,[bS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,$CLJS.tk,$CLJS.KE,eN,WO,new $CLJS.dh(null,new $CLJS.h(null,1,[oO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.QP,WO,new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.SG,null,$CLJS.QP,null,$CLJS.pS,null,$CLJS.kP,null,$CLJS.tj,null],null),null)],null),new $CLJS.h(null,1,[WO,new $CLJS.dh(null,new $CLJS.h(null,1,[JO,null],null),null)],null),new $CLJS.h(null,1,[WO,
new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.tj,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.QP,WO,new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.pS,null,$CLJS.IO,null],null),null)],null),new $CLJS.h(null,1,[WO,new $CLJS.dh(null,new $CLJS.h(null,1,[JM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.QP,WO,new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.pS,null,$CLJS.NN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.QP,WO,new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.pS,
null,$CLJS.HO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,uS,$CLJS.KE,rR,WO,new $CLJS.dh(null,new $CLJS.h(null,1,[LP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.Rs,WO,new $CLJS.dh(null,new $CLJS.h(null,3,[$CLJS.SG,null,$CLJS.tj,null,$CLJS.Rs,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.rj,$CLJS.tk,WO,new $CLJS.dh(null,new $CLJS.h(null,8,[$CLJS.SG,null,qS,null,aN,null,iR,null,JO,null,$CLJS.tj,null,JM,null,$CLJS.Cl,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.rj,
uS,$CLJS.KE,eN,WO,new $CLJS.dh(null,new $CLJS.h(null,1,[DO,null],null),null)],null)]);$CLJS.X(ER,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"valid parameter type"],null)],null),$CLJS.$g($CLJS.oV)));$CLJS.X(mN,$CLJS.hg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"valid template tag widget type"],null),$CLJS.yA],null),$CLJS.$g($CLJS.oV)));
$CLJS.X(RQ,tM(uia,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,FS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,FS],null)],null)],null)])));var pV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RQ],null);$CLJS.X(MM,tM($CLJS.DF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.dT,pV],null)])));var qV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,MM],null);$CLJS.X(HN,tM(GO,$CLJS.H(["target",pV])));
var rV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HN],null),pka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.dT,uM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof qV?new $CLJS.Gc(function(){return qV},$CLJS.qd(sja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,
1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CQ,"metabase/mbql/schema.cljc",21,$CLJS.DF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,$CLJS.BJ,$CLJS.V($CLJS.Uj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,TN,VO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(qV)?qV.H:null])):null));return $CLJS.n(a)?a:CQ}(),qV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=wP.h($CLJS.rd("undefined"!==typeof $CLJS.wM&&
"undefined"!==typeof xM&&"undefined"!==typeof yM&&"undefined"!==typeof rV?new $CLJS.Gc(function(){return rV},$CLJS.qd(Hja,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,wP,$CLJS.hj,$CLJS.Ej,$CLJS.bo,sN,$CLJS.Ai,$CLJS.U,$CLJS.zl],[$CLJS.qd(cR,new $CLJS.h(null,1,[$CLJS.U,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vS,"metabase/mbql/schema.cljc",20,GO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[GO,$CLJS.BJ,
$CLJS.V($CLJS.Uj,VO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n(rV)?rV.H:null])):null));return $CLJS.n(a)?a:vS}(),rV],null)]))],null),pM=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tN],null);
$CLJS.X(tN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.zE($CLJS.VA($CLJS.xQ,$CLJS.WF),$CLJS.We($CLJS.zE($CLJS.xQ,$CLJS.WF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.xQ);var d=$CLJS.J.g(c,$CLJS.WF);c=$CLJS.J.g(c,$CLJS.rj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.As,new $CLJS.h(null,1,[$CLJS.Ht,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.kL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.WF,$CLJS.xQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),ika],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),eV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IN,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.P(null,
9,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,fka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tj,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),FS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BJ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),pka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.ri],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),FS],null),new $CLJS.P(null,3,5,$CLJS.Q,[Aia,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),
FS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.ri],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.ri],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[Zka,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),SS],null)],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[rja,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[CP,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),JS],null),new $CLJS.P(null,3,5,$CLJS.Q,[lN,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),JS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=
$CLJS.O(a);a=$CLJS.J.g(b,CP);b=$CLJS.J.g(b,lN);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[hja,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[yka,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null),new $CLJS.P(null,3,5,$CLJS.Q,[wia,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null),new $CLJS.P(null,3,5,$CLJS.Q,[fia,new $CLJS.h(null,1,[$CLJS.Ts,
!0],null),$CLJS.Rs],null),new $CLJS.P(null,3,5,$CLJS.Q,[Cia,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null),new $CLJS.P(null,3,5,$CLJS.Q,[kka,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Rs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[hia,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Rs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fka,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Rs],
null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zy,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DN,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.rs,$CLJS.Zj,Eja,$CLJS.zO,Gia,rla,Ija,Yia,tja,Qia,xia,Uia,Rja,fja,ria,Hia,qla,nla],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[nka,new $CLJS.h(null,1,[$CLJS.Ts,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,IS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[cja,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,IS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qR,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,Uja],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[ila,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,FS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[eja,new $CLJS.h(null,
1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,IS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tka,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nj,$CLJS.ri,$CLJS.ri],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Zia,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,IS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nj,$CLJS.ri,$CLJS.ri],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XK,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.ri],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.We($CLJS.eP)],
null)],null));var sV=$CLJS.oM(pM);(function(){var a=uha();return function(b){if($CLJS.n(sV.h?sV.h(b):sV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.lM(b);throw $CLJS.li($CLJS.BE("Invalid query: {0}",$CLJS.H([$CLJS.Ph.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Vy,c,mja,b],null));}})();