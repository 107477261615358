var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.cache.js");require("./metabase.util.js");require("./metabase.lib.util.js");require("./metabase.util.log.js");
'use strict';var k9,j9,lva,jza,kza,r9,lza,mza,oza,s9,pza,qza,hza,v9,rza,x9,sza,tza,uza;$CLJS.i9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Da(a,b):null};k9=function(a,b){return j9(a,b,$CLJS.N)};
j9=function(a,b,c){c=$CLJS.O(c);c=$CLJS.J.j(c,hza,!0);if($CLJS.zd(b))return $CLJS.hg.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=$CLJS.i9(b,"_plainObject");d=null==d?null:$CLJS.iA(d);return null==d?null:$CLJS.Ie(d)}():null;return $CLJS.n(c)?$CLJS.hg.j($CLJS.N,a,c):$CLJS.hg.j($CLJS.N,$CLJS.Uk.j($CLJS.lf.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.i9(b,d)],null)}),$CLJS.Vk.h(function(d){$CLJS.I(d,0,null);return"function"===typeof $CLJS.I(d,1,null)}),a),$CLJS.Ca(b))};
lva=function(a){var b=m9.h(a),c=n9.h(a),d=o9.h(a);return $CLJS.Uk.j($CLJS.lf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=$CLJS.Q;f=$CLJS.Ch.h(iza(f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.P(null,2,5,k,[f,e],null)}),$CLJS.ud(b)?$CLJS.Xd:$CLJS.Vk.h(function(e){var f=$CLJS.I(e,0,null);$CLJS.I(e,1,null);return $CLJS.Ld(b,f)}),$CLJS.Va(c)?$CLJS.Xd:$CLJS.lf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return new $CLJS.P(null,2,
5,$CLJS.Q,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};jza=function(a){return k9($CLJS.lf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.Ch.h(iza(c));c=$CLJS.E.g(c,$CLJS.EV)?$CLJS.SE:c;b=$CLJS.E.g(c,$CLJS.SE)?$CLJS.Ch.h(b):b;return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),a)};
kza=function(a){var b=$CLJS.QH(function(d){d=$CLJS.i9(d,"type");var e=new $CLJS.dh(null,new $CLJS.h(null,2,["internal",null,"external",null],null),null);return e.h?e.h(d):e.call(null,d)},a);if($CLJS.n(b)){var c=$CLJS.Ch.h($CLJS.i9(b,"type"));return $CLJS.ll.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.tj,$CLJS.i9(b,"id"),$CLJS.T,$CLJS.i9(b,"name")],null),function(){var d=c instanceof $CLJS.M?c.T:null;switch(d){case "external":return new $CLJS.h(null,2,[$CLJS.nu,$CLJS.yL,$CLJS.iL,$CLJS.i9(b,"human_readable_field_id")],
null);case "internal":return new $CLJS.h(null,1,[$CLJS.nu,$CLJS.sL],null);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}()]))}return null};r9=function(a){return $CLJS.Wk.g(q9.g($CLJS.AG,$CLJS.N),a)};
lza=function(a,b){var c=q9.g($CLJS.JN,new $CLJS.h(null,1,[hza,!1],null)),d=q9.g($CLJS.JN,$CLJS.N);return $CLJS.ll.l($CLJS.H([$CLJS.R.j(function(){var e=$CLJS.i9($CLJS.i9(a,"tables"),["card__",$CLJS.p.h(b)].join(""));return c.h?c.h(e):c.call(null,e)}(),$CLJS.tj,b),function(){var e=$CLJS.i9($CLJS.i9(a,"questions"),$CLJS.p.h(b));var f=$CLJS.i9(e,"_card");e=$CLJS.n(f)?f:e;return d.h?d.h(e):d.call(null,e)}()]))};mza=new $CLJS.M(null,"plain-object","plain-object",-943264281);
$CLJS.nza=new $CLJS.M(null,"cards","cards",169174038);oza=new $CLJS.M(null,"binning-info","binning-info",-2117036653);s9=new $CLJS.M("metabase.lib.js.metadata","field-values","metabase.lib.js.metadata/field-values",-88384962);pza=new $CLJS.M(null,"db","db",993250759);$CLJS.t9=new $CLJS.M(null,"metrics","metrics",394093469);qza=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.u9=new $CLJS.M(null,"segments","segments",1937535949);hza=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);
v9=new $CLJS.M("metabase.lib.js.metadata","dimension","metabase.lib.js.metadata/dimension",2004773191);rza=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.w9=new $CLJS.M(null,"tables","tables",1334623052);x9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);sza=new $CLJS.M(null,"schema-name","schema-name",1666725119);tza=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);uza=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.vza=new $CLJS.M(null,"databases","databases",2040134125);var iza=$CLJS.Sh($CLJS.xB),m9,wza=$CLJS.$e($CLJS.N),xza=$CLJS.$e($CLJS.N),yza=$CLJS.$e($CLJS.N),zza=$CLJS.$e($CLJS.N),Aza=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null)),z9,Qza,Rza,Sza,Tza,Uza;m9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.Ch,Aza,wza,xza,yza,zza);m9.m(null,$CLJS.mi,function(){return null});
var n9,Bza=$CLJS.$e($CLJS.N),Cza=$CLJS.$e($CLJS.N),Dza=$CLJS.$e($CLJS.N),Eza=$CLJS.$e($CLJS.N),Fza=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));n9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.Ch,Fza,Bza,Cza,Dza,Eza);n9.m(null,$CLJS.mi,function(){return null});var p9,Gza=$CLJS.$e($CLJS.N),Hza=$CLJS.$e($CLJS.N),Iza=$CLJS.$e($CLJS.N),Jza=$CLJS.$e($CLJS.N),Kza=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
p9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","lib-type"),$CLJS.Ch,Kza,Gza,Hza,Iza,Jza);var o9,Lza=$CLJS.$e($CLJS.N),Mza=$CLJS.$e($CLJS.N),Nza=$CLJS.$e($CLJS.N),Oza=$CLJS.$e($CLJS.N),Pza=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));o9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Xd,Pza,Lza,Mza,Nza,Oza);o9.m(null,$CLJS.mi,function(){return null});
var q9=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-object-fn*"),function(f){return f},e,a,b,c,d)}();
q9.m(null,$CLJS.mi,function(a,b){var c=lva(a),d=p9.h(a);return function(e){try{var f=$CLJS.R.j(j9(c,e,b),$CLJS.nu,d),k=$CLJS.eB($CLJS.Uy);$CLJS.n($CLJS.dB("metabase.lib.js.metadata",k))&&$CLJS.cB("metabase.lib.js.metadata",k,$CLJS.LW("Parsed metadata %s %s\n%s",$CLJS.H([a,$CLJS.tj.h(f),$CLJS.MW(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.eB($CLJS.Vy),$CLJS.n($CLJS.dB("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.cB("metabase.lib.js.metadata",k,$CLJS.LW("Error parsing %s %s: %s",
$CLJS.H([a,$CLJS.Ph.l($CLJS.H([e])),$CLJS.JV(f)])),f):$CLJS.cB("metabase.lib.js.metadata",k,$CLJS.LW(f,$CLJS.H(["Error parsing %s %s: %s",a,$CLJS.Ph.l($CLJS.H([e])),$CLJS.JV(f)])),null)),null;throw l;}}});$CLJS.y9=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.Ch.h(f)},e,a,b,c,d)}();
Qza=$CLJS.$e($CLJS.N);Rza=$CLJS.$e($CLJS.N);Sza=$CLJS.$e($CLJS.N);Tza=$CLJS.$e($CLJS.N);Uza=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));z9=new $CLJS.hi($CLJS.Ih.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.Ch,Uza,Qza,Rza,Sza,Tza);
$CLJS.y9.m(null,$CLJS.mi,function(a,b){var c=q9.g(a,$CLJS.N);return k9($CLJS.lf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KW(e),new $CLJS.Mh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),$CLJS.i9(b,z9.h(a)))});p9.m(null,$CLJS.iQ,function(){return $CLJS.uL});m9.m(null,$CLJS.iQ,function(){return new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.WE,null,$CLJS.w9,null],null),null)});
n9.m(null,$CLJS.iQ,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "dbms-version":return $CLJS.hA(b,$CLJS.H([$CLJS.Di,!0]));case "features":return $CLJS.hg.j($CLJS.eh,$CLJS.lf.h($CLJS.Ch),b);case "native-permissions":return $CLJS.Ch.h(b);default:return b}}});z9.m(null,$CLJS.iQ,function(){return"databases"});p9.m(null,$CLJS.un,function(){return $CLJS.fL});
m9.m(null,$CLJS.un,function(){return new $CLJS.dh(null,new $CLJS.h(null,5,[x9,null,$CLJS.WE,null,$CLJS.u9,null,$CLJS.iQ,null,$CLJS.t9,null],null),null)});n9.m(null,$CLJS.un,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "entity-type":return $CLJS.Ch.h(b);case "field-order":return $CLJS.Ch.h(b);case "initial-sync-status":return $CLJS.Ch.h(b);case "visibility-type":return $CLJS.Ch.h(b);default:return b}}});
$CLJS.y9.m(null,$CLJS.un,function(a,b){var c=q9.g(a,$CLJS.N);return k9($CLJS.Uk.g($CLJS.Vk.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.lf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KW(e),new $CLJS.Mh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),$CLJS.i9(b,"tables"))});p9.m(null,$CLJS.AG,function(){return $CLJS.YK});
m9.m(null,$CLJS.AG,function(){return new $CLJS.dh(null,new $CLJS.h(null,6,[x9,null,$CLJS.un,null,uza,null,$CLJS.iQ,null,tza,null,$CLJS.t9,null],null),null)});o9.m(null,$CLJS.AG,function(){return new $CLJS.h(null,6,[$CLJS.tA,$CLJS.$K,$CLJS.zi,$CLJS.CW,$CLJS.oS,$CLJS.GE,oza,$CLJS.bX,$CLJS.WL,v9,$CLJS.nt,s9],null)});
n9.m(null,$CLJS.AG,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "base-type":return $CLJS.Ch.h(b);case "coercion-strategy":return $CLJS.Ch.h(b);case "effective-type":return $CLJS.Ch.h(b);case "fingerprint":return $CLJS.zd(b)?$CLJS.MV(b):$CLJS.hA(b,$CLJS.H([$CLJS.Di,!0]));case "has-field-values":return $CLJS.Ch.h(b);case "lib/source":switch(b){case "aggregation":return $CLJS.zL;case "breakout":return $CLJS.qL;default:return $CLJS.Ch.g("source",b)}case "metabase.lib.field/temporal-unit":return $CLJS.Ch.h(b);
case "semantic-type":return $CLJS.Ch.h(b);case "visibility-type":return $CLJS.Ch.h(b);case "id":return $CLJS.Bd(b)?$CLJS.jd(b):b;case "metabase.lib.field/binning":return jza(b);case "metabase.lib.js.metadata/field-values":return $CLJS.E.g($CLJS.i9(b,"type"),"full")?new $CLJS.h(null,2,[$CLJS.nt,$CLJS.iA($CLJS.i9(b,"values")),$CLJS.eL,$CLJS.iA($CLJS.i9(b,"human_readable_values"))],null):null;case "metabase.lib.js.metadata/dimension":return kza(b);default:return b}}});
q9.m(null,$CLJS.AG,function(a,b){var c=function(){var d=$CLJS.ei(q9,$CLJS.mi);return d.g?d.g(a,b):d.call(null,a,b)}();return function(d){d=c.h?c.h(d):c.call(null,d);var e=$CLJS.O(d);d=$CLJS.J.g(e,v9);var f=$CLJS.O(d),k=$CLJS.J.g(f,$CLJS.nu),l=$CLJS.J.g(e,s9);return $CLJS.Lk.l(function(){switch(k instanceof $CLJS.M?k.T:null){case "metadata.column.remapping/external":return $CLJS.R.j(e,$CLJS.mL,f);case "metadata.column.remapping/internal":return $CLJS.R.j(e,$CLJS.tL,$CLJS.ll.l($CLJS.H([f,l])));default:return e}}(),
v9,$CLJS.H([s9]))}});
$CLJS.y9.m(null,$CLJS.AG,function(a,b){var c=q9.g(a,$CLJS.N),d=$CLJS.i9(b,"fields");return k9($CLJS.am(function(e){var f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null);e=function(){var l=$CLJS.KW(f);if($CLJS.n(l))return l;l=$CLJS.qh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==$CLJS.i9(d,l);return m?$CLJS.KW(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.Mh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});
p9.m(null,$CLJS.JN,function(){return $CLJS.xL});m9.m(null,$CLJS.JN,function(){return new $CLJS.dh(null,new $CLJS.h(null,11,[x9,null,$CLJS.ul,null,qza,null,$CLJS.un,null,pza,null,mza,null,$CLJS.u9,null,$CLJS.iQ,null,rza,null,$CLJS.t9,null,sza,null],null),null)});
n9.m(null,$CLJS.JN,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "result-metadata":return $CLJS.n($CLJS.VA($CLJS.yd,$CLJS.Ua)(b))?r9(b):$CLJS.hA(b,$CLJS.H([$CLJS.Di,!0]));case "fields":return r9(b);case "visibility-type":return $CLJS.Ch.h(b);case "dataset-query":return $CLJS.hA(b,$CLJS.H([$CLJS.Di,!0]));case "dataset":return b;default:return b}}});
$CLJS.y9.m(null,$CLJS.JN,function(a,b){return $CLJS.hg.j($CLJS.N,$CLJS.lf.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.Mh(function(){return lza(b,c)})],null)}),$CLJS.hg.j($CLJS.hg.j($CLJS.eh,$CLJS.am($CLJS.XW),$CLJS.Ca($CLJS.i9(b,"tables"))),$CLJS.lf.h($CLJS.KW),$CLJS.Ca($CLJS.i9(b,"questions"))))});p9.m(null,$CLJS.vG,function(){return $CLJS.dL});m9.m(null,$CLJS.vG,function(){return new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.un,null,$CLJS.iQ,null],null),null)});
n9.m(null,$CLJS.vG,function(){return function(a,b){return b}});z9.m(null,$CLJS.vG,function(){return"metrics"});p9.m(null,$CLJS.YF,function(){return $CLJS.bL});m9.m(null,$CLJS.YF,function(){return new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.un,null,$CLJS.iQ,null],null),null)});n9.m(null,$CLJS.YF,function(){return function(a,b){return b}});z9.m(null,$CLJS.YF,function(){return"segments"});$CLJS.A9=q9.g($CLJS.AG,$CLJS.N);