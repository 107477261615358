var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.types.js");require("./metabase.util.malli.js");
'use strict';var o1,vna,p1,b3;$CLJS.m1=function(a,b){return $CLJS.gc($CLJS.db(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Ff),b))};o1=function(a){return $CLJS.n(n1)?["(",$CLJS.p.h(a),")"].join(""):a};vna=function(a,b){var c=$CLJS.Xk.v(a,$CLJS.nQ,$CLJS.mE($CLJS.ee,$CLJS.Ff),b);return $CLJS.Bd($CLJS.WE.h(a))?$CLJS.Xk.v(c,$CLJS.WE,$CLJS.ee,$CLJS.GW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HB,$CLJS.N,$CLJS.PW(b)],null))):c};
p1=function(a,b,c){var d=$CLJS.PW(c);return $CLJS.R.l($CLJS.p0.j(a,b,c),$CLJS.$K,$CLJS.TZ,$CLJS.H([$CLJS.T,d,$CLJS.CE,d]))};b3=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);$CLJS.c3=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var s1=function(){function a(d,e,f){var k=$CLJS.WW(d,e);k=$CLJS.QH($CLJS.Uk.g($CLJS.fh([f]),$CLJS.PW),$CLJS.nQ.h(k));if($CLJS.n(k))return k;throw $CLJS.li($CLJS.BE("No expression named {0}",$CLJS.H([$CLJS.Ph.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.oS,f,$CLJS.WF,d,$CLJS.TL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.S0.m(null,$CLJS.HB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=s1.j(a,b,c);return $CLJS.T0.j(a,b,c)});$CLJS.U0.m(null,$CLJS.HB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.nu,$CLJS.YK,$CLJS.c3,$CLJS.OE.h(d),$CLJS.T,e,$CLJS.GE,e,$CLJS.CE,$CLJS.q0.j(a,b,c),$CLJS.KB,$CLJS.T0.j(a,b,c),$CLJS.$K,$CLJS.TZ],null)});$CLJS.O0.m(null,$CLJS.CB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.O0.m(null,$CLJS.nB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.O0.m(null,$CLJS.BB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.O0.m(null,$CLJS.PB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.O0.m(null,$CLJS.HB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.P0.m(null,$CLJS.HB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var n1=!1,wna=new $CLJS.h(null,4,[$CLJS.js,"+",$CLJS.rt,"-",$CLJS.ls,"×",$CLJS.UG,"÷"],null),d3=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.js,$CLJS.rt,$CLJS.UG,$CLJS.ls],null)),e3=null,f3=0,g3=0;;)if(g3<f3){var fpa=e3.X(null,g3);$CLJS.MF(fpa,b3);g3+=1}else{var gpa=$CLJS.y(d3);if(gpa){var h3=gpa;if($CLJS.Cd(h3)){var ipa=$CLJS.lc(h3),jpa=$CLJS.mc(h3),kpa=ipa,lpa=$CLJS.D(ipa);d3=jpa;e3=kpa;f3=lpa}else{var mpa=$CLJS.A(h3);$CLJS.MF(mpa,b3);d3=$CLJS.B(h3);e3=null;f3=0}g3=0}else break}
$CLJS.O0.m(null,b3,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(wna,c);a:{var e=n1;n1=!0;try{var f=$CLJS.Xt([" ",$CLJS.jh(c)," "].join(""),$CLJS.lf.g($CLJS.wu($CLJS.q0,a,b),d));break a}finally{n1=e}f=void 0}return o1(f)});$CLJS.P0.m(null,b3,function(){return"expression"});
$CLJS.S0.m(null,$CLJS.zH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Td($CLJS.OF,function(){return function k(f){return new $CLJS.qe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Cd(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.te(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v);x=$CLJS.T0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.we($CLJS.ye(u),k($CLJS.mc(l))):$CLJS.we($CLJS.ye(u),null)}u=$CLJS.A(l);return $CLJS.he($CLJS.T0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.O0.m(null,$CLJS.rC,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.q0.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.g1.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=$CLJS.UA(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=o1(f);return[a," ",d.call(b,f)].join("")});
$CLJS.P0.m(null,$CLJS.rC,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.Q0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.g1.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=$CLJS.UA(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.O0.m(null,$CLJS.DG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.q0.v(a,b,e,d)});$CLJS.P0.m(null,$CLJS.DG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.Q0.j(a,b,d)});
$CLJS.Cna=function(){function a(d,e,f,k){return $CLJS.gX.l(d,$CLJS.n(e)?e:-1,vna,$CLJS.H([$CLJS.AW($CLJS.IW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Dna=function(){function a(d,e){var f=$CLJS.Ie($CLJS.nQ.h($CLJS.WW(d,e)));return null==f?null:$CLJS.Wk.g($CLJS.wu(p1,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.A1=function(){function a(d,e){return $CLJS.Ie($CLJS.nQ.h($CLJS.WW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.FW.m(null,$CLJS.HB,function(a){return a});
$CLJS.Ena=function(){function a(d,e,f){var k=$CLJS.hg.g($CLJS.N,$CLJS.hm(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PW(t),m],null)},$CLJS.A1.g(d,e))),l=$CLJS.WW(d,e);d=$CLJS.Z0.j(d,e,l);return $CLJS.Ie($CLJS.m1(function(m){return $CLJS.Va(f)||$CLJS.Sk.g($CLJS.$K.h(m),$CLJS.TZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Fna=function(){function a(d,e,f){return $CLJS.HW(p1(d,e,s1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.i3=$CLJS.JF.h($CLJS.nF);