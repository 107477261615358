var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.util.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.util.malli.registry.js");
'use strict';var NZ,Ana,Bna,Hna,Ina,PZ,Jna,Kna,QZ,Lna,SZ,Mna,Nna,Vna,XZ,YZ,Xna,Yna,f_,Zna,aoa,boa,k_,coa;NZ=function(){};$CLJS.OZ=function(a){return null!=a?$CLJS.Bc===a.ki?!0:a.Wc?!1:$CLJS.Za(NZ,a):$CLJS.Za(NZ,a)};Ana=new $CLJS.r(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.HZ=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);
Bna=new $CLJS.M("metabase.lib.schema.metadata","database","metabase.lib.schema.metadata/database",270611124);Hna=new $CLJS.M("lib","persisted-info","lib/persisted-info",-118569625);$CLJS.IZ=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);$CLJS.JZ=new $CLJS.M(null,"database-id","database-id",1883826326);Ina=new $CLJS.r("metabase.lib.metadata.protocols","metadata-provider?","metabase.lib.metadata.protocols/metadata-provider?",-472923865,null);
$CLJS.KZ=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);$CLJS.LZ=new $CLJS.M(null,"search","search",1564939822);PZ=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.internal","metabase.lib.schema.metadata/column.remapping.internal",-285151559);Jna=new $CLJS.M("metabase.lib.schema.metadata","metadata-providerable","metabase.lib.schema.metadata/metadata-providerable",1520216538);Kna=new $CLJS.M(null,"dbms-version","dbms-version",-69238036);
QZ=new $CLJS.M("metabase.lib.schema.metadata","column.has-field-values","metabase.lib.schema.metadata/column.has-field-values",-1383672167);Lna=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.RZ=new $CLJS.M(null,"dataset","dataset",1159262238);SZ=new $CLJS.M("metabase.lib.schema.metadata","persisted-info","metabase.lib.schema.metadata/persisted-info",1457716669);Mna=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);
$CLJS.TZ=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.UZ=new $CLJS.M(null,"definition","definition",-1198729982);Nna=new $CLJS.M(null,"state","state",-1988618099);$CLJS.VZ=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);Vna=new $CLJS.M(null,"is-audit","is-audit",327058420);$CLJS.WZ=new $CLJS.M("source","joins","source/joins",1225821486);
XZ=new $CLJS.M("metabase.lib.schema.metadata","column-source","metabase.lib.schema.metadata/column-source",1071627828);YZ=new $CLJS.M("metabase.lib.schema.metadata","metadata-provider","metabase.lib.schema.metadata/metadata-provider",-1514728827);$CLJS.ZZ=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);$CLJS.$Z=new $CLJS.M(null,"selected?","selected?",-742502788);Xna=new $CLJS.M("metabase.lib.schema.metadata","segment","metabase.lib.schema.metadata/segment",-393440267);
Yna=new $CLJS.M("metabase.lib.schema.metadata","metric","metabase.lib.schema.metadata/metric",1984974682);$CLJS.a_=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.b_=new $CLJS.M("source","card","source/card",-139977973);$CLJS.c_=new $CLJS.M(null,"active","active",1895962068);$CLJS.d_=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);$CLJS.e_=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);
f_=new $CLJS.M("metabase.lib.schema.metadata","column.remapping.external","metabase.lib.schema.metadata/column.remapping.external",304054297);Zna=new $CLJS.M(null,"details","details",1956795411);$CLJS.g_=new $CLJS.M("source","fields","source/fields",-649667981);$CLJS.h_=new $CLJS.M(null,"has-field-values","has-field-values",626648377);aoa=new $CLJS.M("metabase.lib.schema.metadata","card","metabase.lib.schema.metadata/card",147726027);$CLJS.i_=new $CLJS.M("source","native","source/native",-1444604147);
boa=new $CLJS.M("metabase.lib.schema.metadata","table","metabase.lib.schema.metadata/table",-2128230385);$CLJS.j_=new $CLJS.M(null,"auto-list","auto-list",314197731);k_=new $CLJS.M("metabase.lib.schema.metadata","persisted-info.definition","metabase.lib.schema.metadata/persisted-info.definition",496848961);$CLJS.l_=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);coa=new $CLJS.r(null,"metadata-provider?","metadata-provider?",1669871524,null);
$CLJS.m_=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.n_=new $CLJS.M(null,"engine","engine",1459054265);$CLJS.X(XZ,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.rs,$CLJS.b_,$CLJS.i_,$CLJS.IZ,$CLJS.KZ,$CLJS.g_,$CLJS.zL,$CLJS.qL,$CLJS.WZ,$CLJS.TZ,$CLJS.VZ],null));$CLJS.X(QZ,$CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rs],null),$CLJS.oH($CLJS.Md,new $CLJS.dh(null,new $CLJS.h(null,4,[$CLJS.j_,null,$CLJS.LZ,null,$CLJS.Xj,null,$CLJS.yA,null],null),null))));
$CLJS.X(f_,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.yL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,$CLJS.hL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.HE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iL,$CLJS.wL],null)],null));
$CLJS.X(PZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.sL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,$CLJS.hL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.HE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.ri],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.ri],null)],null)],null));
$CLJS.X($CLJS.gL,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.Ht,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.YK],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.tk],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KB,$CLJS.QE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tj,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.wL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ys,$CLJS.tk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.QE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.wL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.wL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.m_,
new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.HE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.HE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$K,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XZ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.l_,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.jL],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.HE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,new $CLJS.h(null,2,[$CLJS.Wi,1,$CLJS.Fk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$Z,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.h_,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QZ],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PZ],null)],null)],
null)],null));$CLJS.X(k_,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.a_,$CLJS.HE],null),new $CLJS.P(null,2,5,$CLJS.Q,[Mna,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[Lna,$CLJS.HE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KB,$CLJS.QE],null)],null)],null)],null)],null)],null));
$CLJS.X(SZ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.c_,$CLJS.Rs],null),new $CLJS.P(null,2,5,$CLJS.Q,[Nna,$CLJS.HE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.a_,$CLJS.HE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,k_],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XK,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,
$CLJS.HE],null)],null)],null));
$CLJS.X(aoa,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.Ht,"Valid Card metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.xL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,$CLJS.jL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.HE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JZ,$CLJS.kL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.d_,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Dk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.e_,
new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.Dk],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RZ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.cL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hna,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,SZ],null)],null)],null)],null));
$CLJS.X(Xna,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.Ht,"Valid Segment metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.bL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,$CLJS.aL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.HE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oL,$CLJS.cL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Dk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.bu,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.HE],null)],null)],null));
$CLJS.X(Yna,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.Ht,"Valid Metric metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.dL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,$CLJS.ZK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.HE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oL,$CLJS.cL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UZ,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Dk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.bu,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.HE],null)],null)],null));
$CLJS.X(boa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.Ht,"Valid Table metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.fL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,$CLJS.cL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.HE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.HE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ul,new $CLJS.h(null,
1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.HE],null)],null)],null));
$CLJS.X(Bna,new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.Ht,"Valid Database metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.uL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tj,$CLJS.kL],null),new $CLJS.P(null,3,5,$CLJS.Q,[Kna,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Dk],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Zna,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Dk],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.n_,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.pj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zA,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uj,$CLJS.pj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Vna,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Dk],null)],null)],null));
$CLJS.X(YZ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"Valid MetadataProvider"],null),new $CLJS.Gc(function(){return $CLJS.OZ},Ina,$CLJS.Lg([$CLJS.aj,$CLJS.T,$CLJS.Bl,$CLJS.Ei,$CLJS.hj,$CLJS.Ej,$CLJS.bo,$CLJS.Ai,$CLJS.U,$CLJS.zl],[Ana,coa,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.V(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gr],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.OZ)?$CLJS.OZ.H:null]))],null));
$CLJS.X(Jna,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YZ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.h(null,1,[$CLJS.Ht,"map with a MetadataProvider in the key :lib/metadata (i.e. a query)"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YZ],null)],null)],null)],null));