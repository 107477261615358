var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Toa,mra,Uoa,Voa,M3,N3,O3,P3,Woa,Xoa,Yoa,Zoa,$oa,apa,bpa,cpa,dpa,Z3,$3,tra,epa,ura,pra,ora,vra,t4;$CLJS.H3=function(a,b){$CLJS.db(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Toa=function(a){return function(b){var c=$CLJS.cf(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.Kl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};mra=function(a,b){return function f(d,e){return new $CLJS.qe(null,function(){var k;a:{var l=d;for(k=e;;){var m=l;l=$CLJS.I(m,0,null);if(m=$CLJS.y(m)){var t=a.h?a.h(l):a.call(null,l);if($CLJS.Ld(k,t))l=$CLJS.Lc(m);else{k=$CLJS.he(l,f($CLJS.Lc(m),$CLJS.ee.g(k,t)));break a}}else{k=null;break a}}}return k},null,null)}(b,$CLJS.eh)};
Uoa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.w(b.slice(3),0,null):null;return $CLJS.Va($CLJS.y($CLJS.WA(c,d)))?c:$CLJS.Ve.N($CLJS.NX,c,d,e,b)};$CLJS.nra=function(a){return(0,$CLJS.na)($CLJS.ZA(a,$CLJS.hX,""))};$CLJS.I3=function(a,b){return $CLJS.h1.g(a,b instanceof $CLJS.M?b:$CLJS.zi.h(b))};
$CLJS.J3=function(a,b,c,d){if($CLJS.y(c)){d=$CLJS.Wk.g($CLJS.HW,d);var e=$CLJS.hg.j($CLJS.eh,$CLJS.lf.h(function(f){return $CLJS.z2.v(a,b,f,c)}),d);return $CLJS.Wk.g(function(f){return $CLJS.R.j(f,$CLJS.$Z,$CLJS.Ld(e,f))},c)}return null};$CLJS.K3=function(a){return $CLJS.E.g($CLJS.yC(a),$CLJS.BL)};Voa=function(a,b){return $CLJS.n($CLJS.WE.h(a))?$CLJS.Xk.j(a,$CLJS.WE,function(c){return $CLJS.yd(c)?$CLJS.Wk.g(function(d){return $CLJS.L3.g?$CLJS.L3.g(d,b):$CLJS.L3.call(null,d,b)},c):c}):a};
M3=function(a){if($CLJS.n(a)){var b=$CLJS.of;a:try{if($CLJS.Bd(a)&&4===$CLJS.D(a))try{if($CLJS.F(a,0)instanceof $CLJS.M)try{var c=$CLJS.F(a,2);if($CLJS.Bd(c)&&3===$CLJS.D(c))try{var d=$CLJS.F(c,0);if($CLJS.ke(d,$CLJS.AG))try{var e=$CLJS.F(a,3);if($CLJS.Bd(e)&&3===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.ke(f,$CLJS.AG)){$CLJS.F(e,1);$CLJS.F(e,2);$CLJS.F(c,1);$CLJS.F(c,2);$CLJS.F(a,0);$CLJS.F(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=
m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Y){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.A($CLJS.y(b($CLJS.Wa,k)))}else a=null;return a};N3=function(a){if($CLJS.n(M3(a))){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);$CLJS.I(a,3,null);return b}return null};O3=function(a){return $CLJS.n(M3(a))?($CLJS.I(a,0,null),$CLJS.I(a,1,null),$CLJS.I(a,2,null),$CLJS.I(a,3,null)):null};
P3=function(a,b){var c=$CLJS.L3;if($CLJS.Va(M3(a)))return a;var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null),f=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.Ve.j(c,a,b)],null)};
Woa=function(a,b,c){return $CLJS.ud($CLJS.JL.h(a))?a:$CLJS.n(b)?Uoa(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JL],null),function(d){return function l(f,k){try{if($CLJS.Bd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ke(m,$CLJS.AG))try{var t=$CLJS.F(k,1);if(null!=t?t.C&256||$CLJS.Bc===t.mf||(t.C?0:$CLJS.Za($CLJS.wb,t)):$CLJS.Za($CLJS.wb,t))try{var u=$CLJS.J.j(t,$CLJS.IP,$CLJS.IV);if($CLJS.E.g(u,b))return $CLJS.F(k,2),$CLJS.L3.g?$CLJS.L3.g(k,c):$CLJS.L3.call(null,k,c);throw $CLJS.Y;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)return $CLJS.pX(l,f,k);throw v;}throw x;}}($CLJS.Ff,d)}):$CLJS.Xk.j(a,$CLJS.JL,function(d){return $CLJS.Wk.g(function(e){return P3(e,$CLJS.H([c]))},d)})};
$CLJS.L3=function(a,b){var c=$CLJS.yC(a);switch(c instanceof $CLJS.M?c.T:null){case "field":return $CLJS.bF(a,$CLJS.$E,$CLJS.H([$CLJS.IP,b]));case "metadata/column":return $CLJS.$E(a,$CLJS.s_,b);case "mbql/join":return c=$CLJS.R1(a),Woa(Voa($CLJS.$E(a,$CLJS.lF,b),b),c,b);default:return b=$CLJS.eB($CLJS.Vy),$CLJS.n($CLJS.dB("metabase.lib.join",b))&&$CLJS.cB("metabase.lib.join",b,$CLJS.Ww.l($CLJS.H(["with-join-value should not be called with",$CLJS.Ph.l($CLJS.H([a]))])),null),a}};
$CLJS.Q3=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.VA($CLJS.HZ,$CLJS.T)(c);a=$CLJS.S1($CLJS.lF.h(a),$CLJS.VA($CLJS.HZ,$CLJS.T)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.HZ,e,$CLJS.H([$CLJS.ZZ,b]))};Xoa=function(a,b){return $CLJS.Va(b)?a:$CLJS.Wk.g(function(c){var d=O3(c);d=$CLJS.n(d)?$CLJS.n($CLJS.R1(d))?null:P3(c,$CLJS.H([b])):null;return $CLJS.n(d)?d:c},a)};$CLJS.R3=function(a,b){b=Xoa($CLJS.Wk.g($CLJS.IW,b),$CLJS.R1(a));return $CLJS.$E(a,$CLJS.JL,$CLJS.Ie(b))};
$CLJS.S3=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.E.g(b,$CLJS.Ff)?$CLJS.yA:$CLJS.Ie($CLJS.hg.j($CLJS.Ff,$CLJS.Uk.g($CLJS.lf.h($CLJS.HW),function(){var c=$CLJS.R1(a);return $CLJS.n(c)?$CLJS.lf.h(function(d){return $CLJS.L3(d,c)}):$CLJS.Xd}()),b));return $CLJS.$E(a,$CLJS.WE,b)};
Yoa=function(a,b){b=$CLJS.gm(function(d){return $CLJS.z2.g(d,a)},b);var c=$CLJS.QH($CLJS.VA($CLJS.T_,$CLJS.U_),b);if($CLJS.n(c))return c;b=$CLJS.A(b);if($CLJS.n(b))return b;b=$CLJS.QH($CLJS.T_,a);if($CLJS.n(b))return b;b=$CLJS.QH($CLJS.U_,a);return $CLJS.n(b)?b:$CLJS.A(a)};Zoa=function(a){return"string"===typeof a?(0,$CLJS.na)($CLJS.ZA(a,/ id$/i,"")):null};
$oa=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Mh(function(){return $CLJS.T2(b)});var d=new $CLJS.Mh(function(){return $CLJS.T2(a)});return $CLJS.E.g(a,b)||$CLJS.E.g(a,$CLJS.q(c))||$CLJS.E.g($CLJS.q(d),b)||$CLJS.E.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
apa=function(a,b,c){var d=$CLJS.q0.g($CLJS.E.g($CLJS.nu.h(b),$CLJS.vW)?b:a,b),e=$CLJS.n(c)?Zoa($CLJS.q0.g(a,c)):null,f=$oa(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Va($CLJS.qh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
bpa=function(a,b,c,d,e){return function m(k,l){try{if($CLJS.ZY(l,$CLJS.AG)&&$CLJS.Hd($CLJS.z2.v(a,b,l,e)))return $CLJS.L3(l,d);throw $CLJS.Y;}catch(u){if(u instanceof Error){var t=u;if(t===$CLJS.Y)return $CLJS.pX(m,k,l);throw t;}throw u;}}($CLJS.Ff,c)};
cpa=function(a,b,c,d,e,f){c=bpa(a,b,c,d,f);return function t(l,m){try{if($CLJS.Bd(m)&&4===$CLJS.D(m))try{var u=$CLJS.F(m,2);if($CLJS.ZY(u,$CLJS.AG))try{var v=$CLJS.F(m,3);if($CLJS.ZY(v,$CLJS.AG)){var x=$CLJS.F(m,3),z=$CLJS.F(m,2),C=$CLJS.F(m,0),G=$CLJS.F(m,1),K=$CLJS.R1(z),S=$CLJS.R1(x);if($CLJS.Va($CLJS.n(K)?K:S))return $CLJS.n($CLJS.z2.v(a,b,x,e))?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,$CLJS.L3(z,d),x],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,z,$CLJS.L3(x,d)],null);if($CLJS.E.g(K,d)&&$CLJS.E.g(S,d)){var Z=
$CLJS.bF(z,$CLJS.Lk,$CLJS.H([$CLJS.IP])),ca=$CLJS.bF(x,$CLJS.Lk,$CLJS.H([$CLJS.IP])),ka=null==$CLJS.z2.v(a,b,Z,e);var wa=ka?$CLJS.z2.v(a,b,ca,e):ka;return $CLJS.n(wa)?new $CLJS.P(null,4,5,$CLJS.Q,[C,G,z,ca],null):new $CLJS.P(null,4,5,$CLJS.Q,[C,G,Z,x],null)}return m}throw $CLJS.Y;}catch(ib){if(ib instanceof Error){var Qa=ib;if(Qa===$CLJS.Y)throw $CLJS.Y;throw Qa;}throw ib;}else throw $CLJS.Y;}catch(ib){if(ib instanceof Error){Qa=ib;if(Qa===$CLJS.Y)throw $CLJS.Y;throw Qa;}throw ib;}else throw $CLJS.Y;
}catch(ib){if(ib instanceof Error){Qa=ib;if(Qa===$CLJS.Y)return $CLJS.pX(t,l,m);throw Qa;}throw ib;}}($CLJS.Ff,c)};dpa=function(a,b){var c=$CLJS.ZW();$CLJS.H3(c,b);return c(a)};
$CLJS.T3=function(a,b,c){if($CLJS.Ld(c,$CLJS.lF))return c;var d=$CLJS.WW(a,b),e=$CLJS.Z0.j(a,b,d),f=$CLJS.y($CLJS.of($CLJS.Wa,function v(t,u){try{if($CLJS.Bd(u)&&1<=$CLJS.D(u))try{var x=$CLJS.al.j(u,0,1);if($CLJS.Bd(x)&&1===$CLJS.D(x))try{var z=$CLJS.F(x,0);if($CLJS.ke(z,$CLJS.AG))return new $CLJS.P(null,1,5,$CLJS.Q,[u],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var C=G;if(C===$CLJS.Y)throw $CLJS.Y;throw C;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Y)throw $CLJS.Y;
throw C;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){C=G;if(C===$CLJS.Y)return $CLJS.RV(v,t,u);throw C;}throw G;}}($CLJS.Ff,$CLJS.JL.h(c))));f=Yoa(e,f);var k=dpa(apa(a,c,f),$CLJS.gm($CLJS.lF,$CLJS.RN.h(d))),l=$CLJS.X0.h($CLJS.M1.g(a,$CLJS.GL.h(c)));return $CLJS.L3($CLJS.Xk.j(c,$CLJS.JL,function(t){return $CLJS.Wk.g(function(u){return cpa(a,b,u,k,e,l)},t)}),k)};$CLJS.U3=function(a){return $CLJS.JL.h(a)};
$CLJS.V3=function(a){return $CLJS.ll.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.nu,$CLJS.KL,$CLJS.SE,a],null),$CLJS.E.g(a,$CLJS.qF)?new $CLJS.h(null,1,[$CLJS.mi,!0],null):null]))};$CLJS.W3=function(a,b){b=$CLJS.A($CLJS.GL.h(b));return $CLJS.n($CLJS.TV.h(b))?$CLJS.n0(a,$CLJS.TV.h(b)):$CLJS.n($CLJS.sP.h(b))?$CLJS.l0(a,$CLJS.sP.h(b)):null};
Z3=function(a){a=$CLJS.PH(function(d){return $CLJS.U_(d)?ora:$CLJS.T_(d)?pra:$CLJS.iu},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,ora);var c=$CLJS.J.g(b,pra);b=$CLJS.J.g(b,$CLJS.iu);return $CLJS.kf.l(a,c,$CLJS.H([b]))};$3=function(a,b,c,d){return $CLJS.Va(c)?d:$CLJS.Wk.g(function(e){return $CLJS.n($CLJS.$Z.h(e))?$CLJS.I3(e,$CLJS.R2(c)):e},$CLJS.J3(a,b,d,new $CLJS.P(null,1,5,$CLJS.Q,[c],null)))};
tra=function(a,b,c,d){var e=new $CLJS.Mh(function(){return $CLJS.Z0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.r1,!1,$CLJS.v1,!1],null))});return $CLJS.Ie($CLJS.hg.j($CLJS.Ff,$CLJS.am(function(f){var k=$CLJS.O(f),l=$CLJS.J.g(k,$CLJS.lL);return $CLJS.n(function(){var m=$CLJS.T_(k);return m?l:m}())?(f=$CLJS.QH(function(m){return $CLJS.E.g(l,$CLJS.tj.h(m))},$CLJS.q(e)),$CLJS.n(f)?$CLJS.R.j(k,t4,f):null):null}),$CLJS.Z0.j(a,b,c)))};epa={};
ura=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);pra=new $CLJS.M(null,"fk","fk",398442651);ora=new $CLJS.M(null,"pk","pk",-771936732);vra=new $CLJS.r("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);t4=new $CLJS.M("metabase.lib.join","target","metabase.lib.join/target",203858838);$CLJS.O0.m(null,$CLJS.BL,function(a,b,c){b=$CLJS.O(c);b=$CLJS.J.g(b,$CLJS.GL);b=$CLJS.I(b,0,null);b=$CLJS.O(b);c=$CLJS.J.g(b,$CLJS.sP);b=$CLJS.J.g(b,$CLJS.TV);c=$CLJS.n(c)?$CLJS.CE.h($CLJS.l0(a,c)):null;if($CLJS.n(c))return c;$CLJS.n(b)?(c=$CLJS.n0(a,b),a=$CLJS.n(c)?$CLJS.q0.j(a,0,c):$CLJS.W1(b)):a=null;return $CLJS.n(a)?a:$CLJS.eF("Native Query")});$CLJS.V0.m(null,$CLJS.BL,function(a,b,c){a=$CLJS.q0.j(a,b,c);c=$CLJS.lF.h(c);return new $CLJS.h(null,2,[$CLJS.T,$CLJS.n(c)?c:a,$CLJS.CE,a],null)});
$CLJS.U0.m(null,$CLJS.BL,function(){throw $CLJS.li("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.O0.m(null,$CLJS.KL,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.SE);a=a instanceof $CLJS.M?a.T:null;switch(a){case "left-join":return $CLJS.eF("Left outer join");case "right-join":return $CLJS.eF("Right outer join");case "inner-join":return $CLJS.eF("Inner join");case "full-join":return $CLJS.eF("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.V0.m(null,$CLJS.KL,function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.SE);c=$CLJS.J.g(d,$CLJS.mi);a=new $CLJS.h(null,2,[$CLJS.w1,$CLJS.sB(e),$CLJS.CE,$CLJS.q0.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.mi,!0):a});
$CLJS.W0.m(null,$CLJS.BL,function(a,b,c,d){var e=$CLJS.O(c),f=$CLJS.J.g(e,$CLJS.lF),k=$CLJS.J.j(e,$CLJS.WE,$CLJS.yA),l=$CLJS.J.g(e,$CLJS.GL);c=$CLJS.O(d);var m=$CLJS.J.g(c,$CLJS.u1);if($CLJS.E.g(k,$CLJS.yA))return null;var t="undefined"!==typeof $CLJS.wM&&"undefined"!==typeof epa&&"undefined"!==typeof $CLJS.hpa&&"undefined"!==typeof $CLJS.c4?new $CLJS.Gc(function(){return $CLJS.c4},$CLJS.qd(vra,new $CLJS.h(null,1,[$CLJS.uk,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.GL,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.E.g(k,$CLJS.Hy)?$CLJS.X0.v(u,-1,$CLJS.sd(l),c):function(){return function z(x){return new $CLJS.qe(null,function(){for(;;){var C=$CLJS.y(x);if(C){if($CLJS.Cd(C)){var G=$CLJS.lc(C),K=$CLJS.D(G),S=$CLJS.te(K);a:for(var Z=0;;)if(Z<K){var ca=$CLJS.md(G,Z);ca=$CLJS.bF(ca,$CLJS.Lk,$CLJS.H([$CLJS.IP]));ca=$CLJS.p0.j(u,-1,ca);S.add(ca);Z+=1}else{G=!0;break a}return G?$CLJS.we($CLJS.ye(S),z($CLJS.mc(C))):$CLJS.we($CLJS.ye(S),null)}S=$CLJS.A(C);S=$CLJS.bF(S,$CLJS.Lk,
$CLJS.H([$CLJS.IP]));return $CLJS.he($CLJS.p0.j(u,-1,S),z($CLJS.Lc(C)))}return null}},null,null)}(k)}();return $CLJS.Wk.g(function(v){v=$CLJS.R.j(v,$CLJS.m_,f);v=$CLJS.L3($CLJS.R.l(v,$CLJS.CE,$CLJS.q0.j(a,b,v),$CLJS.H([$CLJS.$K,$CLJS.WZ])),f);return $CLJS.Q3(e,m,v)},c)});$CLJS.Y0.m(null,$CLJS.BL,function(a,b,c,d){return $CLJS.X0.v(a,b,$CLJS.R.j(c,$CLJS.WE,$CLJS.Hy),d)});
var d4,zra=$CLJS.$e($CLJS.N),Ira=$CLJS.$e($CLJS.N),Lra=$CLJS.$e($CLJS.N),Mra=$CLJS.$e($CLJS.N),Nra=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));d4=new $CLJS.hi($CLJS.Ih.g("metabase.lib.join","join-clause-method"),$CLJS.yC,Nra,zra,Ira,Lra,Mra);d4.m(null,$CLJS.BL,function(a){return a});d4.m(null,$CLJS.vW,function(a){return $CLJS.GW(new $CLJS.h(null,2,[$CLJS.nu,$CLJS.BL,$CLJS.GL,$CLJS.GL.h($CLJS.UW(a))],null))});
d4.m(null,$CLJS.iW,function(a){return $CLJS.GW(new $CLJS.h(null,2,[$CLJS.nu,$CLJS.BL,$CLJS.GL,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});d4.m(null,$CLJS.xL,function(a){return $CLJS.GW(new $CLJS.h(null,2,[$CLJS.nu,$CLJS.BL,$CLJS.GL,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.TV,$CLJS.tj.h(a),$CLJS.nu,$CLJS.iW],null)],null)],null))});
d4.m(null,$CLJS.fL,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.s_);a=$CLJS.J.g(b,ura);b=d4.h(new $CLJS.h(null,3,[$CLJS.nu,$CLJS.iW,$CLJS.LB,new $CLJS.h(null,1,[$CLJS.OE,$CLJS.p.h($CLJS.dF())],null),$CLJS.sP,$CLJS.tj.h(b)],null));c=$CLJS.n(c)?$CLJS.L3(b,c):b;return $CLJS.n(a)?$CLJS.S3.g?$CLJS.S3.g(c,a):$CLJS.S3.call(null,c,a):c});
$CLJS.e4=function(){function a(d,e){return $CLJS.R3(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.m2(d4.h(d),$CLJS.WE,$CLJS.Hy)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.npa=function(){function a(d,e,f){var k=$CLJS.e4.h(f);f=$CLJS.ud($CLJS.U3.h?$CLJS.U3.h(k):$CLJS.U3.call(null,k))?function(){var m=$CLJS.W3.g?$CLJS.W3.g(d,k):$CLJS.W3.call(null,d,k);return $CLJS.u4.j?$CLJS.u4.j(d,e,m):$CLJS.u4.call(null,d,e,m)}():null;f=$CLJS.y(f)?$CLJS.R3(k,f):k;var l=$CLJS.T3(d,e,f);return $CLJS.gX.l(d,e,$CLJS.Xk,$CLJS.H([$CLJS.RN,function(m){return $CLJS.ee.g($CLJS.Gf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.g4=function(){function a(d,e){return $CLJS.Ie($CLJS.J.g($CLJS.WW(d,e),$CLJS.RN))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.opa=function(){function a(d){d=$CLJS.l3(d);d=$CLJS.zA.h(d);return $CLJS.hg.j($CLJS.Ff,$CLJS.Uk.g($CLJS.nf($CLJS.Ye($CLJS.Ld,d)),$CLJS.lf.h($CLJS.V3)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qF,$CLJS.VE,$CLJS.uF,$CLJS.xF],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.ppa=function(){function a(d,e,f,k){var l=$CLJS.K3(f)?$CLJS.R1(f):null,m=$CLJS.hg.j($CLJS.eh,$CLJS.Uk.g($CLJS.lf.h($CLJS.R1),Toa(function(t){return $CLJS.Sk.g(t,l)})),$CLJS.g4.g(d,e));f=$CLJS.n(k)?k:$CLJS.K3(f)?N3($CLJS.A($CLJS.U3(f))):null;return Z3($3(d,e,f,$CLJS.Vk.g(function(t){t=$CLJS.R1(t);return $CLJS.n(t)?$CLJS.Ld(m,t):null},$CLJS.Z0.v(d,e,$CLJS.WW(d,e),new $CLJS.h(null,1,[$CLJS.r1,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();
$CLJS.qpa=function(){function a(d,e,f,k,l){k=$CLJS.K3(f)?$CLJS.W3(d,f):f;var m=$CLJS.K3(f)?$CLJS.R1(f):null;f=$CLJS.n(l)?l:$CLJS.K3(f)?O3($CLJS.A($CLJS.U3(f))):null;f=$CLJS.n(f)?$CLJS.Va(m)?$CLJS.bF(f,$CLJS.Lk,$CLJS.H([$CLJS.IP])):f:null;return Z3($3(d,e,f,$CLJS.lf.g(function(t){t=$CLJS.R.j(t,$CLJS.$K,$CLJS.WZ);return $CLJS.n(m)?$CLJS.L3(t,m):t},$CLJS.Z0.v(d,e,k,new $CLJS.h(null,1,[$CLJS.r1,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.v=b;c.N=a;return c}();$CLJS.rpa=function(){function a(c,d,e){return b.v?b.v(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.E2}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.v=function(){return $CLJS.E2};return b}();
$CLJS.u4=function(){function a(d,e,f){function k(u,v){u=$CLJS.Lk.g(u,$CLJS.n3);v=$CLJS.Lk.g(v,$CLJS.n3);return $CLJS.G3.l($CLJS.C2.h($CLJS.gs),u,$CLJS.H([v]))}function l(u,v){return $CLJS.Ie(mra(function(x){return $CLJS.tj.h(t4.h(x))},tra(d,e,u,v)))}var m=$CLJS.WW(d,e),t=function(){var u=l(m,f);return $CLJS.n(u)?$CLJS.Wk.g(function(v){return k(v,t4.h(v))},u):null}();if($CLJS.n(t))return t;t=l(f,m);return $CLJS.n(t)?$CLJS.Wk.g(function(u){return k(t4.h(u),u)},t):null}function b(d,e){return c.j?c.j(d,
-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Ora=$CLJS.lf.h(function(a){return $CLJS.R.j(a,$CLJS.$K,$CLJS.WZ)});
$CLJS.spa=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.K3(k)?N3($CLJS.A($CLJS.U3(k))):null;$CLJS.n(l)?(l=$CLJS.t0.j(e,f,l),l=$CLJS.WA(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.un,$CLJS.CE],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.VW(e,f)){l=$CLJS.g4.g(e,f);var m=$CLJS.ud(l);k=m?m:$CLJS.K3(k)?$CLJS.E.g($CLJS.lF.h(k),$CLJS.lF.h($CLJS.A(l))):null;k=$CLJS.n(k)?$CLJS.f0(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.f0(e),k=$CLJS.l0(e,k),e=$CLJS.q0.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.eF("Previous results")}
function b(e,f,k){return d.v?d.v(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.v=a;return d}();
$CLJS.tpa=function(){function a(d,e,f,k){f=$CLJS.IW.h(f);$CLJS.I(f,0,null);$CLJS.I(f,1,null);var l=$CLJS.I(f,2,null),m=$CLJS.I(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.zi.h(k);e=$CLJS.VW(d,e);l=$CLJS.l1.j(d,e,l);d=$CLJS.l1.j(d,e,m);m=null==k||$CLJS.Ld($CLJS.ih($CLJS.lf.g($CLJS.zi,l)),k);d=null==k||$CLJS.Ld($CLJS.ih($CLJS.lf.g($CLJS.zi,d)),k);f=m?$CLJS.Xk.v(f,2,$CLJS.I3,k):f;return d?$CLJS.Xk.v(f,3,$CLJS.I3,k):f}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.R0.m(null,$CLJS.RN,function(a,b){var c=$CLJS.Ie($CLJS.g4.g(a,b));c=null==c?null:$CLJS.lf.g(function(d){return $CLJS.q0.j(a,b,d)},c);return null==c?null:$CLJS.Xt(" + ",c)});