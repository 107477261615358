var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var Mfa,Nfa,Ofa,Pfa,Qfa,Rfa,Sfa,dH,Tfa,Ufa,Vfa,Wfa,Xfa,Yfa,Zfa,$fa,fH,aga;$CLJS.ZG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Mfa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.$G=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Nfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Ofa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.aH=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);Pfa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.bH=new $CLJS.M(null,"display-info","display-info",-816930907);Qfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);Rfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);
Sfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);dH=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);Tfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);Ufa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);Vfa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);Wfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);
Xfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);Yfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);Zfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.eH=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);$fa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);fH=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);
$CLJS.gH=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);aga=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.VF($CLJS.iy,$CLJS.H([$CLJS.rt,$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)],null)],null)]));$CLJS.VF($CLJS.BG,$CLJS.H([$CLJS.rt,$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)],null)],null)]));
$CLJS.TF($CLJS.uG,$CLJS.H([$CLJS.rt,$CLJS.cE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)]));$CLJS.TF($CLJS.eG,$CLJS.H([$CLJS.rt,$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));$CLJS.TF($CLJS.JG,$CLJS.H([$CLJS.rt,$CLJS.Oj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)]));
$CLJS.TF($CLJS.Fk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.MF($CLJS.Fk,$CLJS.sF);$CLJS.TF($CLJS.NG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)]));$CLJS.MF($CLJS.NG,$CLJS.sF);$CLJS.TF($CLJS.Wi,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tF],null)],null)]));$CLJS.MF($CLJS.Wi,$CLJS.sF);
$CLJS.X(dH,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.h(null,1,[$CLJS.Ht,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.TF($CLJS.lG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dH],null)]));$CLJS.MF($CLJS.lG,$CLJS.sF);
$CLJS.TF($CLJS.HG,$CLJS.H([$CLJS.rt,$CLJS.cE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)]));$CLJS.TF($CLJS.OG,$CLJS.H([$CLJS.rt,$CLJS.cE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)]));$CLJS.TF($CLJS.MG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)]));
$CLJS.TF($CLJS.VG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)]));$CLJS.MF($CLJS.MG,$CLJS.sF);$CLJS.MF($CLJS.VG,$CLJS.sF);$CLJS.TF($CLJS.TG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.mF],null)],null)]));$CLJS.MF($CLJS.TG,$CLJS.sF);
$CLJS.TF($CLJS.fG,$CLJS.H([$CLJS.rt,$CLJS.cE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.yF],null)],null)]));$CLJS.X(fH,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.As,$fa,Yfa,Rfa,Nfa,Mfa,Qfa,Sfa,Tfa,Ufa,Xfa,Vfa,aga,Zfa,Ofa,Wfa,$CLJS.Hl],null));$CLJS.X($CLJS.$G,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.h(null,1,[$CLJS.Wi,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fH],null)],null));
$CLJS.mH=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.tG,$CLJS.iy,$CLJS.aH,!1,$CLJS.eH,$CLJS.EF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.CE,$CLJS.eF("Count of rows"),$CLJS.kG,$CLJS.eF("Count"),$CLJS.bu,$CLJS.eF("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.tG,$CLJS.MG,$CLJS.ZG,$CLJS.Ck,$CLJS.aH,!0,$CLJS.eH,$CLJS.EF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.CE,$CLJS.eF("Sum of ..."),$CLJS.kG,$CLJS.eF("Sum"),$CLJS.bu,$CLJS.eF("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.tG,$CLJS.uG,$CLJS.ZG,$CLJS.Ck,$CLJS.aH,!0,$CLJS.eH,$CLJS.EF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.CE,$CLJS.eF("Average of ..."),$CLJS.kG,$CLJS.eF("Average"),$CLJS.bu,$CLJS.eF("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.tG,$CLJS.NG,$CLJS.ZG,$CLJS.Ck,$CLJS.aH,!0,$CLJS.eH,$CLJS.wG,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.CE,$CLJS.eF("Median of ..."),$CLJS.kG,$CLJS.eF("Median"),$CLJS.bu,$CLJS.eF("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.tG,$CLJS.eG,$CLJS.ZG,$CLJS.ri,$CLJS.aH,!0,$CLJS.eH,$CLJS.EF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.CE,$CLJS.eF("Number of distinct values of ..."),$CLJS.kG,$CLJS.eF("Distinct values"),$CLJS.bu,$CLJS.eF("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.tG,$CLJS.VG,$CLJS.ZG,$CLJS.Ck,$CLJS.aH,!0,$CLJS.eH,$CLJS.EF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.CE,$CLJS.eF("Cumulative sum of ..."),
$CLJS.kG,$CLJS.eF("Sum"),$CLJS.bu,$CLJS.eF("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.tG,$CLJS.BG,$CLJS.aH,!1,$CLJS.eH,$CLJS.EF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.CE,$CLJS.eF("Cumulative count of rows"),$CLJS.kG,$CLJS.eF("Count"),$CLJS.bu,$CLJS.eF("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.tG,$CLJS.OG,$CLJS.ZG,$CLJS.Ck,$CLJS.aH,
!0,$CLJS.eH,$CLJS.KG,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.CE,$CLJS.eF("Standard deviation of ..."),$CLJS.kG,$CLJS.eF("SD"),$CLJS.bu,$CLJS.eF("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.tG,$CLJS.Wi,$CLJS.ZG,$CLJS.sj,$CLJS.aH,!0,$CLJS.eH,$CLJS.EF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.CE,$CLJS.eF("Minimum of ..."),$CLJS.kG,$CLJS.eF("Min"),$CLJS.bu,$CLJS.eF("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.tG,$CLJS.Fk,$CLJS.ZG,$CLJS.sj,$CLJS.aH,!0,$CLJS.eH,$CLJS.EF,$CLJS.bH,function(){return new $CLJS.h(null,3,[$CLJS.CE,$CLJS.eF("Maximum of ..."),$CLJS.kG,$CLJS.eF("Max"),$CLJS.bu,$CLJS.eF("Maximum value of a column")],null)}],null)],null);
$CLJS.X(Pfa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.gH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tG,$CLJS.hg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rs],null),$CLJS.lf.h($CLJS.tG),$CLJS.mH)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.pj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aH,$CLJS.Rs],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.eH,$CLJS.pj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bH,$CLJS.od],null)],null));