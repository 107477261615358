var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Zqa,$qa,z7,ara,bra,cra,dra,era,fra,gra,A7,B7,Q7;
Zqa=function(a){return function(b){var c=$CLJS.cf($CLJS.eh);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Ld($CLJS.q(c),t))return l;c.Dd(null,$CLJS.ee.g(c.Qb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};$qa=function(a,b,c){return $CLJS.hg.j($CLJS.Ff,$CLJS.AE(function(d){return $CLJS.Z0.v(a,b,d,new $CLJS.h(null,2,[$CLJS.u1,c,$CLJS.r1,!1],null))}),$CLJS.RN.h($CLJS.WW(a,b)))};z7=function(a,b,c){return $CLJS.hg.j($CLJS.Ff,$CLJS.AE(function(d){return $CLJS.X0.v(a,b,d,c)}),$CLJS.RN.h($CLJS.WW(a,b)))};$CLJS.c4=function(a,b){return $CLJS.db(function(c,d){return $CLJS.gX.l(c,d,$CLJS.R,$CLJS.H([$CLJS.w7,$CLJS.X0.j(c,d,$CLJS.WW(c,d))]))},a,$CLJS.Pu(0,$CLJS.VW(a,b)))};
ara=function(a,b){a=$CLJS.WW(a,b);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.nu),d=$CLJS.J.g(a,$CLJS.TV);b=$CLJS.w7.h(a);if($CLJS.n(b))return b;var e=$CLJS.BV.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.E.g(c,$CLJS.pW);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.T:null;switch(k){case "mbql.stage/native":return $CLJS.i_;case "mbql.stage/mbql":return $CLJS.b_;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.Ie(function(){return function m(l){return new $CLJS.qe(null,
function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Cd(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.te(v);a:for(var z=0;;)if(z<v){var C=$CLJS.md(u,z);C=$CLJS.ll.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.HZ,$CLJS.T.h(C),$CLJS.ZZ,$CLJS.T.h(C)],null),C,new $CLJS.h(null,1,[$CLJS.$K,f],null)]));x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.we($CLJS.ye(x),m($CLJS.mc(t))):$CLJS.we($CLJS.ye(x),null)}x=$CLJS.A(t);return $CLJS.he($CLJS.ll.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.HZ,$CLJS.T.h(x),$CLJS.ZZ,$CLJS.T.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.$K,f],null)])),m($CLJS.Lc(t)))}return null}},null,null)}($CLJS.BW.h(e))}())}return null};
bra=function(a,b,c){return $CLJS.Ie(function(){return function f(e){return new $CLJS.qe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.md(l,v),z=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.VA($CLJS.HZ,$CLJS.T)(x);x=$CLJS.T1(a,x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.$K,$CLJS.qL,$CLJS.H([$CLJS.HZ,S,$CLJS.ZZ,x]));z.add(C);v+=1}else return!0}()?$CLJS.we($CLJS.ye(t),f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),
null)}var u=$CLJS.A(k);return $CLJS.he($CLJS.R.l(u,$CLJS.$K,$CLJS.qL,$CLJS.H([$CLJS.HZ,$CLJS.VA($CLJS.HZ,$CLJS.T)(u),$CLJS.ZZ,function(){var v=$CLJS.T1(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.U5.g(a,b))}())};
cra=function(a,b,c){return $CLJS.Ie(function(){return function f(e){return new $CLJS.qe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.md(l,v),z=t,C=$CLJS.R,G=C.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=G.call(C,K,$CLJS.$K,$CLJS.zL,$CLJS.H([$CLJS.HZ,S,$CLJS.ZZ,x]));z.add(C);v+=1}else return!0}()?$CLJS.we($CLJS.ye(t),f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),null)}var u=
$CLJS.A(k);return $CLJS.he($CLJS.R.l(u,$CLJS.$K,$CLJS.zL,$CLJS.H([$CLJS.HZ,$CLJS.T.h(u),$CLJS.ZZ,function(){var v=$CLJS.T.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.L4.g(a,b))}())};
dra=function(a,b,c){var d=$CLJS.WW(a,b);if($CLJS.n(d)){d=$CLJS.O(d);var e=$CLJS.J.g(d,$CLJS.WE);return $CLJS.Ie(function(){return function l(k){return new $CLJS.qe(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Cd(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.te(u);return function(){for(var K=0;;)if(K<u){var S=$CLJS.md(t,K),Z=$CLJS.I(S,0,null),ca=function(){var wa=Z;wa=wa instanceof $CLJS.M?wa.T:null;switch(wa){case "field":return $CLJS.g_;case "expression":return $CLJS.TZ;default:throw Error(["No matching clause: ",
$CLJS.p.h(wa)].join(""));}}(),ka=$CLJS.p0.j(a,b,S);$CLJS.xe(v,$CLJS.R.l(ka,$CLJS.$K,ca,$CLJS.H([$CLJS.HZ,$CLJS.Q0.j(a,b,ka),$CLJS.ZZ,function(){var wa=$CLJS.T1(a,ka);return c.h?c.h(wa):c.call(null,wa)}()])));K+=1}else return!0}()?$CLJS.we($CLJS.ye(v),l($CLJS.mc(m))):$CLJS.we($CLJS.ye(v),null)}var x=$CLJS.A(m),z=$CLJS.I(x,0,null),C=function(){var K=z;K=K instanceof $CLJS.M?K.T:null;switch(K){case "field":return $CLJS.g_;case "expression":return $CLJS.TZ;default:throw Error(["No matching clause: ",
$CLJS.p.h(K)].join(""));}}(),G=$CLJS.p0.j(a,b,x);return $CLJS.he($CLJS.R.l(G,$CLJS.$K,C,$CLJS.H([$CLJS.HZ,$CLJS.Q0.j(a,b,G),$CLJS.ZZ,function(){var K=$CLJS.T1(a,G);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Lc(m)))}return null}},null,null)}(e)}())}return null};era=function(a,b,c){return $CLJS.Ie($CLJS.hg.j($CLJS.Ff,$CLJS.AE(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.P(null,2,5,$CLJS.Q,[bra,cra],null)))};
fra=function(a,b,c){var d=$CLJS.o2(a,b);return $CLJS.n(d)?$CLJS.Ie(function(){return function k(f){return new $CLJS.qe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Cd(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.te(t);return function(){for(var z=0;;)if(z<t){var C=$CLJS.md(m,z);var G=$CLJS.VA($CLJS.ZZ,$CLJS.HZ)(C);G=$CLJS.n(G)?G:$CLJS.Q0.j(a,b,C);C=$CLJS.Lk.l($CLJS.ll.l($CLJS.H([C,new $CLJS.h(null,3,[$CLJS.$K,$CLJS.IZ,$CLJS.HZ,G,$CLJS.ZZ,c.h?c.h(G):c.call(null,G)],null),$CLJS.n($CLJS.n3.h(C))?
$CLJS.im(C,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.n3],null)):null])),$CLJS.CW,$CLJS.H([$CLJS.GE]));u.add(C);z+=1}else return!0}()?$CLJS.we($CLJS.ye(u),k($CLJS.mc(l))):$CLJS.we($CLJS.ye(u),null)}var v=$CLJS.A(l),x=function(){var z=$CLJS.VA($CLJS.ZZ,$CLJS.HZ)(v);return $CLJS.n(z)?z:$CLJS.Q0.j(a,b,v)}();return $CLJS.he($CLJS.Lk.l($CLJS.ll.l($CLJS.H([v,new $CLJS.h(null,3,[$CLJS.$K,$CLJS.IZ,$CLJS.HZ,x,$CLJS.ZZ,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.n3.h(v))?$CLJS.im(v,new $CLJS.P(null,1,5,$CLJS.Q,
[$CLJS.n3],null)):null])),$CLJS.CW,$CLJS.H([$CLJS.GE])),k($CLJS.Lc(l)))}return null}},null,null)}($CLJS.X0.j(a,d,$CLJS.WW(a,d)))}()):null};gra=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.n0(a,c),$CLJS.n(c)?$CLJS.Ie($CLJS.Z0.v(a,b,c,d)):null):null};
A7=function(a,b,c){return $CLJS.Ie(function(){return function f(e){return new $CLJS.qe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.md(l,v),z=t;var C=$CLJS.KB.h(x);var G=$CLJS.R.l,K=x,S=$CLJS.T.h(x);x=$CLJS.T.h(x);x=c.h?c.h(x):c.call(null,x);C=$CLJS.m2(G.call($CLJS.R,K,$CLJS.$K,$CLJS.TZ,$CLJS.H([$CLJS.HZ,S,$CLJS.ZZ,x])),$CLJS.Pi,$CLJS.n(C)?C:$CLJS.Wj);z.add(C);v+=1}else return!0}()?$CLJS.we($CLJS.ye(t),
f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),null)}var u=$CLJS.A(k);return $CLJS.he(function(){var v=$CLJS.KB.h(u),x=$CLJS.R.l,z=u,C=$CLJS.T.h(u);var G=$CLJS.T.h(u);G=c.h?c.h(G):c.call(null,G);return $CLJS.m2(x.call($CLJS.R,z,$CLJS.$K,$CLJS.TZ,$CLJS.H([$CLJS.HZ,C,$CLJS.ZZ,G])),$CLJS.Pi,$CLJS.n(v)?v:$CLJS.Wj)}(),f($CLJS.Lc(k)))}return null}},null,null)}($CLJS.Dna.g(a,b))}())};
B7=function(a,b,c){var d=$CLJS.O(c),e=$CLJS.J.g(d,$CLJS.u1);return $CLJS.Wk.g(function(f){return $CLJS.Lk.l(f,$CLJS.s_,$CLJS.H([$CLJS.CW,$CLJS.bX,$CLJS.nL]))},function(){var f=fra(a,b,e);if($CLJS.n(f))return f;f=$CLJS.WW(a,b);f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.sP),l=$CLJS.J.g(f,$CLJS.TV),m=$CLJS.n(k)?function(){var t=$CLJS.l0(a,k);return $CLJS.Z0.v(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?gra(a,b,l,$CLJS.R.j(d,$CLJS.r1,!1)):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.qe(null,
function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.Cd(x)){var z=$CLJS.lc(x),C=$CLJS.D(z),G=$CLJS.te(C);return function(){for(var S=0;;)if(S<C){var Z=$CLJS.md(z,S),ca=G,ka=$CLJS.R,wa=ka.l,Qa=Z,ib=$CLJS.T.h(Z);Z=$CLJS.T.h(Z);Z=e.h?e.h(Z):e.call(null,Z);ka=wa.call(ka,Qa,$CLJS.$K,$CLJS.i_,$CLJS.H([$CLJS.HZ,ib,$CLJS.ZZ,Z]));ca.add(ka);S+=1}else return!0}()?$CLJS.we($CLJS.ye(G),v($CLJS.mc(x))):$CLJS.we($CLJS.ye(G),null)}var K=$CLJS.A(x);return $CLJS.he($CLJS.R.l(K,$CLJS.$K,$CLJS.i_,$CLJS.H([$CLJS.HZ,$CLJS.T.h(K),
$CLJS.ZZ,function(){var S=$CLJS.T.h(K);return e.h?e.h(S):e.call(null,S)}()])),v($CLJS.Lc(x)))}return null}},null,null)}($CLJS.BW.h($CLJS.BV.h(f)))}())};Q7=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.hpa={};$CLJS.MF($CLJS.iW,Q7);$CLJS.MF($CLJS.pW,Q7);$CLJS.E1.m(null,$CLJS.iW,function(a){return $CLJS.D1(a,new $CLJS.h(null,2,[$CLJS.pG,$CLJS.Ye($CLJS.Wk,$CLJS.E1),$CLJS.wW,$CLJS.Ye($CLJS.Wk,$CLJS.E1)],null))});$CLJS.U0.m(null,Q7,function(){throw $CLJS.li("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.Y0.m(null,Q7,function(a,b,c,d){var e=$CLJS.O(d);c=$CLJS.J.g(e,$CLJS.u1);d=$CLJS.J.g(e,$CLJS.r1);a=$CLJS.c4(a,b);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.u1),k=$CLJS.J.g(e,$CLJS.t1),l=$CLJS.J.g(e,$CLJS.x1);e=$CLJS.kf.l(B7(a,b,e),$CLJS.n(l)?A7(a,b,f):null,$CLJS.H([$CLJS.n(k)?$qa(a,b,f):null]));return $CLJS.Gf($CLJS.kf.g(e,$CLJS.n(d)?$CLJS.U1(a,b,e,c):null))});
$CLJS.W0.m(null,Q7,function(a,b,c,d){c=$CLJS.O(d);d=$CLJS.J.g(c,$CLJS.u1);var e=ara(a,b);if($CLJS.n(e))return e;a=$CLJS.c4(a,b);e=era(a,b,d);var f=dra(a,b,d);return $CLJS.n(e)?$CLJS.hg.g(e,f):$CLJS.n(f)?($CLJS.ph(f),$CLJS.hg.j($CLJS.Ff,Zqa(function(k){return $CLJS.Lk.l(k,$CLJS.m_,$CLJS.H([$CLJS.$K,$CLJS.c3,$CLJS.ZZ]))}),$CLJS.kf.g(f,z7(a,b,c)))):$CLJS.kf.l(B7(a,b,new $CLJS.h(null,2,[$CLJS.r1,!1,$CLJS.u1,d],null)),A7(a,b,d),$CLJS.H([z7(a,b,c)]))});$CLJS.O0.m(null,$CLJS.pW,function(){return $CLJS.eF("Native query")});
var E7=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sP,$CLJS.TV,$CLJS.RN],null),F7=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.pG,$CLJS.oG,$CLJS.wW,$CLJS.cS,$CLJS.zS],null);
$CLJS.O0.m(null,$CLJS.iW,function(a,b,c,d){var e=$CLJS.c4(a,b);a=$CLJS.Ie(function(){var f=$CLJS.hg.j($CLJS.N,$CLJS.Uk.g($CLJS.LC,$CLJS.lf.h(function(l){return new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.$ma.j(e,b,l)],null)})),new $CLJS.P(null,2,5,$CLJS.Q,[E7,F7],null)),k=$CLJS.Xt(" + ",$CLJS.Vk.g($CLJS.$A,$CLJS.lf.g(f,E7)));f=$CLJS.lf.g(f,F7);return $CLJS.Xt(", ",$CLJS.Vk.g($CLJS.$A,$CLJS.he(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.o2(e,b);return $CLJS.n(a)?$CLJS.q0.v(e,a,$CLJS.WW(e,a),d):null});