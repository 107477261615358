var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var aqa=function(a,b){return new $CLJS.qe(null,function(){a:for(var c=a,d=b;;){d=$CLJS.y(d);var e;if(e=d)e=$CLJS.A(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Lc(d);else break a}return d},null,null)},bqa=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qu(a,b),aqa(a,b)],null)},f5=function(a){var b=$CLJS.ll.l;var c=$CLJS.O(a);var d=$CLJS.J.g(c,$CLJS.o5);c=$CLJS.J.g(c,$CLJS.v5);if($CLJS.n($CLJS.n(d)?$CLJS.E.g(c,"currency"):d)){c=$CLJS.e5.h;d=$CLJS.Ch.h(d);var e=$CLJS.q($CLJS.R4);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.e5,d);c=new $CLJS.h(null,2,[$CLJS.n5,c,$CLJS.w5,c],null)}else c=new $CLJS.h(null,1,[$CLJS.w5,2],null);b=b.call($CLJS.ll,$CLJS.H([c,a]));return $CLJS.n($CLJS.$5.h(a))?$CLJS.R.l($CLJS.Lk.g(b,$CLJS.$5),$CLJS.w5,$CLJS.$5.h(a),$CLJS.H([$CLJS.n5,$CLJS.$5.h(a)])):b},g5=function(a,b){if(null!=a&&null!=a.Oe)a=a.Oe(a,b);else{var c=g5[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=g5._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("NumberFormatter.format-number-basic",
a);}return a},cqa=function(a,b){var c=$CLJS.hA(a.formatToParts(b),$CLJS.H([new $CLJS.h(null,1,[$CLJS.Di,!0],null)]));a=$CLJS.n($CLJS.Ue(function(d){return $CLJS.E.g($CLJS.rj.h(d),"exponentMinusSign")},c))?c:function(){var d=bqa(function(f){return $CLJS.Sk.g($CLJS.rj.h(f),"exponentInteger")},c),e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.kf.l(e,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.rj,"exponentPlusSign",$CLJS.Ij,"+"],null)],null),$CLJS.H([d]))}();return $CLJS.Ve.g($CLJS.p,
$CLJS.lf.g(function(d){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.rj);e=$CLJS.J.g(e,$CLJS.Ij);switch(d){case "exponentSeparator":return"e";default:return e}},a))},dqa=function(a,b){switch($CLJS.v5.h(b)){case "scientific":return function(c){return cqa(a,c)};default:return function(c){return a.format(c)}}},b6=function(a,b,c,d,e){this.options=a;this.Ag=b;this.Dg=c;this.ee=d;this.pi=e;this.C=393216;this.I=0},eqa=function(a,b){return $CLJS.Ve.g($CLJS.p,function(){return function e(d){return new $CLJS.qe(null,
function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.md(k,v),z=$CLJS.O(x);x=$CLJS.J.g(z,$CLJS.rj);z=$CLJS.J.g(z,$CLJS.Ij);var C=new $CLJS.dh(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);C=C.h?C.h(x):C.call(null,x);$CLJS.n(C)&&(x=$CLJS.E.g(x,"integer")?b:z,m.add(x));v+=1}else return!0}()?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}var t=$CLJS.A(f);t=$CLJS.O(t);
var u=$CLJS.J.g(t,$CLJS.rj);t=$CLJS.J.g(t,$CLJS.Ij);if($CLJS.n(function(){var v=new $CLJS.dh(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.he($CLJS.E.g(u,"integer")?b:t,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}($CLJS.hA(a.Ag.formatToParts(1),$CLJS.H([$CLJS.Di,!0])))}())},i5=function(a){var b=$CLJS.E.g($CLJS.v5.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.ol($CLJS.bB($CLJS.Wa,$CLJS.Lg([$CLJS.qta,
$CLJS.Rsa,$CLJS.Vsa,$CLJS.fta,$CLJS.dua,$CLJS.o5,$CLJS.eta,$CLJS.ota,$CLJS.Ssa,$CLJS.Tsa],[$CLJS.w5.g(a,b),$CLJS.a6.h(a),$CLJS.Nsa.h(a),$CLJS.pta.h(a),$CLJS.E.g($CLJS.v5.h(a),"scientific")?null:$CLJS.v5.g(a,"decimal"),$CLJS.o5.h(a),!0,$CLJS.n5.g(a,b),$CLJS.E.g($CLJS.v5.h(a),"scientific")?"scientific":null,$CLJS.aua.h(a)]))));var c=$CLJS.a6.h(a);var d=$CLJS.o5.h(a);c=$CLJS.n(d)?null==c||$CLJS.E.g(c,"symbol"):d;d=dqa(b,a);return new b6(a,b,c,d,$CLJS.N)},j5=function(a,b){return i5(f5(b)).Oe(null,a)},
l5=function(a,b){var c=$CLJS.Lk.l(b,$CLJS.QB,$CLJS.H([$CLJS.v5])),d=$CLJS.UA(a);if(0===a)return"0";if(1E3>d)return $CLJS.k5.g?$CLJS.k5.g(a,c):$CLJS.k5.call(null,a,c);b=$CLJS.A($CLJS.of(function(f){return d>=$CLJS.A(f)},fqa));var e=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return[$CLJS.p.h(function(){var f=a/e,k=$CLJS.ll.l($CLJS.H([c,new $CLJS.h(null,2,[$CLJS.n5,1,$CLJS.w5,1],null)]));return $CLJS.k5.g?$CLJS.k5.g(f,k):$CLJS.k5.call(null,f,k)}()),$CLJS.p.h(b)].join("")},gqa=function(a,b){var c=f5(b);if($CLJS.n(Bua.h(c)))b=
Bua.h(c);else if($CLJS.Va($CLJS.$5.h(c))&&$CLJS.Va($CLJS.n5.h(c))&&$CLJS.Sk.g($CLJS.v5.h(c),"currency")&&$CLJS.UA(a)<($CLJS.E.g($CLJS.v5.h(c),"percent")?.01:1)){b=$CLJS.R.j;var d=$CLJS.Lk.g(c,$CLJS.w5);c=$CLJS.aua.g(c,0);b=i5(b.call($CLJS.R,d,$CLJS.Nsa,2>c?2:c))}else b=i5(c);return g5(b,a)};b6.prototype.P=function(a,b){return new b6(this.options,this.Ag,this.Dg,this.ee,b)};b6.prototype.O=function(){return this.pi};
b6.prototype.Oe=function(a,b){a=this.ee.h?this.ee.h(b):this.ee.call(null,b);var c=$CLJS.$ta.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.Sk.g(c,".,"):c)&&(b=$CLJS.A(c),c=$CLJS.jd(c),a=$CLJS.ZA(a,/[\.,]/,new $CLJS.h(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.Dg)&&(b=$CLJS.o5.h(this.options),c=$CLJS.S4(b),a=$CLJS.ZA($CLJS.ZA(a,[$CLJS.jh(b)," "].join(""),c),$CLJS.jh(b),c));return a};
var Bua=new $CLJS.M(null,"number-formatter","number-formatter",929788393),mva=new $CLJS.M(null,"scale","scale",-230427353),nva=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),ova=new $CLJS.M(null,"scientific","scientific",316285837);var p5=new $CLJS.h(null,1,[$CLJS.a6,"symbol"],null),iqa=$CLJS.ol(p5),fqa=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[1E12,"T"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E9,"B"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E6,"M"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E3,"k"],null)],null),q5=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,k){f=$CLJS.O(k);return $CLJS.J.g(f,$CLJS.v5)},e,a,b,c,d)}();q5.m(null,$CLJS.mi,function(a,b){return l5(a,b)});q5.m(null,"percent",function(a,b){return[$CLJS.p.h(l5(100*a,b)),"%"].join("")});q5.m(null,"currency",function(a,b){b=$CLJS.ll.l($CLJS.H([b,p5]));var c=i5(b);return 1E3>$CLJS.UA(a)?c.Oe(null,a):eqa(c,l5(a,b))});q5.m(null,"scientific",function(a,b){return j5(a,$CLJS.ll.l($CLJS.H([b,new $CLJS.h(null,2,[$CLJS.w5,1,$CLJS.n5,1],null)])))});
$CLJS.k5=function k5(a,b){b=$CLJS.yB(b);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.QB);var e=$CLJS.J.g(d,nva),f=$CLJS.J.g(d,$CLJS.v5),k=$CLJS.J.g(d,mva);return $CLJS.n($CLJS.n(k)?!isNaN(k):k)?(b=k*a,f=$CLJS.Lk.g(d,mva),k5.g?k5.g(b,f):k5.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.R.j(d,nva,!1);return k5.g?k5.g(l,m):k5.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?q5.g(a,f5($CLJS.Lk.g(d,$CLJS.QB))):$CLJS.E.g($CLJS.Ch.h(f),ova)?j5(a,d):gqa(a,d)};
module.exports={compact_currency_options_js:iqa,format_number:$CLJS.k5};