var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var lV,mV,nV,uV,vV,wV,xV,yV,zV,AV;lV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);mV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);nV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.tV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);uV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);vV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);wV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
xV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);yV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);zV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);AV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(lV,$CLJS.hg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid template tag :widget-type"],null),$CLJS.yA],null),$CLJS.$g($CLJS.oV)));$CLJS.X(uV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.rs,$CLJS.UM,$CLJS.JN,$CLJS.DF,$CLJS.gu,$CLJS.Cl,$CLJS.QP],null));
$CLJS.X(xV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.HE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CE,$CLJS.HE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tj,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.HE,$CLJS.vk],null)],null)],null));
$CLJS.X(yV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Hl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kO,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null)],null)],null));
$CLJS.X(vV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.DF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.Ts,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Dk],null)],null)],null)],null));
$CLJS.X(wV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.UM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GM,$CLJS.HE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.vL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iQ,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.kL],null)],null)],null));
$CLJS.X(AV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.JN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qR,$CLJS.jL],null)],null)],null));$CLJS.X(zV,$CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rs],null),$CLJS.cV));
$CLJS.X(mV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zV],null)],null)],null)],null));
$CLJS.X(nV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,uV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,1,[$CLJS.bj,$CLJS.rj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Np,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null)],null)],null)],null));
$CLJS.X($CLJS.tV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nj,$CLJS.HE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Te(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));