var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");
'use strict';$CLJS.Q1=function(a,b){var c=$CLJS.XW(b);return $CLJS.n(c)?$CLJS.n0(a,c):$CLJS.l0(a,b)};$CLJS.R1=function(a){var b=$CLJS.yC(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.IP.h($CLJS.aF(a));case "metadata/column":return $CLJS.s_.h(a);case "mbql/join":return $CLJS.lF.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
$CLJS.S1=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.T1=function(a,b){var c=$CLJS.R1(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.O(b);c=$CLJS.J.g(d,$CLJS.nL);d=$CLJS.J.g(d,$CLJS.oL);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.Q1(a,d),$CLJS.n(d))){d=$CLJS.T.h(d);a=$CLJS.T.h($CLJS.K1(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.S1(a,$CLJS.T.h(b)):$CLJS.T.h(b)};
$CLJS.U1=function(a,b,c,d){var e=$CLJS.hg.j($CLJS.eh,$CLJS.lf.h($CLJS.oL),c);return $CLJS.hg.j($CLJS.Ff,$CLJS.Uk.l($CLJS.nf($CLJS.lL),$CLJS.nf($CLJS.tj),$CLJS.nf($CLJS.Uk.g($CLJS.Fl,$CLJS.tj)),$CLJS.H([$CLJS.lf.h(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.tj),l=$CLJS.J.g(f,$CLJS.lL);return $CLJS.R.l($CLJS.K1(a,l),$CLJS.woa,k,$CLJS.H([$CLJS.xoa,$CLJS.s_.h(f)]))}),$CLJS.Vk.h(function(f){return $CLJS.Ld(e,$CLJS.oL.h(f))}),$CLJS.AE(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.oL),l=$CLJS.J.g(f,
$CLJS.woa),m=$CLJS.J.g(f,$CLJS.xoa);f=$CLJS.l0(a,k);return function v(u){return new $CLJS.qe(null,function(){for(;;){var x=$CLJS.y(u);if(x){if($CLJS.Cd(x)){var z=$CLJS.lc(x),C=$CLJS.D(z),G=$CLJS.te(C);return function(){for(var Z=0;;)if(Z<C){var ca=$CLJS.md(z,Z),ka=$CLJS.R.l(ca,$CLJS.nL,l,$CLJS.H([$CLJS.q1,m,$CLJS.$K,$CLJS.VZ,$CLJS.HZ,$CLJS.T.h(ca)]));ca=G;var wa=$CLJS.R,Qa=wa.j,ib=ka;ka=$CLJS.T1(a,ka);ka=d.h?d.h(ka):d.call(null,ka);wa=Qa.call(wa,ib,$CLJS.ZZ,ka);ca.add(wa);Z+=1}else return!0}()?$CLJS.we($CLJS.ye(G),
v($CLJS.mc(x))):$CLJS.we($CLJS.ye(G),null)}var K=$CLJS.A(x),S=$CLJS.R.l(K,$CLJS.nL,l,$CLJS.H([$CLJS.q1,m,$CLJS.$K,$CLJS.VZ,$CLJS.HZ,$CLJS.T.h(K)]));return $CLJS.he($CLJS.R.j(S,$CLJS.ZZ,function(){var Z=$CLJS.T1(a,S);return d.h?d.h(Z):d.call(null,Z)}()),v($CLJS.Lc(x)))}return null}},null,null)}($CLJS.Y0.v(a,b,f,new $CLJS.h(null,2,[$CLJS.u1,d,$CLJS.r1,!1],null)))})])),c)};$CLJS.V1=function(a,b){return $CLJS.N1.g(a,b)};$CLJS.W1=function(a){return $CLJS.BE("Question {0}",$CLJS.H([$CLJS.Ph.l($CLJS.H([a]))]))};
$CLJS.X1=function(a,b){var c=$CLJS.WE.h(b);$CLJS.n(c)||(c=$CLJS.e_.h(b),$CLJS.n(c)||(c=$CLJS.d_.h(b),c=null!=c?$CLJS.X0.h($CLJS.V1(a,$CLJS.qZ.h(c))):null));return $CLJS.n(c)?(c=$CLJS.Ie($CLJS.zd(c)?$CLJS.BW.h(c):$CLJS.yd(c)?c:null),$CLJS.n(c)?$CLJS.Wk.g($CLJS.wu(Ona,a,b),c):null):null};$CLJS.n3=new $CLJS.M("metabase.lib.card","force-broken-id-refs","metabase.lib.card/force-broken-id-refs",-868945458);$CLJS.Dpa=new $CLJS.M(null,"field-ref","field-ref",-1699303428);$CLJS.O0.m(null,$CLJS.xL,function(a,b,c){return $CLJS.VA($CLJS.CE,$CLJS.T)(c)});$CLJS.U0.m(null,$CLJS.xL,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);c=$CLJS.J.g(a,$CLJS.CE);return $CLJS.Va(c)?$CLJS.R.j(a,$CLJS.CE,$CLJS.P1.g($CLJS.m3,b)):a});
$CLJS.Y0.m(null,$CLJS.xL,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.WE),f=$CLJS.J.g(c,$CLJS.e_);d=$CLJS.O(d);var k=$CLJS.J.g(d,$CLJS.r1),l=$CLJS.J.g(d,$CLJS.u1);return $CLJS.kf.g($CLJS.X0.v(a,b,c,d),$CLJS.n(k)?$CLJS.U1(a,b,$CLJS.kf.g(e,f),l):null)});$CLJS.R0.m(null,$CLJS.TV,function(a,b){var c=$CLJS.WW(a,b);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.TV);if($CLJS.n(c)){var d=$CLJS.n0(a,c);a=$CLJS.n(d)?$CLJS.q0.v(a,b,d,$CLJS.D0):null;return $CLJS.n(a)?a:$CLJS.W1(c)}return null});
var Ona=function(){function a(d,e,f){var k=$CLJS.Lk.g($CLJS.XA(f,$CLJS.xB),$CLJS.Dpa);f=$CLJS.ll.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.KB,$CLJS.Wj,$CLJS.nu,$CLJS.YK],null),function(){var l=$CLJS.tj.h(k);if($CLJS.n(l))try{return $CLJS.K1(d,l)}catch(m){return null}else return null}(),k,new $CLJS.h(null,3,[$CLJS.nu,$CLJS.YK,$CLJS.$K,$CLJS.b_,$CLJS.HZ,$CLJS.VA($CLJS.HZ,$CLJS.T)(k)],null)]));f=$CLJS.n(e)?$CLJS.R.j(f,$CLJS.l_,$CLJS.H1(e)):f;e=$CLJS.n($CLJS.n(!0)?$CLJS.Va(e)||$CLJS.Va($CLJS.RZ.h($CLJS.n0(d,
$CLJS.H1(e)))):!0)?$CLJS.R.j(f,$CLJS.n3,!0):f;return $CLJS.Sk.g($CLJS.rk.h(k),$CLJS.pi)?$CLJS.R.j(e,$CLJS.lL,null):e}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.W0.m(null,$CLJS.xL,function(a,b,c,d){b=$CLJS.O(d);var e=$CLJS.J.g(b,$CLJS.u1);return $CLJS.Wk.g(function(f){var k=$CLJS.KC($CLJS.ZZ,$CLJS.HZ,$CLJS.T)(f);return $CLJS.R.j(f,$CLJS.ZZ,e.h?e.h(k):e.call(null,k))},$CLJS.X1(a,c))});