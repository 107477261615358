var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var KV,OV;$CLJS.JV=function(a){return a instanceof Error?a.message:null};
KV=function(a,b,c){if($CLJS.ce(c)){var d=$CLJS.Ve.g($CLJS.V,$CLJS.lf.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.Cf(c)?(d=new $CLJS.ag(function(){var e=$CLJS.Fb(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Gb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.Gd(c)?(d=$CLJS.ph($CLJS.lf.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.Ad(c)?(d=$CLJS.db(function(e,f){return $CLJS.ee.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.vd(c)?
(d=$CLJS.hg.g($CLJS.ld(c),$CLJS.lf.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.MV=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ch.h(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return $CLJS.LV(function(c){return $CLJS.zd(c)?$CLJS.hg.g($CLJS.N,$CLJS.lf.g(b,c)):c},a)};
$CLJS.NV=function(a){var b=new $CLJS.Fa;for(a=$CLJS.y(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.A(a))),a=$CLJS.B(a);else return b.toString()};$CLJS.PV=function(a,b,c){a=$CLJS.ZA(a,/''/,"'");var d=$CLJS.lu(a,OV);d=$CLJS.E.g($CLJS.D(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.rh(/.*\{0\}.*/,a);return $CLJS.Rt.ngettext($CLJS.Rt.msgid($CLJS.ol(d),$CLJS.n(a)?c:""),$CLJS.ZA($CLJS.ZA(b,/''/,"'"),OV,$CLJS.p.h(c)),c)};
$CLJS.QV=function(a,b){return $CLJS.db(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Ld(a,e)?$CLJS.R.j(c,d,$CLJS.J.g(a,e)):c},$CLJS.Ve.j($CLJS.Lk,a,$CLJS.$g(b)),b)};$CLJS.RV=function(a,b,c){return $CLJS.zd(c)?$CLJS.mf(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.ee.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.H([c])):$CLJS.yd(c)?$CLJS.mf($CLJS.Ye(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.ee.g(b,$CLJS.A(c)):b),$CLJS.H([c])):null};
$CLJS.LV=function LV(a,b){return KV($CLJS.Ye(LV,a),a,b)};OV=/\{0\}/;$CLJS.DV=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.EV=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158);$CLJS.IV=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.SV=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var tka=$CLJS.Lg([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pC,$CLJS.mJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pC,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uJ,null],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.pC,$CLJS.eJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pC,$CLJS.qJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hJ,null],null)],[$CLJS.Mi,$CLJS.lH,$CLJS.zJ,$CLJS.lJ,$CLJS.Gi,$CLJS.gk,$CLJS.Cj,$CLJS.Gk,$CLJS.Qi,$CLJS.lJ,$CLJS.AJ,$CLJS.iH]),WV;$CLJS.ih($CLJS.lf.g($CLJS.A,$CLJS.$g(tka)));var VV,xla=$CLJS.$e($CLJS.N),yla=$CLJS.$e($CLJS.N),zla=$CLJS.$e($CLJS.N),Ala=$CLJS.$e($CLJS.N),Bla=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
VV=new $CLJS.hi($CLJS.Ih.g("metabase.mbql.util","negate*"),$CLJS.A,Bla,xla,yla,zla,Ala);VV.m(null,$CLJS.Ds,function(a){$CLJS.I(a,0,null);return $CLJS.I(a,1,null)});VV.m(null,$CLJS.Ks,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);return $CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.As],null),$CLJS.lf.g(VV,a))});VV.m(null,$CLJS.As,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);return $CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ks],null),$CLJS.lf.g(VV,a))});
VV.m(null,$CLJS.gs,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QG,b,a],null)});VV.m(null,$CLJS.QG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gs,b,a],null)});VV.m(null,$CLJS.cs,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fs,b,a],null)});
VV.m(null,$CLJS.es,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,b,a],null)});VV.m(null,$CLJS.ds,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.es,b,a],null)});VV.m(null,$CLJS.fs,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cs,b,a],null)});
VV.m(null,$CLJS.bG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.es,b,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cs,b,a],null)],null)});VV.m(null,$CLJS.zG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ds,a],null)});VV.m(null,$CLJS.rG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ds,a],null)});
VV.m(null,$CLJS.LG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ds,a],null)});
WV=function WV(a){return $CLJS.zd(a)?$CLJS.Ie($CLJS.hg.g($CLJS.N,function(){return function e(d){return new $CLJS.qe(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);a:for(var t=0;;)if(t<l){var u=$CLJS.md(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=WV.h?WV.h(u):WV.call(null,u);null!=u&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null));t+=1}else{k=!0;break a}return k?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}k=$CLJS.A(f);
m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=WV.h?WV.h(k):WV.call(null,k);if(null!=k)return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[m,k],null),e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(a)}())):$CLJS.yd(a)?$CLJS.Ie($CLJS.hg.g($CLJS.ld(a),$CLJS.of($CLJS.Wa,$CLJS.lf.g(WV,a)))):a};
$CLJS.XV=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.w(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.I(c,0,null),k=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);d=$CLJS.Ie(WV($CLJS.Ve.j(d,c,e)));return $CLJS.n(d)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,d],null):$CLJS.E.g(f,$CLJS.AG)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,null],null):new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}a.A=2;a.B=function(c){var d=
$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,e,c)};a.l=b;return a}();