var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var QL,SL,UL,XL,$L;$CLJS.PL=function(a){return $CLJS.Zh($CLJS.q($CLJS.AC),a,$CLJS.EG)};$CLJS.OL=new $CLJS.M("lib","source-name","lib/source-name",1931622490);QL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.RL=new $CLJS.M(null,"operators","operators",-2064102509);SL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);
$CLJS.TL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);UL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.VL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.WL=new $CLJS.M(null,"dimensions","dimensions",-254818097);XL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.YL=new $CLJS.M(null,"max-value","max-value",687805168);
$CLJS.ZL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);$L=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.aM=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(SL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,$CLJS.ME,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.tH],null)],null)],null));$CLJS.X($L,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,SL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KB,$CLJS.QE],null)],null)],null));$CLJS.X(UL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.AG],null),$L,$CLJS.HE],null));
$CLJS.X(XL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.AG],null),SL,$CLJS.wL],null));
$CLJS.cH.g($CLJS.AG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.AG],null),SL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.wL,$CLJS.HE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.bj,function(a){return $CLJS.yd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.yC(a)):null},$CLJS.Ht,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.CB,XL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BB,UL],null)],null)],null));$CLJS.MF($CLJS.AG,$CLJS.EG);$CLJS.gF.m(null,$CLJS.AG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.VA($CLJS.Pi,$CLJS.KB)(b);return $CLJS.n(a)?a:$CLJS.oF});$CLJS.TF($CLJS.HB,$CLJS.H([$CLJS.HE]));$CLJS.gF.m(null,$CLJS.HB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.VA($CLJS.Pi,$CLJS.KB)(b);return $CLJS.n(a)?a:$CLJS.oF});
$CLJS.MF($CLJS.HB,$CLJS.EG);$CLJS.X(QL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gt,$CLJS.ME,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.HE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.HE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OL,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.HE],null)],null)],null));
$CLJS.cH.g($CLJS.pG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Li,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.pG],null),QL,$CLJS.tk],null));$CLJS.gF.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.VA($CLJS.Pi,$CLJS.KB)(b);return $CLJS.n(a)?a:$CLJS.oF});$CLJS.MF($CLJS.pG,$CLJS.EG);$CLJS.TF($CLJS.YF,$CLJS.H([$CLJS.rt,$CLJS.lk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aL],null)],null)]));$CLJS.MF($CLJS.YF,$CLJS.EG);
$CLJS.TF($CLJS.vG,$CLJS.H([$CLJS.rt,$CLJS.oF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZK],null)],null)]));$CLJS.MF($CLJS.vG,$CLJS.EG);
$CLJS.X($CLJS.EG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,$CLJS.UE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.$t,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Xt(", ",$CLJS.oH($CLJS.Md,$CLJS.pl.g($CLJS.q($CLJS.AC),$CLJS.EG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.PL(a)}],null)],null));