var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.cache.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.field.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.util.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.time.js");require("./metabase.util.log.js");require("./metabase.util.memoize.js");
'use strict';var Sva=function(a,b){const c={};for(const d in a)b.call(void 0,a[d],d,a)&&(c[d]=a[d]);return c},Tva=function(a,b){const c={};for(const d in a)c[d]=b.call(void 0,a[d],d,a);return c},Vza=function(a,b,c){if(b in a)return a[b];c=c();return a[b]=c},Wza=function(a,b,c,d){return $CLJS.z0(a,b,c,d,$CLJS.ll.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.r_,D9],null),new $CLJS.h(null,1,[$CLJS.q_,!0],null)])))},Xza=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.KB);b=$CLJS.J.g(b,$CLJS.Pi);a=$CLJS.n(b)?
b:a;return $CLJS.MC(a,$CLJS.Ak)||$CLJS.MC(a,$CLJS.Hj)},Vva=function(a,b,c,d){var e=$CLJS.hg.j($CLJS.N,$CLJS.km(function(f,k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),d);return function l(k){return new $CLJS.qe(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Cd(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.te(u);a:for(var x=0;;)if(x<u){var z=$CLJS.md(t,x);z=$CLJS.z2.v(a,b,z,d);z=$CLJS.J.j(e,z,-1);v.add(z);x+=1}else{t=!0;break a}return t?$CLJS.we($CLJS.ye(v),l($CLJS.mc(m))):$CLJS.we($CLJS.ye(v),
null)}v=$CLJS.A(m);v=$CLJS.z2.v(a,b,v,d);return $CLJS.he($CLJS.J.j(e,v,-1),l($CLJS.Lc(m)))}return null}},null,null)}(c)},C9=function(a,b){return new $CLJS.Mh(function(){try{return $CLJS.y9.g(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.eB($CLJS.Vy);$CLJS.n($CLJS.dB("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.cB("metabase.lib.js.metadata",d,$CLJS.LW("Error parsing %s objects: %s",$CLJS.H([a,$CLJS.JV(c)])),c):$CLJS.cB("metabase.lib.js.metadata",d,$CLJS.LW(c,$CLJS.H(["Error parsing %s objects: %s",
a,$CLJS.JV(c)])),null));return null}throw e;}})},Wva=function(a,b){return function e(d){return new $CLJS.qe(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.md(k,x);$CLJS.I(z,0,null);var C=$CLJS.I(z,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.oL.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.we($CLJS.ye(m),
e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}var t=$CLJS.A(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.oL.h(v),b):x}()))return $CLJS.he(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.WE.h(a);return null==d?null:$CLJS.q(d)}())},Xva=function(a,b){return function e(d){return new $CLJS.qe(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Cd(f)){var k=
$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.md(k,x);$CLJS.I(z,0,null);var C=$CLJS.I(z,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.oL.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}var t=$CLJS.A(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=
v;return $CLJS.n(x)?$CLJS.E.g($CLJS.oL.h(v),b):x}()))return $CLJS.he(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.t9.h(a);return null==d?null:$CLJS.q(d)}())},Yva=function(a,b){return function e(d){return new $CLJS.qe(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.md(k,x);$CLJS.I(z,0,null);var C=$CLJS.I(z,1,null),G=function(){var K=C;
return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.oL.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}var t=$CLJS.A(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.oL.h(v),b):x}()))return $CLJS.he(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==
a?null:$CLJS.u9.h(a);return null==d?null:$CLJS.q(d)}())},M9=function(a,b,c,d){this.fg=a;this.Ii=b;this.metadata=c;this.ri=d;this.C=393216;this.I=0},Yza=function(a,b){var c=new $CLJS.h(null,6,[$CLJS.vza,C9($CLJS.iQ,b),$CLJS.w9,C9($CLJS.un,b),$CLJS.WE,C9($CLJS.AG,b),$CLJS.nza,C9($CLJS.JN,b),$CLJS.t9,C9($CLJS.vG,b),$CLJS.u9,C9($CLJS.YF,b)],null),d=$CLJS.eB($CLJS.Uy);$CLJS.n($CLJS.dB("metabase.lib.js.metadata",d))&&$CLJS.cB("metabase.lib.js.metadata",d,$CLJS.Ww.l($CLJS.H(["Created metadata provider for metadata"])),
null);return new M9(a,b,c,$CLJS.N)},Zva=function(a){return $CLJS.Xa(a)?Sva(a,function(b){return void 0!==b}):a},E9=function(a){return $CLJS.Wt($CLJS.iA(Tva(a,function(b){return Zva(b)})),function(b){return $CLJS.Xk.j($CLJS.XA(b,$CLJS.Ch),$CLJS.rj,$CLJS.Ch)})},F9=function(a,b){if($CLJS.OZ(b))a=b;else{var c=$CLJS.p.h(a);a=$CLJS.z0(c,b,b,$CLJS.Ye(Yza,a),new $CLJS.h(null,2,[$CLJS.r_,$CLJS.y0,$CLJS.q_,!0],null))}return a},Zza=function(a){a=$CLJS.sB(a);const b=a.length-1;a=0<=b&&a.indexOf("?",b)==b?["is-",
$CLJS.ZA(a,/\?$/,"")].join(""):a;return $CLJS.Qda.h?$CLJS.Qda.h(a):$CLJS.Qda.call(null,a)},W9=function(a){return $CLJS.zd(a)?R9.h?R9.h(a):R9.call(null,a):$CLJS.yd(a)?T9.h?T9.h(a):T9.call(null,a):a instanceof $CLJS.M?$CLJS.sB(a):a},G9=function(a,b){return $CLJS.kX($CLJS.kX((0,$CLJS.UY)($CLJS.B9($CLJS.iA(a))),$CLJS.xQ,function(c){return $CLJS.ll.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.UQ,$CLJS.N],null),c]))}),$CLJS.WF,function(c){var d=function(){var e=$CLJS.WE.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.qe(null,
function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Cd(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.te(u);a:for(var x=0;;)if(x<u){var z=$CLJS.md(t,x);v.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,z,null],null));x+=1}else{t=!0;break a}return t?$CLJS.we($CLJS.ye(v),l($CLJS.mc(m))):$CLJS.we($CLJS.ye(v),null)}v=$CLJS.A(m);return $CLJS.he(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,v,null],null),l($CLJS.Lc(m)))}return null}},null,null)}(b)}();return $CLJS.R.j(c,$CLJS.WE,$CLJS.Uva(d))})},$za=function(a,b){function c(l,
m){l=$CLJS.Va($CLJS.KB.h(m))?$CLJS.Lk.g(l,$CLJS.KB):l;m=$CLJS.Va($CLJS.Pi.h(m))?$CLJS.Lk.g(l,$CLJS.Pi):l;return $CLJS.Ie(m)}var d=$CLJS.I(a,0,null),e=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return $CLJS.E.g(new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c(a,b)],null),new $CLJS.P(null,3,5,$CLJS.Q,[f,k,c(b,a)],null))},aAa=function(a,b){var c=$CLJS.WW(a,b);return $CLJS.ze($CLJS.lf.g(function(d){return $CLJS.R.j(d,$CLJS.$Z,!0)},$CLJS.X0.j(a,b,c)))},bAa=
function(a){return $CLJS.n(function(){var b=$CLJS.A(a),c=new $CLJS.dh(null,new $CLJS.h(null,3,[$CLJS.YF,null,$CLJS.pG,null,$CLJS.vG,null],null),null);return c.h?c.h(b):c.call(null,b)}())?$CLJS.al.j(a,0,2):$CLJS.Xk.v(a,2,$CLJS.Wt,function(b){return $CLJS.pe(b)?$CLJS.sB(b):b})},H9=function(a){return $CLJS.qZ.h($CLJS.Xk.j($CLJS.hA(a,$CLJS.H([$CLJS.Di,!0])),0,$CLJS.Ch))},$va=function(a){var b=$CLJS.Xa(a)?a:null;return $CLJS.n(b)?(a=$CLJS.A9.h?$CLJS.A9.h(b):$CLJS.A9.call(null,b),$CLJS.E.g($CLJS.$K.h(a),
$CLJS.zL)?(b=H9(b.field_ref),$CLJS.R.j(a,$CLJS.c3,$CLJS.kd(b))):a):a},I9=function(a,b){var c=null==a?null:$CLJS.A(a);c=null==c?null:$CLJS.E.g(c,$CLJS.pG);return $CLJS.n(c)?$CLJS.R.j(b,$CLJS.c3,$CLJS.kd(a)):b},X9=function(a,b,c){b=$CLJS.d9.l($CLJS.H([a,b]));a=$CLJS.hg.j($CLJS.N,$CLJS.km(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,l],null)}),b);b=$CLJS.hg.j($CLJS.N,$CLJS.km(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,
1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null)}),b);var d=$CLJS.pZ,e=$CLJS.oZ;$CLJS.pZ=a;$CLJS.oZ=b;try{var f=function(){var k=c.field_ref;return $CLJS.n(k)?H9(k):null}();return I9(f,$CLJS.A9.h?$CLJS.A9.h(c):$CLJS.A9.call(null,c))}finally{$CLJS.oZ=e,$CLJS.pZ=d}},J9=function(a){return function(b){var c=function(){var e=a.h?a.h(b):a.call(null,b);return $CLJS.A9.h?$CLJS.A9.h(e):$CLJS.A9.call(null,e)}(),d=function(){var e=$CLJS.Dpa.h(c);return $CLJS.n(e)?
H9(e):null}();return new $CLJS.h(null,3,[$CLJS.hj,I9(d,c),$CLJS.o6,d,$CLJS.Ij,b.value],null)}},D9=function D9(a){switch(arguments.length){case 0:return D9.o();case 3:return D9.j(arguments[0],arguments[1],arguments[2]);case 4:return D9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};D9.o=function(){return{}};D9.j=function(a,b,c){a=$CLJS.Da(a,b);return $CLJS.n(a)?a.get(c):null};
D9.v=function(a,b,c,d){return Vza(a,b,function(){return new WeakMap}).set(c,d)};D9.A=4;$CLJS.g=M9.prototype;$CLJS.g.P=function(a,b){return new M9(this.fg,this.Ii,this.metadata,b)};$CLJS.g.O=function(){return this.ri};$CLJS.g.ki=$CLJS.Bc;$CLJS.g.vg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.t9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.wg=function(a,b){return Xva(this.metadata,b)};
$CLJS.g.zg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.w9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.ug=function(a,b){return Wva(this.metadata,b)};$CLJS.g.tg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.WE.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.yg=function(a,b){return Yva(this.metadata,b)};$CLJS.g.oh=function(a,b){return $CLJS.i9($CLJS.i9(this.Ii,"settings"),$CLJS.jh(b))};
$CLJS.g.rg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.nza.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.xg=function(a,b){a=this.metadata;a=null==a?null:$CLJS.u9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.sg=function(){var a=this.metadata,b=this.fg;a=null==a?null:$CLJS.vza.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};
var cAa=new $CLJS.M(null,"can-run","can-run",1635004421);var K9=function K9(a){switch(arguments.length){case 1:return K9.h(arguments[0]);case 2:return K9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.extract_template_tags",K9);K9.h=function(a){return K9.g(a,$CLJS.N)};K9.g=function(a,b){return $CLJS.ol($CLJS.Tua.l($CLJS.H([a,E9(b)])))};K9.A=2;
var Y9=function Y9(a){switch(arguments.length){case 2:return Y9.g(arguments[0],arguments[1]);case 3:return Y9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.query",Y9);Y9.g=function(a,b){return $CLJS.Yua.l($CLJS.H([a,b]))};Y9.j=function(a,b,c){return Wza($CLJS.p.h(a),b,c,function(d){return $CLJS.Yua.l($CLJS.H([b,$CLJS.qZ.h((0,$CLJS.UY)($CLJS.m2($CLJS.R7(d),$CLJS.rj,$CLJS.WF)))]))})};Y9.A=3;
var L9=function L9(a){return $CLJS.pe(a)?[$CLJS.le(a),"/",$CLJS.jh(a)].join(""):$CLJS.zd(a)?$CLJS.Wt(a,L9):$CLJS.yd(a)?$CLJS.lf.g(L9,a):a},R9=$CLJS.rB(function(a){return $CLJS.db(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);d=Zza(d);c=W9.h?W9.h(c):W9.call(null,c);b[d]=c;return b},{},a)}),T9=$CLJS.rB(function(a){return $CLJS.ze($CLJS.lf.g(W9,a))}),N9=function N9(a){switch(arguments.length){case 1:return N9.h(arguments[0]);case 2:return N9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",
$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.order_by_clause",N9);N9.h=function(a){return N9.g(a,$CLJS.xG)};N9.g=function(a,b){return $CLJS.Wua.l($CLJS.H([$CLJS.f9.l($CLJS.H([$CLJS.hA(a,$CLJS.H([$CLJS.Di,!0]))])),$CLJS.Ch.h(b)]))};N9.A=2;var O9=function O9(a){switch(arguments.length){case 2:return O9.g(arguments[0],arguments[1]);case 3:return O9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.Aa("metabase.lib.js.available_binning_strategies",O9);O9.g=function(a,b){return $CLJS.ze($CLJS.e9.l($CLJS.H([a,b])))};O9.j=function(a,b,c){return $CLJS.ze($CLJS.e9.l($CLJS.H([a,b,c])))};O9.A=3;var P9=function P9(a){switch(arguments.length){case 2:return P9.g(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.available_temporal_buckets",P9);
P9.g=function(a,b){return $CLJS.ze($CLJS.h9.l($CLJS.H([a,b])))};P9.j=function(a,b,c){return $CLJS.ze($CLJS.h9.l($CLJS.H([a,b,c])))};P9.A=3;
var Z9=function Z9(a,b){return $CLJS.Bd(a)&&$CLJS.Bd(b)&&$CLJS.E.l($CLJS.A(a),$CLJS.A(b),$CLJS.H([$CLJS.AG]))?$za(a,b):$CLJS.zd(a)&&$CLJS.zd(b)?$CLJS.E.g($CLJS.ih($CLJS.$g(a)),$CLJS.ih($CLJS.$g(b)))&&$CLJS.Te(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.J.g(b,e);return Z9.g?Z9.g(d,e):Z9.call(null,d,e)},a):$CLJS.yd(a)&&$CLJS.yd(b)?$CLJS.E.g($CLJS.D(a),$CLJS.D(b))&&$CLJS.Te($CLJS.Pl,$CLJS.lf.j(Z9,a,b)):$CLJS.E.g(a,b)},Q9=function Q9(a){switch(arguments.length){case 2:return Q9.g(arguments[0],
arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.query_EQ_",Q9);Q9.g=function(a,b){return Q9.j(a,b,null)};Q9.j=function(a,b,c){a=G9(a,c);b=G9(b,c);return Z9(a,b)};Q9.A=3;var S9=function S9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return S9.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};
$CLJS.Aa("metabase.lib.js.filter_clause",S9);S9.l=function(a,b,c){return $CLJS.Ve.v($CLJS.kua,a,b,c)};S9.A=2;S9.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};var U9=function U9(a){switch(arguments.length){case 3:return U9.j(arguments[0],arguments[1],arguments[2]);case 4:return U9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.Aa("metabase.lib.js.with_different_database",U9);U9.j=function(a,b,c){return U9.v(a,b,c,null)};U9.v=function(a,b,c,d){return $CLJS.Rua.l($CLJS.H([a,F9(b,c),$CLJS.hA(d,$CLJS.H([$CLJS.Di,!0]))]))};U9.A=4;var awa=J9(function(a){return a.col}),bwa=J9(function(a){return a.column}),V9=function V9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return V9.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.w(c.slice(3),0,null):null)};
$CLJS.Aa("metabase.lib.js.drill_thru",V9);V9.l=function(a,b,c,d){return $CLJS.Ve.N($CLJS.Qta,a,b,c,d)};V9.A=3;V9.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return this.l(b,a,c,d)};
module.exports={returned_columns:function(a,b){return $CLJS.A0($CLJS.Ch.g("returned-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return aAa(a,b)})},legacy_expression_for_expression_clause:function(a,b,c){b=$CLJS.d9.l($CLJS.H([a,b]));a=$CLJS.hg.j($CLJS.N,$CLJS.km(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,l],null)}),b);b=$CLJS.hg.j($CLJS.N,$CLJS.km(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);
l=$CLJS.J.g(l,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null)}),b);var d=$CLJS.pZ,e=$CLJS.oZ;$CLJS.pZ=a;$CLJS.oZ=b;try{var f=$CLJS.gZ.h(c);return $CLJS.ol(function(){var k;if(k=$CLJS.Bd(f)){k=$CLJS.A(f);var l=new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.Ij,null,$CLJS.IM,null],null),null);k=l.h?l.h(k):l.call(null,k)}return $CLJS.n(k)?$CLJS.J.g(f,1):f}())}finally{$CLJS.oZ=e,$CLJS.pZ=d}},drop_stage_if_empty:function(a){return $CLJS.fza.l($CLJS.H([a]))},extract_template_tags:K9,engine:function(a){return $CLJS.jh($CLJS.Mua.l($CLJS.H([a])))},
filter_operator:function(a,b,c){return $CLJS.lua.l($CLJS.H([a,b,c]))},binning:function(a){return $CLJS.Gta.l($CLJS.H([a]))},query:Y9,columns_group_columns:function(a){return $CLJS.ze($CLJS.Lta.l($CLJS.H([a])))},join_fields:function(a){a=$CLJS.wua.l($CLJS.H([a]));return a instanceof $CLJS.M?$CLJS.sB(a):$CLJS.ze(a)},aggregate:function(a,b,c){return $CLJS.Cta.l($CLJS.H([a,b,$CLJS.hA(c,$CLJS.H([$CLJS.Di,!0]))]))},join_conditions:function(a){return $CLJS.ze($CLJS.vua.l($CLJS.H([a])))},query_EQ_:Q9,temporal_bucket:function(a){return $CLJS.jva.l($CLJS.H([a]))},
available_drill_thrus:function(a,b,c,d,e,f){var k=$CLJS.d9.l($CLJS.H([a,b])),l=$CLJS.hg.j($CLJS.N,$CLJS.km(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),k);k=$CLJS.hg.j($CLJS.N,$CLJS.km(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),k);var m=$CLJS.pZ,t=$CLJS.oZ;$CLJS.pZ=l;$CLJS.oZ=k;try{var u=function(){var v=$CLJS.n(c)?
c.field_ref:c;return $CLJS.n(v)?H9(v):null}();return $CLJS.ze($CLJS.Pta.l($CLJS.H([a,b,$CLJS.ll.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.hj,$CLJS.n(c)?I9(u,$CLJS.A9.h?$CLJS.A9.h(c):$CLJS.A9.call(null,c)):null,$CLJS.o6,u,$CLJS.Ij,void 0===d?null:null==d?$CLJS.a7:d],null),$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.qi,$CLJS.Wk.g(awa,e)],null):null,$CLJS.n($CLJS.Ie(f))?new $CLJS.h(null,1,[$CLJS.WL,$CLJS.Wk.g(bwa,f)],null):null]))])))}finally{$CLJS.oZ=t,$CLJS.pZ=m}},has_clauses:function(a,b){return $CLJS.gza.l($CLJS.H([a,
b]))},remove_clause:function(a,b,c){return $CLJS.$ua.l($CLJS.H([a,b,$CLJS.f9.l($CLJS.H([$CLJS.hA(c,$CLJS.H([$CLJS.Di,!0]))]))]))},source_table_or_card_id:function(a){var b=$CLJS.f0(a);if($CLJS.n(b))return b;a=$CLJS.o3(a);return null==a?null:["card__",$CLJS.p.h(a)].join("")},legacy_ref:function(a,b,c){b=$CLJS.pG.h($CLJS.WW(a,b));a=$CLJS.hg.j($CLJS.N,$CLJS.km(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);
b=$CLJS.hg.j($CLJS.N,$CLJS.km(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.pZ,e=$CLJS.oZ;$CLJS.pZ=a;$CLJS.oZ=b;try{return $CLJS.ol(bAa($CLJS.gZ.h($CLJS.g9.l($CLJS.H([c])))))}finally{$CLJS.oZ=e,$CLJS.pZ=d}},replace_clause:function(a,b,c,d){return $CLJS.cva.l($CLJS.H([a,b,$CLJS.f9.l($CLJS.H([$CLJS.hA(c,$CLJS.H([$CLJS.Di,!0]))])),$CLJS.f9.l($CLJS.H([$CLJS.hA(d,$CLJS.H([$CLJS.Di,!0]))]))]))},drop_stage:function(a){return $CLJS.fva.l($CLJS.H([a]))},
join_lhs_display_name:function(a,b,c,d){return $CLJS.xua.l($CLJS.H([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.Zta.l($CLJS.H([a,b,c]))},segment_metadata:function(a,b){return $CLJS.J8(a,b)},pivot_types:function(a){return $CLJS.ze($CLJS.lf.g($CLJS.jh,$CLJS.Sta.l($CLJS.H([a]))))},native_extras:function(a){return $CLJS.ol($CLJS.Pua.l($CLJS.H([a])))},available_temporal_buckets:P9,with_binning:function(a,b){return $CLJS.Hta.l($CLJS.H([a,b]))},append_stage:function(a){return $CLJS.eva.l($CLJS.H([a]))},
join:function(a,b,c){return $CLJS.pua.l($CLJS.H([a,b,c]))},aggregations:function(a,b){return $CLJS.ze($CLJS.d9.l($CLJS.H([a,b])))},drill_thru:V9,raw_native_query:function(a){return $CLJS.Jua.l($CLJS.H([a]))},filterable_column_operators:function(a){return $CLJS.ze($CLJS.jua.l($CLJS.H([a])))},with_temporal_bucket:function(a,b){return $CLJS.kva.l($CLJS.H([a,b]))},valid_filter_for_QMARK_:function(a,b){return $CLJS.v0(a)&&$CLJS.v0(b)||$CLJS.w0(a)&&$CLJS.w0(b)||$CLJS.O_(a)&&$CLJS.O_(b)||$CLJS.MC($CLJS.KB.h(a),
$CLJS.KB.h(b))},with_join_conditions:function(a,b){return $CLJS.Eua.l($CLJS.H([a,$CLJS.hA(b,$CLJS.H([$CLJS.Di,!0]))]))},update_lat_lon_filter:function(a,b,c,d,e){e=$CLJS.hA(e,$CLJS.H([$CLJS.Di,!0]));c=X9(a,b,c);d=X9(a,b,d);return $CLJS.Zya.l($CLJS.H([a,b,c,d,e]))},available_metrics:function(a){return $CLJS.ze($CLJS.Hua.l($CLJS.H([a])))},with_different_database:U9,with_fields:function(a,b,c){return $CLJS.fua.l($CLJS.H([a,b,c]))},is_column_metadata:function(a){return $CLJS.zd(a)&&$CLJS.E.g($CLJS.YK,
$CLJS.nu.h(a))},filter_args_display_name:function(a,b,c){return $CLJS.Yya.l($CLJS.H([a,b,c]))},join_clause:function(a,b){return $CLJS.qua.l($CLJS.H([a,b]))},aggregation_clause:function(a,b){return void 0===b?$CLJS.c9.l($CLJS.H([a])):$CLJS.c9.l($CLJS.H([a,b]))},external_op:function(a){a=$CLJS.Nta.l($CLJS.H([a]));var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.KE);var c=$CLJS.J.g(b,$CLJS.xj);b=$CLJS.J.g(b,$CLJS.dt);return{operator:a,options:$CLJS.ol(c),args:$CLJS.ze(b)}},with_join_fields:function(a,b){return $CLJS.Cua.l($CLJS.H([a,
"string"===typeof b?$CLJS.Ch.h(b):b]))},visible_columns:function(a,b){return $CLJS.A0($CLJS.Ch.g("visible-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){var c=$CLJS.WW(a,b),d=$CLJS.Z0.j(a,b,c);c=$CLJS.X0.j(a,b,c);return $CLJS.ze($CLJS.J3(a,b,d,c))})},update_numeric_filter:function(a,b,c,d,e){c=X9(a,b,c);return $CLJS.$ya.l($CLJS.H([a,b,c,d,e]))},available_binning_strategies:O9,update_temporal_filter:function(a,b,c,d,e){c=X9(a,b,c);return $CLJS.aza.l($CLJS.H([a,b,c,d,e]))},find_column_indexes_from_legacy_refs:function(a,
b,c,d){var e=$CLJS.pG.h($CLJS.WW(a,b)),f=$CLJS.hg.j($CLJS.N,$CLJS.km(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null)}),e);e=$CLJS.hg.j($CLJS.N,$CLJS.km(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null)}),e);var k=$CLJS.pZ,l=$CLJS.oZ;$CLJS.pZ=f;$CLJS.oZ=e;try{var m=$CLJS.Wk.g($va,c),t=$CLJS.lf.g(H9,d);return $CLJS.ze(Vva(a,b,
t,m))}finally{$CLJS.oZ=l,$CLJS.pZ=k}},expression:function(a,b,c,d){return $CLJS.Uta.l($CLJS.H([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.Ch.h(a):a;b="string"===typeof b?$CLJS.Ch.h(b):b;return $CLJS.iva.l($CLJS.H([a,b]))},order_bys:function(a,b){return $CLJS.ze($CLJS.Xua.l($CLJS.H([a,b])))},display_info:function(a,b,c){return $CLJS.A0($CLJS.Ch.g("display-info-outer",["stage-",$CLJS.p.h(b)].join("")),c,function(d){return W9($CLJS.Fua.l($CLJS.H([$CLJS.c4(a,b),b,
d])))})},can_run:function(a){return $CLJS.A0(cAa,a,function(){return $CLJS.cza.l($CLJS.H([a]))})},fieldable_columns:function(a,b){return $CLJS.A0($CLJS.Ch.g("fieldable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.ze($CLJS.bua.l($CLJS.H([a,b])))})},template_tags:function(a){return $CLJS.ol($CLJS.Lua.l($CLJS.H([a])))},selected_aggregation_operators:function(a,b){return $CLJS.ze($CLJS.Fta.l($CLJS.H([$CLJS.y(a),b])))},order_by_clause:N9,filterable_columns:function(a,b){return $CLJS.A0($CLJS.Ch.g("filterable-columns",
["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.ze($CLJS.iua.l($CLJS.H([a,b])))})},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.ze($CLJS.tua.l($CLJS.H([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.uua.l($CLJS.H([a,b,c,d]))},filters:function(a,b){return $CLJS.ze($CLJS.hua.l($CLJS.H([a,b])))},join_condition_lhs_columns:function(a,b,c,d,e){return $CLJS.ze($CLJS.rua.l($CLJS.H([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.Q1(a,
b)},with_expression_name:function(a,b){return $CLJS.Wya.l($CLJS.H([a,b]))},orderable_columns:function(a,b){return $CLJS.A0($CLJS.Ch.g("orderable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.ze($CLJS.N7.g(a,b))})},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.mua.l($CLJS.H([a,b,$CLJS.hA(c,$CLJS.H([$CLJS.Di,!0]))]))},suggestedName:function(a){return $CLJS.Gua.l($CLJS.H([a]))},find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.Tta.l($CLJS.H([a,b,c,d]))},has_write_permission:function(a){return $CLJS.Sua.l($CLJS.H([a]))},
metadataProvider:F9,filter_clause:S9,fields:function(a,b){return $CLJS.ze($CLJS.cua.l($CLJS.H([a,b])))},dependent_metadata:function(a){return $CLJS.ze($CLJS.lf.g($CLJS.ol,$CLJS.Xya.l($CLJS.H([a]))))},available_aggregation_operators:function(a,b){return $CLJS.ze($CLJS.Eta.l($CLJS.H([a,b])))},breakouts:function(a,b){return $CLJS.ze($CLJS.Kta.l($CLJS.H([a,b])))},joinable_columns:function(a,b,c){return $CLJS.ze($CLJS.zua.l($CLJS.H([a,b,c])))},group_columns:function(a){return $CLJS.ze($CLJS.Mta.l($CLJS.H([a])))},
describe_temporal_unit:function(a,b){b="string"===typeof b?$CLJS.Ch.h(b):b;return $CLJS.gva.l($CLJS.H([a,b]))},breakout_column:function(a,b,c){return $CLJS.Uya.l($CLJS.H([a,b,c]))},joins:function(a,b){return $CLJS.ze($CLJS.Aua.l($CLJS.H([a,b])))},native_query:function(a,b,c){return $CLJS.Iua.l($CLJS.H([F9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.ze($CLJS.oua.l($CLJS.H([a,b])))},legacy_query:function(a){return $CLJS.ol.l(L9($CLJS.gZ.h(a)),$CLJS.H([$CLJS.zk,$CLJS.sB]))},stage_count:function(a){return $CLJS.dza.l($CLJS.H([a]))},
expression_clause:function(a,b,c){return $CLJS.f9.l($CLJS.H([$CLJS.Xta.l($CLJS.H([$CLJS.Ch.h(a),b,$CLJS.hA(c,$CLJS.H([$CLJS.Di,!0]))]))]))},find_matching_column:function(a,b,c,d){return $CLJS.Vya.l($CLJS.H([a,b,c,d]))},with_native_extras:function(a,b){return $CLJS.Qua.l($CLJS.H([a,$CLJS.hA(b,$CLJS.H([$CLJS.Di,!0]))]))},join_strategy:function(a){return $CLJS.yua.l($CLJS.H([a]))},picker_info:function(a,b){var c=$CLJS.nu.h(b);switch(c instanceof $CLJS.M?c.T:null){case "metadata/table":return{databaseId:$CLJS.iQ.h(a),
tableId:$CLJS.tj.h(b)};case "metadata/card":return{databaseId:$CLJS.iQ.h(a),tableId:["card__",$CLJS.p.h($CLJS.tj.h(b))].join(""),cardId:$CLJS.tj.h(b),isModel:$CLJS.RZ.h(b)};default:return a=$CLJS.eB($CLJS.Xy),$CLJS.n($CLJS.dB("metabase.lib.js",a))&&$CLJS.cB("metabase.lib.js",a,$CLJS.Ww.l($CLJS.H(["Cannot provide picker-info for",$CLJS.nu.h(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.Yta.l($CLJS.H([a,b,c]));return $CLJS.LV(function(d){if($CLJS.zd(d)&&$CLJS.E.g($CLJS.C8,$CLJS.nu.h(d))){var e=
$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.KE);var f=$CLJS.J.g(e,$CLJS.xj);e=$CLJS.J.g(e,$CLJS.dt);return{operator:$CLJS.jh(d),options:$CLJS.ol($CLJS.im(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IG,$CLJS.vo],null))),args:$CLJS.ze($CLJS.lf.g(function(k){return k instanceof $CLJS.M?$CLJS.sB(k):k},e))}}return d},a)},expression_clause_for_legacy_expression:function(a,b,c){b=$CLJS.d9.l($CLJS.H([a,b]));a=$CLJS.hg.j($CLJS.N,$CLJS.km(function(l,m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.OE);
return new $CLJS.P(null,2,5,$CLJS.Q,[l,m],null)}),b);b=$CLJS.hg.j($CLJS.N,$CLJS.km(function(l,m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[m,l],null)}),b);var d=$CLJS.pZ,e=$CLJS.oZ;$CLJS.pZ=a;$CLJS.oZ=b;try{var f=$CLJS.hA(c,$CLJS.H([$CLJS.Di,!0])),k=$CLJS.A($CLJS.VY(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF,$CLJS.pG],null),new $CLJS.P(null,1,5,$CLJS.Q,[f],null)));return $CLJS.qZ.h(k)}finally{$CLJS.oZ=e,$CLJS.pZ=d}},format_relative_date_range:function(a,
b,c,d,e){b=$CLJS.Ch.h(b);d=null==d?null:$CLJS.Ch.h(d);e=$CLJS.hA(e,$CLJS.H([$CLJS.Di,!0]));return $CLJS.O2((0,$CLJS.fJ)(),a,b,c,d,e)},aggregation_operator_columns:function(a){return $CLJS.ze($CLJS.Dta.l($CLJS.H([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.nua.l($CLJS.H([a,b,c]))},available_segments:function(a,b){return $CLJS.ze($CLJS.dva.l($CLJS.H([a,b])))},join_condition_operators:function(a,b,c,d){return $CLJS.ze($CLJS.sua.l($CLJS.H([a,b,c,d])))},aggregation_column:function(a,
b,c){return $CLJS.Tya.l($CLJS.H([a,b,c]))},expressionable_columns:function(a,b,c){return $CLJS.A0($CLJS.Ch.g("expressionable-columns",["stage-",$CLJS.p.h(b),"-",$CLJS.p.h(c)].join("")),a,function(){return $CLJS.ze($CLJS.Wta.l($CLJS.H([a,b,c])))})},filter:function(a,b,c){return $CLJS.gua.l($CLJS.H([a,b,$CLJS.hA(c,$CLJS.H([$CLJS.Di,!0]))]))},metric_metadata:function(a,b){return $CLJS.o0(a,b)},change_direction:function(a,b){return $CLJS.Uua.l($CLJS.H([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.ze($CLJS.Rta.l($CLJS.H([a,
$CLJS.Ch.h(b)])))},filter_drill_details:function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.WF);var c=$CLJS.J.g(b,$CLJS.hj),d=$CLJS.J.g(b,$CLJS.TL);b=$CLJS.J.g(b,$CLJS.Ij);return{column:c,query:a,stageIndex:d,value:$CLJS.E.g(b,$CLJS.a7)?null:b}},remove_field:function(a,b,c){return $CLJS.eua.l($CLJS.H([a,b,c]))},with_template_tags:function(a,b){return $CLJS.Nua.l($CLJS.H([a,E9(b)]))},expressions:function(a,b){return $CLJS.ze($CLJS.Vta.l($CLJS.H([a,b])))},suggested_join_conditions:function(a,b,c){return $CLJS.ze($CLJS.bza.l($CLJS.H([a,
b,c])))},with_join_strategy:function(a,b){return $CLJS.Dua.l($CLJS.H([a,b]))},order_by:function(a,b,c,d){return $CLJS.Vua.l($CLJS.H([a,b,c,$CLJS.Ch.h(d)]))},field_values_search_info:function(a,b){var c=$CLJS.Xk.j;if($CLJS.n(b)){var d=$CLJS.tj.h(b),e=$CLJS.tj.h;var f=$CLJS.U_(b)?b:null;$CLJS.n(f)?a=f:(f=$CLJS.WA(b,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mL,$CLJS.iL],null)),a=$CLJS.n(f)?$CLJS.K1(a,f):null,a=$CLJS.n(a)?a:b);a=Xza(a)?a:null;e=e.call($CLJS.tj,a);d=$CLJS.Sl(d)?d:null;e=$CLJS.Sl(e)?e:null;$CLJS.n(b)?
(b=$CLJS.O(b),a=$CLJS.J.g(b,$CLJS.h_),b=$CLJS.E.g(a,$CLJS.j_)?$CLJS.Xj:$CLJS.n(a)?a:Xza(b)?$CLJS.LZ:$CLJS.yA):b=$CLJS.yA;b=new $CLJS.h(null,3,[$CLJS.iL,d,$CLJS.Uwa,e,$CLJS.h_,b],null)}else b=null;return $CLJS.ol($CLJS.XA(c.call($CLJS.Xk,b,$CLJS.h_,$CLJS.jh),Zza))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.Ch.h(a):a;b="string"===typeof b?$CLJS.Ch.h(b):b;return $CLJS.hva.l($CLJS.H([a,b]))},breakout:function(a,b,c){return $CLJS.Ita.l($CLJS.H([a,b,$CLJS.g9.l($CLJS.H([c]))]))},
database_id:function(a){return $CLJS.Ota.l($CLJS.H([a]))},required_native_extras:function(a,b){return $CLJS.ze($CLJS.lf.g($CLJS.sB,$CLJS.Oua.l($CLJS.H([F9(a,b)]))))},remove_join:function(a,b,c){return $CLJS.ava.l($CLJS.H([a,b,c]))},joined_thing:function(a,b){return $CLJS.W3(a,b)},legacy_column__GT_metadata:X9,with_native_query:function(a,b){return $CLJS.Kua.l($CLJS.H([a,b]))},breakoutable_columns:function(a,b){return $CLJS.A0($CLJS.Ch.g("breakoutable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.ze($CLJS.Jta.l($CLJS.H([a,
b])))})},rename_join:function(a,b,c,d){return $CLJS.bva.l($CLJS.H([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.Zua.l($CLJS.H([a,b]))}};