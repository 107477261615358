var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var Efa,EE,IE,TE;
$CLJS.zE=function(a,b){return function(){function c(l,m,t){return $CLJS.Hd(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Hd(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Hd(l)}var f=null,k=function(){function l(t,u,v,x){var z=null;if(3<arguments.length){z=0;for(var C=Array(arguments.length-3);z<C.length;)C[z]=arguments[z+3],++z;z=new $CLJS.w(C,0,null)}return m.call(this,t,u,v,z)}function m(t,u,v,x){return $CLJS.Hd(function(){var z=f.j(t,u,v);return $CLJS.n(z)?$CLJS.Te(function(C){var G=a.h?a.h(C):a.call(null,C);return $CLJS.n(G)?
b.h?b.h(C):b.call(null,C):G},x):z}())}l.A=3;l.B=function(t){var u=$CLJS.A(t);t=$CLJS.B(t);var v=$CLJS.A(t);t=$CLJS.B(t);var x=$CLJS.A(t);t=$CLJS.Lc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.w(x,0,null)}return k.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.A=3;f.B=k.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};$CLJS.AE=function(a){return $CLJS.Uk.g($CLJS.lf.h(a),$CLJS.LC)};$CLJS.BE=function(a,b){a=$CLJS.lu($CLJS.ZA(a,/''/,"'"),/\{\d+\}/);return $CLJS.Ve.j($CLJS.Rt.t,$CLJS.ol(a),$CLJS.ol(b))};$CLJS.lE=new $CLJS.M("lib","metadata","lib/metadata",1798917220);$CLJS.nE=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);
$CLJS.rE=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.sE=new $CLJS.M(null,"mbql","mbql",69346710);$CLJS.CE=new $CLJS.M(null,"display-name","display-name",694513143);Efa=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.DE=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);
EE=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.FE=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);$CLJS.GE=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);$CLJS.HE=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);IE=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);
$CLJS.JE=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.KE=new $CLJS.M(null,"operator","operator",-1860875338);$CLJS.LE=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.ME=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);
$CLJS.NE=new $CLJS.M("metabase.lib.schema.common","positive-number","metabase.lib.schema.common/positive-number",2061626826);$CLJS.OE=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.PE=new $CLJS.M(null,"x","x",2099068185);$CLJS.QE=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);$CLJS.RE=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.SE=new $CLJS.M(null,"strategy","strategy",-1471631918);
TE=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.X($CLJS.HE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,new $CLJS.h(null,1,[$CLJS.Wi,1],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"non-blank string"],null),$CLJS.We($CLJS.$A)],null)],null));$CLJS.X($CLJS.LE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ki,new $CLJS.h(null,1,[$CLJS.Wi,0],null)],null));$CLJS.X($CLJS.nE,$CLJS.Tl);
$CLJS.X($CLJS.NE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"positive number"],null),$CLJS.zE($CLJS.Fl,$CLJS.Yl)],null));$CLJS.X(TE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,new $CLJS.h(null,2,[$CLJS.Wi,36,$CLJS.Fk,36],null)],null));
$CLJS.X(IE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,2,[$CLJS.Ht,"valid semantic type",$CLJS.$t,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return["Not a valid semantic type: ",$CLJS.Ph.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.MC(a,$CLJS.Kj)}],null));
$CLJS.X(EE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,2,[$CLJS.Ht,"valid relation type",$CLJS.$t,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return["Not a valid relation type: ",$CLJS.Ph.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.MC(a,$CLJS.ti)}],null));$CLJS.X($CLJS.DE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EE],null)],null));
$CLJS.X($CLJS.QE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,2,[$CLJS.Ht,"valid base type",$CLJS.$t,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return["Not a valid base type: ",$CLJS.Ph.l($CLJS.H([a]))].join("")}],null),function(a){return $CLJS.MC(a,$CLJS.Wj)}],null));
$CLJS.X($CLJS.ME,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OE,TE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KB,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.QE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.QE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rk,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,
$CLJS.DE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.HE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.HE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.HE],null)],null)],null));
$CLJS.X(Efa,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.FE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,$CLJS.tk,$CLJS.pj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Tj,$CLJS.ri],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xj,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.ME],null)],null));