var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var bga,cga,dga,ega,fga,gga,hga;$CLJS.hH=new $CLJS.M(null,"second","second",-444702010);$CLJS.iH=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);$CLJS.jH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.kH=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.lH=new $CLJS.M(null,"year-of-era","year-of-era",682445876);
bga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);cga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.pH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);
$CLJS.qH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.rH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);dga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);
ega=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);fga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);gga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);
$CLJS.kE=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);$CLJS.sH=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.tH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);
$CLJS.uH=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);hga=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var BH=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Qi,$CLJS.gk,$CLJS.ak,$CLJS.yi,$CLJS.Gk,$CLJS.Mi,$CLJS.oi,$CLJS.lH],null),CH=$CLJS.ih(BH),tfa,ufa,vfa;$CLJS.X(fga,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid date extraction unit"],null)],null),CH));var DH=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.vn,$CLJS.Fj,$CLJS.Pj,$CLJS.wi,$CLJS.oi],null),EH=$CLJS.ih(DH);
$CLJS.X(ega,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid date truncation unit"],null)],null),EH));$CLJS.FH=$CLJS.hg.j($CLJS.Ff,$CLJS.nl.o(),$CLJS.kf.g(DH,BH));tfa=$CLJS.ih($CLJS.FH);$CLJS.X($CLJS.rH,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid date bucketing unit"],null)],null),tfa));var GH=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iH,$CLJS.Cj,$CLJS.Gi],null),HH=$CLJS.ih(GH);
$CLJS.X(dga,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid time extraction unit"],null)],null),HH));var IH=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kH,$CLJS.hH,$CLJS.kj,$CLJS.Dl],null),JH=$CLJS.ih(IH);$CLJS.X(hga,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid time truncation unit"],null)],null),JH));$CLJS.KH=$CLJS.hg.j($CLJS.Ff,$CLJS.nl.o(),$CLJS.kf.g(IH,GH));ufa=$CLJS.ih($CLJS.KH);
$CLJS.X(cga,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid time bucketing unit"],null)],null),ufa));$CLJS.LH=$CLJS.hg.j($CLJS.Ff,$CLJS.nl.o(),$CLJS.kf.l(IH,DH,$CLJS.H([GH,BH])));vfa=$CLJS.ih($CLJS.LH);$CLJS.X($CLJS.jH,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid datetime bucketing unit"],null)],null),$CLJS.LH));var wfa=$CLJS.ee.g(vfa,$CLJS.mi);
$CLJS.X($CLJS.tH,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid temporal bucketing unit"],null)],null),wfa));$CLJS.MH=$CLJS.vu.g(EH,JH);$CLJS.X($CLJS.pH,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid datetime truncation unit"],null)],null),$CLJS.MH));$CLJS.NH=$CLJS.vu.g(CH,HH);$CLJS.X(bga,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid datetime extraction unit"],null)],null),$CLJS.NH));
var OH=$CLJS.ee.g(EH,$CLJS.oi);$CLJS.X($CLJS.uH,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid date interval unit"],null)],null),OH));$CLJS.X($CLJS.qH,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid time interval unit"],null)],null),JH));var xfa=$CLJS.vu.g(OH,JH);$CLJS.X($CLJS.kE,$CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.Ht,"Valid datetime interval unit"],null)],null),xfa));
$CLJS.X(gga,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.sH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zi,$CLJS.tH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null)],null));