var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var k7,m7,n7,Pqa,Qqa,Rqa,r7,s7,u7,v7,Sqa,Swa,Twa,Vwa,C7,Wwa,Xwa,Ywa;k7=function(a){var b=new $CLJS.h(null,3,[$CLJS.OE,$CLJS.p.h($CLJS.dF()),$CLJS.KB,$CLJS.KB.h(a),$CLJS.Pi,$CLJS.VA($CLJS.Pi,$CLJS.KB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HB,b,$CLJS.VA($CLJS.GE,$CLJS.T)(a)],null)};m7=function(a){return $CLJS.R.j(a,$CLJS.nu,$CLJS.a5)};n7=function(a,b){return $CLJS.E.g($CLJS.sE.h(a),$CLJS.im(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SE,$CLJS.rE,$CLJS.RE],null)))};
Pqa=function(a){return $CLJS.D1(a,new $CLJS.h(null,1,[$CLJS.SE,$CLJS.Ch],null))};
Qqa=function(a,b){var c=$CLJS.Ue(function(e){return $CLJS.QH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.eB($CLJS.Xy);if($CLJS.n($CLJS.dB("metabase.lib.field",c))){var d=$CLJS.BE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Ph.l($CLJS.H([a])),$CLJS.Ph.l($CLJS.H([$CLJS.Wk.g($CLJS.ZZ,b)]))]));d instanceof Error?$CLJS.cB("metabase.lib.field",c,$CLJS.Ww(),d):$CLJS.cB("metabase.lib.field",
c,$CLJS.Ww.l($CLJS.H([d])),null)}return null};
Rqa=function(a,b,c){if($CLJS.n(o7))return null;var d=o7;o7=!0;try{var e=$CLJS.o2(a,b),f=$CLJS.n(e)?$CLJS.WW(a,e):$CLJS.WW(a,b),k=function(){var m=$CLJS.w7.h(f);if($CLJS.n(m))return m;m=$CLJS.WA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BV,$CLJS.BW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.TV.h(f);if($CLJS.n(u))return u;u=$CLJS.sP.h(f);if($CLJS.n(u))return u;u=$CLJS.nQ.h(f);return $CLJS.n(u)?u:$CLJS.WE.h(f)}())?$CLJS.Z0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.eB($CLJS.Xy);if($CLJS.n($CLJS.dB("metabase.lib.field",
m))){var t=$CLJS.BE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Ph.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.cB("metabase.lib.field",m,$CLJS.Ww(),t):$CLJS.cB("metabase.lib.field",m,$CLJS.Ww.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?Qqa(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.L3($CLJS.Lk.l(l,$CLJS.tj,$CLJS.H([$CLJS.oL,$CLJS.bX,$CLJS.CW])),null),$CLJS.T,function(){var m=$CLJS.ZZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.$K,$CLJS.IZ):l:null}finally{o7=d}};
r7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.IP);var f=$CLJS.I(c,2,null);c=$CLJS.ll.l($CLJS.H([function(){var k=$CLJS.KB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.KB,k],null):null}(),function(){var k=$CLJS.VA($CLJS.Pi,$CLJS.KB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Pi,k],null):null}(),function(){var k=$CLJS.IR.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.bX,k],null):null}(),function(){var k=$CLJS.GG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.CW,k],null):null}(),$CLJS.Id(f)?function(){var k=$CLJS.t2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.nu,$CLJS.YK,$CLJS.T,$CLJS.p.h(f)],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.nu,$CLJS.YK,$CLJS.T,$CLJS.p.h(f)],null):function(){var k=Rqa(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.nu,$CLJS.YK,$CLJS.T,$CLJS.p.h(f)],null)}()]));return $CLJS.n(d)?$CLJS.L3(c,d):c};
s7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.CW);return $CLJS.n($CLJS.n(b)?$CLJS.Ld($CLJS.NH,b):b)?$CLJS.Oj:$CLJS.VA($CLJS.Pi,$CLJS.KB)(a)};
$CLJS.t7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.OE),l=$CLJS.J.g(f,$CLJS.KB),m=$CLJS.J.g(f,$CLJS.IR),t=$CLJS.J.g(f,$CLJS.Pi);e=$CLJS.J.g(f,$CLJS.IP);var u=$CLJS.J.g(f,$CLJS.SN),v=$CLJS.J.g(f,$CLJS.GG),x=$CLJS.ll.l;k=new $CLJS.h(null,2,[$CLJS.nu,$CLJS.YK,$CLJS.c3,k],null);f=$CLJS.CE.h(f);a=$CLJS.n(f)?f:$CLJS.q0.j(a,b,d);c=x.call($CLJS.ll,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.CE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Pi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.KB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.CW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.bX,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.nL,u):m;return $CLJS.n(e)?$CLJS.L3(u,e):u};u7=function(a,b,c){return $CLJS.n($CLJS.Ue(function(d){return $CLJS.E.g($CLJS.zi.h(d),c)},a))?$CLJS.Wk.g(function(d){var e=$CLJS.Ld(d,b)?$CLJS.Lk.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.zi.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
v7=function(a){var b=$CLJS.n($CLJS.n3.h(a))?null:function(){var e=$CLJS.$K.h(a),f=new $CLJS.dh(null,new $CLJS.h(null,3,[$CLJS.b_,null,$CLJS.i_,null,$CLJS.IZ,null],null),null);return f.h?f.h(e):f.call(null,e)}(),c=$CLJS.ll.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.OE,$CLJS.p.h($CLJS.dF()),$CLJS.KB,$CLJS.KB.h(a),$CLJS.Pi,s7(a)],null),function(){var e=function(){var f=$CLJS.Va(b);return f?(f=$CLJS.Va($CLJS.nL.h(a)))?(f=$CLJS.Sk.g($CLJS.VZ,$CLJS.$K.h(a)))?$CLJS.m_.h(a):f:f:f}();return $CLJS.n(e)?new $CLJS.h(null,
1,[$CLJS.IP,e],null):null}(),function(){var e=$CLJS.R1(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.IP,e],null):null}(),function(){var e=$CLJS.CW.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.GG,e],null):null}(),function(){var e=$CLJS.aX.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.aX,e],null):null}(),function(){var e=$CLJS.bX.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.IR,e],null):null}(),function(){var e=$CLJS.nL.h(a);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.SN,e],null):null}()])),
d=($CLJS.n(b)?$CLJS.VA($CLJS.ZZ,$CLJS.T):$CLJS.VA($CLJS.tj,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,c,d],null)};Sqa=function(a,b){return $CLJS.of(function(c){return $CLJS.E.g($CLJS.$K.h(c),$CLJS.TZ)},$CLJS.Z0.v(a,b,$CLJS.WW(a,b),new $CLJS.h(null,3,[$CLJS.t1,!1,$CLJS.x1,!0,$CLJS.r1,!1],null)))};Swa=new $CLJS.M("lib","simple-name","lib/simple-name",-734346338);Twa=new $CLJS.M("metabase.lib.field","field-values-search-info","metabase.lib.field/field-values-search-info",-468454179);
$CLJS.Uwa=new $CLJS.M(null,"search-field-id","search-field-id",-699326724);Vwa=new $CLJS.M("metabase.lib.field","field-values-search-info.has-field-values","metabase.lib.field/field-values-search-info.has-field-values",1753198226);C7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.w7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);Wwa=new $CLJS.M("metabase.lib.field","simple-display-name","metabase.lib.field/simple-display-name",494797345);
Xwa=new $CLJS.M(null,"earliest","earliest",-1928154382);Ywa=new $CLJS.M(null,"latest","latest",24323665);$CLJS.E1.m(null,$CLJS.AG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ch.h(b),$CLJS.D1(c,new $CLJS.h(null,2,[$CLJS.GG,$CLJS.Ch,$CLJS.IR,Pqa],null)),a],null)});
var o7=!1,x7=function x7(a,b){var d=$CLJS.K1(a,C7.h(b));a=$CLJS.n(C7.h(d))?x7.g?x7.g(a,d):x7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T),f=$CLJS.J.g(a,$CLJS.CE);return $CLJS.Xk.j($CLJS.R.j($CLJS.Xk.j($CLJS.R.j(b,Swa,$CLJS.T.h(b)),$CLJS.T,function(k){return[$CLJS.p.h(e),".",$CLJS.p.h(k)].join("")}),Wwa,$CLJS.CE.h(b)),$CLJS.CE,function(k){return[$CLJS.p.h(f),": ",$CLJS.p.h(k)].join("")})};$CLJS.S0.m(null,$CLJS.YK,function(a,b,c){return s7(c)});
$CLJS.S0.m(null,$CLJS.AG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.GG);$CLJS.I(c,2,null);c=r7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.CW,d):c;return $CLJS.T0.j(a,b,c)});$CLJS.U0.m(null,$CLJS.YK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.U0.m(null,$CLJS.AG,function(a,b,c){var d=r7(a,b,c);b=$CLJS.t7(a,b,d,c);return $CLJS.n(C7.h(b))?x7(a,b):b});
$CLJS.O0.m(null,$CLJS.YK,function(a,b,c,d){function e(ca){var ka=$CLJS.u5(l,k);return $CLJS.ha.j?$CLJS.ha.j("%s: %s",ca,ka):$CLJS.ha.call(null,"%s: %s",ca,ka)}function f(ca){var ka=$CLJS.vB($CLJS.ZA($CLJS.jh(u),"-"," "));return $CLJS.ha.j?$CLJS.ha.j("%s: %s",ca,ka):$CLJS.ha.call(null,"%s: %s",ca,ka)}var k=$CLJS.O(c),l=$CLJS.J.g(k,$CLJS.bX),m=$CLJS.J.g(k,$CLJS.nL),t=$CLJS.J.g(k,$CLJS.oL),u=$CLJS.J.g(k,$CLJS.zi),v=$CLJS.J.g(k,$CLJS.m_),x=$CLJS.J.g(k,Wwa),z=$CLJS.J.g(k,$CLJS.T),C=$CLJS.J.g(k,$CLJS.Jva),
G=$CLJS.J.g(k,$CLJS.CE),K=$CLJS.J.g(k,C7),S=$CLJS.P1.g($CLJS.m3,z);c=function(){if($CLJS.n(x))return x;if($CLJS.n($CLJS.n(K)?null==G||$CLJS.E.g(G,S):K)){a:{var ca=$CLJS.O(k);var ka=$CLJS.J.g(ca,$CLJS.CE);ca=$CLJS.J.g(ca,C7);for(ka=new $CLJS.be(null,ka,null,1,null);;)if($CLJS.n(ca)){ca=$CLJS.K1(a,ca);var wa=$CLJS.O(ca);ca=$CLJS.J.g(wa,$CLJS.CE);wa=$CLJS.J.g(wa,C7);ka=$CLJS.ee.g(ka,ca);ca=wa}else break a}ka=$CLJS.Te($CLJS.Wa,ka)?$CLJS.Xt(": ",ka):null}else ka=null;return $CLJS.n(ka)?ka:$CLJS.n(G)?G:
"string"===typeof z?S:$CLJS.p.h(z)}();var Z=$CLJS.E.g(d,$CLJS.D0)&&-1==c.indexOf(" → ")?function(){if($CLJS.n(m)){var ca=$CLJS.K1(a,m);$CLJS.n(ca)?ca=$CLJS.nra($CLJS.CE.h($CLJS.t0.j(a,b,ca))):(ca=$CLJS.Q1(a,t),ca=$CLJS.q0.v(a,b,ca,d))}else ca=null;return $CLJS.n(ca)?ca:$CLJS.n(v)?v:$CLJS.R1(k)}():null;c=$CLJS.n(Z)?[$CLJS.p.h(Z)," → ",$CLJS.p.h(c)].join(""):c;return $CLJS.n(function(){var ca=$CLJS.Sk.g(d,$CLJS.D0);return ca?C:ca}())?c:$CLJS.n($CLJS.n(u)?$CLJS.Sk.g(c,f(S)):u)?f(c):$CLJS.n($CLJS.n(l)?
$CLJS.Sk.g(c,e(S)):l)?e(c):c});$CLJS.O0.m(null,$CLJS.AG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.IR);var k=$CLJS.J.g(f,$CLJS.IP),l=$CLJS.J.g(f,$CLJS.GG);f=$CLJS.J.g(f,$CLJS.SN);$CLJS.I(c,2,null);c=r7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.m_,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.zi,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.bX,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.nL,f):e;return $CLJS.n(e)?$CLJS.q0.v(a,b,e,d):$CLJS.eF("[Unknown Field]")});
$CLJS.P0.m(null,$CLJS.YK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.P0.m(null,$CLJS.AG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=r7(a,b,c);return $CLJS.n(c)?$CLJS.Q0.j(a,b,c):"unknown_field"});
$CLJS.V0.m(null,$CLJS.YK,function(a,b,c){return $CLJS.ll.l($CLJS.H([function(){var d=$CLJS.ei($CLJS.V0,$CLJS.mi);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),new $CLJS.h(null,2,[$CLJS.CE,$CLJS.q0.j(a,b,c),$CLJS.E0,$CLJS.q0.v(a,b,c,$CLJS.D0)],null),$CLJS.E.g($CLJS.$K.h(c),$CLJS.b_)?function(){var d=$CLJS.l_.h(c);return $CLJS.n(d)?(d=$CLJS.n0(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.un,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.CE,$CLJS.T.h(d)],null)],null):null):null}():null]))});
$CLJS.i1.m(null,$CLJS.AG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.GG.h(b)});$CLJS.i1.m(null,$CLJS.YK,function(a){return $CLJS.CW.h(a)});
$CLJS.h1.m(null,$CLJS.AG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Ld($CLJS.NH,b),e=$CLJS.KC($CLJS.aX,$CLJS.Pi,$CLJS.KB)(c);c=$CLJS.R.l(c,$CLJS.GG,b,$CLJS.H([$CLJS.Pi,d?$CLJS.Oj:e,$CLJS.aX,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,c,a],null)}b=$CLJS.aX.h(c);c=$CLJS.n(b)?$CLJS.Lk.g($CLJS.R.j(c,$CLJS.Pi,b),$CLJS.aX):c;c=$CLJS.Lk.g(c,$CLJS.GG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,c,a],null)});
$CLJS.h1.m(null,$CLJS.YK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.CW,b,$CLJS.H([$CLJS.aX,$CLJS.KC($CLJS.aX,$CLJS.Pi,$CLJS.KB)(a)])):$CLJS.Lk.l(a,$CLJS.CW,$CLJS.H([$CLJS.aX]))});$CLJS.k1.m(null,$CLJS.AG,function(a,b,c){return $CLJS.l1.j(a,b,r7(a,b,c))});
$CLJS.k1.m(null,$CLJS.YK,function(a,b,c){if($CLJS.Sk.g($CLJS.$K.h(c),$CLJS.TZ)){a=$CLJS.VA($CLJS.Pi,$CLJS.KB)(c);b=null==c?null:$CLJS.fO.h(c);if(null==b)var d=null;else try{var e=$CLJS.qo.h($CLJS.rj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,Xwa),l=$CLJS.J.g(f,Ywa),m=$CLJS.lna.l($CLJS.H([$CLJS.f1.h(k),$CLJS.f1.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.is.g?$CLJS.is.g(1,m):$CLJS.is.call(null,1,m))?$CLJS.kj:$CLJS.n($CLJS.is.g?$CLJS.is.g(31,m):$CLJS.is.call(null,31,m))?$CLJS.vn:$CLJS.n($CLJS.is.g?
$CLJS.is.g(365,m):$CLJS.is.call(null,365,m))?$CLJS.Fj:$CLJS.Pj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.MC(a,$CLJS.qo)?$CLJS.tna:$CLJS.MC(a,$CLJS.no)?$CLJS.sna:$CLJS.MC(a,$CLJS.yo)?$CLJS.rna:$CLJS.Ff;d=$CLJS.n(d)?u7(e,$CLJS.mi,d):e;return $CLJS.n($CLJS.CW.h(c))?u7(d,$CLJS.J0,$CLJS.CW.h(c)):d}return $CLJS.Ff});
$CLJS.x5.m(null,$CLJS.AG,function(a){var b=null==a?null:$CLJS.aF(a);b=null==b?null:$CLJS.IR.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.nu,$CLJS.d6,$CLJS.H([$CLJS.c6,function(c,d){return r7(c,d,a)}]))});$CLJS.x5.m(null,$CLJS.YK,function(a){var b=null==a?null:$CLJS.bX.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.nu,$CLJS.d6,$CLJS.H([$CLJS.c6,$CLJS.Xe(a)]))});$CLJS.r5.m(null,$CLJS.AG,function(a,b){return $CLJS.bF(a,$CLJS.$E,$CLJS.H([$CLJS.IR,b]))});
$CLJS.r5.m(null,$CLJS.YK,function(a,b){return $CLJS.$E(a,$CLJS.bX,b)});$CLJS.y5.m(null,$CLJS.AG,function(a,b,c){return $CLJS.z5.j(a,b,r7(a,b,c))});
$CLJS.y5.m(null,$CLJS.YK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Pi);var d=$CLJS.J.g(b,$CLJS.fO),e=$CLJS.J.g(b,$CLJS.rk);if($CLJS.Sk.g($CLJS.$K.h(b),$CLJS.TZ)){var f=function(){var m=null==a?null:$CLJS.l3(a);m=null==m?null:$CLJS.zA.h(m);return null==m?null:$CLJS.Ld(m,$CLJS.IR)}(),k=$CLJS.WA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rj,$CLJS.ok],null)),l=$CLJS.l7(b);return function u(t){return new $CLJS.qe(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Cd(v)){var x=$CLJS.lc(v),z=$CLJS.D(x),
C=$CLJS.te(z);return function(){for(var K=0;;)if(K<z){var S=$CLJS.md(x,K),Z=C,ca=S;S=n7(S,l)?$CLJS.R.j(ca,$CLJS.J0,!0):ca;Z.add(S);K+=1}else return!0}()?$CLJS.we($CLJS.ye(C),u($CLJS.mc(v))):$CLJS.we($CLJS.ye(C),null)}var G=$CLJS.A(v);return $CLJS.he(function(){var K=G;return n7(G,l)?$CLJS.R.j(K,$CLJS.J0,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Va(function(){if($CLJS.n(f)){var t=$CLJS.Wi.h(k);return $CLJS.n(t)?$CLJS.Fk.h(k):t}return f}())?null:$CLJS.MC(e,$CLJS.Sj)?$CLJS.Wk.g(m7,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.t5(),new $CLJS.h(null,2,[$CLJS.CE,$CLJS.eF("Bin every 0.1 degrees"),$CLJS.sE,new $CLJS.h(null,2,[$CLJS.SE,$CLJS.RE,$CLJS.RE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.CE,$CLJS.eF("Bin every 1 degree"),$CLJS.sE,new $CLJS.h(null,2,[$CLJS.SE,$CLJS.RE,$CLJS.RE,1],null)],null),new $CLJS.h(null,2,[$CLJS.CE,$CLJS.eF("Bin every 10 degrees"),$CLJS.sE,new $CLJS.h(null,2,[$CLJS.SE,$CLJS.RE,$CLJS.RE,10],null)],null),new $CLJS.h(null,2,[$CLJS.CE,$CLJS.eF("Bin every 20 degrees"),$CLJS.sE,new $CLJS.h(null,
2,[$CLJS.SE,$CLJS.RE,$CLJS.RE,20],null)],null)],null)):$CLJS.MC(c,$CLJS.ok)&&!$CLJS.MC(e,$CLJS.ti)?$CLJS.Wk.g(m7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.t5(),new $CLJS.h(null,2,[$CLJS.CE,$CLJS.eF("10 bins"),$CLJS.sE,new $CLJS.h(null,2,[$CLJS.SE,$CLJS.rE,$CLJS.rE,10],null)],null),new $CLJS.h(null,2,[$CLJS.CE,$CLJS.eF("50 bins"),$CLJS.sE,new $CLJS.h(null,2,[$CLJS.SE,$CLJS.rE,$CLJS.rE,50],null)],null),new $CLJS.h(null,2,[$CLJS.CE,$CLJS.eF("100 bins"),$CLJS.sE,new $CLJS.h(null,2,[$CLJS.SE,$CLJS.rE,$CLJS.rE,
100],null)],null)],null)):null)}return $CLJS.Ff});$CLJS.FW.m(null,$CLJS.AG,function(a){return a});
$CLJS.FW.m(null,$CLJS.YK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.$K);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,3,[$CLJS.OE,$CLJS.p.h($CLJS.dF()),$CLJS.Pi,$CLJS.VA($CLJS.Pi,$CLJS.KB)(a),$CLJS.OL,$CLJS.T.h(a)],null),a=$CLJS.c3.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,b,a],null);case "source/expressions":return k7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.GE.h(a))?k7(a):v7(a);default:return v7(a)}});
$CLJS.Vqa=function(){function a(e,f,k){k=$CLJS.Ie($CLJS.Wk.g($CLJS.HW,k));var l=Sqa(e,f),m=$CLJS.hg.j($CLJS.eh,$CLJS.am(function(t){return $CLJS.z2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Ff);m=$CLJS.Vk.g(m,l);k=$CLJS.n(k)?$CLJS.hg.j(k,$CLJS.lf.h($CLJS.HW),m):null;return $CLJS.gX.l(e,f,$CLJS.$E,$CLJS.H([$CLJS.WE,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.y7=function(){function a(d,e){return $CLJS.WE.h($CLJS.WW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Wqa=function(){function a(d,e){var f=$CLJS.Z0.v(d,e,$CLJS.WW(d,e),new $CLJS.h(null,3,[$CLJS.t1,!1,$CLJS.x1,!1,$CLJS.r1,!1],null)),k=$CLJS.y7.g(d,e);return $CLJS.ud(k)?$CLJS.Wk.g(function(l){return $CLJS.R.j(l,$CLJS.$Z,!0)},f):$CLJS.J3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.X(Vwa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rs,$CLJS.Xj,$CLJS.LZ,$CLJS.yA],null));
$CLJS.X(Twa,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Uwa,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.h_,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,Vwa],null)],null)],null));