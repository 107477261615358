var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./metabase.mbql.util.js");require("./cljs.core.match.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var mX,oX,qX,rX,Uka,tX,uX,lX,vX,eX,Vka,Wka,Xka,Yka,yX,zX,BX,CX,DX,EX,FX,GX,$ka,ala,JX,bla,KX,cla,MX,dla,ela,fla,gla,hla,iX;$CLJS.jX=function(a,b){if("string"===typeof b)return iX(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.kX=function(a,b,c){var d=$CLJS.jm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Gb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
mX=function(a){var b=lX;return $CLJS.aB(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};$CLJS.Esa=function(a,b){return $CLJS.aB(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};oX=function(a){if($CLJS.od(a))return a;throw $CLJS.li("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Ti,a],null));};
$CLJS.pX=function(a,b,c){return $CLJS.zd(c)?$CLJS.hg.g(c,function(){return function f(e){return new $CLJS.qe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.md(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,Z=G;G=$CLJS.ee.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[Z,G],null));z+=1}else return!0}()?$CLJS.we($CLJS.ye(t),f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.ee.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.yd(c)?$CLJS.Wk.g($CLJS.Ye(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.ee.g(b,$CLJS.A(c)):b),c):c};qX=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.le(a):b;return $CLJS.n(b)?[$CLJS.le(a),"/",$CLJS.jh(a)].join(""):$CLJS.jh(a)};
rX=function(a,b){var c=$CLJS.yd(b)&&!$CLJS.Cf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.vd(a)?(b=$CLJS.A(b),a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
Uka=function(a,b){return $CLJS.mf(function(c){var d=$CLJS.of;a:try{if($CLJS.Bd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.al.j(c,0,1);if($CLJS.Bd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ye($CLJS.E,a)(f))){var k=$CLJS.al.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
tX=function(a,b){for(;;)switch(b=$CLJS.nl.h($CLJS.of($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),sX.h?sX.h(a):sX.call(null,a);default:if($CLJS.n($CLJS.Ue($CLJS.Ye(rX,a),b))){var c=a;b=Uka(a,b);a=c}else{c=$CLJS.lf.g(sX,b);if($CLJS.E.g(c,b))return $CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};uX=function(a){var b=$CLJS.yd(a);return b?(b=!$CLJS.Cf(a))?$CLJS.VA($CLJS.je,$CLJS.Ya)($CLJS.A(a)):b:b};
lX=function(a){return $CLJS.n($CLJS.VA($CLJS.je,$CLJS.Ya)(a))?$CLJS.Ch.h($CLJS.ZA(qX(a).toLowerCase(),/_/,"-")):a};vX=function(a,b){var c=uX(b);return $CLJS.n(c)?(b=lX($CLJS.A(b)),$CLJS.vd(a)?(a=$CLJS.ih(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};
eX=function(a){a=wX.g?wX.g(a,dX):wX.call(null,a,dX);var b=$CLJS.n($CLJS.KB.h(a))?$CLJS.Xk.j(a,$CLJS.KB,$CLJS.Ch):a;b=$CLJS.n($CLJS.GG.h(a))?$CLJS.Xk.j(b,$CLJS.GG,$CLJS.Ch):b;return $CLJS.n($CLJS.IR.h(a))?$CLJS.Xk.j(b,$CLJS.IR,function(c){return $CLJS.n($CLJS.SE.h(c))?$CLJS.Xk.j(c,$CLJS.SE,$CLJS.Ch):c}):b};
Vka=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.mi,$CLJS.Xd,$CLJS.rj,lX,$CLJS.dO,lX],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.He([a,b]);b=wX.g?wX.g(b,dX):wX.call(null,b,dX);return d.call(c,b,a)})};
Wka=function(a){a=$CLJS.hg.j($CLJS.N,$CLJS.lf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=lX(c);var d=Vka(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.rj.h(a),$CLJS.DF)&&$CLJS.Va($CLJS.dO.h(a))?$CLJS.R.j(a,$CLJS.dO,$CLJS.SG):a};Xka=function(a){return $CLJS.hg.j($CLJS.N,$CLJS.lf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=qX(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(Wka(b),$CLJS.T,c)],null)}),a)};
Yka=function(a){a=wX.h?wX.h(a):wX.call(null,a);return nX.h?nX.h(a):nX.call(null,a)};yX=function(a){return $CLJS.kX($CLJS.kX($CLJS.db(function(b,c){return $CLJS.kX(b,c,$CLJS.Ch)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.uD,$CLJS.efa,$CLJS.RO,fma,$CLJS.tA,$CLJS.zi],null)),$CLJS.vC,Yka),$CLJS.fO,$CLJS.MV)};zX=function(a){return $CLJS.Id(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,a,null],null):a};BX=function(a){return AX.h(zX(a))};
CX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return sX($CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.lf.g(AX,b)))};DX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,BX(b)],null),$CLJS.lf.g(AX,c))};EX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,BX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
FX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,BX(a)],null)};GX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,AX.h(a)],null)};$ka=function(a){return $CLJS.Gf($CLJS.gm(nX,IX(a)))};ala=function(a){for(;;)if($CLJS.n(uX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Ie($CLJS.Wk.g(zX,a))};
JX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.DF);return $CLJS.n(b)?$CLJS.Xk.j(a,$CLJS.DF,AX):a};
bla=function(a){return $CLJS.hg.g($CLJS.N,function(){return function d(c){return new $CLJS.qe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.te(k);a:for(var m=0;;)if(m<k){var t=$CLJS.md(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,JX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.we($CLJS.ye(l),d($CLJS.mc(e))):$CLJS.we($CLJS.ye(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.he(new $CLJS.P(null,
2,5,$CLJS.Q,[l,JX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};KX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.UQ);return $CLJS.n(b)?$CLJS.Xk.j(a,$CLJS.UQ,bla):a};cla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.xQ);b=$CLJS.Va(a)?LX.h?LX.h(b):LX.call(null,b):b;return $CLJS.n(a)?KX(b):b};MX=function(a){return $CLJS.vd(a)?$CLJS.y(a):null!=a};
dla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.WF);var c=$CLJS.J.g(b,$CLJS.IN),d=$CLJS.J.g(b,$CLJS.eP),e=$CLJS.J.g(b,$CLJS.xQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.eP);var l=$CLJS.mM($CLJS.Lk.g(f,$CLJS.eP),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF,$CLJS.eP],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Xk.j(l,$CLJS.WF,LX):l,t=$CLJS.n(c)?$CLJS.Xk.j(m,$CLJS.IN,$CLJS.Ye($CLJS.Wk,nX)):m,u=$CLJS.n(e)?$CLJS.Xk.j(t,$CLJS.xQ,KX):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.xQ);return $CLJS.n(x)?$CLJS.R.j(nX($CLJS.Lk.g(v,
$CLJS.xQ)),$CLJS.xQ,x):nX(v)}catch(z){if(z instanceof Error)throw m=z,$CLJS.ki($CLJS.BE("Error canonicalizing query: {0}",$CLJS.H([$CLJS.JV(m)])),new $CLJS.h(null,1,[$CLJS.WF,a],null),m);throw z;}};
ela=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.WF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.oG);c=$CLJS.J.g(c,$CLJS.WE);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.hg.j($CLJS.eh,$CLJS.LC,$CLJS.y($CLJS.of($CLJS.Wa,function k(e,f){try{if($CLJS.Bd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.ke(l,$CLJS.AG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,m,$CLJS.Lk.g(t,$CLJS.GG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.RV(k,e,f);throw l;}throw u;}}($CLJS.Ff,b)))),$CLJS.NX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WF,$CLJS.WE],null),$CLJS.Uk.g($CLJS.Gf,$CLJS.Ye($CLJS.Vk,b)))):a};
fla=function(a){try{return ela(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.ki($CLJS.eF("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.WF,a],null),b);}throw c;}};
gla=function(a,b){var c=$CLJS.hg.g($CLJS.ld(a),function(){return function f(e){return new $CLJS.qe(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.md(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.ee.g(b,G);C=OX.g?OX.g(C,K):OX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.we($CLJS.ye(t),f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.ee.g(b,v);return OX.g?OX.g(z,C):OX.call(null,z,C)}();if(null!=u)return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};hla=function(a,b){a=$CLJS.Wk.g(function(c){var d=$CLJS.ee.g(b,fX);return OX.g?OX.g(c,d):OX.call(null,c,d)},a);return $CLJS.n($CLJS.Ue($CLJS.Wa,a))?a:null};
iX=function iX(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Gf(d);return $CLJS.he(f,new $CLJS.qe(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),iX.g?iX.g(a,k):iX.call(null,a,k)):null},null,null))};
$CLJS.NX=function NX(a){switch(arguments.length){case 3:return NX.j(arguments[0],arguments[1],arguments[2]);case 4:return NX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return NX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return NX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return NX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.NX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.NX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.NX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.NX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.NX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.NX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.NX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.NX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.NX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Ve.l($CLJS.NX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Ve.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.NX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.NX.A=6;
var sX=function sX(a){for(;;){if($CLJS.zd(a))return $CLJS.Wt(a,sX);if($CLJS.Gd(a))a=$CLJS.Gf(a);else if($CLJS.Bd(a)){if($CLJS.Va($CLJS.Ue($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Ql(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.jd(m);continue;case "and":return tX($CLJS.As,$CLJS.lf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.Ds,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return tX($CLJS.Ks,$CLJS.lf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ds,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return tX($CLJS.Ks,t);case "or":return tX($CLJS.As,t);default:return $CLJS.Wk.g(sX,a)}}else return a}},gma=new $CLJS.M(null,"value_field","value_field",-980977878),xX=new $CLJS.M(null,"ascending","ascending",-988350486),
HX=new $CLJS.M(null,"named","named",-422393479),PX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),QX=new $CLJS.M(null,"descending","descending",-24766135),RX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),hma=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),SX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),TX=new $CLJS.M(null,"viz-settings","viz-settings",256055379),ima=new $CLJS.M(null,"rows","rows",850049680),jma=
new $CLJS.M(null,"special-fn","special-fn",1290649344),fX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),dX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),kma=new $CLJS.M(null,"label_field","label_field",-1573182765),lma=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),mma=new $CLJS.M(null,"joined-field","joined-field",-2048778268),fma=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var YX,nma=$CLJS.$e($CLJS.N),oma=$CLJS.$e($CLJS.N),pma=$CLJS.$e($CLJS.N),qma=$CLJS.$e($CLJS.N),rma=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));YX=new $CLJS.hi($CLJS.Ih.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Uk.g(lX,$CLJS.A),rma,nma,oma,pma,qma);YX.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pG,b],null);return null!=a?$CLJS.ee.g(b,a):b});
YX.m(null,$CLJS.HB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HB,b instanceof $CLJS.M?qX(b):b],null);a=$CLJS.Ie($CLJS.Esa(new $CLJS.dh(null,new $CLJS.h(null,3,[$CLJS.KB,null,$CLJS.GG,null,$CLJS.IR,null],null),null),eX(a)));return $CLJS.n(a)?$CLJS.ee.g(b,a):b});
YX.m(null,$CLJS.EV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.ee.g(YX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EV,wX.g?wX.g(b,dX):wX.call(null,b,dX),lX(c)],null)});YX.m(null,$CLJS.AG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,b,eX(a)],null)});
YX.m(null,SX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[SX,b instanceof $CLJS.M?qX(b):b,$CLJS.Ch.h(a)],null)});YX.m(null,RX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[RX,wX.g?wX.g(b,dX):wX.call(null,b,dX),$CLJS.pt,lX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[RX,wX.g?wX.g(b,dX):wX.call(null,b,dX),lX(c)],null)});
YX.m(null,$CLJS.tC,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.ee.g(YX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tC,b,c,d],null)),wX.g?wX.g(a,dX):wX.call(null,a,dX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.tC,wX.g?wX.g(b,dX):wX.call(null,b,dX),$CLJS.Id(c)?c:lX(c),lX(d)],null)});
YX.m(null,$CLJS.lC,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lC,b,lX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lC,$CLJS.iw],null)});YX.m(null,$CLJS.kC,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kC,b,lX(a)],null)});
YX.m(null,$CLJS.rC,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rC,wX.g?wX.g(b,dX):wX.call(null,b,dX),c,lX(a)],null)});YX.m(null,$CLJS.uC,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.uC,wX.g?wX.g(b,dX):wX.call(null,b,dX),c,lX(a)],null)});
YX.m(null,$CLJS.pC,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pC,wX.g?wX.g(b,dX):wX.call(null,b,dX),lX(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pC,wX.g?wX.g(b,dX):wX.call(null,b,dX)],null)});
YX.m(null,$CLJS.sC,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.sC,wX.g?wX.g(b,dX):wX.call(null,b,dX),lX(c),lX(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sC,wX.g?wX.g(b,dX):wX.call(null,b,dX),lX(c)],null)});
YX.m(null,$CLJS.fC,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fC,wX.g?wX.g(b,dX):wX.call(null,b,dX),wX.g?wX.g(c,dX):wX.call(null,c,dX),lX(a)],null)});YX.m(null,$CLJS.Ij,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,b,a],null)});
YX.m(null,$CLJS.mi,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.hg.j(new $CLJS.P(null,1,5,$CLJS.Q,[lX(a)],null),$CLJS.lf.h(function(c){return wX.g?wX.g(c,dX):wX.call(null,c,dX)}),b)});
var ZX=function ZX(a){if($CLJS.n($CLJS.VA($CLJS.je,$CLJS.Ya)(a))){var c=lX(a);var d=new $CLJS.dh(null,new $CLJS.h(null,18,[$CLJS.Wi,null,$CLJS.ls,null,$CLJS.OG,null,$CLJS.JG,null,$CLJS.rt,null,$CLJS.BG,null,$CLJS.TG,null,$CLJS.UG,null,$CLJS.eG,null,$CLJS.lG,null,$CLJS.js,null,$CLJS.fG,null,$CLJS.NG,null,$CLJS.HG,null,$CLJS.Fk,null,$CLJS.iy,null,$CLJS.uG,null,$CLJS.MG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(uX(a))?(a=$CLJS.A(a),ZX.h?ZX.h(a):ZX.call(null,
a)):null},ula=new $CLJS.h(null,8,[$CLJS.rj,lX,$CLJS.xQ,function(a){a=mX(a);return $CLJS.y($CLJS.UQ.h(a))?$CLJS.Xk.j(a,$CLJS.UQ,Xka):a},$CLJS.WF,new $CLJS.h(null,6,[$CLJS.pG,function $X(a){if($CLJS.n($CLJS.VA($CLJS.je,$CLJS.Ya)(a)))return lX(a);if($CLJS.n(vX(HX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[HX,$X.h?$X.h(a):$X.call(null,a)],null),c)}return $CLJS.n($CLJS.n(uX(a))?ZX($CLJS.jd(a)):null)?$CLJS.Wk.g($X,a):wX.g?wX.g(a,
dX):wX.call(null,a,dX)},$CLJS.nQ,function(a){return $CLJS.hg.g($CLJS.N,function(){return function d(c){return new $CLJS.qe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.te(k);a:for(var m=0;;)if(m<k){var t=$CLJS.md(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[qX(u),wX.g?wX.g(t,dX):wX.call(null,t,dX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.we($CLJS.ye(l),d($CLJS.mc(e))):$CLJS.we($CLJS.ye(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[qX(l),wX.g?wX.g(f,dX):wX.call(null,f,dX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.cS,function(a){return $CLJS.Gf(function(){return function d(c){return new $CLJS.qe(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Cd(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.te(k);a:for(var m=0;;)if(m<k){var t=$CLJS.md(f,m);t=$CLJS.n(uX(t))?YX.h(t):$CLJS.fe(YX.h($CLJS.fe(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.we($CLJS.ye(l),d($CLJS.mc(e))):$CLJS.we($CLJS.ye(l),null)}l=$CLJS.A(e);return $CLJS.he($CLJS.n(uX(l))?YX.h(l):$CLJS.fe(YX.h($CLJS.fe(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.WQ,function(a){a=mX(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.xQ);if($CLJS.n(b))return a=$CLJS.QV(a,new $CLJS.h(null,1,[$CLJS.xQ,$CLJS.WF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xQ],null),a=wX.g?wX.g(a,b):wX.call(null,a,b),$CLJS.QV(a,new $CLJS.h(null,1,[$CLJS.WF,$CLJS.xQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WF],null);return wX.g?wX.g(a,b):wX.call(null,a,b)},$CLJS.eP,new $CLJS.h(null,1,[fX,yX],null),$CLJS.RN,new $CLJS.h(null,1,[fX,function(a){a=wX.g?wX.g(a,$CLJS.WF):wX.call(null,a,$CLJS.WF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.SE),d=$CLJS.J.g(b,$CLJS.WE);a=$CLJS.J.g(b,$CLJS.lF);b=$CLJS.n(c)?$CLJS.Xk.j(b,$CLJS.SE,lX):b;d=$CLJS.n($CLJS.VA($CLJS.je,$CLJS.Ya)(d))?$CLJS.Xk.j(b,$CLJS.WE,lX):b;return $CLJS.n(a)?$CLJS.Xk.j(d,$CLJS.lF,qX):d}],null)],null),$CLJS.Zy,
new $CLJS.h(null,1,[$CLJS.CO,$CLJS.Xd],null),$CLJS.IN,new $CLJS.h(null,1,[fX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.rj),d=$CLJS.J.g(b,$CLJS.BJ),e=$CLJS.J.g(b,$CLJS.tj);a=$CLJS.J.g(b,PX);b=$CLJS.n(e)?$CLJS.Xk.j(b,$CLJS.tj,qX):b;c=$CLJS.n(c)?$CLJS.Xk.j(b,$CLJS.rj,lX):b;d=$CLJS.n(d)?$CLJS.Xk.j(c,$CLJS.BJ,function(f){return wX.g?wX.g(f,dX):wX.call(null,f,dX)}):c;d=$CLJS.n(a)?$CLJS.NX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[PX,kma],null),function(f){return wX.g?wX.g(f,dX):wX.call(null,f,dX)}):d;return $CLJS.n(a)?
$CLJS.NX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[PX,gma],null),function(f){return wX.g?wX.g(f,dX):wX.call(null,f,dX)}):d}],null),$CLJS.DN,function(a){return null==a?null:lX(a)},$CLJS.eP,new $CLJS.h(null,1,[fX,yX],null),TX,lX],null),wX=function wX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return wX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
wX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Gf(b);b=$CLJS.y(c)?$CLJS.WA(ula,c):null;try{return $CLJS.od(b)?b.h?b.h(a):b.call(null,a):$CLJS.Ad(a)?a:$CLJS.zd(a)?$CLJS.hg.g($CLJS.N,function(){return function k(f){return new $CLJS.qe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Cd(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.te(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=lX(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,wX.l(x,$CLJS.H([$CLJS.ee.g($CLJS.Gf(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.we($CLJS.ye(u),k($CLJS.mc(l))):$CLJS.we($CLJS.ye(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=lX(u);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[u,wX.l(m,$CLJS.H([$CLJS.ee.g($CLJS.Gf(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(uX(a))?YX.h(a):$CLJS.yd(a)?$CLJS.Wk.g(function(e){return wX.l(e,$CLJS.H([$CLJS.ee.g($CLJS.Gf(c),fX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.ki($CLJS.BE("Error normalizing form: {0}",$CLJS.H([$CLJS.JV(d)])),new $CLJS.h(null,3,[$CLJS.mj,a,$CLJS.tl,c,jma,b],null),d);}throw e;}};wX.A=1;wX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var AX=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(uX(f))?$CLJS.A(f):null},e,a,b,c,d)}();AX.m(null,$CLJS.mi,function(a){return a});
AX.m(null,$CLJS.AG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(vX($CLJS.AG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return AX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,c,$CLJS.Ie($CLJS.ll.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,b,$CLJS.Ie(a)],null)});
AX.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.ud(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pG,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,b,a],null)});AX.m(null,$CLJS.iL,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(uX(a))?AX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,a,null],null)});
AX.m(null,SX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,b,new $CLJS.h(null,1,[$CLJS.KB,a],null)],null)});AX.m(null,lma,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=BX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=BX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,a,$CLJS.R.j(c,$CLJS.SN,b)],null)});
AX.m(null,mma,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=BX(a);return $CLJS.Ve.v($CLJS.XV,a,$CLJS.R,$CLJS.H([$CLJS.IP,b]))});
AX.m(null,RX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=BX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.KB);$CLJS.Va(d)||$CLJS.bT.g(d,b)?a=$CLJS.Ve.v($CLJS.XV,a,$CLJS.R,$CLJS.H([$CLJS.GG,b])):(c=$CLJS.eB($CLJS.Xy),$CLJS.n($CLJS.dB("metabase.mbql.util",c))&&(b=$CLJS.BE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Ph.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.cB("metabase.mbql.util",c,$CLJS.Ww(),b):$CLJS.cB("metabase.mbql.util",c,$CLJS.Ww.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),AX.h(new $CLJS.P(null,3,5,$CLJS.Q,[RX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
AX.m(null,$CLJS.EV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=BX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,b,$CLJS.R.j(e,$CLJS.IR,$CLJS.ll.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.SE,c],null),$CLJS.n(d)?$CLJS.He([c,d]):null,a])))],null)});
for(var UX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,$CLJS.As,$CLJS.Ds],null)),VX=null,WX=0,XX=0;;)if(XX<WX){var aY=VX.X(null,XX);AX.m(null,aY,function(){return function(a){return CX(a)}}(UX,VX,WX,XX,aY));XX+=1}else{var bY=$CLJS.y(UX);if(bY){var cY=bY;if($CLJS.Cd(cY)){var dY=$CLJS.lc(cY),sma=$CLJS.mc(cY),tma=dY,uma=$CLJS.D(dY);UX=sma;VX=tma;WX=uma}else{var eY=$CLJS.A(cY);AX.m(null,eY,function(){return function(a){return CX(a)}}(UX,VX,WX,XX,eY,cY,bY));UX=$CLJS.B(cY);VX=null;WX=0}XX=0}else break}
AX.m(null,$CLJS.hG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.hg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,BX(a),BX(b)],null),c)});AX.m(null,$CLJS.tC,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=BX(a);a=$CLJS.n(rX($CLJS.AG,a))?$CLJS.XV.l(c,$CLJS.Lk,$CLJS.H([$CLJS.GG])):c;return $CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tC,a],null),b)});
for(var fY=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.rG,$CLJS.LG,$CLJS.zG,$CLJS.sG,$CLJS.gs,$CLJS.QG,$CLJS.es,$CLJS.fs,$CLJS.cs,$CLJS.ds,$CLJS.cG,$CLJS.jG,$CLJS.aG,$CLJS.mG,$CLJS.bG],null)),gY=null,hY=0,iY=0;;)if(iY<hY){var jY=gY.X(null,iY);AX.m(null,jY,function(){return function(a){return DX(a)}}(fY,gY,hY,iY,jY));iY+=1}else{var kY=$CLJS.y(fY);if(kY){var lY=kY;if($CLJS.Cd(lY)){var mY=$CLJS.lc(lY),vma=$CLJS.mc(lY),wma=mY,xma=$CLJS.D(mY);fY=vma;gY=wma;hY=xma}else{var nY=$CLJS.A(lY);AX.m(null,nY,
function(){return function(a){return DX(a)}}(fY,gY,hY,iY,nY,lY,kY));fY=$CLJS.B(lY);gY=null;hY=0}iY=0}else break}AX.m(null,ima,function(){return null});AX.m(null,$CLJS.IM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IM,AX.h(b),a],null)});
AX.m(null,HX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=AX.h;var e=$CLJS.Q;b=AX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,hma)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.CE,a],null);return c.call(AX,new $CLJS.P(null,3,5,e,[$CLJS.IM,b,a],null))});
for(var oY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iy,$CLJS.BG],null)),pY=null,qY=0,rY=0;;)if(rY<qY){var sY=pY.X(null,rY);AX.m(null,sY,function(){return function(a){return EX(a)}}(oY,pY,qY,rY,sY));rY+=1}else{var tY=$CLJS.y(oY);if(tY){var uY=tY;if($CLJS.Cd(uY)){var vY=$CLJS.lc(uY),yma=$CLJS.mc(uY),zma=vY,Ama=$CLJS.D(vY);oY=yma;pY=zma;qY=Ama}else{var wY=$CLJS.A(uY);AX.m(null,wY,function(){return function(a){return EX(a)}}(oY,pY,qY,rY,wY,uY,tY));oY=$CLJS.B(uY);pY=null;qY=0}rY=0}else break}
for(var xY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.uG,$CLJS.VG,$CLJS.eG,$CLJS.OG,$CLJS.MG,$CLJS.Wi,$CLJS.Fk,$CLJS.NG,$CLJS.fG],null)),yY=null,zY=0,AY=0;;)if(AY<zY){var BY=yY.X(null,AY);AX.m(null,BY,function(){return function(a){return FX(a)}}(xY,yY,zY,AY,BY));AY+=1}else{var CY=$CLJS.y(xY);if(CY){var DY=CY;if($CLJS.Cd(DY)){var EY=$CLJS.lc(DY),Bma=$CLJS.mc(DY),Cma=EY,Dma=$CLJS.D(EY);xY=Bma;yY=Cma;zY=Dma}else{var FY=$CLJS.A(DY);AX.m(null,FY,function(){return function(a){return FX(a)}}(xY,yY,zY,AY,
FY,DY,CY));xY=$CLJS.B(DY);yY=null;zY=0}AY=0}else break}AX.m(null,$CLJS.lG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lG,BX(b),a],null)});
for(var GY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HG,$CLJS.JG],null)),HY=null,IY=0,JY=0;;)if(JY<IY){var KY=HY.X(null,JY);AX.m(null,KY,function(){return function(a){return GX(a)}}(GY,HY,IY,JY,KY));JY+=1}else{var LY=$CLJS.y(GY);if(LY){var MY=LY;if($CLJS.Cd(MY)){var NY=$CLJS.lc(MY),Ema=$CLJS.mc(MY),Fma=NY,Gma=$CLJS.D(NY);GY=Ema;HY=Fma;IY=Gma}else{var OY=$CLJS.A(MY);AX.m(null,OY,function(){return function(a){return GX(a)}}(GY,HY,IY,JY,OY,MY,LY));GY=$CLJS.B(MY);HY=null;IY=0}JY=0}else break}
AX.m(null,$CLJS.TG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TG,AX.h(b),AX.h(a)],null)});
AX.m(null,$CLJS.iG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.ee.g(AX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iG,b],null)),wX.l(a,$CLJS.H([dX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iG,$CLJS.Gf(function(){return function e(d){return new $CLJS.qe(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Cd(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.te(l);a:for(var t=0;;)if(t<l){var u=$CLJS.md(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[AX.h(v),AX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.we($CLJS.ye(m),e($CLJS.mc(f))):$CLJS.we($CLJS.ye(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[AX.h(m),AX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
AX.m(null,$CLJS.CF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.hg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CF,AX.h(a),$CLJS.E.g(0,b)?1:AX.h(b)],null),$CLJS.lf.g(AX,c))});
var nX=function nX(a){if($CLJS.Ad(a))return $CLJS.Wd(function(d,e,f){return $CLJS.R.j(d,e,nX.h?nX.h(f):nX.call(null,f))},a,a);if($CLJS.zd(a))return $CLJS.Wt(a,nX);if($CLJS.n(uX(a))){var c=function(){try{return AX.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.eB($CLJS.Vy);if($CLJS.n($CLJS.dB("metabase.mbql.normalize",e))){var f=$CLJS.eF("Invalid clause:");f instanceof Error?$CLJS.cB("metabase.mbql.normalize",e,$CLJS.Ww.l($CLJS.H([a])),f):$CLJS.cB("metabase.mbql.normalize",e,$CLJS.Ww.l($CLJS.H([f,
a])),null)}throw $CLJS.ki($CLJS.BE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.JV(d)])),new $CLJS.h(null,1,[$CLJS.nS,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.hg.j($CLJS.ee.g($CLJS.ld(c),$CLJS.A(c)),$CLJS.lf.h(nX),$CLJS.Lc(c)):c}return $CLJS.Gd(a)?$CLJS.Wk.g(nX,a):$CLJS.vd(a)?$CLJS.hg.j($CLJS.ld(a),$CLJS.lf.h(nX),a):a},IX=function IX(a){return function f(d,e){try{if($CLJS.n(function(){var x=oX($CLJS.Gd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Gf(e));throw $CLJS.Y;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=oX($CLJS.je);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.Bd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.al.j(e,0,2);if($CLJS.Bd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.zE($CLJS.je,$CLJS.We(new $CLJS.dh(null,new $CLJS.h(null,5,[$CLJS.ls,null,$CLJS.rt,null,$CLJS.UG,null,$CLJS.js,null,
HX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(ZX(m)))return $CLJS.hg.j($CLJS.Ff,$CLJS.AE(IX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.Bd(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.al.j(e,0,1);if($CLJS.Bd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;
}else throw d;else throw x;}}($CLJS.Ff,a)},TY=function TY(a){return function f(d,e){try{var k=oX($CLJS.Gd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Gf(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.Bd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.ke(m,$CLJS.xG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.ke(m,
$CLJS.qG))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qG,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.ke(m,xX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.ke(m,QX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===
$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.ke(v,xX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xG,t],null));throw $CLJS.Y;}catch(ca){if(ca instanceof Error)if(m=ca,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.ke(v,QX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qG,t],null));throw $CLJS.Y;}catch(ka){if(ka instanceof Error)if(m=ka,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.ke(v,$CLJS.xG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xG,BX(t)],null);throw $CLJS.Y;
}catch(wa){if(wa instanceof Error)if(m=wa,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.ke(v,$CLJS.qG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qG,BX(t)],null);throw $CLJS.Y;}catch(Qa){if(Qa instanceof Error){t=Qa;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Qa;}else throw m;else throw wa;}else throw m;else throw ka;}else throw m;else throw ca;}else throw m;else throw Z;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof
Error)if(l=G,l===$CLJS.Y)try{if($CLJS.Bd(e)&&0<=$CLJS.D(e))try{var x=$CLJS.al.j(e,0,0);if($CLJS.Bd(x)&&0===$CLJS.D(x))try{var z=$CLJS.al.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Gf($CLJS.nl.h($CLJS.lf.g(TY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.pX(f,d,e);
throw u;}throw K;}else throw l;else throw G;}else throw l;else throw C;}}($CLJS.Ff,a)},LX=$CLJS.Uk.g(nX,function(a){var b=MX($CLJS.pG.h(a))?$CLJS.Xk.j(a,$CLJS.pG,$ka):a;b=MX($CLJS.oG.h(a))?$CLJS.Xk.j(b,$CLJS.oG,ala):b;b=MX($CLJS.WE.h(a))?$CLJS.Xk.j(b,$CLJS.WE,$CLJS.Ye($CLJS.Wk,zX)):b;b=MX($CLJS.cS.h(a))?$CLJS.Xk.j(b,$CLJS.cS,TY):b;return MX($CLJS.WQ.h(a))?$CLJS.Xk.j(b,$CLJS.WQ,cla):b}),Kla=new $CLJS.h(null,3,[$CLJS.xQ,$CLJS.Xd,$CLJS.WF,new $CLJS.h(null,2,[$CLJS.WQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,
$CLJS.xQ);if($CLJS.n(b))return a=$CLJS.QV(a,new $CLJS.h(null,1,[$CLJS.xQ,$CLJS.WF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xQ],null),a=OX.g?OX.g(a,b):OX.call(null,a,b),$CLJS.QV(a,new $CLJS.h(null,1,[$CLJS.WF,$CLJS.xQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WF],null);return OX.g?OX.g(a,b):OX.call(null,a,b)},$CLJS.RN,new $CLJS.h(null,1,[fX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WF],null);return OX.g?OX.g(a,b):OX.call(null,a,b)}],null)],null),TX,$CLJS.Xd],null),OX=function OX(a){switch(arguments.length){case 1:return OX.h(arguments[0]);
case 2:return OX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};OX.h=function(a){return OX.g(a,$CLJS.Ff)};OX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.WA(Kla,b):null;return $CLJS.od(c)?c.h?c.h(a):c.call(null,a):$CLJS.Ad(a)?a:$CLJS.zd(a)?gla(a,b):$CLJS.yd(a)?hla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.ki("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.mj,a,$CLJS.tl,b],null),d);throw d;}};OX.A=2;
$CLJS.UY=function(){var a=$CLJS.Uk.l(OX,fla,dla,$CLJS.H([wX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.ki($CLJS.BE("Error normalizing query: {0}",$CLJS.H([$CLJS.JV(c)])),new $CLJS.h(null,1,[$CLJS.WF,b],null),c);}throw d;}}}();$CLJS.VY=function VY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.UY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.Ut(a);b=$CLJS.He([$CLJS.kd(a),b]);f=VY.g?VY.g(f,b):VY.call(null,f,b);a=e.call(d,f,$CLJS.kd(a))}return a};