var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var WY,Mla,Nla,Ola,$Y,Qla,Rla,Sla,Tla,dZ,eZ,fZ,hZ,Vla,iZ,jZ,Wla,Ima,Jma,QY,Hma,RY,SY,Kma,bZ;WY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.ef(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
$CLJS.Lla=function(a,b){return function(){function c(m,t,u){return new $CLJS.P(null,2,5,$CLJS.Q,[a.j?a.j(m,t,u):a.call(null,m,t,u),b.j?b.j(m,t,u):b.call(null,m,t,u)],null)}function d(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[a.g?a.g(m,t):a.call(null,m,t),b.g?b.g(m,t):b.call(null,m,t)],null)}function e(m){return new $CLJS.P(null,2,5,$CLJS.Q,[a.h?a.h(m):a.call(null,m),b.h?b.h(m):b.call(null,m)],null)}function f(){return new $CLJS.P(null,2,5,$CLJS.Q,[a.o?a.o():a.call(null),b.o?b.o():b.call(null)],null)}
var k=null,l=function(){function m(u,v,x,z){var C=null;if(3<arguments.length){C=0;for(var G=Array(arguments.length-3);C<G.length;)G[C]=arguments[C+3],++C;C=new $CLJS.w(G,0,null)}return t.call(this,u,v,x,C)}function t(u,v,x,z){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ve.N(a,u,v,x,z),$CLJS.Ve.N(b,u,v,x,z)],null)}m.A=3;m.B=function(u){var v=$CLJS.A(u);u=$CLJS.B(u);var x=$CLJS.A(u);u=$CLJS.B(u);var z=$CLJS.A(u);u=$CLJS.Lc(u);return t(v,x,z,u)};m.l=t;return m}();k=function(m,t,u,v){switch(arguments.length){case 0:return f.call(this);
case 1:return e.call(this,m);case 2:return d.call(this,m,t);case 3:return c.call(this,m,t,u);default:var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.l(m,t,u,x)}throw Error("Invalid arity: "+arguments.length);};k.A=3;k.B=l.B;k.o=f;k.h=e;k.g=d;k.j=c;k.l=l.l;return k}()};$CLJS.PY=function(a,b){return $CLJS.gM(a)(b,$CLJS.Ff,$CLJS.Ff)};
Mla=function(a,b,c){return $CLJS.db(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.pG,b),$CLJS.Ct(2,2,c))};Nla=function(a,b){var c=$CLJS.jm(a,$CLJS.UQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Gb(c);b=$CLJS.Wt.g?$CLJS.Wt.g(c,b):$CLJS.Wt.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.UQ,b)}return a};Ola=function(a){return $CLJS.kf.g($CLJS.bW(a),$CLJS.cW(a))};
$Y=function(a,b,c){var d=new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.Xs,null,$CLJS.xl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.Ru(2,c):$CLJS.Ru(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?WY(2,c):WY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.NX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.Bd(k)?$CLJS.hg.j($CLJS.Ff,$CLJS.Vk.h($CLJS.El),l):$CLJS.zd(k)?$CLJS.bB($CLJS.Wa,l):l}):$CLJS.Lk.g(a,f)};
Qla=function(a){return $CLJS.db(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $Y(b,Hma,d)},a,Ola(a))};
Rla=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.GL.h(a),b);a:{var d=c;for(var e=$CLJS.Ff;;){var f=$CLJS.A($CLJS.lf.g($CLJS.Lla($CLJS.rj,$CLJS.rl),$CLJS.of($CLJS.Uk.j(aZ,$CLJS.A,$CLJS.rl),$CLJS.at.h($CLJS.PY($CLJS.zW,d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=$Y(d,f,k),m=$CLJS.eB($CLJS.Xy);if($CLJS.n($CLJS.dB("metabase.lib.convert",m))){var t=$CLJS.VB.l($CLJS.H([$CLJS.Rz,$CLJS.Ph.l($CLJS.H([k]))])),u=$CLJS.VB,v=u.l,x=$CLJS.Ph,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.lM($CLJS.PY($CLJS.zW,
d));$CLJS.cB("metabase.lib.convert",m,$CLJS.LW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.Rz,z.call(x,$CLJS.H([C]))])),$CLJS.VB.l($CLJS.H([$CLJS.Dz,$CLJS.MW($CLJS.A($CLJS.yu(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.ee.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=Qla(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.GL.h(a))-1))return a;b+=1}else a=$CLJS.Xk.N(a,$CLJS.GL,$CLJS.R,b,d)}};
Sla=function(a){var b=$CLJS.ZW();return $CLJS.Wk.g(function(c){return $CLJS.E.g($CLJS.lF.h(c),"__join")?$CLJS.Xk.j(c,$CLJS.lF,b):c},a)};Tla=function(a){return"string"===typeof $CLJS.sP.h(a)?$CLJS.Lk.g($CLJS.R.j(a,$CLJS.TV,$CLJS.XW($CLJS.sP.h(a))),$CLJS.sP):a};dZ=function(){return $CLJS.Vk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.pe(b))?(b=$CLJS.le(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};
eZ=function(a){return $CLJS.hg.j($CLJS.N,dZ(),a)};fZ=function(a){return $CLJS.Ie($CLJS.hg.j($CLJS.N,$CLJS.Uk.g(dZ(),$CLJS.Vk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Pi)})),a))};
hZ=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.hg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.lf.h($CLJS.gZ),c);a=$CLJS.E.g(a,$CLJS.iG)&&$CLJS.B(c)?$CLJS.ee.g($CLJS.td(d),new $CLJS.h(null,1,[$CLJS.mi,$CLJS.sd(d)],null)):d;b=$CLJS.Ie(fZ(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IM,a,b],null):a};Vla=function(a){return $CLJS.hg.j($CLJS.Ff,$CLJS.Uk.g($CLJS.lf.h(function(b){return $CLJS.XA(b,$CLJS.$B)}),$CLJS.lf.h($CLJS.gZ)),$CLJS.BW.h(a))};
iZ=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.GL);a=$CLJS.A($CLJS.db(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.gZ.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.WQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.eP,Vla(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.BV.h(c)],null)},null,a));return $CLJS.n($CLJS.xQ.h(a))?$CLJS.QV(a,new $CLJS.h(null,1,[$CLJS.xQ,$CLJS.WF],null)):a};
jZ=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.kX(a,b,$CLJS.Uk.g($CLJS.gZ,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.kX(d,b,function(e){return $CLJS.hg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Ks],null),$CLJS.lf.h($CLJS.gZ),e)}):d;return $CLJS.QV(a,$CLJS.He([b,c]))};Wla=function(a){var b=$CLJS.TV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Lk.g(a,$CLJS.TV),$CLJS.sP,["card__",$CLJS.p.h(b)].join("")):a};
Ima=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);Jma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);QY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);Hma=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);RY=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
SY=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.YY=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);Kma=new $CLJS.M(null,"m","m",1632677161);bZ=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var aZ,Lma,Mma,Nma,Oma,Pma,Qma,Rma,Sma,Vma,Wma;$CLJS.oZ=$CLJS.N;$CLJS.pZ=$CLJS.N;aZ=new $CLJS.dh(null,new $CLJS.h(null,7,[$CLJS.wW,null,$CLJS.WE,null,$CLJS.RN,null,$CLJS.nQ,null,$CLJS.oG,null,$CLJS.cS,null,$CLJS.pG,null],null),null);Lma=$CLJS.$e($CLJS.N);Mma=$CLJS.$e($CLJS.N);Nma=$CLJS.$e($CLJS.N);Oma=$CLJS.$e($CLJS.N);Pma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
$CLJS.qZ=new $CLJS.hi($CLJS.Ih.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.yC,Pma,Lma,Mma,Nma,Oma);$CLJS.qZ.m(null,$CLJS.mi,function(a){if($CLJS.Bd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.sd(a);var c=$CLJS.zd(b);a=c?$CLJS.td(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.GW($CLJS.hg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.lf.h($CLJS.qZ),d))}else b=a;return b});$CLJS.qZ.m(null,$CLJS.vW,function(a){return a});
$CLJS.qZ.m(null,$CLJS.iW,function(a){var b=$CLJS.qZ.h($CLJS.pG.h(a)),c=$CLJS.Ie($CLJS.Wk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.AW($CLJS.qZ.h(t),u)},$CLJS.nQ.h(a))),d=$CLJS.hg.j($CLJS.N,$CLJS.km(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.hg.j($CLJS.N,$CLJS.km(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.OE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.pZ,k=$CLJS.oZ;$CLJS.pZ=d;$CLJS.oZ=e;try{var l=Mla(Tla(a),b,$CLJS.H([$CLJS.nQ,c])),m=$CLJS.db(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Xk.j(t,u,$CLJS.qZ)},l,$CLJS.Mk.l(aZ,$CLJS.pG,$CLJS.H([$CLJS.nQ])));return $CLJS.n($CLJS.RN.h(m))?$CLJS.Xk.j(m,$CLJS.RN,Sla):m}finally{$CLJS.oZ=k,$CLJS.pZ=f}});$CLJS.qZ.m(null,$CLJS.pW,function(a){return Nla(a,function(b){return $CLJS.kX(b,$CLJS.DF,$CLJS.qZ)})});
$CLJS.qZ.m(null,$CLJS.BL,function(a){a=$CLJS.Xk.j($CLJS.Xk.j(a,$CLJS.JL,$CLJS.qZ),$CLJS.GL,$CLJS.qZ);var b=$CLJS.n($CLJS.WE.h(a))?$CLJS.Xk.j(a,$CLJS.WE,function(c){return $CLJS.Ql(c)?$CLJS.Wk.g($CLJS.qZ,c):$CLJS.Ch.h(c)}):a;return $CLJS.Va($CLJS.lF.h(a))?$CLJS.R.j(b,$CLJS.lF,"__join"):b});$CLJS.qZ.m(null,$CLJS.EB,function(a){return $CLJS.Wk.g($CLJS.qZ,a)});
$CLJS.qZ.m(null,$CLJS.GB,function(a){return $CLJS.n($CLJS.rj.h(a))?Rla($CLJS.R.j($CLJS.Xk.j($CLJS.UW(a),$CLJS.GL,function(b){return $CLJS.Wk.g($CLJS.qZ,b)}),$CLJS.YY,!0)):$CLJS.Wt(a,$CLJS.qZ)});$CLJS.qZ.m(null,$CLJS.AG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.zd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.GW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,a,b],null))});
$CLJS.qZ.m(null,$CLJS.Ij,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.QV(a,new $CLJS.h(null,3,[$CLJS.uD,$CLJS.KB,$CLJS.RO,$CLJS.rk,$CLJS.kS,$CLJS.JE],null));var c=$CLJS.R.j;var d=$CLJS.Pi.h(a);$CLJS.n(d)||(d=$CLJS.KB.h(a),d=$CLJS.n(d)?d:$CLJS.hF(b));a=c.call($CLJS.R,a,$CLJS.Pi,d);return $CLJS.GW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,a,b],null))});
$CLJS.qZ.m(null,$CLJS.iG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.mi.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,$CLJS.Lk.g(c,$CLJS.mi),$CLJS.Wk.g($CLJS.qZ,b)],null);b=$CLJS.GW(b);return null!=a?$CLJS.ee.g(b,$CLJS.qZ.h(a)):b});$CLJS.qZ.m(null,$CLJS.HB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.GW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.qZ.m(null,$CLJS.pG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null),d=$CLJS.I(a,2,null),e=$CLJS.Q;var f=$CLJS.J.g($CLJS.pZ,c);if(!$CLJS.n(f))throw $CLJS.li(["Error converting :aggregation reference: no aggregation at index ",$CLJS.p.h(c)].join(""),new $CLJS.h(null,1,[$CLJS.nS,a],null));return $CLJS.GW(new $CLJS.P(null,3,5,e,[b,d,f],null))});
$CLJS.qZ.m(null,$CLJS.IM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.qZ.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.hg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.ll.l($CLJS.H([c,a]))],null),d)});Qma=$CLJS.$e($CLJS.N);Rma=$CLJS.$e($CLJS.N);Sma=$CLJS.$e($CLJS.N);Vma=$CLJS.$e($CLJS.N);Wma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));
$CLJS.gZ=new $CLJS.hi($CLJS.Ih.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.yC,Wma,Qma,Rma,Sma,Vma);
$CLJS.gZ.m(null,$CLJS.mi,function(a){if($CLJS.Bd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.zd(a)){c=$CLJS.hg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.kf.g;d=$CLJS.lf.g($CLJS.gZ,d);a=fZ(a);a=c.call($CLJS.hg,b,e.call($CLJS.kf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.hg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.lf.g($CLJS.gZ,$CLJS.he(a,d)))}else a=$CLJS.zd(a)?$CLJS.Wt(eZ(a),$CLJS.gZ):
a;return a});for(var cZ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[bZ,SY],null)),kZ=null,lZ=0,mZ=0;;)if(mZ<lZ){var Xma=kZ.X(null,mZ);$CLJS.MF(Xma,RY);mZ+=1}else{var nZ=$CLJS.y(cZ);if(nZ){var rZ=nZ;if($CLJS.Cd(rZ)){var sZ=$CLJS.lc(rZ),Yma=$CLJS.mc(rZ),Zma=sZ,ana=$CLJS.D(sZ);cZ=Yma;kZ=Zma;lZ=ana}else{var bna=$CLJS.A(rZ);$CLJS.MF(bna,RY);cZ=$CLJS.B(rZ);kZ=null;lZ=0}mZ=0}else break}
for(var tZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.iy,$CLJS.uG,$CLJS.JG,$CLJS.eG,$CLJS.Fk,$CLJS.NG,$CLJS.Wi,$CLJS.lG,$CLJS.HG,$CLJS.OG,$CLJS.MG,$CLJS.TG],null)),uZ=null,vZ=0,wZ=0;;)if(wZ<vZ){var hna=uZ.X(null,wZ);$CLJS.MF(hna,bZ);wZ+=1}else{var xZ=$CLJS.y(tZ);if(xZ){var yZ=xZ;if($CLJS.Cd(yZ)){var zZ=$CLJS.lc(yZ),mna=$CLJS.mc(yZ),nna=zZ,ona=$CLJS.D(zZ);tZ=mna;uZ=nna;vZ=ona}else{var pna=$CLJS.A(yZ);$CLJS.MF(pna,bZ);tZ=$CLJS.B(yZ);uZ=null;vZ=0}wZ=0}else break}
for(var AZ=$CLJS.y($CLJS.Bf([$CLJS.js,$CLJS.rt,$CLJS.ls,$CLJS.UG,$CLJS.iG,$CLJS.DG,$CLJS.$F,$CLJS.WG,$CLJS.BF,$CLJS.nG,$CLJS.FF,$CLJS.yG,$CLJS.FG,$CLJS.XF,$CLJS.kC,$CLJS.lC,$CLJS.Yy,$CLJS.jJ,$CLJS.oJ,$CLJS.cJ,$CLJS.pC,$CLJS.iJ,$CLJS.aJ,$CLJS.rJ,$CLJS.wJ,$CLJS.tJ,$CLJS.hJ,$CLJS.yJ,$CLJS.rC,$CLJS.uC,$CLJS.ZF,$CLJS.CF,$CLJS.XG,$CLJS.nI,$CLJS.CG,$CLJS.$x,$CLJS.PG,$CLJS.RG,$CLJS.YG,$CLJS.ut,$CLJS.Dt],!0)),BZ=null,CZ=0,DZ=0;;)if(DZ<CZ){var qna=BZ.X(null,DZ);$CLJS.MF(qna,SY);DZ+=1}else{var EZ=$CLJS.y(AZ);
if(EZ){var FZ=EZ;if($CLJS.Cd(FZ)){var GZ=$CLJS.lc(FZ),una=$CLJS.mc(FZ),xna=GZ,yna=$CLJS.D(GZ);AZ=una;BZ=xna;CZ=yna}else{var zna=$CLJS.A(FZ);$CLJS.MF(zna,SY);AZ=$CLJS.B(FZ);BZ=null;CZ=0}DZ=0}else break}$CLJS.gZ.m(null,RY,function(a){return hZ(a)});$CLJS.gZ.m(null,$CLJS.GB,function(a){return $CLJS.hg.j($CLJS.N,$CLJS.Uk.g(dZ(),$CLJS.lf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.gZ.h(b)],null)})),a)});
$CLJS.gZ.m(null,$CLJS.pG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.zd(b))try{var d=fZ(b);b=$CLJS.Q;var e=$CLJS.oZ,f=$CLJS.J.j(e,c,QY);if($CLJS.E.g(f,QY))throw $CLJS.li(["Unable to find key ",$CLJS.Ph.l($CLJS.H([c]))," in map."].join(""),new $CLJS.h(null,2,[Kma,e,$CLJS.ix,c],null));var k=new $CLJS.P(null,2,5,b,[$CLJS.pG,f],null);return $CLJS.y(d)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IM,k,d],null):k}catch(l){throw c=l,d=$CLJS.JV(c),d=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",
d):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",d),$CLJS.ki(d,new $CLJS.h(null,1,[$CLJS.W,a],null),c);}else return a});$CLJS.gZ.m(null,$CLJS.EB,function(a){return $CLJS.Wk.g($CLJS.gZ,a)});
$CLJS.gZ.m(null,$CLJS.AG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.zd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,$CLJS.gZ.h(a),fZ(b)],null)});
$CLJS.gZ.m(null,$CLJS.Ij,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=fZ($CLJS.QV(b,new $CLJS.h(null,3,[$CLJS.KB,$CLJS.uD,$CLJS.rk,$CLJS.RO,$CLJS.JE,$CLJS.kS],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,a,b],null)});
$CLJS.gZ.m(null,$CLJS.BL,function(a){var b=eZ(a);a=0==$CLJS.lF.h(a).lastIndexOf("__join",0)?$CLJS.Lk.g(b,$CLJS.lF):b;return $CLJS.ll.l($CLJS.H([$CLJS.Wt($CLJS.Lk.l(a,$CLJS.GL,$CLJS.H([$CLJS.JL])),$CLJS.gZ),jZ($CLJS.im(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.JL],null)),$CLJS.JL,$CLJS.eS),iZ(a)]))});
$CLJS.gZ.m(null,$CLJS.iW,function(a){var b=$CLJS.pG.h(a),c=$CLJS.hg.j($CLJS.N,$CLJS.km(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.hg.j($CLJS.N,$CLJS.km(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.pZ,e=$CLJS.oZ;$CLJS.pZ=c;$CLJS.oZ=b;try{return $CLJS.db(function(f,k){return $CLJS.kX(f,
k,$CLJS.gZ)},jZ($CLJS.kX($CLJS.kX(Wla(eZ(a)),$CLJS.pG,function(f){return $CLJS.Wk.g(hZ,f)}),$CLJS.nQ,function(f){return $CLJS.hg.g($CLJS.N,function(){return function m(l){return new $CLJS.qe(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Cd(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.te(v);a:for(var z=0;;)if(z<v){var C=$CLJS.md(u,z),G=$CLJS.gZ.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PW(C),$CLJS.E.g($CLJS.Ij,$CLJS.A(G))?$CLJS.jd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.we($CLJS.ye(x),
m($CLJS.mc(t))):$CLJS.we($CLJS.ye(x),null)}x=$CLJS.A(t);u=$CLJS.gZ.h(x);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PW(x),$CLJS.E.g($CLJS.Ij,$CLJS.A(u))?$CLJS.jd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.wW,$CLJS.dG),$CLJS.Mk.l(aZ,$CLJS.pG,$CLJS.H([$CLJS.wW,$CLJS.nQ])))}finally{$CLJS.oZ=e,$CLJS.pZ=d}});$CLJS.gZ.m(null,$CLJS.pW,function(a){return $CLJS.Wt(eZ(a),$CLJS.gZ)});
$CLJS.gZ.m(null,$CLJS.vW,function(a){try{var b=eZ(a),c=$CLJS.IN.h(b),d=iZ(b),e=$CLJS.E.g($CLJS.nu.h($CLJS.kd($CLJS.GL.h(a))),$CLJS.pW)?$CLJS.xQ:$CLJS.WF;return $CLJS.ll.l($CLJS.H([$CLJS.Lk.l(b,$CLJS.GL,$CLJS.H([$CLJS.IN,$CLJS.YY])),function(){var k=$CLJS.He([$CLJS.rj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.IN,c):k}()]))}catch(k){var f=k;throw $CLJS.ki(function(){var l=$CLJS.JV(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.WF,a],null),f);}});
$CLJS.MZ=function(){function a(d,e,f){f=$CLJS.VY(null,$CLJS.hA(f,$CLJS.H([$CLJS.Di,!0])));var k=$CLJS.WW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.pG);k=$CLJS.hg.j($CLJS.N,$CLJS.km(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.hg.j($CLJS.N,$CLJS.km(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.pZ,t=$CLJS.oZ;$CLJS.pZ=k;$CLJS.oZ=l;try{try{return $CLJS.qZ.h(f)}catch(v){var u=v;throw $CLJS.ki(function(){var x=$CLJS.JV(u);return $CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.WF,d,$CLJS.TL,e,Jma,f,Ima,$CLJS.pZ],null),u);}}finally{$CLJS.oZ=t,$CLJS.pZ=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();