var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var i0,q4,B4,C4,D4,E4,F4,Vra;i0=function(a,b){if(null!=a&&null!=a.vg)a=a.vg(a,b);else{var c=i0[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=i0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("MetadataProvider.metric",a);}return a};$CLJS.o0=function(a,b){return i0($CLJS.k0(a),b)};
q4=function(a,b,c){var d=$CLJS.WW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.pG);d=$CLJS.QH($CLJS.Uk.j($CLJS.fh([c]),$CLJS.OE,$CLJS.jd),d);if(!$CLJS.n(d))throw $CLJS.li($CLJS.BE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.vk,c,$CLJS.WF,a,$CLJS.TL,b],null));return d};$CLJS.r4=function(a){return $CLJS.n2($CLJS.MG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};B4=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);
C4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);D4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);E4=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);F4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);
Vra=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.R0.m(null,$CLJS.pG,function(a,b){var c=$CLJS.Ie($CLJS.pG.h($CLJS.WW(a,b)));return $CLJS.n(c)?$CLJS.p2($CLJS.eF("and"),function(){return function f(e){return new $CLJS.qe(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Cd(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.te(m);a:for(var u=0;;)if(u<m){var v=$CLJS.md(l,u);v=$CLJS.q0.v(a,b,v,$CLJS.D0);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.we($CLJS.ye(t),f($CLJS.mc(k))):$CLJS.we($CLJS.ye(t),null)}t=$CLJS.A(k);return $CLJS.he($CLJS.q0.v(a,
b,t,$CLJS.D0),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.U0.m(null,$CLJS.pG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.KB);e=$CLJS.J.g(e,$CLJS.Pi);c=$CLJS.I(c,2,null);c=q4(a,b,c);return $CLJS.ll.l($CLJS.H([$CLJS.p0.j(a,b,c),new $CLJS.h(null,2,[$CLJS.$K,$CLJS.zL,$CLJS.c3,$CLJS.OE.h($CLJS.jd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.KB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Pi,e],null):null]))});
$CLJS.O0.m(null,$CLJS.pG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.q0.v(a,b,q4(a,b,c),d)});$CLJS.MF(D4,F4);
for(var G4=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iy,$CLJS.BG,$CLJS.JG],null)),H4=null,I4=0,M4=0;;)if(M4<I4){var Wra=H4.X(null,M4);$CLJS.MF(Wra,D4);M4+=1}else{var Xra=$CLJS.y(G4);if(Xra){var N4=Xra;if($CLJS.Cd(N4)){var Yra=$CLJS.lc(N4),Zra=$CLJS.mc(N4),$ra=Yra,asa=$CLJS.D(Yra);G4=Zra;H4=$ra;I4=asa}else{var bsa=$CLJS.A(N4);$CLJS.MF(bsa,D4);G4=$CLJS.B(N4);H4=null;I4=0}M4=0}else break}
$CLJS.O0.m(null,D4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.q0.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.BE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.BE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.eF("Count");case "cum-count":return $CLJS.eF("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.P0.m(null,D4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.U0.m(null,E4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.ei($CLJS.U0,F4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.rk,$CLJS.jD)});
$CLJS.MF(E4,F4);$CLJS.MF(D4,E4);$CLJS.MF($CLJS.eG,E4);$CLJS.O0.m(null,$CLJS.iG,function(){return $CLJS.eF("Case")});$CLJS.P0.m(null,$CLJS.iG,function(){return"case"});$CLJS.MF(C4,F4);
for(var O4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.uG,$CLJS.VG,$CLJS.eG,$CLJS.Fk,$CLJS.NG,$CLJS.Wi,$CLJS.OG,$CLJS.MG,$CLJS.fG],null)),P4=null,Q4=0,T4=0;;)if(T4<Q4){var csa=P4.X(null,T4);$CLJS.MF(csa,C4);T4+=1}else{var dsa=$CLJS.y(O4);if(dsa){var U4=dsa;if($CLJS.Cd(U4)){var esa=$CLJS.lc(U4),fsa=$CLJS.mc(U4),gsa=esa,hsa=$CLJS.D(esa);O4=fsa;P4=gsa;Q4=hsa}else{var isa=$CLJS.A(U4);$CLJS.MF(isa,C4);O4=$CLJS.B(U4);P4=null;Q4=0}T4=0}else break}
$CLJS.P0.m(null,C4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.O0.m(null,C4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.q0.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.BE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.BE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.BE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.BE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.BE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.BE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.BE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.BE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.BE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.O0.m(null,$CLJS.lG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.BE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.q0.v(a,b,e,d)]))});$CLJS.P0.m(null,$CLJS.lG,function(){return"percentile"});
$CLJS.MF(B4,F4);for(var V4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lG,$CLJS.fG],null)),W4=null,Y4=0,Z4=0;;)if(Z4<Y4){var jsa=W4.X(null,Z4);$CLJS.MF(jsa,B4);Z4+=1}else{var ksa=$CLJS.y(V4);if(ksa){var $4=ksa;if($CLJS.Cd($4)){var lsa=$CLJS.lc($4),msa=$CLJS.mc($4),nsa=lsa,osa=$CLJS.D(lsa);V4=msa;W4=nsa;Y4=osa}else{var psa=$CLJS.A($4);$CLJS.MF(psa,B4);V4=$CLJS.B($4);W4=null;Y4=0}Z4=0}else break}
$CLJS.U0.m(null,B4,function(a,b,c){var d=$CLJS.Lk.g,e=$CLJS.ei($CLJS.U0,F4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.Lk,a,$CLJS.rk)});$CLJS.O0.m(null,$CLJS.TG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.BE("Sum of {0} matching condition",$CLJS.H([$CLJS.q0.v(a,b,e,d)]))});
$CLJS.P0.m(null,$CLJS.TG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.Q0.j(a,b,d))].join("")});$CLJS.MF($CLJS.TG,F4);$CLJS.O0.m(null,$CLJS.HG,function(){return $CLJS.eF("Share of rows matching condition")});$CLJS.P0.m(null,$CLJS.HG,function(){return"share"});$CLJS.U0.m(null,$CLJS.HG,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.ei($CLJS.U0,F4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.rk,$CLJS.CD)});
$CLJS.MF($CLJS.HG,F4);$CLJS.O0.m(null,$CLJS.JG,function(){return $CLJS.eF("Count of rows matching condition")});$CLJS.U0.m(null,F4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.ll.l;d=$CLJS.n(d)?$CLJS.im($CLJS.p0.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pL,$CLJS.rk],null)):null;var f=$CLJS.ei($CLJS.U0,$CLJS.mi);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.ll,$CLJS.H([d,a]))});
$CLJS.Lpa=function(){function a(d){return $CLJS.n2($CLJS.iy,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.n2($CLJS.iy,$CLJS.Ff)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.FW.m(null,$CLJS.pG,function(a){return a});
$CLJS.J4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.yC(f),$CLJS.dL))f=$CLJS.HW(f);else return $CLJS.q2(d,e,$CLJS.pG,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.K4=function(){function a(d,e){return $CLJS.Ie($CLJS.pG.h($CLJS.WW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.L4=function(){function a(d,e){var f=$CLJS.Ie($CLJS.pG.h($CLJS.WW(d,e)));return null==f?null:$CLJS.hg.j($CLJS.Ff,$CLJS.lf.h(function(k){var l=$CLJS.p0.j(d,e,k),m=$CLJS.R.l,t=$CLJS.KB.h(l);return m.call($CLJS.R,$CLJS.m2(l,$CLJS.Pi,$CLJS.n(t)?t:$CLJS.Wj),$CLJS.$K,$CLJS.zL,$CLJS.H([$CLJS.c3,$CLJS.OE.h($CLJS.jd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.O0.m(null,$CLJS.gH,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.bH);return $CLJS.CE.h(a.o?a.o():a.call(null))});$CLJS.V0.m(null,$CLJS.gH,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.tG);b=$CLJS.J.g(d,$CLJS.bH);c=$CLJS.J.g(d,$CLJS.aH);d=$CLJS.J.g(d,$CLJS.$Z);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.w1,$CLJS.sB(a),$CLJS.H([$CLJS.Aoa,c]));return null!=d?$CLJS.R.j(a,$CLJS.J0,d):a});
$CLJS.Mpa=function(){function a(d,e){var f=function(){var m=$CLJS.zA.h($CLJS.l3(d));return $CLJS.n(m)?m:$CLJS.eh}(),k=$CLJS.WW(d,e),l=$CLJS.Z0.j(d,e,k);return $CLJS.Ie($CLJS.hg.j($CLJS.Ff,$CLJS.Uk.j($CLJS.nf(function(m){m=$CLJS.eH.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.am(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.aH),u=$CLJS.J.g(m,$CLJS.ZG);if($CLJS.Va(t))return m;if($CLJS.E.g(u,$CLJS.ri))return $CLJS.R.j(m,$CLJS.BW,l);t=$CLJS.Ie($CLJS.m1(function(v){return $CLJS.N_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.BW,t):null}),$CLJS.lf.h(function(m){return $CLJS.R.j(m,$CLJS.nu,$CLJS.gH)})),$CLJS.mH))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Npa=function(){function a(d,e){return $CLJS.GW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG.h(d),$CLJS.N,$CLJS.IW.h(e)],null))}function b(d){if($CLJS.Va($CLJS.aH.h(d)))return $CLJS.GW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tG.h(d),$CLJS.N],null));var e=$CLJS.tG.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.li(e,new $CLJS.h(null,1,[Vra,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();