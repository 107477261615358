var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var HF,QF,NF,Lfa;HF=function(a){if(null!=a&&null!=a.ia)a=a.cache;else{var b=HF[$CLJS.xa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=HF._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.ab("Cached.-cache",a);}return a};
$CLJS.IF=function(a,b,c){if(null!=a&&$CLJS.Bc===a.Wa){var d=HF(a);var e=$CLJS.q(d);e=e.h?e.h(b):e.call(null,b);if($CLJS.n(e))return e;a=$CLJS.Rh.v(d,$CLJS.R,b,c.h?c.h(a):c.call(null,a));return a.h?a.h(b):a.call(null,b)}return c.h?c.h(a):c.call(null,a)};$CLJS.KF=function(a,b,c){a=$CLJS.JF.g(a,c);return a.h?a.h(b):a.call(null,b)};$CLJS.LF=function(a,b){return $CLJS.KF(a,b,null)};$CLJS.MF=function(a,b){$CLJS.Rh.v($CLJS.AC,$CLJS.zB,a,b)};$CLJS.OF=function(a,b){a=NF(a,b);return $CLJS.n(a)?a:$CLJS.Wj};
QF=function(){return $CLJS.hg.j(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,new $CLJS.h(null,2,[$CLJS.bj,$CLJS.A,$CLJS.$t,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ij);return $CLJS.Bd(a)?["Invalid ",$CLJS.Ph.l($CLJS.H([$CLJS.A(a)]))," clause: ",$CLJS.Ph.l($CLJS.H([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Np,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,"not a known MBQL clause"],null),$CLJS.Xe(!1)],null)],null)],null),$CLJS.lf.h(function(a){return new $CLJS.P(null,
2,5,$CLJS.Q,[a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.Ch.g("mbql.clause",$CLJS.jh(a))],null)],null)}),$CLJS.q(PF))};$CLJS.TF=function(a,b){$CLJS.Ve.v($CLJS.RF,$CLJS.SF,a,b)};$CLJS.VF=function(a,b){$CLJS.Ve.v($CLJS.RF,UF,a,b)};$CLJS.Up.prototype.ia=$CLJS.va(26,function(){return this.cache});$CLJS.Wp.prototype.ia=$CLJS.va(25,function(){return this.cache});$CLJS.Yp.prototype.ia=$CLJS.va(24,function(){return this.cache});$CLJS.$p.prototype.ia=$CLJS.va(23,function(){return this.cache});
$CLJS.Lo.prototype.ia=$CLJS.va(22,function(){return this.cache});$CLJS.cq.prototype.ia=$CLJS.va(21,function(){return this.cache});$CLJS.fq.prototype.ia=$CLJS.va(20,function(){return this.cache});$CLJS.hq.prototype.ia=$CLJS.va(19,function(){return this.cache});$CLJS.jq.prototype.ia=$CLJS.va(18,function(){return this.cache});$CLJS.lq.prototype.ia=$CLJS.va(17,function(){return this.cache});$CLJS.nq.prototype.ia=$CLJS.va(16,function(){return this.cache});$CLJS.qq.prototype.ia=$CLJS.va(15,function(){return this.cache});
$CLJS.sq.prototype.ia=$CLJS.va(14,function(){return this.cache});$CLJS.vq.prototype.ia=$CLJS.va(13,function(){return this.cache});$CLJS.xq.prototype.ia=$CLJS.va(12,function(){return this.cache});$CLJS.zq.prototype.ia=$CLJS.va(11,function(){return this.cache});$CLJS.Cq.prototype.ia=$CLJS.va(10,function(){return this.cache});$CLJS.Kq.prototype.ia=$CLJS.va(9,function(){return this.cache});$CLJS.Oq.prototype.ia=$CLJS.va(8,function(){return this.cache});$CLJS.Qq.prototype.ia=$CLJS.va(7,function(){return this.cache});
$CLJS.br.prototype.ia=$CLJS.va(6,function(){return this.cache});$CLJS.iE.prototype.ia=$CLJS.va(5,function(){return this.cache});$CLJS.JF=function JF(a){switch(arguments.length){case 1:return JF.h(arguments[0]);case 2:return JF.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.JF.h=function(a){return $CLJS.JF.g(a,null)};$CLJS.JF.g=function(a,b){return $CLJS.IF($CLJS.Co.g(a,b),$CLJS.Bk,$CLJS.An)};$CLJS.JF.A=2;
$CLJS.AF=function AF(a,b){return $CLJS.Zh($CLJS.fE,a,b)||$CLJS.Hd($CLJS.Ue(function(d){return AF.g?AF.g(a,d):AF.call(null,a,d)},$CLJS.pl.h(b)))||$CLJS.Hd($CLJS.Ue(function(d){return AF.g?AF.g(d,b):AF.call(null,d,b)},$CLJS.ai(a)))};
NF=function NF(a,b){return $CLJS.E.g(a,$CLJS.Wj)?null:$CLJS.E.g(b,$CLJS.Wj)?null:$CLJS.AF(a,b)?b:$CLJS.AF(b,a)?a:$CLJS.Ue(function(d){return $CLJS.Ue(function(e){return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[d,e],null),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))?null:NF.g?NF.g(d,e):NF.call(null,d,e)},$CLJS.he(b,$CLJS.ai(b)))},$CLJS.he(a,$CLJS.ai(a)))};$CLJS.BF=new $CLJS.M(null,"exp","exp",-261706262);$CLJS.CF=new $CLJS.M(null,"substring","substring",1140866276);
$CLJS.DF=new $CLJS.M(null,"dimension","dimension",543254198);$CLJS.EF=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.FF=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.WF=new $CLJS.M(null,"query","query",-1288509510);$CLJS.XF=new $CLJS.M(null,"power","power",-937852079);$CLJS.YF=new $CLJS.M(null,"segment","segment",-964921196);$CLJS.ZF=new $CLJS.M(null,"concat","concat",-2108183992);$CLJS.$F=new $CLJS.M(null,"abs","abs",-246026477);
$CLJS.aG=new $CLJS.M(null,"is-null","is-null",-1997050930);$CLJS.bG=new $CLJS.M(null,"between","between",1131099276);$CLJS.cG=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.dG=new $CLJS.M(null,"filter","filter",-948537934);$CLJS.eG=new $CLJS.M(null,"distinct","distinct",-1788879121);$CLJS.fG=new $CLJS.M(null,"var","var",-769682797);$CLJS.gG=new $CLJS.M(null,"next","next",-117701485);$CLJS.hG=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.iG=new $CLJS.M(null,"case","case",1143702196);
$CLJS.jG=new $CLJS.M(null,"not-empty","not-empty",388922063);$CLJS.kG=new $CLJS.M(null,"column-name","column-name",551523580);$CLJS.lG=new $CLJS.M(null,"percentile","percentile",-601188752);$CLJS.mG=new $CLJS.M(null,"not-null","not-null",-1326718535);$CLJS.nG=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.oG=new $CLJS.M(null,"breakout","breakout",-732419050);$CLJS.pG=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.qG=new $CLJS.M(null,"desc","desc",2093485764);
$CLJS.rG=new $CLJS.M(null,"starts-with","starts-with",366503009);$CLJS.sG=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);$CLJS.tG=new $CLJS.M(null,"short","short",1928760516);$CLJS.uG=new $CLJS.M(null,"avg","avg",197406200);$CLJS.vG=new $CLJS.M(null,"metric","metric",408798077);$CLJS.wG=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.xG=new $CLJS.M(null,"asc","asc",356854569);$CLJS.yG=new $CLJS.M(null,"floor","floor",1882041021);
$CLJS.zG=new $CLJS.M(null,"contains","contains",676899812);$CLJS.AG=new $CLJS.M(null,"field","field",-1302436500);$CLJS.BG=new $CLJS.M(null,"cum-count","cum-count",-1964431543);$CLJS.CG=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);$CLJS.DG=new $CLJS.M(null,"coalesce","coalesce",654622029);$CLJS.EG=new $CLJS.M("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.FG=new $CLJS.M(null,"round","round",2009433328);
$CLJS.GG=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);$CLJS.HG=new $CLJS.M(null,"share","share",-589433933);$CLJS.IG=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.JG=new $CLJS.M(null,"count-where","count-where",385407720);$CLJS.KG=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.LG=new $CLJS.M(null,"ends-with","ends-with",-456791011);$CLJS.MG=new $CLJS.M(null,"sum","sum",136986814);
$CLJS.NG=new $CLJS.M(null,"median","median",569566131);Lfa=new $CLJS.M("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);$CLJS.OG=new $CLJS.M(null,"stddev","stddev",-865474939);$CLJS.PG=new $CLJS.M(null,"trim","trim",774319767);$CLJS.QG=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);$CLJS.RG=new $CLJS.M(null,"ltrim","ltrim",1654269283);$CLJS.SG=new $CLJS.M(null,"category","category",-593092832);
$CLJS.TG=new $CLJS.M(null,"sum-where","sum-where",2135348428);$CLJS.UG=new $CLJS.M(null,"/","/",1282502798);$CLJS.VG=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);$CLJS.WG=new $CLJS.M(null,"log","log",-1595516004);$CLJS.XG=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.YG=new $CLJS.M(null,"rtrim","rtrim",-661336449);var PF=$CLJS.$e($CLJS.eh),UF;$CLJS.X($CLJS.UE,QF());$CLJS.BC(PF,Lfa,function(){return $CLJS.X($CLJS.UE,QF())});
$CLJS.cH=function(){function a(d,e,f,k){c.g?c.g(d,k):c.call(null,d,k);$CLJS.gF.m(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.Ch.g("mbql.clause",$CLJS.jh(d));$CLJS.X(f,e);$CLJS.Ld($CLJS.q(PF),d)||$CLJS.Rh.j(PF,$CLJS.ee,d);return null}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
UF=function UF(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return UF.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
UF.l=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,$CLJS.hg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Vi,new $CLJS.h(null,1,[$CLJS.Ht,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,a],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ul,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ME],null)],null)],null)],null),b)],null)};UF.A=1;
UF.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};$CLJS.SF=function SF(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return SF.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
$CLJS.SF.l=function(a,b){return $CLJS.hg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Li,new $CLJS.h(null,1,[$CLJS.Ht,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ME],null)],null),b)};$CLJS.SF.A=1;$CLJS.SF.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
$CLJS.RF=function RF(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return RF.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};$CLJS.RF.l=function(a,b,c){c=$CLJS.E.g($CLJS.A(c),$CLJS.rt)?$CLJS.he($CLJS.jd(c),$CLJS.ef(2,c)):$CLJS.he(null,c);var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);a=$CLJS.Ve.j(a,b,d);return $CLJS.n(c)?$CLJS.cH.v(b,$CLJS.rt,c,a):$CLJS.cH.g(b,a)};$CLJS.RF.A=2;
$CLJS.RF.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};