var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var JW,Mka,Nka,RW,Oka,Pka,Rka,NW,$la,Zla;JW=function(a){return a+1};$CLJS.KW=function(a){if("string"===typeof a){var b=$CLJS.qh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.nm(a));};
Mka=function(a){var b=new $CLJS.Fa,c=$CLJS.Tu;$CLJS.Tu=new $CLJS.sc(b);try{var d=$CLJS.Tu,e=$CLJS.Va($CLJS.aw(d)),f=$CLJS.Tu;$CLJS.Tu=e?$CLJS.Sv(d):d;try{d=$CLJS.cw;$CLJS.cw=!0;try{$CLJS.ew(a)}finally{$CLJS.cw=d}$CLJS.E.g(0,$CLJS.$u($CLJS.Tu,$CLJS.pu))||$CLJS.bc($CLJS.Tu,"\n");$CLJS.Zu()}finally{$CLJS.Tu=f}$CLJS.xh($CLJS.p.h(b))}finally{$CLJS.Tu=c}};$CLJS.LW=function(a,b){return $CLJS.Ve.j($CLJS.ha,a,b)};
$CLJS.MW=function(a){var b=$CLJS.Pv;$CLJS.Pv=120;try{var c=new $CLJS.Fa,d=$CLJS.Oa,e=$CLJS.Ra;$CLJS.Oa=!0;$CLJS.Ra=function(f){return c.append(f)};try{Mka(a)}finally{$CLJS.Ra=e,$CLJS.Oa=d}return $CLJS.p.h(c)}finally{$CLJS.Pv=b}};
Nka=function(a){a=$CLJS.O(a);var b=$CLJS.J.j(a,$CLJS.SV,$CLJS.Xd),c=$CLJS.J.j(a,$CLJS.DV,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.$e($CLJS.N),e=$CLJS.$e($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var z=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),C=$CLJS.q(d);return C.h?C.h(z):C.call(null,z)}();if($CLJS.n(v))return v;var x=$CLJS.J.g($CLJS.Rh.v(e,$CLJS.Xk,u,$CLJS.mE(JW,0)),u);if($CLJS.E.g(x,1))return $CLJS.Rh.v(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var z=t,C=$CLJS.p.h(x);return c.g?c.g(z,C):c.call(null,z,C)}()}}function k(m){var t=l,u=t.g;null==NW&&(NW=$CLJS.$e(0));var v=$CLJS.Ih.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Rh.g(NW,JW))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.OW=function(a){var b=$CLJS.Bd(a);return b?(b=$CLJS.A(a)instanceof $CLJS.M)?(a=$CLJS.J.g(a,1),$CLJS.zd(a)&&$CLJS.Ld(a,$CLJS.OE)):b:b};$CLJS.PW=function(a){return $CLJS.OW(a)?$CLJS.GE.h($CLJS.aF(a)):null};$CLJS.AW=function(a,b){return $CLJS.cF($CLJS.OW(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ij,new $CLJS.h(null,2,[$CLJS.OE,$CLJS.p.h($CLJS.dF()),$CLJS.Pi,$CLJS.hF(a)],null),a],null),function(c){return $CLJS.Lk.l($CLJS.R.j(c,$CLJS.GE,b),$CLJS.T,$CLJS.H([$CLJS.CE]))})};
RW=function(a,b,c){var d=$CLJS.Ld(a,b)?$CLJS.Xk.j(a,b,function(e){return $CLJS.Bd(e)&&$CLJS.E.g($CLJS.A(e),$CLJS.Ks)?$CLJS.Gf($CLJS.ef(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Ld(a,b)?$CLJS.QV(d,$CLJS.He([b,c])):d};Oka=function(a){var b=$CLJS.im(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sP,$CLJS.WQ],null));b=SW.h?SW.h(b):SW.call(null,b);return $CLJS.GW($CLJS.R.l(RW($CLJS.Lk.l(a,$CLJS.sP,$CLJS.H([$CLJS.WQ])),$CLJS.eS,$CLJS.JL),$CLJS.nu,$CLJS.BL,$CLJS.H([$CLJS.GL,b])))};
Pka=function(a){return $CLJS.Wk.g(Oka,a)};Rka=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Xk.j($CLJS.Ql(a)?new $CLJS.h(null,1,[$CLJS.BW,a],null):a,$CLJS.BW,function(b){return $CLJS.Wk.g(function(c){return $CLJS.R.j($CLJS.XA(c,$CLJS.xB),$CLJS.nu,$CLJS.YK)},b)}),$CLJS.nu,Zla):null};
$CLJS.UW=function(a){if($CLJS.E.g($CLJS.nu.h(a),$CLJS.vW))return a;var b=$CLJS.rj.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.ll.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.nu,$CLJS.vW,$CLJS.GL,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ll.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.nu,$CLJS.pW],null),$CLJS.QV($CLJS.xQ.h(a),new $CLJS.h(null,1,[$CLJS.WF,$CLJS.xQ],null))]))],null)],null),$CLJS.Lk.l(a,$CLJS.rj,$CLJS.H([$CLJS.xQ]))]));case "query":return $CLJS.ll.l($CLJS.H([new $CLJS.h(null,
2,[$CLJS.nu,$CLJS.vW,$CLJS.GL,SW($CLJS.WF.h(a))],null),$CLJS.Lk.l(a,$CLJS.rj,$CLJS.H([$CLJS.WF]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.VW=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.GL);var c=0>b?$CLJS.D(a)+b:b;if(c>=$CLJS.D(a)||0>c)throw $CLJS.li($CLJS.BE("Stage {0} does not exist",$CLJS.H([b])),new $CLJS.h(null,1,[$la,$CLJS.D(a)],null));return c};
$CLJS.WW=function(a,b){a=$CLJS.UW(a);a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.GL);return $CLJS.J.g($CLJS.Gf(c),$CLJS.VW(a,b))};$CLJS.XW=function(a){return"string"===typeof a&&(a=$CLJS.rh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.I(a,0,null),a=$CLJS.I(a,1,null),$CLJS.KW(a)):null};$CLJS.ZW=function(){return $CLJS.Uk.g(YW,Nka($CLJS.H([$CLJS.SV,$CLJS.tB,$CLJS.DV,function(a,b){return YW.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};NW=null;
$CLJS.CW=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.QW=new $CLJS.M("metabase.lib.util","cannot-remove-final-join-condition","metabase.lib.util/cannot-remove-final-join-condition",-470911224);$CLJS.TW=new $CLJS.M(null,"join","join",-758861890);$CLJS.$W=new $CLJS.M(null,"stage","stage",1843544772);$la=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.BW=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.aX=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);Zla=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.bX=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);var ama,bma,cma,dma,ema,SW,YW;ama=$CLJS.$e($CLJS.N);bma=$CLJS.$e($CLJS.N);cma=$CLJS.$e($CLJS.N);dma=$CLJS.$e($CLJS.N);ema=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));$CLJS.cX=new $CLJS.hi($CLJS.Ih.g("metabase.lib.util","custom-name-method"),$CLJS.yC,ema,ama,bma,cma,dma);$CLJS.cX.m(null,$CLJS.mi,function(a){return $CLJS.OW(a)?$CLJS.VA($CLJS.CE,$CLJS.GE)($CLJS.aF(a)):null});
SW=function SW(a){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.WQ),d=$CLJS.J.g(a,$CLJS.eP);c=$CLJS.n(c)?SW.h?SW.h(c):SW.call(null,c):$CLJS.Ff;d=Rka(d);var e=$CLJS.D(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.mM(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.BV],null),d):c;d=$CLJS.n($CLJS.xQ.h(a))?$CLJS.pW:$CLJS.iW;a=$CLJS.ll.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.nu,d],null),$CLJS.Lk.l(a,$CLJS.WQ,$CLJS.H([$CLJS.eP]))]));a=$CLJS.y($CLJS.RN.h(a))?$CLJS.Xk.j(a,$CLJS.RN,Pka):a;a=RW(a,$CLJS.dG,$CLJS.wW);return $CLJS.ee.g(c,
a)};
$CLJS.gX=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.UW(c);c=$CLJS.O(c);var k=$CLJS.J.g(c,$CLJS.GL);d=$CLJS.VW(c,d);e=$CLJS.Ve.N($CLJS.Xk,$CLJS.Gf(k),d,e,f);return $CLJS.R.j(c,$CLJS.GL,e)}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,e,f,c)};a.l=
b;return a}();
YW=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.EW.str(d,0)>>>0).toString(16);;)if(8>$CLJS.D(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.hX=RegExp(" id$","i");