var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var fF,kF,Ffa,Gfa,Hfa,Ifa,Jfa,Kfa;
$CLJS.dF=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.CA([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.eF=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.BE(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};fF=function(a){return $CLJS.Bd(a)&&$CLJS.A(a)instanceof $CLJS.M};
$CLJS.hF=function(a){var b=function(){var c=fF(a);return c?(c=$CLJS.zd($CLJS.jd(a)))?(c=$CLJS.Pi.h($CLJS.jd(a)),$CLJS.n(c)?c:$CLJS.KB.h($CLJS.jd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.gF.h(a)};
kF=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,2,[$CLJS.Ht,"valid MBQL clause",$CLJS.$t,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.Ij);return["invalid MBQL clause: ",$CLJS.Ph.l($CLJS.H([c]))].join("")}],null),$CLJS.We(fF)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.UE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Ht,b],null),function(c){c=$CLJS.hF(c);
return jF(c,a)}],null)],null)};$CLJS.VE=new $CLJS.M(null,"right-join","right-join",-56349359);Ffa=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);Gfa=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);Hfa=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.WE=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.XE=new $CLJS.M(null,"expr","expr",745722291);$CLJS.YE=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.ZE=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.lF=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.mF=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.nF=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.oF=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.pF=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);Ifa=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.qF=new $CLJS.M(null,"left-join","left-join",-672831855);
$CLJS.rF=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.sF=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.tF=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);Jfa=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);
Kfa=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.uF=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.vF=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.UE=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.wF=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.xF=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.yF=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.zF=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.gF=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.yC(f);return $CLJS.E.g(k,$CLJS.JB)?$CLJS.$a(f):k},e,a,b,c,d)}();
$CLJS.X(Gfa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gs,$CLJS.oF],null),$CLJS.QE],null));$CLJS.gF.m(null,$CLJS.mi,function(a){throw $CLJS.li($CLJS.BE("{0}: Don''t know how to determine the type of {1}",$CLJS.H([Jfa,$CLJS.Ph.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.XE,a],null));});$CLJS.gF.m(null,$CLJS.sF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.hF(a)});
var jF=function jF(a,b){return $CLJS.xd(a)?$CLJS.Ue(function(d){return jF.g?jF.g(d,b):jF.call(null,d,b)},a):$CLJS.xd(b)?$CLJS.Ue(function(d){return jF.g?jF.g(a,d):jF.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.oF)?!0:$CLJS.MC(a,b)};$CLJS.X($CLJS.mF,kF($CLJS.lk,"expression returning a boolean"));$CLJS.X($CLJS.wF,kF($CLJS.Ak,"expression returning a string"));$CLJS.X($CLJS.zF,kF($CLJS.Oj,"expression returning an integer"));$CLJS.X(Hfa,kF($CLJS.cE,"expression returning a non-integer real number"));
$CLJS.X($CLJS.yF,kF($CLJS.ok,"expression returning a number"));$CLJS.X(Ifa,kF($CLJS.no,"expression returning a date"));$CLJS.X(Kfa,kF($CLJS.yo,"expression returning a time"));$CLJS.X(Ffa,kF($CLJS.qo,"expression returning a date time"));$CLJS.X($CLJS.ZE,kF($CLJS.sk,"expression returning a date, time, or date time"));$CLJS.GF=new $CLJS.dh(null,new $CLJS.h(null,4,[$CLJS.ok,null,$CLJS.Ak,null,$CLJS.sk,null,$CLJS.lk,null],null),null);$CLJS.X($CLJS.tF,kF($CLJS.GF,"an expression that can be compared with :\x3e or :\x3c"));
var Tea=new $CLJS.dh(null,new $CLJS.h(null,8,[$CLJS.TC,null,$CLJS.ok,null,$CLJS.Ak,null,$CLJS.xD,null,$CLJS.sk,null,$CLJS.Wj,null,$CLJS.lk,null,$CLJS.aE,null],null),null);$CLJS.X($CLJS.vF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.As,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.wF],null),kF($CLJS.xD,"expression returning a BSONID")],null));$CLJS.X($CLJS.rF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,kF(Tea,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.X($CLJS.nF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,kF($CLJS.Wj,"any type of expression")],null));
$CLJS.X($CLJS.YE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tj,new $CLJS.h(null,1,[$CLJS.Wi,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GE,$CLJS.tk],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ls,$CLJS.ri],null)],null)],null)],null));