var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.cache.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var Tma,e0,Uma,g0,h0,j0,C0,uoa,soa,voa,eoa,yoa,F0,zoa,B0,Boa,H0;Tma=function(a){return $CLJS.y(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};e0=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Ld($CLJS.Rda,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
Uma=function(a){var b=$CLJS.O(new $CLJS.h(null,1,[$CLJS.MB,!0],null)),c=$CLJS.J.g(b,$CLJS.Nea),d=$CLJS.J.g(b,$CLJS.MB);return $CLJS.y(a)?(b=$CLJS.NV(function(){return function k(f){return new $CLJS.qe(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Cd(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.te(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v);x=e0(x,$CLJS.Va(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.we($CLJS.ye(u),k($CLJS.mc(l))):$CLJS.we($CLJS.ye(u),null)}u=$CLJS.A(l);return $CLJS.he(e0(u,
$CLJS.Va(d)),k($CLJS.Lc(l)))}return null}},null,null)}(Tma($CLJS.tB(a)))}()),$CLJS.n(c)?$CLJS.NV($CLJS.df(c,b)):b):null};$CLJS.f0=function(a){return $CLJS.sP.h($CLJS.A($CLJS.GL.h(a)))};g0=function(a,b){if(null!=a&&null!=a.zg)a=a.zg(a,b);else{var c=g0[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=g0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("MetadataProvider.table",a);}return a};
h0=function(a,b){if(null!=a&&null!=a.rg)a=a.rg(a,b);else{var c=h0[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=h0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("MetadataProvider.card",a);}return a};j0=function(a,b){if(null!=a&&null!=a.ug)a=a.ug(a,b);else{var c=j0[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=j0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("MetadataProvider.fields",a);}return a};
$CLJS.k0=function(a){return $CLJS.OZ(a)?a:$CLJS.lE.h(a)};$CLJS.l0=function(a,b){return g0($CLJS.k0(a),b)};$CLJS.m0=function(a,b){return j0($CLJS.k0(a),b)};$CLJS.n0=function(a,b){return h0($CLJS.k0(a),b)};
$CLJS.z0=function(a,b,c,d,e){var f=$CLJS.O(e);e=$CLJS.J.j(f,$CLJS.r_,$CLJS.y0);f=$CLJS.J.g(f,$CLJS.q_);if($CLJS.n($CLJS.n(f)?f:$CLJS.Xa(b)||$CLJS.zd(b))&&($CLJS.n(b.__mbcache)||(b.__mbcache=e.o?e.o():e.call(null)),b=b.__mbcache,$CLJS.n(b))){f=e.j?e.j(b,a,c):e.call(null,b,a,c);if($CLJS.n(f))return f;d=d.h?d.h(c):d.call(null,c);e.v?e.v(b,a,c,d):e.call(null,b,a,c,d);return d}return d.h?d.h(c):d.call(null,c)};
$CLJS.A0=function(a,b,c){return $CLJS.z0(a,b,b,c,new $CLJS.h(null,2,[$CLJS.r_,$CLJS.y0,$CLJS.q_,!1],null))};
C0=function(a,b,c){var d=$CLJS.p0.j(a,b,c);return $CLJS.ll.l($CLJS.H([$CLJS.im(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,$CLJS.CE,$CLJS.rk],null)),function(){var e=$CLJS.cX.h(c);return $CLJS.n(e)?new $CLJS.h(null,2,[$CLJS.CE,e,B0,!0],null):null}(),function(){var e=$CLJS.q0.v(a,b,c,$CLJS.D0);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.E0,e],null):null}(),function(){var e=$CLJS.VA($CLJS.Pi,$CLJS.KB)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Pi,e],null):null}(),function(){var e=$CLJS.oL.h(d);return $CLJS.n(e)?
(e=$CLJS.Id(e)?$CLJS.l0(a,e):"string"===typeof e?$CLJS.n0(a,$CLJS.XW(e)):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.un,$CLJS.t0.j(a,b,e)],null):null):null}(),function(){var e=$CLJS.$K.h(d);return $CLJS.n(e)?new $CLJS.h(null,6,[F0,$CLJS.E.g(e,$CLJS.IZ),$CLJS.G0,$CLJS.E.g(e,$CLJS.WZ),H0,$CLJS.E.g(e,$CLJS.TZ),$CLJS.I0,$CLJS.E.g(e,$CLJS.VZ),eoa,$CLJS.E.g(e,$CLJS.zL),soa,$CLJS.E.g(e,$CLJS.qL)],null):null}(),function(){var e=$CLJS.$Z.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.J0,e],null)}(),function(){var e=
$CLJS.VA($CLJS.CW,$CLJS.GG)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.K0,$CLJS.Ld($CLJS.NH,e)&&!$CLJS.Ld($CLJS.MH,e)],null):null}(),$CLJS.im(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.L0,$CLJS.M0,$CLJS.N0],null))]))};$CLJS.y0=function y0(a){switch(arguments.length){case 0:return y0.o();case 3:return y0.j(arguments[0],arguments[1],arguments[2]);case 4:return y0.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.y0.o=function(){return $CLJS.$e($CLJS.N)};$CLJS.y0.j=function(a,b){return $CLJS.J.g($CLJS.q(a),b)};$CLJS.y0.v=function(a,b,c,d){return $CLJS.Rh.v(a,$CLJS.R,b,d)};$CLJS.y0.A=4;$CLJS.q1=new $CLJS.M(null,"fk-join-alias","fk-join-alias",997510084);$CLJS.N0=new $CLJS.M(null,"filter-positions","filter-positions",378749375);$CLJS.toa=new $CLJS.M(null,"direction","direction",-633359395);$CLJS.I0=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);
$CLJS.L0=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.r1=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.J0=new $CLJS.M(null,"selected","selected",574897764);uoa=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);soa=new $CLJS.M(null,"is-breakout","is-breakout",-1878069983);
voa=new $CLJS.r("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);$CLJS.woa=new $CLJS.M("metabase.lib.metadata.calculation","source-field-id","metabase.lib.metadata.calculation/source-field-id",352667413);$CLJS.xoa=new $CLJS.M("metabase.lib.metadata.calculation","source-join-alias","metabase.lib.metadata.calculation/source-join-alias",-1432523829);eoa=new $CLJS.M(null,"is-aggregation","is-aggregation",872502629);
yoa=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.t1=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);F0=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);zoa=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);B0=new $CLJS.M(null,"named?","named?",-213401078);$CLJS.u1=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);
$CLJS.G0=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.K0=new $CLJS.M(null,"is-temporal-extraction","is-temporal-extraction",-1029608872);$CLJS.Aoa=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.M0=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);Boa=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.E0=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);
$CLJS.v1=new $CLJS.M(null,"include-implicitly-joinable-for-source-card?","include-implicitly-joinable-for-source-card?",1240062794);$CLJS.D0=new $CLJS.M(null,"long","long",-171452093);$CLJS.w1=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.x1=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);H0=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.O0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.yC(l)},e,a,b,c,d)}();
$CLJS.P0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.yC(l)},e,a,b,c,d)}();
$CLJS.q0=function(){function a(f,k,l,m){var t=$CLJS.VA($CLJS.CE,$CLJS.GE)($CLJS.aF(l));if($CLJS.n(t))return t;try{return $CLJS.O0.v(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.ki($CLJS.BE("Error calculating display name for {0}: {1}",$CLJS.H([$CLJS.Ph.l($CLJS.H([l])),$CLJS.JV(k)])),new $CLJS.h(null,2,[$CLJS.WF,f,$CLJS.PE,l],null),k);throw u;}}function b(f,k,l){return e.v?e.v(f,k,l,$CLJS.mi):e.call(null,f,k,l,$CLJS.mi)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
$CLJS.Q0=function(){function a(d,e,f){var k=$CLJS.T.h($CLJS.aF(f));if($CLJS.n(k))return k;try{return $CLJS.P0.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.ki($CLJS.BE("Error calculating column name for {0}: {1}",$CLJS.H([$CLJS.Ph.l($CLJS.H([f])),$CLJS.JV(k)])),new $CLJS.h(null,3,[$CLJS.PE,f,$CLJS.WF,d,$CLJS.TL,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.O0.m(null,$CLJS.mi,function(a,b,c){a=$CLJS.eB($CLJS.Xy);$CLJS.n($CLJS.dB("metabase.lib.metadata.calculation",a))&&$CLJS.cB("metabase.lib.metadata.calculation",a,$CLJS.LW("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.H([$CLJS.Ph.l($CLJS.H([c])),voa,$CLJS.yC(c)])),null);return $CLJS.Bd(c)&&$CLJS.A(c)instanceof $CLJS.M?$CLJS.jh($CLJS.A(c)):$CLJS.Ph.l($CLJS.H([c]))});
$CLJS.P0.m(null,$CLJS.mi,function(a,b,c){return Uma($CLJS.ZA($CLJS.q0.j(a,b,c),/[\(\)]/,""))});$CLJS.R0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.$ma=function(){function a(d,e,f){return $CLJS.R0.j(d,e,$CLJS.Ch.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.S0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.yC(l)},e,a,b,c,d)}();
$CLJS.T0=function(){function a(d,e,f){var k=$CLJS.aF(f);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.GG),m=$CLJS.Pi.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Ld($CLJS.NH,l):l)?$CLJS.Oj:null;if($CLJS.n(l))return l;k=$CLJS.KB.h(k);if($CLJS.n(k))return k;d=$CLJS.S0.j(d,e,f);return $CLJS.MC(d,$CLJS.Wj)?d:$CLJS.Wj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.S0.m(null,$CLJS.mi,function(a,b,c){return $CLJS.hF(c)});$CLJS.S0.m(null,$CLJS.sF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.T0.j(a,b,c)});$CLJS.S0.m(null,$CLJS.kJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);return"string"===typeof d?$CLJS.hF(c):$CLJS.T0.j(a,b,d)});
$CLJS.U0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.yC(l)},e,a,b,c,d)}();
$CLJS.U0.m(null,$CLJS.mi,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.nu,$CLJS.YK,$CLJS.KB,$CLJS.T0.j(a,b,c),$CLJS.T,$CLJS.Q0.j(a,b,c),$CLJS.CE,$CLJS.q0.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.ki($CLJS.BE("Error calculating metadata for {0}: {1}",$CLJS.H([$CLJS.Ph.l($CLJS.H([$CLJS.yC(c)])),$CLJS.JV(d)])),new $CLJS.h(null,3,[$CLJS.WF,a,$CLJS.TL,b,$CLJS.PE,c],null),d);}throw e;}});
$CLJS.p0=function(){function a(e,f,k){return $CLJS.U0.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.V0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.yC(l)},e,a,b,c,d)}();
$CLJS.X(yoa,new $CLJS.P(null,20,5,$CLJS.Q,[$CLJS.Dk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CE,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.tk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.E0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.tk],null),new $CLJS.P(null,3,5,$CLJS.Q,[B0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.un,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yoa],null)],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[F0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Rs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.G0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Rs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[H0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Rs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.I0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Rs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[zoa,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Rs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Boa,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Rs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[uoa,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ys,$CLJS.Rs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,
1,[$CLJS.Ts,!0],null),$CLJS.tk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kG,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.tk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bu,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.tk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.w1,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.tk],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aoa,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.J0,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mi,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),$CLJS.Rs],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.toa,new $CLJS.h(null,1,[$CLJS.Ts,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,$CLJS.xG,$CLJS.qG],null)],null)],null));
$CLJS.t0=function(){function a(d,e,f){return $CLJS.A0($CLJS.Ch.g("display-info",["stage-",$CLJS.p.h(e)].join("")),f,function(k){try{return $CLJS.V0.j(d,e,k)}catch(m){if(m instanceof Error){var l=m;throw $CLJS.ki($CLJS.BE("Error calculating display info for {0}: {1}",$CLJS.H([$CLJS.yC(k),$CLJS.JV(l)])),new $CLJS.h(null,3,[$CLJS.WF,d,$CLJS.TL,e,$CLJS.PE,k],null),l);}throw m;}})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.V0.m(null,$CLJS.mi,function(a,b,c){return C0(a,b,c)});$CLJS.V0.m(null,$CLJS.fL,function(a,b,c){return $CLJS.ll.l($CLJS.H([C0(a,b,c),new $CLJS.h(null,1,[zoa,$CLJS.E.g($CLJS.f0(a),$CLJS.tj.h(c))],null)]))});
$CLJS.W0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.yC(l)},e,a,b,c,d)}();$CLJS.W0.m(null,$CLJS.NB,function(){return $CLJS.Ff});
$CLJS.X0=function(){function a(f,k,l,m){m=$CLJS.ll.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.u1,$CLJS.ZW()],null),m]));return $CLJS.W0.v(f,k,l,m)}function b(f,k,l){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.WW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();$CLJS.Y0=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.yC(l)},e,a,b,c,d)}();$CLJS.Y0.m(null,$CLJS.NB,function(){return $CLJS.Ff});
$CLJS.Y0.m(null,$CLJS.mi,function(a,b,c,d){return $CLJS.W0.v(a,b,c,d)});
$CLJS.Z0=function(){function a(f,k,l,m){m=$CLJS.ll.l($CLJS.H([$CLJS.ll.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.u1,$CLJS.ZW()],null),new $CLJS.h(null,4,[$CLJS.t1,!0,$CLJS.x1,!0,$CLJS.r1,!0,$CLJS.v1,!0],null)])),m]));return $CLJS.Y0.v(f,k,l,m)}function b(f,k,l){return $CLJS.n(function(){var m=$CLJS.zd(l);if(m){m=$CLJS.nu.h(l);var t=new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.pW,null,$CLJS.iW,null],null),null);return t.h?t.h(m):t.call(null,m)}return m}())?$CLJS.A0($CLJS.Ch.h([$CLJS.p.h(k),"__visible-columns-no-opts"].join("")),
f,function(){return e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}):e.v?e.v(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.WW(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.v=a;return e}();
module.exports={display_name:$CLJS.q0};