var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var Zz=function(){},$z=function(a){return $CLJS.J.g($CLJS.Oy,a).value},oda=function(a){var b=$z(a);return $CLJS.n(function(){var c=$z($CLJS.bz);return $CLJS.Pk.g?$CLJS.Pk.g(c,b):$CLJS.Pk.call(null,c,b)}())?$CLJS.Dz:$CLJS.n(function(){var c=$z($CLJS.az);return $CLJS.Pk.g?$CLJS.Pk.g(c,b):$CLJS.Pk.call(null,c,b)}())?$CLJS.Qz:$CLJS.n(function(){var c=$z($CLJS.Zy);return $CLJS.Pk.g?$CLJS.Pk.g(c,b):$CLJS.Pk.call(null,c,b)}())?$CLJS.Jz:$CLJS.n(function(){var c=$z($CLJS.Ty);return $CLJS.Pk.g?
$CLJS.Pk.g(c,b):$CLJS.Pk.call(null,c,b)}())?$CLJS.Pz:$CLJS.n(function(){var c=$z($CLJS.$y);return $CLJS.Pk.g?$CLJS.Pk.g(c,b):$CLJS.Pk.call(null,c,b)}())?$CLJS.Rz:$CLJS.n(function(){var c=$z($CLJS.Wy);return $CLJS.Pk.g?$CLJS.Pk.g(c,b):$CLJS.Pk.call(null,c,b)}())?$CLJS.Mz:$CLJS.n(function(){var c=$z($CLJS.Jy);return $CLJS.Pk.g?$CLJS.Pk.g(c,b):$CLJS.Pk.call(null,c,b)}())?$CLJS.Nz:$CLJS.Oz},aA=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},bA=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.ee.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Yz,c))].join(""),$CLJS.H(["color:black"]))],null)},cA=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.ee.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Yz,$CLJS.Sz)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.Yz,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},pda=function(a){function b(c,d){return d>=c}a=$z(a);if(b($z($CLJS.bz),a))return"error";if(b($z($CLJS.az),a))return"warn";if(b($z($CLJS.Zy),a))return"info";b($z($CLJS.Ty),a);return"log"},dA=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.My),e=$CLJS.J.g(c,$CLJS.Bt);b=$CLJS.J.g(c,$CLJS.ao);e=pda(e);e=$CLJS.Da(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Ve.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},eA=function eA(a,b){for(;;){if($CLJS.E.g($CLJS.Bz,b))return bA(a,", ",$CLJS.Oz);if($CLJS.E.g($CLJS.Fz,b))return aA(a);if(b instanceof $CLJS.M)return bA(a,b,$CLJS.Jz);if(b instanceof $CLJS.r)return bA(a,b,$CLJS.Pz);if("string"===typeof b)return bA(a,$CLJS.Ph.l($CLJS.H([b])),$CLJS.Hz);if($CLJS.Cf(b)){var d=aA(function(){var f=a,k=$CLJS.Fb(b);return eA.g?eA.g(f,k):eA.call(null,f,k)}()),e=$CLJS.Gb(b);
return eA.g?eA.g(d,e):eA.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.Kg)return d=a,d=bA(d,"{",$CLJS.Tz),d=$CLJS.db(eA,d,$CLJS.jf($CLJS.Bz,b)),bA(d,"}",$CLJS.Tz);if($CLJS.zd(b))return d=a,d=bA(d,["#",$CLJS.p.h(function(){var f=$CLJS.$a(b),k=f.name;return $CLJS.ud(k)?$CLJS.Ph.l($CLJS.H([f])):k}())," "].join(""),$CLJS.Kz),d=bA(d,"{",$CLJS.Tz),d=$CLJS.db(eA,d,$CLJS.jf($CLJS.Bz,b)),bA(d,"}",$CLJS.Tz);if($CLJS.xd(b))return d=a,d=bA(d,"#{",$CLJS.Tz),d=$CLJS.db(eA,d,$CLJS.jf($CLJS.Fz,b)),bA(d,
"}",$CLJS.Tz);if($CLJS.Bd(b))return d=a,d=bA(d,"[",$CLJS.Tz),d=$CLJS.db(eA,d,$CLJS.jf($CLJS.Fz,b)),bA(d,"]",$CLJS.Tz);if(b instanceof $CLJS.Uf)d=bA(a,"#queue ",$CLJS.Kz),e=$CLJS.hg.g($CLJS.Ff,b),a=d,b=e;else{if($CLJS.Gd(b))return d=a,d=bA(d,"(",$CLJS.Kz),d=$CLJS.db(eA,d,$CLJS.jf($CLJS.Fz,b)),bA(d,")",$CLJS.Kz);if(null!=b?b.I&16384||$CLJS.Bc===b.$i||(b.I?0:$CLJS.Za(Zz,b)):$CLJS.Za(Zz,b))d=bA(a,"#atom ",$CLJS.Kz),e=$CLJS.q(b),a=d,b=e;else if($CLJS.ii(b))d=bA(a,"#uuid ",$CLJS.Kz),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Xa(b))d=bA(a,"#js ",$CLJS.Kz),e=$CLJS.db(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.Ch.h(m),$CLJS.Da(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ua(b))d=bA(a,"#js ",$CLJS.Kz),e=$CLJS.hg.g($CLJS.Ff,b),a=d,b=e;else return bA(a,$CLJS.Ph.l($CLJS.H([b])),$CLJS.Lz)}}};$CLJS.qda=dA(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Bt);var b=$CLJS.J.g(a,$CLJS.My),c=$CLJS.J.g(a,$CLJS.dj);$CLJS.J.g(a,$CLJS.ao);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.rda=dA(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Bt);a=$CLJS.J.g(b,$CLJS.My);var d=$CLJS.J.g(b,$CLJS.dj);$CLJS.J.g(b,$CLJS.ao);b=oda(c);d=eA(aA(cA(cA(cA(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Ff],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.he(a,d)});
$CLJS.sda=dA(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Bt);var b=$CLJS.J.g(a,$CLJS.My),c=$CLJS.J.g(a,$CLJS.dj);$CLJS.J.g(a,$CLJS.ao);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Ph.l($CLJS.H([c]))],null)});