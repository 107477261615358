var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.util.internal.time.js");require("./metabase.shared.util.internal.time_common.js");require("./metabase.util.js");
'use strict';var cna,dna,ena,X2,Y2,Z2,fna,Foa,gna,$2,a3,Goa;$CLJS.b1=function(a){var b=$CLJS.jd($CLJS.qh($CLJS.sh(["(.*?)",$CLJS.JI($CLJS.H([$CLJS.PI])),"$"].join("")),a));return $CLJS.n(b)?b:a};cna=function(a,b){return a.isSame(b,"day")};dna=function(a,b){return a.isSame(b,"month")};ena=function(a,b){return a.isSame(b,"year")};
X2=function(a){return $CLJS.Ue(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);var d=$CLJS.O(b);b=$CLJS.J.g(d,$CLJS.ik);d=$CLJS.J.g(d,$CLJS.v2);return $CLJS.n($CLJS.qh(b,a))?(b=$CLJS.fJ.parseZone(a,d,!0),$CLJS.n(b.isValid())?new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null):null):null},$CLJS.Eoa)};Y2=function(a){return a instanceof Date?$CLJS.fJ.utc(a):a};Z2=function(a,b,c){b="string"===typeof b?$CLJS.A(X2(b)):Y2(b);return("string"===typeof c?$CLJS.A(X2(c)):Y2(c)).diff(b,$CLJS.jh(a))};
fna=function(a,b){return Z2.j?Z2.j($CLJS.vn,a,b):Z2.call(null,$CLJS.vn,a,b)};Foa=function(a,b){if("string"===typeof a)return a;a=Y2(a);var c=function(){if($CLJS.MC(b,$CLJS.ro))return $CLJS.z1;if($CLJS.MC(b,$CLJS.yo))return $CLJS.y1;if($CLJS.MC(b,$CLJS.po))return $CLJS.w2;if($CLJS.MC(b,$CLJS.qo))return $CLJS.Y1;if($CLJS.MC(b,$CLJS.no))return $CLJS.Z1;throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}();return $CLJS.x2.h(new $CLJS.P(null,2,5,$CLJS.Q,[a,c],null))};
$CLJS.c1=function(a){if($CLJS.n($CLJS.fJ.isMoment(a)))return a;if("string"===typeof a)return(0,$CLJS.fJ)($CLJS.b1(a),gna);throw $CLJS.li("Unknown input to coerce-to-time; expecting a string",new $CLJS.h(null,1,[$CLJS.Ij,a],null));};gna=["HH:mm:ss.SSS[Z]","HH:mm:ss.SSS","HH:mm:ss","HH:mm"];$2=function $2(a,b){if("string"===typeof a){var d=X2(a);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);b=$2.g?$2.g(a,b):$2.call(null,a,b);return $CLJS.x2.h(new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null))}a=Y2(a);return a.startOf($CLJS.jh(b))};
a3=function a3(a,b,c){if("string"===typeof a){var e=X2(a);a=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);b=a3.j?a3.j(a,b,c):a3.call(null,a,b,c);return $CLJS.x2.h(new $CLJS.P(null,2,5,$CLJS.Q,[b,e],null))}a=Y2(a);return a.add(c,$CLJS.jh(b))};Goa=new $CLJS.M(null,"local","local",-1497766724);$CLJS.d1=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ve.g($CLJS.KI,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.e1=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ve.g($CLJS.a1,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.ina=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ve.g(cna,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.jna=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ve.g(dna,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.kna=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ve.g(ena,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.lna=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ve.g(fna,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.Hoa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ve.g(Z2,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.Ioa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ve.g($2,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.Joa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ve.g(a3,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.Koa=function(){function a(c){var d=null;if(0<arguments.length){d=0;for(var e=Array(arguments.length-0);d<e.length;)e[d]=arguments[d+0],++d;d=new $CLJS.w(e,0,null)}return b.call(this,d)}function b(c){return $CLJS.Ve.g(Foa,c)}a.A=0;a.B=function(c){c=$CLJS.y(c);return b(c)};a.l=b;return a}();
$CLJS.f1=function f1(a){switch(arguments.length){case 1:return f1.h(arguments[0]);case 2:return f1.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.shared.util.time.coerce_to_timestamp",$CLJS.f1);$CLJS.f1.h=function(a){return $CLJS.f1.g(a,$CLJS.N)};
$CLJS.f1.g=function(a,b){b=$CLJS.ll.l($CLJS.H([$CLJS.N,$CLJS.yB(b)]));if(!$CLJS.n($CLJS.$0(a))){var c=(c="string"===typeof a)?$CLJS.qh(/.*(Z|[+-]\d\d:?\d\d)$/,a):c;a=$CLJS.n(c)?$CLJS.fJ.parseZone(a):"string"===typeof a?$CLJS.LI.g(a,b):$CLJS.MI.g(a,b)}return $CLJS.n(Goa.h(b))?a.local():a};$CLJS.f1.A=2;module.exports={coerce_to_timestamp:$CLJS.f1,coerce_to_time:$CLJS.c1};