var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var l2,Qna,r2,s2,u2,Rna,Sna,Epa,Tna,Una,Wna,Gpa,Hpa,Fpa,Jpa,Kpa;l2=function(a,b){var c=$CLJS.Jd,d=k2;for(a=$CLJS.y(a);;)if(null!=a){d=$CLJS.J.j(d,$CLJS.A(a),c);if(c===d)return b;a=$CLJS.B(a)}else return d};
$CLJS.XY=function(a){return function(b){var c=$CLJS.cf(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
$CLJS.m2=function(a,b,c){return null==c||$CLJS.Ld(a,b)?a:$CLJS.R.j(a,b,c)};$CLJS.n2=function(a,b){return $CLJS.hg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,new $CLJS.h(null,1,[$CLJS.OE,$CLJS.p.h($CLJS.dF())],null)],null),$CLJS.lf.h($CLJS.IW),b)};$CLJS.ZY=function(a,b){return $CLJS.OW(a)&&$CLJS.E.g($CLJS.A(a),b)};$CLJS.o2=function(a,b){a=$CLJS.VW(a,b);return 0<a?a-1:null};
$CLJS.p2=function(a,b){if($CLJS.y(b)){if($CLJS.E.g($CLJS.D(b),1))return $CLJS.A(b);a=[" ",(0,$CLJS.na)(a)," "].join("");return $CLJS.E.g($CLJS.D(b),2)?[$CLJS.p.h($CLJS.A(b)),a,$CLJS.p.h($CLJS.jd(b))].join(""):[$CLJS.Xt(", ",$CLJS.Ut(b)),",",a,$CLJS.p.h($CLJS.kd(b))].join("")}return null};$CLJS.o3=function(a){return $CLJS.TV.h($CLJS.A($CLJS.GL.h(a)))};
$CLJS.q2=function(a,b,c,d){a=$CLJS.UW(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.WW(a,b);e=!($CLJS.y($CLJS.pG.h(e))||$CLJS.y($CLJS.oG.h(e)));c=$CLJS.gX.l(a,b,$CLJS.Xk,$CLJS.H([c,function(f){return $CLJS.ee.g($CLJS.Gf(f),$CLJS.IW.h(d))}]));return e?$CLJS.Xk.N((0,$CLJS.gX)(c,b,function(f){return $CLJS.kX($CLJS.Lk.l(f,$CLJS.cS,$CLJS.H([$CLJS.WE])),$CLJS.RN,function(k){return $CLJS.Wk.g(function(l){return $CLJS.Lk.g(l,$CLJS.WE)},k)})}),$CLJS.GL,$CLJS.Uk.g(function(f){return $CLJS.hg.g($CLJS.Ff,f)},$CLJS.al),0,
$CLJS.VW(a,b)+1):c};Qna=function(a,b){b=$CLJS.n0(a,b);return $CLJS.n(b)?$CLJS.X1(a,b):null};r2=function(a){return $CLJS.hg.j($CLJS.eh,$CLJS.Vk.h(function(b){return $CLJS.pe(b)&&$CLJS.Sk.g(b,$CLJS.nu)}),$CLJS.$g(a))};s2=function(a){return $CLJS.hg.j($CLJS.N,$CLJS.km(function(b,c){$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.OE);return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),$CLJS.pG.h(a))};
$CLJS.t2=function(a,b,c){var d=$CLJS.ll.l;b=$CLJS.Va($CLJS.o2(a,b))?function(){var f=$CLJS.o3(a);return $CLJS.n(f)?(f=Qna(a,f),$CLJS.n(f)?$CLJS.QH(function(k){return $CLJS.E.g($CLJS.tj.h(k),c)},f):null):null}():null;try{var e=$CLJS.K1(a,c)}catch(f){e=null}return d.call($CLJS.ll,$CLJS.H([b,e]))};
u2=function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null),d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.IP);d=$CLJS.J.g(d,$CLJS.SN);$CLJS.I(a,2,null);a=$CLJS.n(d)?$CLJS.E.g(d,$CLJS.nL.h(b)):d;return $CLJS.n(a)?a:$CLJS.E.g($CLJS.VA($CLJS.s_,$CLJS.m_)(b),c)};
Rna=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null),d=$CLJS.Ie($CLJS.of(function(e){var f=$CLJS.E.g($CLJS.ZZ.h(e),c);return f?u2(a,e):f},b));return $CLJS.n(d)?d:$CLJS.of(function(e){var f=$CLJS.E.g($CLJS.T.h(e),c);return f?u2(a,e):f},b)};
Sna=function(a,b,c){$CLJS.I(a,0,null);var d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.Ie($CLJS.of(function(k){var l=$CLJS.E.g($CLJS.tj.h(k),e);if(l){if(l=$CLJS.Va($CLJS.IP.h(d))){l=$CLJS.$K.h(k);var m=new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.b_,null,$CLJS.g_,null],null),null);l=m.h?m.h(l):m.call(null,l)}return $CLJS.n(l)?l:u2(a,k)}return l},b));if($CLJS.n(f))return f;b=$CLJS.n(c)?$CLJS.Ie($CLJS.of(function(k){return $CLJS.E.g($CLJS.tj.h(k),e)},b)):null;return $CLJS.n(b)?b:$CLJS.Ff};
Epa=function(a){var b=$CLJS.y2.g($CLJS.$K.h(a),$CLJS.TZ);return $CLJS.n(b)?b:$CLJS.GE.h(a)};
Tna=function(a,b){b=$CLJS.Ie($CLJS.Vk.g($CLJS.nL,b));if($CLJS.n(b))if($CLJS.Va($CLJS.B(b)))a=$CLJS.A(b);else{if($CLJS.n($CLJS.y2.g($CLJS.A(a),$CLJS.AG))){var c=$CLJS.Ie($CLJS.Vk.g(Epa,b));c=$CLJS.n(c)?$CLJS.B(c)?null:$CLJS.A(c):null}else c=null;a=$CLJS.n(c)?c:console.warn($CLJS.li("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.h(null,2,[$CLJS.W,a,$CLJS.BW,b],null)))}else a=null;return a};
Una=function(a,b){b=$CLJS.Ie($CLJS.Vk.g(function(c){var d=$CLJS.VA($CLJS.s_,$CLJS.m_)(c);$CLJS.n(d)?(c=$CLJS.$K.h(c),d=new $CLJS.dh(null,new $CLJS.h(null,1,[$CLJS.b_,null],null),null),c=d.h?d.h(c):d.call(null,c),c=$CLJS.Va(c)):c=d;return c},b));return $CLJS.n(b)?$CLJS.Va($CLJS.B(b))?$CLJS.A(b):Tna(a,b):null};
Wna=function(a,b){var c=$CLJS.aF(a);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.IP);return $CLJS.n(d)?(b=$CLJS.Ie($CLJS.of(function(e){return $CLJS.E.g($CLJS.VA($CLJS.s_,$CLJS.m_)(e),d)},b)),$CLJS.n(b)?$CLJS.Va($CLJS.B(b))?$CLJS.A(b):console.warn("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.h(null,2,[$CLJS.W,a,Fpa,b],null)):null):Una(a,b)};Gpa=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
Hpa=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.p3=new $CLJS.M(null,"generous?","generous?",1247836118);$CLJS.Ipa=new $CLJS.M(null,"matching-refs","matching-refs",324693611);Fpa=new $CLJS.M(null,"matches","matches",635497998);Jpa=new $CLJS.M(null,"left","left",-399115937);Kpa=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);$CLJS.y2=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.equality","\x3d"),function(f,k){f=$CLJS.yC(f);k=$CLJS.yC(k);return $CLJS.Sk.g(f,k)?Kpa:f},e,a,b,c,d)}();$CLJS.y2.m(null,Kpa,function(){return!1});
$CLJS.y2.m(null,$CLJS.GB,function(a,b){var c=r2(a),d=r2(b);return $CLJS.E.g(c,d)&&$CLJS.Te(function(e){return $CLJS.y2.g($CLJS.J.g(a,e),$CLJS.J.g(b,e))},c)});$CLJS.y2.m(null,$CLJS.EB,function(a,b){var c=$CLJS.E.g($CLJS.D(a),$CLJS.D(b));if(c)for(c=$CLJS.y(a),$CLJS.A(c),$CLJS.B(c),c=$CLJS.y(b),$CLJS.A(c),$CLJS.B(c),c=a,a=b;;){c=$CLJS.y(c);b=$CLJS.A(c);var d=$CLJS.B(c);c=b;b=d;a=$CLJS.y(a);d=$CLJS.A(a);a=$CLJS.B(a);c=$CLJS.y2.g(c,d);if($CLJS.n(c)){if(c=$CLJS.ud(b))return c;c=b}else return c}else return c});
var k2=null;$CLJS.y2.m(null,$CLJS.iW,function(a,b){var c=k2;k2=new $CLJS.h(null,2,[Jpa,s2(a),$CLJS.Kx,s2(b)],null);try{var d=$CLJS.ei($CLJS.y2,$CLJS.GB);return d.g?d.g(a,b):d.call(null,a,b)}finally{k2=c}});
$CLJS.y2.m(null,$CLJS.pG,function(a,b){var c=$CLJS.I(a,0,null),d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null),l=$CLJS.I(b,2,null);return(a=$CLJS.E.l(3,$CLJS.D(a),$CLJS.H([$CLJS.D(b)])))?(c=$CLJS.E.g(c,f))?(d=$CLJS.y2.g(d,k),$CLJS.n(d)?$CLJS.n(k2)?$CLJS.E.g(l2(new $CLJS.P(null,2,5,$CLJS.Q,[Jpa,e],null),Hpa),l2(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kx,l],null),Gpa)):$CLJS.E.g(e,l):d):c:a});
$CLJS.y2.m(null,$CLJS.mi,function(a,b){if($CLJS.zd(a)){var c=$CLJS.ei($CLJS.y2,$CLJS.GB);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.yd(a)?(c=$CLJS.ei($CLJS.y2,$CLJS.EB),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.E.g(a,b)});
$CLJS.z2=function(){function a(f,k,l,m,t){var u=$CLJS.OW(l)?l:$CLJS.HW(l),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null),z=$CLJS.I(u,2,null),C=e.j?e.j(u,m,t):e.call(null,u,m,t);if($CLJS.n(C))return C;C=$CLJS.n(function(){var K=$CLJS.y2.g(v,$CLJS.pG);return $CLJS.n(K)?$CLJS.OL.h(x):K}())?$CLJS.QH(function(K){var S=$CLJS.y2.g($CLJS.$K.h(K),$CLJS.zL);return $CLJS.n(S)?$CLJS.y2.g($CLJS.T.h(K),$CLJS.OL.h(x)):S},m):null;if($CLJS.n(C))return C;if($CLJS.n($CLJS.n(f)?"number"===typeof z:f)&&(m=$CLJS.Ie($CLJS.Vk.g($CLJS.tj,
m)),$CLJS.n(m))){var G=$CLJS.OW(l)?$CLJS.t2(f,k,z):l;if($CLJS.n(G))return f=$CLJS.cF($CLJS.R.j(u,2,function(){var K=$CLJS.ZZ.h(G);return $CLJS.n(K)?K:$CLJS.T.h(G)}()),$CLJS.Ye($CLJS.ll,new $CLJS.h(null,1,[$CLJS.KB,$CLJS.Wj],null))),e.j?e.j(f,m,t):e.call(null,f,m,t)}return null}function b(f,k,l,m){return e.N?e.N(f,k,l,m,$CLJS.N):e.call(null,f,k,l,m,$CLJS.N)}function c(f,k,l){var m=$CLJS.I(f,0,null);$CLJS.I(f,1,null);var t=$CLJS.I(f,2,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.p3);switch(m instanceof $CLJS.M?
m.T:null){case "aggregation":return $CLJS.QH(function(u){return $CLJS.E.g($CLJS.$K.h(u),$CLJS.zL)&&$CLJS.E.g($CLJS.c3.h(u),t)},k);case "expression":case "field":switch(k="string"===typeof t?Rna(f,k):Sna(f,k,l),$CLJS.D(k)){case 0:return null;case 1:return $CLJS.A(k);default:return Wna(f,k)}default:throw $CLJS.li("Unknown type of ref",new $CLJS.h(null,1,[$CLJS.W,f],null));}}function d(f,k){return e.j?e.j(f,k,$CLJS.N):e.call(null,f,k,$CLJS.N)}var e=null;e=function(f,k,l,m,t){switch(arguments.length){case 2:return d.call(this,
f,k);case 3:return c.call(this,f,k,l);case 4:return b.call(this,f,k,l,m);case 5:return a.call(this,f,k,l,m,t)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;e.v=b;e.N=a;return e}();
$CLJS.$na=function(){function a(d,e,f,k){return $CLJS.z2.v(d,e,$CLJS.MZ.j(d,e,f),k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();