var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./cljs.reader.js");
'use strict';var IA=function(a,b,c,d,e){this.ab=a;this.qa=b;this.K=c;this.Na=d;this.J=e;this.C=2565220111;this.I=0},FA=new $CLJS.M("tailrecursion.priority-map","not-found","tailrecursion.priority-map/not-found",-436727517);$CLJS.g=IA.prototype;$CLJS.g.na=function(a,b){return $CLJS.J.g(this.qa,b)};$CLJS.g.$=function(a,b,c){return $CLJS.J.j(this.qa,b,c)};$CLJS.g.da=function(a,b,c){return $CLJS.vh(b,function(d){return $CLJS.vh(b,$CLJS.Bh,""," ","",c,d)},"#tailrecursion.priority-map {",", ","}",c,this)};$CLJS.g.O=function(){return this.K};$CLJS.g.ha=function(){return $CLJS.D(this.qa)};
$CLJS.g.pc=function(){if(0===$CLJS.D(this.qa))return null;var a=$CLJS.A(this.ab),b=$CLJS.A($CLJS.Gb(a));return $CLJS.n(this.Na)?new $CLJS.P(null,2,5,$CLJS.Q,[b,this.qa.h?this.qa.h(b):this.qa.call(null,b)],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Fb(a)],null)};
$CLJS.g.qc=function(){if(0===$CLJS.D(this.qa))throw Error("Can't pop empty priority map");var a=$CLJS.A(this.ab),b=$CLJS.Gb(a),c=$CLJS.A(b);a=$CLJS.Fb(a);return $CLJS.E.g($CLJS.D(b),1)?new IA($CLJS.Lk.g(this.ab,a),$CLJS.Lk.g(this.qa,c),this.K,this.Na,null):new IA($CLJS.R.j(this.ab,a,$CLJS.Mk.g(b,c)),$CLJS.Lk.g(this.qa,c),this.K,this.Na,null)};
$CLJS.g.oc=function(){var a=this,b=this;return $CLJS.n(a.Na)?$CLJS.y(function(){return function e(d){return new $CLJS.qe(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.A(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,z,C,G,K,S){return function ka(ca){return new $CLJS.qe(null,function(){return function(){for(;;){var wa=$CLJS.y(ca);if(wa){if($CLJS.Cd(wa)){var Qa=$CLJS.lc(wa),ib=$CLJS.D(Qa),Ta=$CLJS.te(ib);a:for(var Db=0;;)if(Db<ib){var xb=$CLJS.md(Qa,Db);
xb=new $CLJS.P(null,2,5,$CLJS.Q,[xb,a.qa.h?a.qa.h(xb):a.qa.call(null,xb)],null);Ta.add(xb);Db+=1}else{Qa=!0;break a}return Qa?$CLJS.we($CLJS.ye(Ta),ka($CLJS.mc(wa))):$CLJS.we($CLJS.ye(Ta),null)}Ta=$CLJS.A(wa);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[Ta,a.qa.h?a.qa.h(Ta):a.qa.call(null,Ta)],null),ka($CLJS.Lc(wa)))}return null}}}(v,x,z,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.kf.g(k,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null}},null,null)}($CLJS.ac(a.ab))}()):$CLJS.y(function(){return function e(d){return new $CLJS.qe(null,
function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.A(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,z,C,G,K,S){return function ka(ca){return new $CLJS.qe(null,function(wa,Qa,ib){return function(){for(;;){var Ta=$CLJS.y(ca);if(Ta){if($CLJS.Cd(Ta)){var Db=$CLJS.lc(Ta),xb=$CLJS.D(Db),kb=$CLJS.te(xb);a:for(var mb=0;;)if(mb<xb){var Pa=$CLJS.md(Db,mb);kb.add(new $CLJS.P(null,2,5,$CLJS.Q,[Pa,ib],null));mb+=1}else{Db=!0;break a}return Db?$CLJS.we($CLJS.ye(kb),ka($CLJS.mc(Ta))):
$CLJS.we($CLJS.ye(kb),null)}kb=$CLJS.A(Ta);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[kb,ib],null),ka($CLJS.Lc(Ta)))}return null}}}(v,x,z,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.kf.g(k,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null}},null,null)}($CLJS.ac(a.ab))}())};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return $CLJS.Tb(this.qa,b)};$CLJS.g.oa=function(){return $CLJS.qd($CLJS.KA,this.K)};
$CLJS.g.Gb=function(a,b){a=this.qa.g?this.qa.g(b,FA):this.qa.call(null,b,FA);if($CLJS.E.g(a,FA))return this;a=this.Na.h?this.Na.h(a):this.Na.call(null,a);var c=this.ab.h?this.ab.h(a):this.ab.call(null,a);return $CLJS.E.g($CLJS.D(c),1)?new IA($CLJS.Lk.g(this.ab,a),$CLJS.Lk.g(this.qa,b),this.K,this.Na,null):new IA($CLJS.R.j(this.ab,a,$CLJS.Mk.g(c,b)),$CLJS.Lk.g(this.qa,b),this.K,this.Na,null)};
$CLJS.g.ma=function(a,b,c){var d=$CLJS.J.j(this.qa,b,null);if($CLJS.n(d)){if($CLJS.E.g(d,c))return this;a=this.Na.h?this.Na.h(c):this.Na.call(null,c);d=this.Na.h?this.Na.h(d):this.Na.call(null,d);var e=$CLJS.J.g(this.ab,d);return $CLJS.E.g($CLJS.D(e),1)?new IA($CLJS.R.j($CLJS.Lk.g(this.ab,d),a,$CLJS.ee.g($CLJS.J.j(this.ab,a,$CLJS.eh),b)),$CLJS.R.j(this.qa,b,c),this.K,this.Na,null):new IA($CLJS.R.l(this.ab,d,$CLJS.Mk.g($CLJS.J.g(this.ab,d),b),$CLJS.H([a,$CLJS.ee.g($CLJS.J.j(this.ab,a,$CLJS.eh),b)])),
$CLJS.R.j(this.qa,b,c),this.K,this.Na,null)}a=this.Na.h?this.Na.h(c):this.Na.call(null,c);return new IA($CLJS.R.j(this.ab,a,$CLJS.ee.g($CLJS.J.j(this.ab,a,$CLJS.eh),b)),$CLJS.R.j(this.qa,b,c),this.K,this.Na,null)};$CLJS.g.Va=function(a,b){return $CLJS.Ld(this.qa,b)};
$CLJS.g.ga=function(){var a=this,b=this;return $CLJS.n(a.Na)?$CLJS.y(function(){return function e(d){return new $CLJS.qe(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.A(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,z,C,G,K,S){return function ka(ca){return new $CLJS.qe(null,function(){return function(){for(;;){var wa=$CLJS.y(ca);if(wa){if($CLJS.Cd(wa)){var Qa=$CLJS.lc(wa),ib=$CLJS.D(Qa),Ta=$CLJS.te(ib);a:for(var Db=0;;)if(Db<ib){var xb=$CLJS.md(Qa,Db);
xb=new $CLJS.P(null,2,5,$CLJS.Q,[xb,a.qa.h?a.qa.h(xb):a.qa.call(null,xb)],null);Ta.add(xb);Db+=1}else{Qa=!0;break a}return Qa?$CLJS.we($CLJS.ye(Ta),ka($CLJS.mc(wa))):$CLJS.we($CLJS.ye(Ta),null)}Ta=$CLJS.A(wa);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[Ta,a.qa.h?a.qa.h(Ta):a.qa.call(null,Ta)],null),ka($CLJS.Lc(wa)))}return null}}}(v,x,z,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.kf.g(k,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null}},null,null)}(a.ab)}()):$CLJS.y(function(){return function e(d){return new $CLJS.qe(null,
function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.A(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,z,C,G,K,S){return function ka(ca){return new $CLJS.qe(null,function(wa,Qa,ib){return function(){for(;;){var Ta=$CLJS.y(ca);if(Ta){if($CLJS.Cd(Ta)){var Db=$CLJS.lc(Ta),xb=$CLJS.D(Db),kb=$CLJS.te(xb);a:for(var mb=0;;)if(mb<xb){var Pa=$CLJS.md(Db,mb);kb.add(new $CLJS.P(null,2,5,$CLJS.Q,[Pa,ib],null));mb+=1}else{Db=!0;break a}return Db?$CLJS.we($CLJS.ye(kb),ka($CLJS.mc(Ta))):
$CLJS.we($CLJS.ye(kb),null)}kb=$CLJS.A(Ta);return $CLJS.he(new $CLJS.P(null,2,5,$CLJS.Q,[kb,ib],null),ka($CLJS.Lc(Ta)))}return null}}}(v,x,z,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.kf.g(k,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null}},null,null)}(a.ab)}())};$CLJS.g.P=function(a,b){return new IA(this.ab,this.qa,b,this.Na,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.Bd(b)?this.ma(null,$CLJS.md(b,0),$CLJS.md(b,1)):$CLJS.db($CLJS.pb,this,b)};
$CLJS.g.call=function(a){switch(arguments.length-1){case 1:return this.h(arguments[1]);case 2:return this.g(arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length-1)].join(""));}};$CLJS.g.apply=function(a,b){return this.call.apply(this,[this].concat($CLJS.cb(b)))};$CLJS.g.h=function(a){return this.na(null,a)};$CLJS.g.g=function(a,b){return this.$(null,a,b)};
$CLJS.KA=new IA(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;a:for(b=$CLJS.y(0<b.length?new $CLJS.w(b.slice(0),0,null):null),d=$CLJS.jl;;)if(b)c=$CLJS.B($CLJS.B(b)),d=$CLJS.R.j(d,$CLJS.A(b),$CLJS.jd(b)),b=c;else break a;return d}(),$CLJS.N,$CLJS.N,$CLJS.Xd,null);$CLJS.J.g($CLJS.q($CLJS.HA),"tailrecursion.priority-map");
$CLJS.Rh.v($CLJS.HA,$CLJS.R,"tailrecursion.priority-map",function(a){if($CLJS.zd(a))return $CLJS.hg.g($CLJS.KA,a);throw Error("Priority map literal expects a map for its elements.");});