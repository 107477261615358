var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var I1,J1,Bpa,Cpa;$CLJS.F1=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Te(function(c){return $CLJS.Ld(b,c)},a)};$CLJS.G1=function(a,b){return $CLJS.bB($CLJS.We(a),b)};$CLJS.H1=function(a){var b;a:for(b=a;;)if($CLJS.zd(b))b=$CLJS.tj.h(b);else{b=$CLJS.Id(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.BE("Not something with an ID: {0}",$CLJS.H([$CLJS.Ph.l($CLJS.H([a]))])));};
I1=function(a){if(null!=a&&null!=a.sg)a=a.sg(a);else{var b=I1[$CLJS.xa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=I1._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.ab("MetadataProvider.database",a);}return a};J1=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=J1[$CLJS.xa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=J1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.ab("MetadataProvider.field",a);}return a};$CLJS.l3=function(a){return I1($CLJS.k0(a))};
$CLJS.K1=function(a,b){return J1($CLJS.k0(a),b)};$CLJS.Apa=function(a){var b=$CLJS.WW(a,0),c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.sP),e=$CLJS.J.g(c,$CLJS.TV);return $CLJS.Hd(function(){var f=$CLJS.l3(a);$CLJS.n(f)?(f=$CLJS.O(f),f=$CLJS.J.g(f,$CLJS.tj),f=$CLJS.E.g($CLJS.iQ.h(a),f)):f=null;if($CLJS.n(f)){f=$CLJS.n(d)?$CLJS.l0(a,d):d;if($CLJS.n(f))return f;f=$CLJS.n(e)?$CLJS.n0(a,e):e;return $CLJS.n(f)?f:$CLJS.E.g($CLJS.nu.h(c),$CLJS.pW)&&$CLJS.E.g($CLJS.o_,$CLJS.p_.h($CLJS.l3(a)))}return f}())};
Bpa=new $CLJS.M(null,"is-native","is-native",-1797717934);Cpa=new $CLJS.M(null,"is-editable","is-editable",269018020);$CLJS.E1.m(null,$CLJS.vW,function(a){return $CLJS.D1(a,new $CLJS.h(null,2,[$CLJS.rj,$CLJS.Ch,$CLJS.GL,$CLJS.Ye($CLJS.Wk,$CLJS.E1)],null))});$CLJS.U0.m(null,$CLJS.vW,function(){throw $CLJS.li("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.W0.m(null,$CLJS.vW,function(a,b,c,d){return $CLJS.X0.v(a,b,$CLJS.WW(c,b),d)});$CLJS.O0.m(null,$CLJS.vW,function(a,b,c,d){return $CLJS.q0.v(a,b,$CLJS.WW(c,b),d)});
$CLJS.V0.m(null,$CLJS.vW,function(a,b,c){a=$CLJS.WW(c,0);a=$CLJS.E.g($CLJS.nu.h(a),$CLJS.pW);return new $CLJS.h(null,2,[Bpa,a,Cpa,$CLJS.Apa(c)],null)});$CLJS.L1=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.nu.h($CLJS.WW(f,0))},e,a,b,c,d)}();$CLJS.L1.m(null,$CLJS.mi,function(){return!0});
$CLJS.M1=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.nu,$CLJS.vW,$CLJS.lE,$CLJS.k0(e),$CLJS.iQ,d,$CLJS.GL,f],null)}function b(d,e){var f=$CLJS.tj.h($CLJS.l3(d));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.N1=function(){var a=$CLJS.$e($CLJS.N),b=$CLJS.$e($CLJS.N),c=$CLJS.$e($CLJS.N),d=$CLJS.$e($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.AC],null),$CLJS.Bj,$CLJS.Yh.o?$CLJS.Yh.o():$CLJS.Yh.call(null));return new $CLJS.hi($CLJS.Ih.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.yC(k)},e,a,b,c,d)}();$CLJS.N1.m(null,$CLJS.GB,function(a,b){b=$CLJS.qZ.h(b);return $CLJS.M1.g(a,$CLJS.GL.h(b))});
$CLJS.N1.m(null,$CLJS.vW,function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.YY);var d=$CLJS.k0(a),e=$CLJS.Lk.g($CLJS.R.j(c,$CLJS.lE,d),$CLJS.YY);a=$CLJS.GL.h(e);return $CLJS.n(b)?$CLJS.R.j(e,$CLJS.GL,$CLJS.hg.j($CLJS.Ff,$CLJS.lf.h(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.Bd(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.ke(v,$CLJS.AG))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.zE($CLJS.zd,$CLJS.We($CLJS.VA($CLJS.KB,$CLJS.Pi)))(x)))try{var z=$CLJS.F(t,
2);if($CLJS.n($CLJS.zE($CLJS.Fl,$CLJS.Yl)(z))){var C=$CLJS.F(t,2),G=$CLJS.F(t,1),K=$CLJS.im($CLJS.K1(d,C),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KB,$CLJS.Pi],null));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,$CLJS.ll.l($CLJS.H([K,G])),C],null)}throw $CLJS.Y;}catch(ka){if(ka instanceof Error){var S=ka;if(S===$CLJS.Y)throw $CLJS.Y;throw S;}throw ka;}else throw $CLJS.Y;}catch(ka){if(ka instanceof Error){S=ka;if(S===$CLJS.Y)throw $CLJS.Y;throw S;}throw ka;}else throw $CLJS.Y;}catch(ka){if(ka instanceof
Error)if(S=ka,S===$CLJS.Y)try{if(v=$CLJS.F(t,0),$CLJS.ke(v,$CLJS.HB))try{x=$CLJS.F(t,1);if($CLJS.n($CLJS.zE($CLJS.zd,$CLJS.We($CLJS.VA($CLJS.KB,$CLJS.Pi)))(x))){G=$CLJS.F(t,1);var Z=$CLJS.F(t,2);K=function(){try{return $CLJS.G1(function(wa){return $CLJS.E.g($CLJS.Wj,wa)},$CLJS.im($CLJS.jd($CLJS.Fna.j(e,k,Z)),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KB,$CLJS.Pi],null)))}catch(wa){return null}}();return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HB,$CLJS.ll.l($CLJS.H([K,G])),Z],null)}throw $CLJS.Y;}catch(wa){if(wa instanceof
Error){var ca=wa;if(ca===$CLJS.Y)throw $CLJS.Y;throw ca;}throw wa;}else throw $CLJS.Y;}catch(wa){if(wa instanceof Error){ca=wa;if(ca===$CLJS.Y)throw $CLJS.Y;throw ca;}throw wa;}else throw S;else throw ka;}else throw $CLJS.Y;}catch(ka){if(ka instanceof Error){S=ka;if(S===$CLJS.Y)return $CLJS.pX(u,m,t);throw S;}throw ka;}}($CLJS.Ff,f)}),$CLJS.hm($CLJS.ft,a))):e});
$CLJS.N1.m(null,$CLJS.fL,function(a,b){return $CLJS.M1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.nu,$CLJS.iW,$CLJS.sP,$CLJS.H1(b)],null)],null))});$CLJS.N1.m(null,$CLJS.xL,function(a,b){return $CLJS.M1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.nu,$CLJS.iW,$CLJS.TV,$CLJS.H1(b)],null)],null))});