var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.util.js");require("./cljs.core.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var A5,oqa,pqa,qqa,C5,vva,E5,F5,rqa,sqa,I5,G5,H5,J5,f6,wva,xva,yva,zva,Ava,Bva,Cva,Dva,Eva,Fva,K5,tqa,uqa,vqa,L5,wqa,xqa,M5,yqa,Gva,Hva,Iva;A5=function(a,b){if(b=$CLJS.y(b)){var c=$CLJS.y(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.y(c)?(c=A5($CLJS.J.g(a,b),c),$CLJS.ud(c)?$CLJS.Lk.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.Lk.g(a,b)}return a};
oqa=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.y(k);k=$CLJS.A(u);u=$CLJS.B(u);var v=$CLJS.jm(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Gb(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.Ve.j(l,$CLJS.Gb(v),m)):f}(a,b,c,d)};pqa=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return oqa(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.w(b.slice(3),0,null):null)};$CLJS.B5=function(a){return $CLJS.OE.h($CLJS.aF(a))};
qqa=function(a,b,c,d){var e=$CLJS.E.g($CLJS.nQ,$CLJS.A(b))?$CLJS.AW(d,function(){var f=$CLJS.cX.h(d);return $CLJS.n(f)?f:$CLJS.PW(c)}()):d;return pqa(a,b,function(f){return $CLJS.Gf(function(){return function m(l){return new $CLJS.qe(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Cd(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.te(v);a:for(var z=0;;)if(z<v){var C=$CLJS.md(u,z);C=$CLJS.E.g($CLJS.B5(C),$CLJS.B5(c))?e:C;x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.we($CLJS.ye(x),m($CLJS.mc(t))):
$CLJS.we($CLJS.ye(x),null)}x=$CLJS.A(t);return $CLJS.he($CLJS.E.g($CLJS.B5(x),$CLJS.B5(c))?e:x,m($CLJS.Lc(t)))}return null}},null,null)}(f)}())})};
C5=function(a,b,c,d){var e=$CLJS.WA(a,b);if($CLJS.n(e)){var f=$CLJS.B5(c),k=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A(b),$CLJS.kd(b)],null);c=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);e=$CLJS.hg.j($CLJS.Ff,$CLJS.Vk.h($CLJS.Uk.g($CLJS.fh([f]),$CLJS.B5)),e);e=$CLJS.E.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WE],null))&&$CLJS.Te(function(l){return $CLJS.ZY(l,$CLJS.HB)},e)?null:e;if($CLJS.y(e))return $CLJS.mM(a,b,e);if($CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RN,$CLJS.JL],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,k],null)))throw $CLJS.li($CLJS.eF("Cannot remove the final join condition"),new $CLJS.h(null,5,[$CLJS.Vy,$CLJS.QW,$CLJS.JL,$CLJS.WA(a,b),$CLJS.TW,$CLJS.WA(a,$CLJS.td(b)),$CLJS.TL,d,$CLJS.$W,a],null));return $CLJS.E.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RN,$CLJS.WE],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,k],null))?$CLJS.NX.v(a,$CLJS.td(b),$CLJS.Lk,k):A5(a,b)}return a};$CLJS.D5=function(a,b){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.GL);b=0>b?$CLJS.D(a)+b:b;return b+1<$CLJS.D(a)?b+1:null};
$CLJS.uva=function(a,b){a=$CLJS.gX.l(a,b,$CLJS.Lk,$CLJS.H([$CLJS.WE]));return $CLJS.hg.j($CLJS.Ff,$CLJS.Vk.h($CLJS.Uk.g(new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.WZ,null,$CLJS.VZ,null],null),null),$CLJS.$K)),$CLJS.X0.j(a,b,$CLJS.WW(a,b)))};
vva=function(a,b,c,d){var e=$CLJS.y2.g($CLJS.D(c),$CLJS.D(d));return $CLJS.n(e)?(c=$CLJS.PH(function(f){return $CLJS.z2.v(a,b,f,d)},c),(e=!$CLJS.Ld(c,null))?(e=$CLJS.y2.g($CLJS.D(c),$CLJS.D(d)),$CLJS.n(e)?$CLJS.Te(function(f){return $CLJS.y2.g($CLJS.D(f),1)},$CLJS.bh(c)):e):e):e};
E5=function(a,b){var c=$CLJS.g4.g(a,b),d=$CLJS.oh(0,$CLJS.D(c));return $CLJS.kf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.cS],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.oG],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.wW],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.WE],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.pG],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.nQ],null)],null),function(){return function k(f){return new $CLJS.qe(null,function(){for(var l=f;;)if(l=$CLJS.y(l)){if($CLJS.Cd(l)){var m=
$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.te(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v),z=$CLJS.F(c,x);$CLJS.WE.h(z)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,x,$CLJS.WE],null));v+=1}else{m=!0;break a}return m?$CLJS.we($CLJS.ye(u),k($CLJS.mc(l))):$CLJS.we($CLJS.ye(u),null)}u=$CLJS.A(l);m=$CLJS.F(c,u);if($CLJS.WE.h(m)instanceof $CLJS.M)l=$CLJS.Lc(l);else return $CLJS.he(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,u,$CLJS.WE],null),k($CLJS.Lc(l)))}else return null},null,null)}(d)}(),$CLJS.H([function(){return function k(f){return new $CLJS.qe(null,
function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Cd(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.te(t);a:for(var v=0;;)if(v<t){var x=$CLJS.md(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,x,$CLJS.JL],null));v+=1}else{m=!0;break a}return m?$CLJS.we($CLJS.ye(u),k($CLJS.mc(l))):$CLJS.we($CLJS.ye(u),null)}u=$CLJS.A(l);return $CLJS.he(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,u,$CLJS.JL],null),k($CLJS.Lc(l)))}return null}},null,null)}(d)}()]))};
F5=function(a,b,c){var d=$CLJS.I(c,0,null),e=$CLJS.I(c,1,null);e=$CLJS.O(e);var f=$CLJS.J.g(e,$CLJS.GG),k=$CLJS.J.g(e,$CLJS.IR),l=$CLJS.I(c,2,null);return $CLJS.A($CLJS.QH(function(m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);$CLJS.I(m,0,null);$CLJS.I(m,1,null);m=$CLJS.I(m,2,null);return $CLJS.E.g($CLJS.A(m),d)&&$CLJS.E.g($CLJS.GG.h($CLJS.jd(m)),f)&&$CLJS.E.g($CLJS.IR.h($CLJS.jd(m)),k)&&$CLJS.E.g($CLJS.kd(m),l)},$CLJS.hm($CLJS.ft,$CLJS.cS.h($CLJS.WW(a,b)))))};
rqa=function(a,b,c,d){c=F5(a,b,c);return $CLJS.n(c)?$CLJS.gX.l(a,b,$CLJS.NX,$CLJS.H([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.cS,c,2,1],null),$CLJS.Uk.g(function(e){return $CLJS.G1($CLJS.El,e)},$CLJS.ll),d])):a};sqa=function(a,b,c){c=F5(a,b,c);return $CLJS.n(c)?$CLJS.gX.l(a,b,C5,$CLJS.H([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.cS],null),$CLJS.WA($CLJS.WW(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cS,c],null)),b])):a};
I5=function(a,b,c,d,e,f){var k=$CLJS.gX.l(a,b,f,$CLJS.H([d,e])),l=$CLJS.B5(e);return $CLJS.Sk.g(a,k)?$CLJS.A($CLJS.y($CLJS.of($CLJS.Wa,function(m,t){try{if($CLJS.Bd(t)&&1===$CLJS.D(t))try{var u=$CLJS.F(t,0);if($CLJS.ke(u,$CLJS.nQ))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.PW(e);K=G5.W?G5.W(k,b,c,$CLJS.HB,$CLJS.N,K):G5.call(null,k,b,c,$CLJS.HB,$CLJS.N,K);return H5.v?H5.v(K,b,c,l):H5.call(null,K,b,c,l)}()],null);throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{u=
$CLJS.F(t,0);if($CLJS.ke(u,$CLJS.pG))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var S=G5.W?G5.W(k,b,c,$CLJS.pG,$CLJS.N,l):G5.call(null,k,b,c,$CLJS.pG,$CLJS.N,l);return H5.v?H5.v(S,b,c,l):H5.call(null,S,b,c,l)}()],null);throw $CLJS.Y;}catch(S){if(S instanceof Error){var v=S;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw S;}else throw m;else throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{if($CLJS.Bd(t)&&1===$CLJS.D(t))try{var x=$CLJS.F(t,0);if($CLJS.ke(x,$CLJS.oG))return new $CLJS.P(null,
1,5,$CLJS.Q,[H5.v?H5.v(k,b,c,l):H5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{x=$CLJS.F(t,0);if($CLJS.ke(x,$CLJS.WE))return new $CLJS.P(null,1,5,$CLJS.Q,[H5.v?H5.v(k,b,c,l):H5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){var z=Z;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw Z;}else throw v;else throw S;}else throw $CLJS.Y;}catch(S){if(S instanceof Error)if(v=S,v===$CLJS.Y)try{if($CLJS.Bd(t)&&3===$CLJS.D(t))try{var C=$CLJS.F(t,
0);if($CLJS.ke(C,$CLJS.RN))try{var G=$CLJS.F(t,2);if($CLJS.ke(G,$CLJS.WE))return new $CLJS.P(null,1,5,$CLJS.Q,[H5.v?H5.v(k,b,c,l):H5.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){z=Z;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){z=Z;if(z===$CLJS.Y)throw $CLJS.Y;throw z;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){z=Z;if(z===$CLJS.Y)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw z;}throw Z;}else throw v;
else throw S;}else throw m;else throw K;}}($CLJS.Ff,d)))):k};
G5=function(a,b,c,d,e,f){var k=$CLJS.WW(a,b),l=$CLJS.mf(function(t){var u=$CLJS.WA(k,t);return $CLJS.n(u)?$CLJS.gm(function(v){return $CLJS.A($CLJS.y($CLJS.of($CLJS.Wa,function G(z,C){try{if($CLJS.Bd(C)&&3===$CLJS.D(C))try{var K=$CLJS.F(C,0);if($CLJS.E.g(K,d))try{var S=$CLJS.F(C,1);if($CLJS.ud(e)||$CLJS.F1($CLJS.ih(e),$CLJS.ih(S)))try{var Z=$CLJS.F(C,2);if($CLJS.E.g(Z,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[t,v],null)],null);throw $CLJS.Y;}catch(ka){if(ka instanceof Error){var ca=
ka;if(ca===$CLJS.Y)throw $CLJS.Y;throw ca;}throw ka;}else throw $CLJS.Y;}catch(ka){if(ka instanceof Error){ca=ka;if(ca===$CLJS.Y)throw $CLJS.Y;throw ca;}throw ka;}else throw $CLJS.Y;}catch(ka){if(ka instanceof Error){ca=ka;if(ca===$CLJS.Y)throw $CLJS.Y;throw ca;}throw ka;}else throw $CLJS.Y;}catch(ka){if(ka instanceof Error){ca=ka;if(ca===$CLJS.Y)return $CLJS.RV(G,z,C);throw ca;}throw ka;}}($CLJS.Ff,v))))},u):null},$CLJS.H([E5(a,b)])),m=$CLJS.cf($CLJS.ec($CLJS.Ff));a=$CLJS.db(function(t,u){var v=
$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);return I5(t,b,c,v,u,function(x,z,C){try{return C5(x,z,C,b)}catch(K){if(K instanceof Error){z=K;var G=$CLJS.O($CLJS.lm(z));C=$CLJS.J.g(G,$CLJS.Vy);G=$CLJS.J.g(G,$CLJS.TW);if($CLJS.E.g(C,$CLJS.QW))return m.Dd(null,$CLJS.Je(m.Qb(null),G)),x;throw z;}throw K;}})},a,l);return $CLJS.db(function(t,u){return $CLJS.N5.j?$CLJS.N5.j(t,b,u):$CLJS.N5.call(null,t,b,u)},a,$CLJS.gc($CLJS.q(m)))};
H5=function(a,b,c,d){b=$CLJS.D5(c,b);if($CLJS.n(b)){var e=$CLJS.WW(c,b);c=$CLJS.Ue(function(f){f=$CLJS.O(f);var k=$CLJS.J.g(f,$CLJS.$K),l=$CLJS.J.g(f,$CLJS.c3);return $CLJS.E.g($CLJS.IZ,k)&&$CLJS.E.g(d,l)?$CLJS.ZZ.h(f):null},$CLJS.Z0.j(c,b,e));return $CLJS.n(c)?G5(a,b,a,$CLJS.AG,$CLJS.N,c):a}return a};
J5=function(a,b,c,d,e){var f=$CLJS.IW.h(c),k=$CLJS.WW(a,b);c=$CLJS.QH(function(t){var u=$CLJS.WA(k,t);if($CLJS.n(u)){var v=$CLJS.B5(f);return $CLJS.n($CLJS.Ue($CLJS.Uk.j($CLJS.fh([v]),$CLJS.OE,$CLJS.jd),u))?t:null}return null},E5(a,b));var l=(d=$CLJS.E.g($CLJS.XG,d))?$CLJS.IW.h(e):null;e=d?function(t,u,v){return qqa(t,u,v,l)}:function(t,u,v){return C5(t,u,v,b)};var m=$CLJS.E.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.oG],null),c);a=d&&m&&$CLJS.E.g($CLJS.A(f),$CLJS.A(l))&&$CLJS.E.g($CLJS.kd(f),$CLJS.kd(l))?
rqa(a,b,f,$CLJS.im($CLJS.jd(l),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IR,$CLJS.GG],null))):m?sqa(a,b,f):a;return $CLJS.n(c)?(a=I5(a,b,a,c,f,e),$CLJS.e6.h?$CLJS.e6.h(a):$CLJS.e6.call(null,a)):a};f6=function(a){return $CLJS.bF(a,$CLJS.R,$CLJS.H([$CLJS.OE,$CLJS.p.h($CLJS.dF())]))};
wva=function(a,b,c){return function(d){return function l(f,k){try{if($CLJS.Bd(k)&&3===$CLJS.D(k))try{var m=$CLJS.F(k,0);if($CLJS.ke(m,$CLJS.HB))try{var t=$CLJS.F(k,2);if($CLJS.E.g(t,b))return f6(c);throw $CLJS.Y;}catch(v){if(v instanceof Error){var u=v;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw v;}else throw $CLJS.Y;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw v;}else throw $CLJS.Y;}catch(v){if(v instanceof Error){u=v;if(u===$CLJS.Y)return $CLJS.pX(l,f,k);throw u;
}throw v;}}($CLJS.Ff,d)}(a)};xva=function(a,b,c){var d=function(){var l=$CLJS.PW(c);return $CLJS.n(l)?l:$CLJS.T.h($CLJS.aF(c))}(),e=f6($CLJS.AW(c,d));a=$CLJS.Xk.j(a,$CLJS.nQ,function(l){return $CLJS.Wk.g(function(m){return $CLJS.E.g(m,b)?e:m},l)});var f=$CLJS.PW(b),k=$CLJS.Pi.h($CLJS.aF(c));return wva(a,f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HB,new $CLJS.h(null,1,[$CLJS.Pi,k],null),d],null))};
yva=function(a,b,c){return $CLJS.LV(function(d){return $CLJS.E.g(d,$CLJS.B5(b))?$CLJS.B5(c):d},$CLJS.n($CLJS.PW(b))?xva(a,b,c):$CLJS.LV(function(d){return $CLJS.E.g(d,b)?c:d},a))};zva=function(a,b,c){a=$CLJS.X0.j(a,b,$CLJS.WW(a,b));b=$CLJS.X0.j(c,b,$CLJS.WW(c,b));return $CLJS.of(function(d){return $CLJS.Sk.g($CLJS.A(d),$CLJS.jd(d))},$CLJS.lf.j($CLJS.ft,a,b))};
Ava=function(a,b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);var e=$CLJS.ZZ.h(d),f=$CLJS.HW($CLJS.R.j(c,$CLJS.$K,$CLJS.IZ));return $CLJS.lf.g(function(k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,f6(f)],null)},$CLJS.y($CLJS.of($CLJS.Wa,function t(l,m){try{if($CLJS.Bd(m)&&3===$CLJS.D(m))try{var u=$CLJS.F(m,0);if($CLJS.ke(u,$CLJS.AG))try{var v=$CLJS.F(m,2);if($CLJS.E.g(v,e))return new $CLJS.P(null,1,5,$CLJS.Q,[m],null);throw $CLJS.Y;}catch(z){if(z instanceof Error){var x=z;if(x===$CLJS.Y)throw $CLJS.Y;
throw x;}throw z;}else throw $CLJS.Y;}catch(z){if(z instanceof Error){x=z;if(x===$CLJS.Y)throw $CLJS.Y;throw x;}throw z;}else throw $CLJS.Y;}catch(z){if(z instanceof Error){x=z;if(x===$CLJS.Y)return $CLJS.RV(t,l,m);throw x;}throw z;}}($CLJS.Ff,$CLJS.WW(a,b)))))};Bva=function(a,b,c){var d=$CLJS.Pi.h($CLJS.aF(c));$CLJS.n(d)||(d=$CLJS.i3.h?$CLJS.i3.h(c):$CLJS.i3.call(null,c),d=$CLJS.Va(d)||!$CLJS.OW(c));if($CLJS.n(d))return c;a=$CLJS.T0.j(a,b,c);return $CLJS.bF(c,$CLJS.R,$CLJS.H([$CLJS.Pi,a]))};
Cva=function(a,b){a=$CLJS.PW(a);return $CLJS.n($CLJS.n(a)?$CLJS.Va($CLJS.PW(b))&&$CLJS.Va($CLJS.T.h($CLJS.aF(b))):a)?$CLJS.AW(b,a):b};
Dva=function(a,b,c,d){d=Bva(a,b,Cva(c,d));c=$CLJS.gX.l(a,b,yva,$CLJS.H([c,d]));for(var e=b;;){var f=$CLJS.D5(c,e);if($CLJS.n(f)){b=f;var k=$CLJS.mf(function(l,m,t){return function(u){return Ava(l,t,u)}}(c,e,b,f,a,d),$CLJS.H([zva(a,e,c)]));c=$CLJS.db(function(l,m,t,u){return function(v,x){var z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);return $CLJS.gX.l(v,u,yva,$CLJS.H([z,x]))}}(c,e,k,b,f,a,d),c,k);e=b}else return c}};
Eva=function(a,b){b=$CLJS.rl.h(b);if($CLJS.E.g($CLJS.A(b),$CLJS.GL)){var c=$CLJS.jd(b),d=$CLJS.B($CLJS.B(b)),e=function(){var f=$CLJS.B(d);return f?$CLJS.Ue(function(k){return $CLJS.Te($CLJS.Pl,$CLJS.lf.j($CLJS.E,k,d))?k:null},E5(a,c)):f}();return $CLJS.n(e)?$CLJS.al.j(b,0,$CLJS.D(e)+2+1):null}return null};
Fva=function(a,b,c,d){for(var e=Dva(a,b,c,d);;){var f=$CLJS.PY($CLJS.ZL,e),k=$CLJS.nl.h($CLJS.gm(function(l){return function(m){return Eva(l,m)}}(e,f),$CLJS.at.h(f)));if($CLJS.y(k))e=$CLJS.db(function(){return function(l,m){try{return $CLJS.O5.j(l,$CLJS.jd(m),$CLJS.WA(l,m))}catch(x){if(x instanceof Error){var t=x,u=$CLJS.O($CLJS.lm(t)),v=$CLJS.J.g(u,$CLJS.Vy);u=$CLJS.J.g(u,$CLJS.TW);if($CLJS.E.g(v,$CLJS.QW))return m=$CLJS.jd(m),$CLJS.N5.j?$CLJS.N5.j(l,m,u):$CLJS.N5.call(null,l,m,u);throw t;}throw x;
}}}(e,f,k),e,k);else return $CLJS.n(f)?J5(a,b,c,$CLJS.XG,d):e}};K5=function(a,b){return $CLJS.ZY(a,$CLJS.AG)&&$CLJS.E.g($CLJS.R1(a),b)};tqa=function(a,b,c){return function k(e,f){try{if(K5(f,b))return $CLJS.L3(f,c);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)return $CLJS.pX(k,e,f);throw l;}throw m;}}($CLJS.Ff,a)};
uqa=function(a,b,c){var d=$CLJS.RN.h(a),e=-1<b&&b<$CLJS.D(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.WA(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.lF],null))],null):null;b=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Sk.g(e,c):b)){var f=$CLJS.ZW();$CLJS.H3(f,$CLJS.lf.g($CLJS.lF,d));c=f(c);return tqa($CLJS.mM(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,b,$CLJS.lF],null),c),e,c)}return a};
vqa=function(a,b,c){if($CLJS.Id(c))return c;var d=function(){var e=$CLJS.fh([c]);return"string"===typeof c?$CLJS.Uk.g(e,$CLJS.lF):e}();return $CLJS.Ue(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.hm($CLJS.ft,$CLJS.RN.h($CLJS.WW(a,b))))};
L5=function(a,b,c,d){b=$CLJS.Bu.g($CLJS.ih($CLJS.Z0.j(b,c,$CLJS.WW(b,c))),$CLJS.ih($CLJS.Z0.j(a,c,$CLJS.WW(a,c))));return $CLJS.db(function(e,f){return $CLJS.Ve.N(G5,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};wqa=function(a,b,c){a=L5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,new $CLJS.h(null,1,[$CLJS.IP,$CLJS.s_.h(d)],null),$CLJS.tj.h(d)],null)});c=$CLJS.D5(a,c);return $CLJS.n(c)?L5(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AG,$CLJS.N,$CLJS.ZZ.h(d)],null)}):a};
xqa=function(a,b,c){return $CLJS.Id(c)?$CLJS.WA($CLJS.WW(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,c,$CLJS.lF],null)):$CLJS.zd(c)?$CLJS.lF.h(c):c};
M5=function(a,b,c,d){var e=xqa(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.gX)(a,b,function(k){var l=$CLJS.RN.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.$E(k,$CLJS.RN,l)});return(0,$CLJS.gX)(f,b,function(k){return $CLJS.kX(k,$CLJS.RN,function(l){return $CLJS.Wk.g(function(m){return $CLJS.T3(f,b,m)},l)})})}(),c=wqa(c,a,b),$CLJS.e6.h?$CLJS.e6.h(c):$CLJS.e6.call(null,c)):a};
yqa=function(a,b){return null!=$CLJS.A($CLJS.y($CLJS.of($CLJS.Wa,function f(d,e){try{if(K5(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Y)return $CLJS.RV(f,d,e);throw k;}throw l;}}($CLJS.Ff,a))))};Gva=function(a,b){var c=$CLJS.WE.h($CLJS.WW(a,b));if($CLJS.n(c)){var d=$CLJS.Va($CLJS.Ue($CLJS.Uk.g($CLJS.SN,$CLJS.aF),c));return d?vva(a,b,c,$CLJS.uva(a,b)):d}return c};
Hva=function(a,b){var c=$CLJS.WW(a,b),d=$CLJS.n(Gva(a,b))?$CLJS.gX.l(a,b,$CLJS.Lk,$CLJS.H([$CLJS.WE])):a;return $CLJS.n($CLJS.RN.h(c))?$CLJS.gX.l(d,b,$CLJS.Xk,$CLJS.H([$CLJS.RN,$CLJS.Ye($CLJS.Wk,function(e){var f=$CLJS.WE.h(e);var k=new $CLJS.dh(null,new $CLJS.h(null,2,[$CLJS.Hy,null,$CLJS.yA,null],null),null);f=k.h?k.h(f):k.call(null,f);return $CLJS.n(f)?e:$CLJS.n(vva(a,b,$CLJS.WE.h(e),$CLJS.X0.j(a,b,$CLJS.R.j(e,$CLJS.WE,$CLJS.Hy))))?$CLJS.R.j(e,$CLJS.WE,$CLJS.Hy):e})])):d};
$CLJS.e6=function(a){return $CLJS.db(Hva,a,$CLJS.oh(0,$CLJS.D($CLJS.GL.h(a))))};Iva=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.Jva=new $CLJS.M("lib","hide-bin-bucket?","lib/hide-bin-bucket?",-701033945);var g6;$CLJS.O5=function(){function a(d,e,f){return $CLJS.zd(f)&&$CLJS.E.g($CLJS.nu.h(f),$CLJS.BL)?$CLJS.N5.j?$CLJS.N5.j(d,e,f):$CLJS.N5.call(null,d,e,f):J5(d,e,f,Iva,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();g6=$CLJS.JF.h($CLJS.nF);
$CLJS.Q5=function(){function a(d,e,f,k){if($CLJS.zd(f)&&$CLJS.E.g($CLJS.nu.h(f),$CLJS.BL))d=$CLJS.P5.v?$CLJS.P5.v(d,e,f,k):$CLJS.P5.call(null,d,e,f,k);else{var l=g6.h?g6.h(f):g6.call(null,f);l=$CLJS.n(l)?g6.h?g6.h(k):g6.call(null,k):l;d=$CLJS.n(l)?Fva(d,e,f,k):J5(d,e,f,$CLJS.XG,k)}return d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+
arguments.length);};c.j=b;c.v=a;return c}();$CLJS.Aqa=function(){function a(d,e,f,k){f=vqa(d,e,f);return $CLJS.n(f)?$CLJS.gX.l(d,e,uqa,$CLJS.H([f,k])):d}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.N5=function(){function a(d,e,f){try{return M5(d,e,f,function(u,v){return $CLJS.Ie($CLJS.m1(function(x){return!($CLJS.E.g($CLJS.lF.h(x),v)||yqa(x,v))},u))})}catch(u){var k=u,l=$CLJS.O($CLJS.lm(k)),m=$CLJS.J.g(l,$CLJS.TL),t=$CLJS.J.g(l,$CLJS.Vy);l=$CLJS.J.g(l,$CLJS.TW);if($CLJS.E.g(t,$CLJS.QW))return d=c.j?c.j(d,m,l):c.call(null,d,m,l),c.j?c.j(d,e,f):c.call(null,d,e,f);throw k;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.P5=function(){function a(d,e,f,k){return null==k?$CLJS.N5.j(d,e,f):M5(d,e,f,function(l,m){return $CLJS.Wk.g(function(t){return $CLJS.E.g($CLJS.lF.h(t),m)?k:t},l)})}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();